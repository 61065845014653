import { useGetProjectTags } from '@/service-library/hooks/project-tags'
import ZerapixTable from '../zerapix-table/ZerapixTable'
import { ProjectTag } from '@/types/project-tags'
import useZerapixTable from '../zerapix-table/useZerapixTable'
import { useMemo } from 'react'
import { MRT_ColumnDef } from 'material-react-table'
import TagCount from './TagCount'
import { Box, Button } from '@mui/material'
import { useProjectContext } from '../project-tables/ProjectProvider'
import { Add } from '@mui/icons-material'
import TagActionsMenu from './TagActionsMenu'
import AddEditTagDialog from './AddEditTagDialog'
import useOverlay from '@/hooks/useOverlay'
import TagChip from '../tags/TagChip'

export default function TagsList() {
  const { project } = useProjectContext()
  const newTagOverlay = useOverlay()
  const { projectTags = [], queryKey } = useGetProjectTags({
    filters: {
      limit: '1000',
      project_id: project.id,
    },
  })

  const columns = useMemo<MRT_ColumnDef<ProjectTag>[]>(() => {
    return [
      {
        header: 'Tag',
        accessorKey: 'name',
        Cell: ({ row }) => {
          return (
            <Box sx={{ py: 1 }}>
              <TagChip tag={row.original} />
            </Box>
          )
        },
      },
      {
        header: 'Documents',
        Cell: ({ row }) => {
          return <TagCount projectTag={row.original} />
        },
      },
    ]
  }, [])

  const table = useZerapixTable({
    data: projectTags,
    columns,
    enableRowActions: true,
    title: 'Document Tags',
    muiTableBodyCellProps: {
      sx: {
        py: 1,
        px: 2,
      },
    },
    renderRowActions: ({ row }) => {
      return (
        <TagActionsMenu projectTag={row.original} listQueryKey={queryKey} />
      )
    },
    renderTableActions: () => {
      return (
        <Box>
          <Button
            variant="text"
            startIcon={<Add />}
            onClick={newTagOverlay.open}
          >
            New Tag
          </Button>
          <AddEditTagDialog overlay={newTagOverlay} listQueryKey={queryKey} />
        </Box>
      )
    },
  })

  return <ZerapixTable<ProjectTag> table={table} />
}

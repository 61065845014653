import {
  List,
  Box,
  Button,
  Card,
  Stack,
  Typography,
  ListSubheader,
} from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { WorkflowState } from '@/types/workflows'
import useOverlay from '@/hooks/useOverlay'
import queryKeys from '@/service-library/query-keys'
import { useGetProjectGridFields } from '@/service-library/hooks/project-grid-fields'
import { useUpdateWorkflowState } from '@/service-library/hooks/workflow-states'
import {
  getBaseGrid,
  getFieldsTree,
  sortBySortOrder,
} from '@/utils/field-utils'
import { showErrorSnackbar } from '@/utils/snackbars'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import FieldListItem from '@/components/project-settings/FieldListItem'
import SetStateFieldsDialog from './SetStateFieldsDialog'

type WorkflowStateRelevantFieldsProps = {
  workflowState: WorkflowState
  detailQueryKey: QueryKey
  excludedFieldsIds: string[]
}

export default function WorkflowStateRelevantFields({
  workflowState,
  detailQueryKey,
  excludedFieldsIds,
}: WorkflowStateRelevantFieldsProps) {
  const { project } = useProjectContext()
  const setFieldsOverlay = useOverlay()

  const { updateWorkflowState } = useUpdateWorkflowState({
    sideEffectQueryKeys: [
      queryKeys.workflows.lists(), // Update it in case they've already fetched it on other pages
    ],
    detailQueryKey,
    onError: () => {
      showErrorSnackbar(
        'Unable to update fields in workflow state. Please try again later.',
      )
    },
  })

  const { projectGridFields = [], isLoading } = useGetProjectGridFields({
    filters: {
      limit: '1000',
      project_id: project.id,
      fields__include: 'project_grid_field_type',
    },
  })

  const baseGrid = getBaseGrid(project.project_grids || [])
  const baseFields = sortBySortOrder(
    projectGridFields.filter(
      ({ project_grid_id }) => project_grid_id === baseGrid?.id,
    ),
  )

  const fieldInfos = getFieldsTree(baseFields, project.project_grids)
  const allFieldIds = projectGridFields.map((field) => field.id)

  return (
    <Stack spacing={1}>
      <Box>
        <Typography variant="h6">Relevant Fields</Typography>
        <Typography variant="caption" component="p" color="text.secondary">
          Determines which fields are shown on the validation screen when a
          document is in this workflow state.
        </Typography>
      </Box>
      <Box>
        <Button
          variant="text"
          disabled={isLoading}
          onClick={setFieldsOverlay.open}
        >
          Set Fields
        </Button>
      </Box>
      <SetStateFieldsDialog
        overlay={setFieldsOverlay}
        fieldInfos={fieldInfos}
        allFieldIds={allFieldIds}
        excludedFieldsIds={excludedFieldsIds}
        onSave={(excludedGridFieldsIds) => {
          updateWorkflowState({
            ...workflowState,
            excluded_project_grid_fields_ids: excludedGridFieldsIds,
          })
        }}
      />
      <Card>
        {!isLoading && excludedFieldsIds.length == projectGridFields.length ? (
          <Typography
            component="p"
            variant="caption"
            color="text.secondary"
            sx={{ px: 2, py: 1.5 }}
          >
            <i>No fields selected.</i>
          </Typography>
        ) : (
          <List dense>
            {fieldInfos.map((fieldInfo) => {
              if (excludedFieldsIds.includes(fieldInfo.field.id)) return null
              return !fieldInfo.fields ? (
                <FieldListItem
                  key={fieldInfo.field.id}
                  field={fieldInfo.field}
                />
              ) : (
                <List key={fieldInfo.field.id} dense sx={{ my: 1 }}>
                  <ListSubheader
                    sx={{
                      background: (theme) => theme.palette.background.paper,
                      zIndex: 2,
                    }}
                  >
                    {fieldInfo.field.name}
                  </ListSubheader>
                  {fieldInfo.fields.map((subField) => {
                    if (excludedFieldsIds.includes(subField.id)) return null
                    return (
                      <FieldListItem
                        key={subField.id}
                        field={subField}
                        indented
                      />
                    )
                  })}
                </List>
              )
            })}
          </List>
        )}
      </Card>
    </Stack>
  )
}

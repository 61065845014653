import { createContext, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Stack, CircularProgress } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { WorkflowState } from '@/types/workflows'
import useLocationHelpers from '@/hooks/useLocationHelpers'
import { useGetWorkflowState } from '@/service-library/hooks/workflow-states'
import ProjectBreadcrumb from '@/components/project-tables/ProjectBreadcrumb'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import WorkflowStateDetails from './WorkflowStateDetails'

export const WorkflowStateContext = createContext<WorkflowState | undefined>(
  undefined,
)

export const useWorkflowStatePageContext = () =>
  useContext(WorkflowStateContext)

type WorkflowStatePageProps = {
  workflowStateId: string
  workflowState?: WorkflowState
  listQueryKey: QueryKey
}

export default function WorkflowStatePage({
  workflowStateId,
  workflowState,
  listQueryKey,
}: WorkflowStatePageProps) {
  const navigate = useNavigate()
  const { selectedWorkflow } = useSelectedWorkflowContext()
  const { appendCurrentParamsToUrl } = useLocationHelpers()

  const {
    workflowState: currentState,
    isLoading,
    queryKey,
  } = useGetWorkflowState({
    id: workflowStateId,
    filters: {
      fields__include: 'excluded_project_grid_fields_ids',
    },
    initialData: workflowState,
    staleTime: 1000,
  })

  useEffect(() => {
    if (currentState && selectedWorkflow?.id !== currentState.workflow_id) {
      navigate(appendCurrentParamsToUrl('..'))
    }
  })

  return (
    <WorkflowStateContext.Provider value={currentState}>
      {currentState && (
        <ProjectBreadcrumb
          label={currentState?.name || ''}
          url={`../${currentState?.id}`}
        />
      )}

      {!isLoading && !currentState && (
        <Alert severity="error">
          Unable to load this workflow state. You may not have access or this
          workflow state does not exist.
        </Alert>
      )}

      {isLoading ? (
        <Stack alignItems="center" sx={{ p: 4 }}>
          <CircularProgress />
        </Stack>
      ) : (
        currentState && (
          <WorkflowStateDetails
            workflow={selectedWorkflow}
            workflowState={currentState}
            detailQueryKey={queryKey}
            listQueryKey={listQueryKey}
          />
        )
      )}
    </WorkflowStateContext.Provider>
  )
}

import { useDeferredValue, useEffect, useMemo, useState } from 'react'
import { Add } from '@mui/icons-material'
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  // MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { ProjectGridFieldType } from '@/types/fields'
// import { DocType } from '@/types/doc-categories'
import { InfoType } from '@/types/info-types'
import { ProjectGrid } from '@/types/projects'
import { OverlayState } from '@/hooks/useOverlay'
import { useGetInfoTypes } from '@/service-library/hooks/info-types'
import { useCreateProjectGridFieldWithDefaults } from '@/service-library/hooks/project-grid-fields'
import { useGetProjectGridFieldTypes } from '@/service-library/hooks/project-grid-field-types'
// import { useGetDocTypes } from '@/service-library/hooks/doc-types'
import getDataTypeIcon from '@/utils/get-data-type-icon'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import { useProjectGridsContext } from '@/components/project-settings/ProjectGridsProvider'

type CreateFieldDialogProps = {
  overlay: OverlayState
  projectGrid: ProjectGrid
  onCustomFieldClick: () => void
}

type GroupedInfoTypesProps = {
  infoTypes: InfoType[]
  fieldTypesMap: Record<string, ProjectGridFieldType>
  infoTypeIdsInUse: string[]
  handleAddField: (infoType: InfoType, fieldType: ProjectGridFieldType) => void
  subHeaderTitle?: string
  // docTypes?: DocType[]
}

function GroupedInfoTypes({
  infoTypes,
  fieldTypesMap,
  infoTypeIdsInUse,
  handleAddField,
  subHeaderTitle = 'Global',
}: // docTypes,
GroupedInfoTypesProps) {
  return (
    <>
      <ListSubheader
        sx={{
          background: (theme) => theme.palette.background.default,
        }}
      >
        {subHeaderTitle}
      </ListSubheader>
      {infoTypes.map((infoType) => {
        const fieldType = fieldTypesMap[infoType.preferred_field_type_id]
        if (!fieldType) return null
        const Icon = getDataTypeIcon(fieldType.code)
        const alreadyInUse = infoTypeIdsInUse.includes(infoType.id)

        // const infoTypeDocCategory = docTypes.find((docType) =>
        //   docType.namespaces_ids.includes(infoType.namespace_id),
        // )

        return (
          <ListItemButton
            key={infoType.id}
            sx={{ pl: 3 }}
            disabled={alreadyInUse}
            onClick={() => {
              handleAddField(infoType, fieldType)
            }}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>

            <ListItemText primary={infoType.name} secondary={fieldType.name} />

            {/* {infoTypeDocCategory && (
          <Typography variant="caption" color="text.secondary">
            {infoTypeDocCategory.name}
          </Typography>
        )} */}
          </ListItemButton>
        )
      })}

      {infoTypes.length === 0 && (
        <ListItem sx={{ pl: 5 }}>
          <Typography color="text.secondary" variant="caption">
            No results found
          </Typography>
        </ListItem>
      )}
    </>
  )
}

export default function CreateFieldDialog({
  overlay,
  projectGrid,
  onCustomFieldClick,
}: CreateFieldDialogProps) {
  const [searchValue, setSearchValue] = useState('')
  const deferredSearchValue = useDeferredValue(searchValue)
  // const [docTypeId, setDocTypeId] = useState<string | null>(null)
  const { rootOrganization } = useRootOrganization()

  const { projectGridFieldTypes } = useGetProjectGridFieldTypes({
    filters: {
      limit: '1000',
    },
    enabled: overlay.isOpen,
  })

  // const { docTypes = [] } = useGetDocTypes({
  //   filters: {
  //     limit: '1000',
  //   },
  //   enabled: overlay.isOpen,
  // })

  // const docCategory = docTypes.find((docType) => docType.id === docTypeId)

  // const searchEnabled =
  //   overlay.isOpen &&
  //   (docCategory ? docCategory?.namespaces_ids.length > 0 : true)
  const searchEnabled = overlay.isOpen

  const { infoTypes: globalInfoTypes, isLoading: globalInfoTypesIsLoading } =
    useGetInfoTypes({
      filters: {
        limit: '1000',
        namespace_id__isnull: 'true',
        name__icontains: deferredSearchValue,
      },
      keepPreviousData: true,
      // enabled: overlay.isOpen && !docTypeId
      enabled: overlay.isOpen,
    })

  const { infoTypes: orgInfoTypes, isLoading: orgInfoTypesIsLoading } =
    useGetInfoTypes({
      filters: {
        limit: '1000',
        namespace__root_org_id: rootOrganization.id,
        name__icontains: deferredSearchValue,
        // namespace_id__in: docCategory?.namespaces_ids?.join(),
      },
      keepPreviousData: true,
      enabled: searchEnabled,
    })

  const isLoading = globalInfoTypesIsLoading || orgInfoTypesIsLoading

  const { projectGrids, queryKey } = useProjectGridsContext()
  const projectGridFields = projectGrids.flatMap(
    (grid) => grid.project_grid_fields,
  )

  const fieldTypesMap = useMemo(
    () =>
      projectGridFieldTypes.reduce<Record<string, ProjectGridFieldType>>(
        (acc, fieldType) => {
          acc[fieldType.id] = fieldType
          return acc
        },
        {},
      ),
    [projectGridFieldTypes],
  )

  const infoTypesInUse = useMemo(() => {
    return projectGridFields.map((field) => field.info_type_id)
  }, [projectGridFields])

  const { createProjectGridField } = useCreateProjectGridFieldWithDefaults({
    sideEffectQueryKeys: [queryKey],
    onSuccess: () => {
      showSuccessSnackbar('Field Added')
    },
    onError: () => {
      showErrorSnackbar('Failed to add field. Please try again later.')
    },
  })

  function handleAddField(infoType: InfoType, fieldType: ProjectGridFieldType) {
    createProjectGridField({
      id: generateUuid(),
      project_grid_id: projectGrid.id,
      alignment: 'left',
      project_grid_field_type_id: fieldType.id,
      required: false,
      needed: 'optional',
      has_category_model: false, // TODO: Consider adjusting this, once we have global pickers.
      name: infoType.name,
      params: {}, // TODO: Once we have global pickers or live lists picker info types, we will need to adjust this
      sort_order: 1000001,
      input_behavior: 'manual_allowed' as const,
      is_hidden: false,
      is_user_editable: true,
      metadata: {},
      project_content_category_id: null,
      sub_project_grid_id: null,
      use_region_of_interest: false,
      region_of_interest_bottom: null,
      region_of_interest_left: null,
      region_of_interest_right: null,
      region_of_interest_top: null,
      region_of_interest_page: 1,
      contextual_type: null,
      user_can_change_data_type: true,
      user_can_delete: true,
      field_group_id: null,
      project_grid_field_type: fieldType, // This is just to pass the data for the on mutate update
      category_items: undefined, // Deprecated
      fields: fieldType.code === 'grid' ? [] : undefined,
      included_grid_fields_ids: [],
      included_grid_fields: [],
      project_grid_field_rules: [],
      aggregate_function: 'none',
      info_type_id: infoType.id,
    })
  }

  useEffect(() => {
    if (overlay.isOpen) {
      setSearchValue('')
    }
  }, [overlay.isOpen])

  return (
    <Dialog {...overlay} title="Add Field">
      <Stack spacing={2} sx={{ pl: 3, pr: 2, pb: 1 }}>
        <Typography variant="body2">
          Choose a field type to add. Try to be as specific as possible. If none
          of the existing field types meet your needs, you can create a custom
          field type.
        </Typography>

        <Box>
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              autoFocus
              name="search"
              label="Search"
              value={searchValue}
              onChange={({ target }) => setSearchValue(target.value)}
              sx={{ flexGrow: 2 }}
              disabled={isLoading}
            />
            {/* <Box sx={{ flexGrow: 1 }}>
              <TextField
                name="docCategory"
                select
                label="Document Type"
                value={docTypeId || 'all'}
                onChange={({ target }) => setDocTypeId(target.value)}
                variant="filled"
                size="small"
                fullWidth
              >
                <MenuItem value="all">All</MenuItem>
                {docTypes.map((docType) => (
                  <MenuItem key={docType.id} value={docType.id}>
                    {docType.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box> */}
          </Stack>
        </Box>
      </Stack>

      <DialogContent disablePadding={!isLoading}>
        {searchEnabled && isLoading && (
          <Stack spacing={1}>
            {Array.from({ length: 5 }).map((_, index) => {
              return (
                <Box key={index}>
                  <Skeleton height={20} width={200} />
                  <Skeleton height={20} width={125} />
                </Box>
              )
            })}
          </Stack>
        )}
        {!isLoading && (
          <List dense disablePadding sx={{ pb: 4, mt: -1 }}>
            {/* Global info types */}
            <GroupedInfoTypes
              infoTypes={globalInfoTypes}
              fieldTypesMap={fieldTypesMap}
              handleAddField={handleAddField}
              infoTypeIdsInUse={infoTypesInUse}
            />

            {/* Organization info types */}
            <GroupedInfoTypes
              infoTypes={orgInfoTypes}
              fieldTypesMap={fieldTypesMap}
              subHeaderTitle={rootOrganization.name}
              handleAddField={handleAddField}
              infoTypeIdsInUse={infoTypesInUse}
              // docTypes={docTypes}
            />
          </List>
        )}
      </DialogContent>

      <DialogFooter
        details={
          <Button
            startIcon={<Add />}
            onClick={onCustomFieldClick}
            variant="text"
          >
            Custom Field
          </Button>
        }
      >
        <Button onClick={overlay.close} variant="text">
          Cancel
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

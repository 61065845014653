import { Node } from '@xyflow/react'
import { getHeightOfNodeGroup } from './helpers'

/**
 * Distributes the given nodes vertically.
 */
export default function distributeNodesVertically({
  nodes,
  yGap = 0,
  startingY,
  alignment = 'center',
}: {
  nodes: Node[]
  yGap?: number
  startingY?: number
  alignment?: 'center' | 'top' | 'bottom'
}) {
  if (nodes.length === 0) return nodes
  if (nodes.some((node) => !node.measured)) return nodes
  startingY ??= nodes[0].position.y

  // Sort the nodes by their y position so we get them top to bottom
  const sortedNodes = nodes.sort((a, b) => a.position.y - b.position.y)

  // Update the position of each node to distribute them vertically
  let updatedNodes = sortedNodes.map((node, index) => {
    const previousNodesHeight = sortedNodes
      .slice(0, index)
      .reduce((acc, curr) => acc + (curr.measured?.height || 0), 0)
    const newY = startingY + previousNodesHeight + index * yGap
    return {
      ...node,
      position: {
        ...node.position,
        y: newY,
      },
    } as Node
  })

  // Alignments
  // 'center' - Center the nodes on the starting y position
  // 'top' - Start the nodes at the starting y position
  // 'bottom' - End the nodes at the starting y position

  // Note: We don't need to do anything extra to get the result we want for alignment 'bottom'

  if (alignment === 'center' || alignment === 'top') {
    const nodeGroupHeight = getHeightOfNodeGroup(updatedNodes)
    const yOffset =
      alignment === 'center' ? nodeGroupHeight / 2 : nodeGroupHeight
    updatedNodes = updatedNodes.map((node) => ({
      ...node,
      position: {
        ...node.position,
        y: node.position.y - yOffset,
      },
    }))
  }

  return updatedNodes
}

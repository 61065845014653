import { Box, Stack, Typography } from '@mui/material'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import PropertyField from '@/components/project-settings/params-schema-property-fields/PropertyField'
import CodeEditorButton from './CodeEditorButton'
import SetOrgByListMatchFields from './SetOrgByListMatchFields'
import { useWorkflowStatePageContext } from './WorkflowStatePage'
import TextToMatchFields from './TextToMatchFields'

type ActionParamFieldsProps = {
  action: WorkflowStateAction
}

export default function ActionParamFields({ action }: ActionParamFieldsProps) {
  const { action_type } = action
  const workflowState = useWorkflowStatePageContext()

  if (
    !workflowState ||
    (action_type && Object.keys(action_type?.params_schema).length === 0)
  )
    return null

  switch (action_type?.code) {
    case 'custom_python_script':
      return (
        <Box sx={{ my: 1 }}>
          <CodeEditorButton action={action} />
        </Box>
      )
    case 'set_organization_by_list_match':
      return <SetOrgByListMatchFields action={action} />
    case 'clear_chips_from_page':
      return (
        <Stack spacing={2} sx={{ mt: 1 }}>
          <Typography variant="caption">{action_type?.description}</Typography>
          <TextToMatchFields required />
        </Stack>
      )
  }

  const orderedProperties = Object.entries(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action_type?.params_schema.properties as Record<string, any>,
  ).toSorted((a, b) => a[1].sort_order - b[1].sort_order)

  const fields = orderedProperties.map(([propertyId, property]) => {
    const required = action_type?.params_schema.required?.includes(propertyId)
    return (
      <PropertyField
        key={`${action.id}-${propertyId}`}
        propertyId={propertyId}
        property={property}
        required={required}
        variant="outlined"
        dependencyValues={{
          current_workflow_id: workflowState.workflow_id,
          current_workflow_state_id: workflowState.id,
          current_event_id: action.event_id,
        }}
      />
    )
  })

  return fields.length > 0 ? (
    <Stack spacing={2} sx={{ my: 1, mt: 2, mr: 0.5 }}>
      {fields}
    </Stack>
  ) : null
}

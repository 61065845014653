import {
  Box,
  Button,
  Card,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { Document } from '@/types/documents'
import SplitDocumentImages from './SplitDocumentPages'
import SplitDocumentOptions from './SplitDocumentOptions'
import useSplitDocuments from './useSplitDocuments'
import {
  DensityMedium,
  FormatAlignJustify,
  HelpOutline,
} from '@mui/icons-material'
import useLocalStorage from '@/hooks/useLocalStorage'
import ChildDocumentsCard from './ChildDocumentsCard'

type SplitDocumentViewProps = {
  document: Document
  onDelete: () => void
}

const iconMap = {
  high: DensityMedium,
  low: FormatAlignJustify,
}

export default function SplitDocumentView({
  document,
  onDelete,
}: SplitDocumentViewProps) {
  const data = useSplitDocuments({
    document,
    onDelete,
  })

  const { splitPoints, createDocuments, resetStates, isLoading } = data

  const [density, setDensity] = useLocalStorage<'low' | 'high'>(
    'split-view-density',
    'low',
  )
  const DensityIcon = iconMap[density]

  return (
    <Stack
      sx={{
        height: '100%',
        background: (theme) =>
          theme.palette.mode === 'dark'
            ? undefined
            : theme.palette.background.default,
      }}
    >
      {document.page_count > 1 ? (
        <Stack sx={{ height: '100%', position: 'relative' }}>
          <Card
            component={Stack}
            direction="row"
            spacing={2}
            sx={{
              p: 1,
              pl: 2,
              position: 'absolute',
              zIndex: 2,
              top: 8,
              right: 8,
              width: 'fit-content',
              alignSelf: 'flex-end',
              flexShrink: 0,
            }}
            alignItems="center"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Tooltip
                title="Click between the page images to choose where to split the document.
          Each section will become a new document."
              >
                <HelpOutline />
              </Tooltip>
              <Tooltip title="Change Density">
                <IconButton
                  onClick={() => {
                    setDensity(density === 'low' ? 'high' : 'low')
                  }}
                >
                  <DensityIcon sx={{ transform: 'rotate(90deg)' }} />
                </IconButton>
              </Tooltip>
            </Stack>

            <SplitDocumentOptions
              pages={document.document_pages}
              splitDocumentsData={data}
            />

            <Button
              disabled={!splitPoints.length || isLoading}
              variant="contained"
              onClick={() => {
                createDocuments().then(() => {
                  resetStates()
                })
              }}
            >
              Confirm
            </Button>
          </Card>

          <ChildDocumentsCard document={document} isSplitting={isLoading} />

          <Stack justifyContent="center" flexGrow={1}>
            <SplitDocumentImages
              document={document}
              splitDocumentsData={data}
              isSplitView
              useFullImage={density === 'high'}
            />
          </Stack>
        </Stack>
      ) : (
        <Stack alignItems="center" spacing={3} sx={{ p: 2, margin: 'auto 0' }}>
          <Typography>This document only has one page.</Typography>
          <Box
            sx={{
              height: '70vh',
              textAlign: 'center',
            }}
          >
            <img
              loading="lazy"
              src={document.document_pages[0].image_urls.full}
              style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  )
}

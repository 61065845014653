import { useMemo } from 'react'
import { animated } from '@react-spring/web'
import { useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Bar } from '@nivo/bar'
import { MetricReportKind } from '@/types/workflow-metric-reports'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import { getNumberTicks } from '@/utils/chart-utils'
import { useContainerSize } from '@/components/size-provider/SizeProvider'
import { colorMap } from './OverallSTPMetric'

type MetricBarChartProps<T extends Record<string, MetricReportKind>> = {
  report?: T
  reportKey: keyof T
}

const STP_TICK_VALUES = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

function getIdleTime(value: number, isLabel = false) {
  let time = value / 60 / 60 / 24

  if (!isLabel) return `${time.toFixed(0)} days`

  let timeInHours = false
  if (isLabel && value < 2 * 24 * 60 * 60) {
    time = value / 60 / 60
    timeInHours = true
  }
  return timeInHours ? `${time.toFixed(1)} hrs` : `${time.toFixed(1)} days`
}

export default function MetricBarChart<
  T extends Record<string, MetricReportKind>,
>({ report, reportKey }: MetricBarChartProps<T>) {
  const theme = useTheme()
  const getThemeColor = useGetThemeColor()
  const { width } = useContainerSize()

  const isStp = reportKey === 'doc_stp_average'
  const isIdleTime = reportKey === 'doc_idle_average'

  const reports = report?.[reportKey].reports

  const adjustMarginForStp = useMemo(
    () => isStp && reports?.some((report) => report.avg_result >= 98),
    [isStp, reports],
  )

  const adjustMarginForIdleTime = useMemo(
    () =>
      isIdleTime &&
      reports?.some((report) => report.avg_result > 6.72 * 24 * 60 * 60),
    [isIdleTime, reports],
  )

  const adjustMarginForUserTime = useMemo(
    () =>
      !isStp &&
      !isIdleTime &&
      reports?.some((report) => report.avg_result > 2.9 * 60),
    [isIdleTime, isStp, reports],
  )

  const tickValues = useMemo(() => {
    if (isStp) {
      return STP_TICK_VALUES
    }
    const maxValue = Math.ceil(
      Math.max(
        isIdleTime ? 7 : 3,
        ...(reports || [])?.map((report) =>
          isIdleTime
            ? report.avg_result / 24 / 60 / 60
            : report.avg_result / 60,
        ),
      ),
    )
    return getNumberTicks(0, maxValue, 10, true).map((tick) =>
      isIdleTime ? tick * 24 * 60 * 60 : tick * 60,
    )
  }, [isStp, isIdleTime, reports])

  if (!reports) return null

  return (
    <Bar
      data={reports}
      enableGridY={false}
      indexBy="workflow_state__name"
      isInteractive={false}
      keys={['avg_result']}
      layout="horizontal"
      width={width - 40}
      height={reports.length * Math.max(30 - reports.length, 20)}
      padding={0.3}
      axisBottom={{
        format: (value) =>
          isStp
            ? `${value}%`
            : isIdleTime
            ? getIdleTime(value, false)
            : `${Math.ceil(value / 60)} min`,
        tickValues,
      }}
      maxValue={tickValues[tickValues.length - 1]}
      axisLeft={{
        truncateTickAt: 14,
        renderTick: (tick) => (
          <>
            {/* @ts-expect-error -- it is the right type */}
            <animated.g transform={tick.animatedProps.transform}>
              <rect
                width="3"
                height="11"
                transform="translate(-105,-5)"
                style={{
                  fill: getThemeColor(
                    reports[tick.tickIndex].workflow_state__color,
                  ),
                  fontSize: 11,
                }}
              />
              <text
                dominantBaseline={tick.textBaseline}
                transform="translate(-95,0)"
                style={{
                  fill: theme.palette.text.primary,
                  fontSize: 11,
                }}
              >
                {tick.value}
              </text>
            </animated.g>
          </>
        ),
      }}
      margin={{
        top: 0,
        bottom: 30,
        left: 110,
        right: isStp
          ? adjustMarginForStp
            ? 45
            : 20
          : adjustMarginForIdleTime || adjustMarginForUserTime
          ? 55
          : 18,
      }}
      label={(d) =>
        isStp
          ? `${(d.value || 0).toFixed(1)}%`
          : isIdleTime
          ? getIdleTime(d.value || 0, true)
          : `${((d.value || 0) / 60).toFixed(1)} min`
      }
      labelOffset={4}
      labelPosition="end"
      labelTextColor={theme.palette.text.primary}
      colors={({ data }) => {
        if (isStp) {
          return (
            colorMap.find(([threshold]) => data.avg_result >= threshold)?.[1] ||
            'red'
          )
        }
        return theme.palette.mode === 'dark'
          ? theme.palette.neutral.main
          : grey[400]
      }}
      theme={{
        axis: {
          ticks: {
            text: {
              fill: theme.palette.text.primary,
            },
          },
        },
      }}
    />
  )
}

import { useDocumentContext } from '@/components/image-zoom-pan/providers/DocumentProvider'
import LiveListPickerField from './LiveListPickerField'
import { useState } from 'react'
import { useGetOrganization } from '@/service-library/hooks/organizations'
import useGetEntrataVendors from '@/hooks/useGetEntrataVendors'
import { LiveListEntity } from '@/types/live-lists'
import { EntrataVendor } from '@/types/entrata-vendor'
import { useProjectGridFieldsContext } from '../ProjectGridFieldsProvider'
import useFieldValue from '../useFieldValue'

type EntrataLiveVendorListProps = {
  jsonValue: LiveListEntity<EntrataVendor>
  onManualChange: (
    newValue: string | null,
    refId: string | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jsonObject?: Record<string, any> | null,
  ) => void
  openOnFocus?: boolean
}

export default function EntrataLiveVendorList({
  jsonValue,
  onManualChange,
  openOnFocus = false,
}: EntrataLiveVendorListProps) {
  const { document } = useDocumentContext()

  const [open, setOpen] = useState(false)

  const [searchValue, setSearchValue] = useState(
    jsonValue?.entity.vendor_name || '',
  )

  const { organization, isFetched } = useGetOrganization({
    id: document?.owner_org_id || '',
    filters: {
      fields__include: 'org_type',
    },
    enabled: !!document,
  })
  const isPropertyOrg = organization?.org_type?.code === 'entrata_property'
  const clientOrgId = isPropertyOrg ? organization.parent_org_id || '' : ''
  const { entrataVendors, hasNextPage, fetchNextPage, isLoading } =
    useGetEntrataVendors({
      filters: {
        client_org_id: clientOrgId,
        vendor_name: searchValue,
        property_ids: organization?.code || '',
      },
      paginator: 'page',
      enabled: open && !!clientOrgId,
    })
  const options = entrataVendors.map((vendor) => ({
    entity_id: vendor.vendor_id.toString(),
    entity: vendor,
  }))

  const fields = useProjectGridFieldsContext()
  const vendorNameField = fields.find((field) => field.name === 'Vendor')
  const vendorRemitToField = fields.find(
    (field) => field.name === 'Vendor Remit To',
  )

  const { dataLineageValues } = useFieldValue({
    field: vendorNameField,
  })
  const vendorName = dataLineageValues.original_value
  const { value: vendorRemitTo } = useFieldValue({
    field: vendorRemitToField,
  })

  const suggestedSearches = [
    ['vendorName', vendorName],
    ['vendorRemitTo', vendorRemitTo],
  ].filter(([, suggestion]) => suggestion) as [string, string][]

  if (vendorRemitTo === vendorName) {
    suggestedSearches.pop()
  }

  return (
    <LiveListPickerField
      value={jsonValue as LiveListEntity<EntrataVendor>}
      label="Invoice Vendor"
      setDisableArrowKeys={() => false}
      onChange={onManualChange}
      openOnFocus={openOnFocus}
      options={options}
      open={open}
      setOpen={setOpen}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isLoading={isLoading}
      disabled={!clientOrgId}
      suggestedSearches={suggestedSearches}
      noOptionsText="No vendors found"
      helperText={
        isFetched && !clientOrgId
          ? 'Please set this document to a property before selecting a vendor.'
          : ''
      }
    />
  )
}

import { useCallback } from 'react'
import { IsValidConnection, useReactFlow } from 'reactflow'
import {
  getHandlesFromLogixNode,
  getHandleFromList,
} from '../helpers/logix-helpers'
import validInsertPairs from '../helpers/valid-insert-pairs'
import { useDataTypeInheritance } from '../InheritancesProvider'
import { DataType } from '../types'

export default function useIsValidConnection(): IsValidConnection {
  const reactFlowInstance = useReactFlow()
  const { inheritances } = useDataTypeInheritance()

  return useCallback(
    ({
      source: sourceNodeId,
      target: targetNodeId,
      sourceHandle: sourceHandleId,
      targetHandle: targetHandleId,
    }) => {
      if (
        !reactFlowInstance ||
        !sourceNodeId ||
        !targetNodeId ||
        !sourceHandleId ||
        !targetHandleId
      )
        return false

      const currentNodes = reactFlowInstance.getNodes()
      const sourceNode = reactFlowInstance.getNode(sourceNodeId)
      const targetNode = reactFlowInstance.getNode(targetNodeId)

      if (!sourceNode || !targetNode) return false

      const sourceNodeHandles = getHandlesFromLogixNode(
        sourceNode.data.logixNode,
      )
      const targetNodeHandles = getHandlesFromLogixNode(
        targetNode.data.logixNode,
      )

      const sourceHandle = getHandleFromList(
        sourceHandleId,
        sourceNodeHandles.outputs,
      )
      const targetHandle = getHandleFromList(
        targetHandleId,
        targetNodeHandles.inputs,
      )

      // Source handle must be an output on the source node.
      // Target handle must be an input on the target node.
      if (!sourceHandle || !targetHandle) {
        return false
      }

      // FIXME: Fix this - will not work with current LOGIX setup
      const sourceFullParentNode = sourceNode.parentNode
        ? currentNodes.find(({ id }) => id === sourceNode.parentNode)
        : undefined
      const targetFullParentNode = targetNode.parentNode
        ? currentNodes.find(({ id }) => id === targetNode.parentNode)
        : undefined

      // If it's a group node child connecting to anything or if a non-child node is connecting to a group node child, it's valid
      // FIXME: Verify this works
      const isGroupNodeException =
        (!sourceFullParentNode &&
          targetFullParentNode?.data.logixNode.node_type
            .allow_nested_parents) ||
        sourceFullParentNode?.data.logixNode.node_type.allow_nested_parents

      // EXECUTION LINES
      if (
        sourceHandle.data_type_code === 'execution' &&
        targetHandle.data_type_code === 'execution'
      ) {
        // Execution handles cannot connect to each other from different scopes (different iteration loops, for example),
        // unless it's connecting a child to its parent (e.g. "Exit Loop" inside Loop node) or from/to node in a group node
        const areInSameScope = targetNode.parentNode === sourceNode.parentNode

        // If it's from a parent's iteration handle to a child node, it's valid
        const isBetweenParentAndChild =
          (targetHandle.area === 'iteration' &&
            sourceNode.parentNode === targetNode.id) ||
          (sourceHandle.area === 'iteration' &&
            targetNode.parentNode === sourceNode.id)

        return areInSameScope || isBetweenParentAndChild || isGroupNodeException
      }

      const sourceDataTypeCode =
        inheritances[`${sourceHandle.data_type_match_key}${sourceNode.id}`] ||
        sourceHandle.data_type_code

      const targetDataTypeCode =
        (inheritances[
          `${targetHandle.data_type_match_key}${targetNode.id}`
        ] as DataType) || targetHandle.data_type_code

      // You can never connect any to any
      if (sourceDataTypeCode === 'any' && targetDataTypeCode === 'any')
        return false

      // They must both be an array or both not be an array
      if (!!sourceHandle.is_list !== !!targetHandle.is_list) return false

      // If the source comes from within a parent node, it must connect to another child node or to the parent node itself
      if (
        !isGroupNodeException &&
        sourceNode.parentNode &&
        // The source node parent is not the same as the target node parent
        sourceNode.parentNode !== targetNode.parentNode &&
        // The source node is not a child of the target node
        sourceNode.parentNode !== targetNode.id
      ) {
        return false
      }

      // If the source is an iteration handle, it has to connect to a
      // child node of its source node or connect to the source node itself
      if (
        sourceHandle.area === 'iteration' &&
        targetNode.parentNode !== sourceNode.id &&
        targetNode.id !== sourceNode.id
      ) {
        return false
      }

      // FIXME: Objects must have the same properties
      // if (
      //   sourceHandle.properties &&
      //   targetHandle.properties &&
      //   sourceHandle.properties.length > 0 &&
      //   targetHandle.properties.length > 0 &&
      //   !compareObjectProperties(
      //     sourceHandle.properties,
      //     targetHandle.properties,
      //   )
      // ) {
      //   return false
      // }

      return (
        // True if they are the same data type, or...
        sourceDataTypeCode === targetDataTypeCode ||
        // True if the input is any, or...
        targetDataTypeCode === 'any' || // True if we can create a node inbetween to make them work (e.g. a type conversion node)
        validInsertPairs[sourceDataTypeCode]?.[targetDataTypeCode]
      )
    },
    [inheritances, reactFlowInstance],
  )
}

import { Dispatch, SetStateAction, useState } from 'react'
import { Button, Stack, Skeleton, Box, Card } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { OrgCredential } from '@/types/credentials'
import { IntegrationType } from '@/types/integrations'
import { Organization } from '@/types/organizations'
import useOverlay, { OverlayState } from '@/hooks/useOverlay'
import { useCreateIntegration } from '@/service-library/hooks/integrations'
import { useGetIntegrationTypes } from '@/service-library/hooks/integration-types'
import generateUuid from '@/utils/generate-uuid'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import OrganizationPickerWithDialog from '@/components/organization-select/OrganizationPickerWithDialog'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import CredentialPickerDialog from './CredentialPickerDialog'
import IntegrationTypeCard from './IntegrationTypeCard'

type NewIntegrationDialogProps = {
  isFetchingOrgs: boolean
  listQueryKey: QueryKey
  organizations: Organization[]
  selectedOrganization: Organization
  setSelectedOrganization: Dispatch<SetStateAction<Organization>>
  overlay: OverlayState
}

export default function NewIntegrationDialog({
  isFetchingOrgs,
  listQueryKey,
  organizations,
  selectedOrganization,
  setSelectedOrganization,
  overlay,
}: NewIntegrationDialogProps) {
  const { project } = useProjectContext()
  const credentialPickerOverlay = useOverlay()

  const [selectedIntegrationType, setSelectedIntegrationType] =
    useState<IntegrationType | null>(null)

  const { integrationTypes, isLoading } = useGetIntegrationTypes({
    refetchOnWindowFocus: false,
  })

  const { createIntegration } = useCreateIntegration({
    listQueryKey,
    onError: (error) => {
      console.error('Error creating integration:', error)
    },
  })

  async function onAddIntegration(credential: OrgCredential) {
    if (!selectedIntegrationType) return
    createIntegration({
      id: generateUuid(),
      name: selectedIntegrationType.name,
      color: 'blue',
      external_id: null,
      image_url: null,
      is_enabled: true,
      org_id: selectedOrganization.id,
      project_id: project.id,
      metadata: {},
      org_credential_id: credential.id,
      integration_type_id: selectedIntegrationType.id,
    })
    credentialPickerOverlay.close()
    overlay.close()
  }

  return (
    <Dialog {...overlay} title="New Integration">
      <DialogContent>
        <Stack spacing={4}>
          <Box flexShrink={0} minWidth={300}>
            <Card>
              <OrganizationPickerWithDialog
                currentOrganization={selectedOrganization}
                organizations={organizations}
                rootTreeOrgId={project.org_id}
                isFetching={isFetchingOrgs}
                onSave={setSelectedOrganization}
              />
            </Card>
          </Box>

          <Stack spacing={1}>
            {isLoading && (
              <>
                <Skeleton width={200} />
                <Skeleton width={150} />
                <Skeleton width={275} />
              </>
            )}

            {!isLoading &&
              integrationTypes?.map((integrationType) => (
                <IntegrationTypeCard
                  key={integrationType.id}
                  integrationType={integrationType}
                  onAdd={(typeToAdd) => {
                    setSelectedIntegrationType(typeToAdd)
                    credentialPickerOverlay.open()
                  }}
                  selectedIntegrationType={selectedIntegrationType}
                />
              ))}
          </Stack>
        </Stack>

        {selectedIntegrationType && (
          <CredentialPickerDialog
            overlay={credentialPickerOverlay}
            integrationType={selectedIntegrationType}
            organization={selectedOrganization}
            onClose={() => {
              setSelectedIntegrationType(null)
            }}
            onConfirm={(credential) => {
              if (!selectedIntegrationType) return
              onAddIntegration(credential)
              setSelectedIntegrationType(null)
            }}
          />
        )}
      </DialogContent>
      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

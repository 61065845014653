import {
  useMemo,
  useRef,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
  useState,
} from 'react'
import {
  MRT_TableInstance,
  MRT_ColumnDef,
  MRT_Updater,
  MRT_ColumnOrderState,
  MRT_SortingState,
} from 'material-react-table'
import {
  Add,
  ContentCopy,
  Delete,
  Edit,
  SettingsApplications,
} from '@mui/icons-material'
import {
  Button,
  Typography,
  Box,
  Stack,
  Tooltip,
  IconButton,
  MenuItem,
  ListItemIcon,
  Divider,
} from '@mui/material'
import {
  DataList,
  DataListColumn,
  DataListEntry,
  DataListEntryCellValue,
} from '@/types/data-lists'
import useFetchMoreOnBottomReached from '@/hooks/useFetchMoreOnBottomReached'
import { OverlayState } from '@/hooks/useOverlay'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import getFormattedDateTimeString from '@/utils/getFormattedDateTimeString'
import {
  showErrorSnackbar,
  showInfoSnackbar,
  showSuccessSnackbar,
} from '@/utils/snackbars'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import useZerapixTable from '@/components/zerapix-table/useZerapixTable'
import copyToClipboard from '@/components/document-actions-menu/copy-to-clipboard'
import constructTableRows, { RowData } from './constructTableRows'
import EntryCellEditInput from './EntryCellEditInput'
import EntryMenu from './EntryMenu'
import { useDeleteDataList } from '@/service-library/hooks/data-lists'
import queryKeys from '@/service-library/query-keys'
import {
  useDeleteDataListColumn,
  useGetDataListColumns,
  useUpdateDataListColumns,
} from '@/service-library/hooks/data-list-columns'
import { sortBySortOrder } from '@/utils/field-utils'
import {
  useCreateDataListEntry,
  useGetDataListEntries,
} from '@/service-library/hooks/data-list-entries'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import generateUuid from '@/utils/generate-uuid'
import SyncHelper from './SyncHelper'

type UseDataListTableProps = {
  dataList: DataList
  parentDataList: DataList
  dataListColumnsQuery: ReturnType<typeof useGetDataListColumns>
  dataListEntriesQuery: ReturnType<typeof useGetDataListEntries>
  addEditColumnOverlay: OverlayState
  valueMappingsOverlay: OverlayState
  setSelectedColumnId: Dispatch<SetStateAction<string | undefined>>
  setMappingColumnsData: Dispatch<
    SetStateAction<{
      mainColumn: DataListColumn
      mappingColumns: DataListColumn[]
    }>
  >
  sorting: MRT_SortingState
  showOrgTree: boolean
  setShowOrgTree: Dispatch<SetStateAction<boolean>>
  setSorting: Dispatch<SetStateAction<MRT_SortingState>>
}

export default function useDataListTable({
  dataList,
  parentDataList,
  dataListColumnsQuery,
  dataListEntriesQuery,
  addEditColumnOverlay,
  valueMappingsOverlay,
  setMappingColumnsData,
  setSelectedColumnId,
  sorting,
  showOrgTree,
  setShowOrgTree,
  setSorting,
}: UseDataListTableProps) {
  const isSubOrgList = !!dataList.parent_data_list_id

  const [isDeletingSublist, setIsDeletingSublist] = useState(false)

  const {
    queryKey,
    dataListColumns = [],
    isLoading: columnsIsLoading,
    refetch,
  } = dataListColumnsQuery

  const { updateDataListColumns } = useUpdateDataListColumns({
    listQueryKey: queryKey,
    onIdle: () => {
      showSuccessSnackbar('Column order updated')
    },
    onError: () => {
      showErrorSnackbar('Unable to reorder columns. Please try again later.')
    },
  })

  const { deleteDataListColumn } = useDeleteDataListColumn({
    listQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar('Unable to delete column. Please try again later.')
    },
  })

  const {
    dataListEntries = [],
    hasNextPage,
    isLoading: entriesIsLoading,
    isFetching,
    isRefetching,
    fetchNextPage,
  } = dataListEntriesQuery

  const { deleteDataList } = useDeleteDataList({
    sideEffectQueryKeys: [queryKeys.dataLists.details()],
    onSuccess: () => {
      showSuccessSnackbar('List reverted to parent successfully.')
    },
  })

  const { createDataListEntry } = useCreateDataListEntry({
    listQueryKey: dataListEntriesQuery.queryKey,
    onError: () => {
      showErrorSnackbar('Unable to create new row. Please try again later.')
    },
  })

  const [demoMode] = useDemoModeContext()
  const isSuperUser = useIsSuperUser()

  const showRowActions =
    (isSuperUser && !demoMode) || parentDataList.is_entries_editable_in_ui

  const tableInstanceRef = useRef<MRT_TableInstance<RowData>>(null)
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const waitingForNewRowRef = useRef(false)

  const visibleDataListColumns = useMemo(() => {
    return sortBySortOrder(
      dataListColumns.filter(({ is_system_managed }) => !is_system_managed),
    ) as DataListColumn[]
  }, [dataListColumns])

  const rows = useMemo(() => {
    return constructTableRows(dataListEntries, dataListColumns)
  }, [dataListColumns, dataListEntries])

  const handleCreateNewRow = useCallback(() => {
    // Updating this ref tells us that once the row is put in the UI, we should focus on it.
    waitingForNewRowRef.current = true
    return createDataListEntry({
      id: generateUuid(),
      data_list_id: dataList.id,
      data_list_entry_cells: [],
      sort_order: dataListEntries.length,
    })
  }, [createDataListEntry, dataList.id, dataListEntries.length])

  const columns = useMemo<MRT_ColumnDef<RowData>[]>(() => {
    return visibleDataListColumns.map((dataListColumn) => {
      const {
        name,
        id,
        data_list_column_type,
        mapping_columns_ids = [],
      } = dataListColumn
      const { postgres_data_type } = data_list_column_type
      return {
        header: name,
        Header: () => {
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <div>{name}</div>
              {mapping_columns_ids.length > 0 && (
                <Tooltip title="Edit Value Mappings">
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={(event) => {
                      event.stopPropagation()
                      valueMappingsOverlay.open()
                      setMappingColumnsData({
                        mainColumn: dataListColumn,
                        mappingColumns: mapping_columns_ids
                          .map((id) => {
                            return dataListColumns.find(
                              (column) => column.id === id,
                            )
                          })
                          .filter(Boolean) as DataListColumn[],
                      })
                    }}
                  >
                    <SettingsApplications />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          )
        },
        id,
        // This is the key that the data has to match to have it be under this column
        accessorKey: `${id}.values`,
        Cell: ({ cell }) => {
          const values = cell.getValue<DataListEntryCellValue[]>()

          let displayedValue = ''
          if (
            postgres_data_type === 'date' ||
            postgres_data_type === 'datetime' ||
            postgres_data_type === 'time'
          ) {
            const firstValue = values[0]?.value
            // Format any date or time values so they are readable (they are stored in ISO format)
            // [SC-10730] - If the value is 'MM/DD/YYYY' then we don't want to display it (it displays as "Invalid Date")
            displayedValue =
              firstValue && firstValue !== 'MM/DD/YYYY'
                ? getFormattedDateTimeString(firstValue, postgres_data_type) ||
                  ''
                : ''
          } else {
            displayedValue = values.map(({ value }) => value).join(', ')
          }
          // Wrap with Typography so it will have our standard font size
          return <Typography noWrap>{displayedValue}</Typography>
        },
        Edit: (props) => (
          <Box m={-1} sx={{ flexGrow: 1 }}>
            {/* Box with negative margins to override the padding in the cell, so the edit will look gucci */}
            <EntryCellEditInput
              {...props}
              name="cell"
              columnId={id}
              dataListColumnType={data_list_column_type}
              createNewRow={handleCreateNewRow}
              listQueryKey={dataListEntriesQuery.queryKey}
            />
          </Box>
        ),
      }
    })
  }, [
    dataListColumns,
    dataListEntriesQuery.queryKey,
    handleCreateNewRow,
    setMappingColumnsData,
    valueMappingsOverlay,
    visibleDataListColumns,
  ])

  const columnsIds = useMemo(() => {
    const cols = showRowActions ? ['mrt-row-actions'] : []
    cols.push(...columns.map(({ id }) => id as string))
    return cols
  }, [columns, showRowActions])

  const handleColumnOrderChange = (
    columnsOrderUpdaterOrValue: MRT_Updater<MRT_ColumnOrderState>,
  ) => {
    let columnsOrderIds: string[] = []
    if (columnsOrderUpdaterOrValue instanceof Function) {
      columnsOrderIds = columnsOrderUpdaterOrValue(columnsIds)
    } else {
      columnsOrderIds = columnsOrderUpdaterOrValue
    }

    // we leave mrt-row-actions out
    updateDataListColumns(
      columnsOrderIds.slice(1).map((id, index) => {
        const col = dataListColumns.find(
          (column) => column.id === id,
        ) as DataListColumn
        return { ...col, sort_order: index }
      }),
    )
  }

  // Normally, we would just react to data changes instead of having
  // a ref be a trigger for updating what cell we're editing. There are
  // too many layers here to make it practical.
  useEffect(() => {
    if (waitingForNewRowRef.current) {
      waitingForNewRowRef.current = false
      const table = tableInstanceRef.current
      if (!table) return
      const allRows = table.getRowModel().rows
      const lastRow = table.getRow(`${allRows.length - 1}`)
      const nextRowCells = lastRow.getVisibleCells()
      // @ts-expect-error -- Too complicated of a type structure to make it worth it to get working
      table.setEditingCell(nextRowCells[1])
    }
  }, [dataListEntries])

  const { fetchMoreOnBottomReached } = useFetchMoreOnBottomReached({
    isFetching,
    hasNextPage,
    tableContainerRef,
    fetchNextPage,
    offset: 1000,
  })

  const table = useZerapixTable<RowData>({
    title: dataList.name,
    columns,
    data: rows,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '',
        size: 50,
        Cell: ({ row }) => {
          return (
            <EntryMenu
              isSuperUserOnly={
                !parentDataList.is_entries_editable_in_ui &&
                isSuperUser &&
                !demoMode
              }
              dataListEntry={
                dataListEntries.find(
                  ({ id }) => id === row.original.dataEntryId,
                ) as DataListEntry
              }
              listQueryKey={dataListEntriesQuery.queryKey}
            />
          )
        },
        muiTableBodyCellProps: {
          sx: {
            boxShadow: 'none',
            padding: 0,
            justifyContent: 'center',
          },
        },
        muiTableHeadCellProps: {
          sx: {
            boxShadow: 'none',
          },
        },
      },
    },
    enableEditing: parentDataList.is_cells_editable_in_ui,
    editDisplayMode: 'cell',
    enableColumnActions: true,
    enableColumnOrdering: parentDataList.is_columns_editable_in_ui,
    enableColumnDragging: parentDataList.is_columns_editable_in_ui,
    enableColumnResizing: true,
    enableColumnFilters: false,
    enableColumnVirtualization: columns.length > 12,
    enableDensityToggle: false,
    enableHiding: false,
    enableRowActions: showRowActions,
    enableRowVirtualization: rows.length > 200,
    enableSorting: true,
    enableStickyHeader: true,
    localization: {
      noRecordsToDisplay: 'No data has been added yet.',
    },
    manualSorting: true,
    muiTableContainerProps: {
      id: 'potato',
      sx: { height: 'calc(100vh - 232px)' },
      ref: tableContainerRef,
      onScroll: (
        event, //add an event listener to the table container element
      ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    muiTablePaperProps: {
      sx: {
        flexGrow: 1,
      },
    },
    muiTableBodyRowProps: {
      sx: {
        minHeight: 42,
      },
    },
    onColumnOrderChange: handleColumnOrderChange,
    onSortingChange: setSorting,
    renderColumnActionsMenuItems: ({
      column,
      internalColumnMenuItems,
      closeMenu,
    }) => {
      return [
        !isSubOrgList && parentDataList.is_columns_editable_in_ui ? (
          <MenuItem
            key="edit"
            onClick={() => {
              refetch() // We refetch columns in case values got updated
              setSelectedColumnId(column.id)
              addEditColumnOverlay.open()
              closeMenu()
            }}
          >
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            Edit Column
          </MenuItem>
        ) : null,
        !isSubOrgList && parentDataList.is_columns_editable_in_ui ? (
          <MenuItem
            key="delete"
            onClick={() => {
              deleteDataListColumn(column.id)
              closeMenu()
            }}
          >
            <ListItemIcon>
              <Delete fontSize="small" />
            </ListItemIcon>
            Delete Column
          </MenuItem>
        ) : null,
        isSuperUser && !demoMode ? (
          <MenuItem
            key="copy-id"
            onClick={() => {
              copyToClipboard(column.id)
              showInfoSnackbar('Copied to Clipboard')
              closeMenu()
            }}
            sx={{ color: SUPER_USER_ONLY_COLOR }}
          >
            <ListItemIcon sx={{ color: SUPER_USER_ONLY_COLOR }}>
              <ContentCopy fontSize="small" />
            </ListItemIcon>
            Copy ID
          </MenuItem>
        ) : null,
        parentDataList.is_columns_editable_in_ui ? (
          <Divider key="divider-1" />
        ) : null,
        ...internalColumnMenuItems,
      ]
    },
    renderTopToolbarCustomActions: () => (
      <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
        <Button variant="text" onClick={() => setShowOrgTree((prev) => !prev)}>
          {showOrgTree ? 'Hide' : 'Show'} Organizations
        </Button>
        {/* Sync Button and Info */}
        {dataList.data_list_type?.integration_type_id && (
          <SyncHelper dataListId={dataList.id} />
        )}
        {parentDataList.is_entries_editable_in_ui && (
          <Button
            disabled={!visibleDataListColumns.length}
            variant="text"
            onClick={handleCreateNewRow}
            startIcon={<Add />}
          >
            Add Row
          </Button>
        )}
        {!isSubOrgList && parentDataList.is_columns_editable_in_ui && (
          <Button
            variant="text"
            onClick={addEditColumnOverlay.open}
            startIcon={<Add />}
          >
            Add Column
          </Button>
        )}

        <div style={{ flexGrow: 1 }} />
        {isSubOrgList && parentDataList.is_deletable_in_ui && (
          <Tooltip title="Delete this organization's version of this list. Revert to inheriting from its parent.">
            <Button
              variant="text"
              color="error"
              disabled={isDeletingSublist}
              onClick={() => {
                setIsDeletingSublist(true)
                deleteDataList(dataList.id)
              }}
            >
              Revert to Parent List
            </Button>
          </Tooltip>
        )}
      </Stack>
    ),
    state: {
      columnPinning: { left: ['mrt-row-actions'] },
      columnOrder: columnsIds,
      showSkeletons: columnsIsLoading || entriesIsLoading,
      showProgressBars: isFetching && !isRefetching,
      sorting,
    },
  })

  return table
}

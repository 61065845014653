import { createContext, ReactNode, useContext } from 'react'
import { ProjectGridField } from '@/types/fields'

const ProjectGridFieldsContext = createContext<ProjectGridField[]>([])

export const useProjectGridFieldsContext = () =>
  useContext(ProjectGridFieldsContext)

type ProjectGridFieldsProviderProps = {
  fields: ProjectGridField[]
  children: ReactNode
}

export default function ProjectGridFieldsProvider({
  fields,
  children,
}: ProjectGridFieldsProviderProps) {
  return (
    <ProjectGridFieldsContext.Provider value={fields}>
      {children}
    </ProjectGridFieldsContext.Provider>
  )
}

import { Alert, Stack, Typography } from '@mui/material'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { useGetDataList } from '@/service-library/hooks/data-lists'
import PrimaryTextWrapper from './PrimaryTextWrapper'

type SetOrganizationByListMatchDisplayProps = {
  action: WorkflowStateAction
}

export default function SetOrganizationByListMatchDisplay({
  action,
}: SetOrganizationByListMatchDisplayProps) {
  const { dataList, isLoading } = useGetDataList({
    id: action.params.data_list_id,
    filters: {
      fields__only: 'id,name',
    },
  })

  if (!dataList && !isLoading) {
    return (
      <Alert severity="error">
        The list this action used was deleted. Please reach out to Pixydocs
        support to fix this.
      </Alert>
    )
  }

  const columnName = (
    <PrimaryTextWrapper>
      {action.params.data_list_column_name}
    </PrimaryTextWrapper>
  )

  const targetFieldName = (
    <PrimaryTextWrapper>{action.params.target_field_name}</PrimaryTextWrapper>
  )
  const hasColumnFields = Object.entries(action.params).some(
    ([key, value]) => key.startsWith('address') && !!value,
  )

  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary">
        Set the document&apos;s organization if the {targetFieldName} field
        matches a value in the {columnName} column of the{' '}
        <PrimaryTextWrapper>{dataList?.name}</PrimaryTextWrapper> list. If a
        match can&apos;t be found, the document will be flagged with{' '}
        {action.params.flag_level === 'warning' ? 'a' : 'an'}{' '}
        <PrimaryTextWrapper>{action.params.flag_level}</PrimaryTextWrapper>.
      </Typography>
      {hasColumnFields && (
        <Stack>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            If the column can&apos;t find a match, the address components from
            the {targetFieldName} field will be matched to the following
            columns:
          </Typography>
          {Object.entries(action.params).map(([key, value]) => {
            if (!value || !key.startsWith('address')) return
            return (
              <Typography variant="body2" key={key}>
                <b>
                  {
                    action.action_type?.params_schema.properties[key]?.display
                      .label
                  }
                  :
                </b>{' '}
                {value}
              </Typography>
            )
          })}
        </Stack>
      )}
    </Stack>
  )
}

import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useState,
  ReactNode,
} from 'react'
import useWebsocket, { WebsocketData } from './useWebsocket'

type CallbackFunction = (data: WebsocketData) => void

type UseNotificationsProps = {
  keys: (string | undefined)[]
  callback: CallbackFunction
}

type NotificationProviderProps = {
  children: ReactNode
}

type NotificationProviderValue = {
  registerCallback: (callbackIds: string[], callback: CallbackFunction) => void
  unregisterCallback: (
    callbackIds: string[],
    callback: CallbackFunction,
  ) => void
}

const NotificationContext = createContext<NotificationProviderValue>(
  {} as NotificationProviderValue,
)

function NotificationProvider({ children }: NotificationProviderProps) {
  const [callbacks, setCallbacks] = useState<
    Record<string, CallbackFunction[]>
  >({})

  useWebsocket({
    onMessage: (event) => {
      const data = JSON.parse(event.data)
      const { action } = data as WebsocketData

      data?.['updated_entity_ids']?.forEach((entityId: string) => {
        // Fire callback for each subscriber to each entityId
        callbacks[entityId]?.forEach((cb) => {
          cb(data)
        })
      })

      // Fire callback for each subscriber to the action
      callbacks[action]?.forEach((cb) => {
        cb(data)
      })
    },
  })

  const registerCallback = useCallback(
    (callbackIds: string[], callback: CallbackFunction) => {
      setCallbacks((callbacks) => {
        const newCallbacks = callbackIds.reduce(
          (acc, callbackId) => ({
            ...acc,
            [callbackId]: [...(callbacks[callbackId] || []), callback],
          }),
          {},
        )
        return {
          ...callbacks,
          ...newCallbacks,
        }
      })
    },
    [],
  )

  const unregisterCallback = useCallback(
    (callbackIds: string[], callback: CallbackFunction) => {
      setCallbacks((callbacks) => {
        const newCallbacks = {
          ...callbacks,
        }
        callbackIds.forEach((callbackId) => {
          newCallbacks[callbackId] = callbacks[callbackId].filter(
            (cb) => cb !== callback,
          )
        })

        return newCallbacks
      })
    },
    [],
  )

  const notificationHooks = useMemo(() => {
    return {
      registerCallback,
      unregisterCallback,
    }
  }, [registerCallback, unregisterCallback])

  return (
    <NotificationContext.Provider value={notificationHooks}>
      {children}
    </NotificationContext.Provider>
  )
}

function useNotifications({ keys, callback }: UseNotificationsProps) {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error(
      'useNotifications must be used within an NotificationProvider',
    )
  }

  const { registerCallback, unregisterCallback } = context

  // casting to string[] because ts keeps saying type is (string|undefined)[]
  const validKeys = useMemo(() => keys.filter((key) => key) as string[], [keys])

  useEffect(() => {
    if (validKeys.length) registerCallback(validKeys, callback)

    return () => {
      if (validKeys.length) unregisterCallback(validKeys, callback)
    }
  }, [callback, registerCallback, unregisterCallback, validKeys])

  return context
}

export { NotificationProvider, useNotifications }

import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, List, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useGetDocument } from '@/service-library/hooks/documents'
import { useDocumentComparisonDataContext } from '@/components/image-zoom-pan/providers/DocumentComparisonDataProvider'
import { FormTextField, PixyDocsForm } from '@/components/forms'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import { Document } from '@/types/documents'
import ComparisonDocSuggestion from './ComparisonDocSuggestion'
import { getDuplicateSuggestions } from './suggestion-helpers'

type DocumentComparisonSelectionProps = {
  onSuccess?: () => void
  document: Document
  comparisonDocument?: Document
  loadIfOnlyOneSuggestion?: boolean
}

export type ComparisonModeOptions = 'image' | 'all_fields' | 'relevant_fields'

export default function DocumentComparisonSelection({
  onSuccess,
  document,
  comparisonDocument,
  loadIfOnlyOneSuggestion,
}: DocumentComparisonSelectionProps) {
  const { project } = useProjectContext()
  const [docId, setDocId] = useState('')

  const { isError, isFetching, isSuccess } = useGetDocument({
    id: docId,
    filters: {
      project_id: project?.id, // The secondary document should be in the same project as the main
      fields__only: 'id',
    },
    retry: (failureCount, error) => {
      if (error?.response?.status === 404) return false
      return failureCount < 3
    },
  })

  const {
    secondaryDocumentQuery,
    setCompareDocumentEnabled,
    setDocumentIdToCompare,
  } = useDocumentComparisonDataContext()
  const methods = useForm({
    defaultValues: {
      documentId: '',
    },
  })

  const { register, reset, formState } = methods

  useEffect(() => {
    // We check that the document exists and is in the same project
    // before we use it for the comparison data. This way, if there is
    // already a document, we don't override it when there is an error.
    if (isSuccess) {
      setDocumentIdToCompare(docId)
      setCompareDocumentEnabled(true)
    }
  }, [docId, isSuccess, setCompareDocumentEnabled, setDocumentIdToCompare])

  useEffect(() => {
    if (
      formState.isSubmitSuccessful &&
      secondaryDocumentQuery.isSuccess &&
      secondaryDocumentQuery.document?.id === docId
    ) {
      reset()
      setCompareDocumentEnabled(false)
      onSuccess?.()
    }
  }, [
    docId,
    formState.isSubmitSuccessful,
    onSuccess,
    reset,
    secondaryDocumentQuery.document?.id,
    secondaryDocumentQuery.isSuccess,
    setCompareDocumentEnabled,
  ])

  const duplicateSuggestions = useMemo(
    () => getDuplicateSuggestions(document),
    [document],
  )

  const loadIfOnlyOne =
    loadIfOnlyOneSuggestion && duplicateSuggestions.length === 1
  useEffect(() => {
    if (loadIfOnlyOne) {
      setDocId(duplicateSuggestions[0].docId)
    }
  }, [duplicateSuggestions, loadIfOnlyOne])

  // If there is only one suggestion, we're going to load it immediately, so don't show anything
  if (loadIfOnlyOne) return null

  return (
    <Stack spacing={1}>
      <List>
        {duplicateSuggestions.map((suggestion) => (
          <ComparisonDocSuggestion
            key={suggestion.docId}
            documentId={suggestion.docId}
            subText={suggestion.label}
            onClick={() => {
              onSuccess?.()
              setDocId(suggestion.docId)
            }}
            isSelected={comparisonDocument?.id === suggestion.docId}
          />
        ))}
      </List>

      <PixyDocsForm
        methods={methods}
        onSubmit={({ documentId }) => {
          setDocId(documentId)
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          columnGap: '8px',
        }}
      >
        <FormTextField
          required
          label="Document ID to Compare"
          fullWidth
          sx={{ maxWidth: 300 }}
          error={!!isError}
          helperText={isError ? 'Document not found' : ''}
          size="small"
          {...register('documentId', {
            validate: (value) => value.trim().length === 36, // Basic check for UUID
          })}
        />

        <Box>
          <LoadingButton
            type="submit"
            disabled={!formState.isValid}
            loading={isFetching || secondaryDocumentQuery.isFetching}
            size="medium"
          >
            Compare
          </LoadingButton>
        </Box>
      </PixyDocsForm>
    </Stack>
  )
}

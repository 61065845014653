import { useRef } from 'react'
import {
  Delete,
  MoreHoriz,
  VerticalAlignBottom,
  VerticalAlignTop,
} from '@mui/icons-material'
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material'
import { DocumentRow } from '@/types/documents'
import { ChangeSet } from '@/types/doc-changes'
import useOverlay from '@/hooks/useOverlay'

export type FieldTableRowActionsMenuProps = {
  documentRow: DocumentRow
  addRow: ({
    rowId,
    position,
    shouldFocus,
  }: {
    rowId?: string
    position?: 'before' | 'after'
    shouldFocus?: boolean
  }) => void
  deleteDocumentRows: (rowIds: string[]) => Promise<ChangeSet[] | undefined>
}

export default function FieldTableRowActionsMenu({
  documentRow,
  addRow,
  deleteDocumentRows,
}: FieldTableRowActionsMenuProps) {
  const menuOverlay = useOverlay()
  const buttonRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <Tooltip title="Row Actions" enterDelay={1000}>
        <IconButton
          ref={buttonRef}
          onClick={menuOverlay.open}
          size="small"
          tabIndex={-1}
        >
          <MoreHoriz fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Menu
        MenuListProps={{
          dense: true,
        }}
        anchorEl={buttonRef.current}
        open={menuOverlay.isOpen}
        onClose={menuOverlay.close}
      >
        <MenuItem
          onClick={() => {
            addRow({
              rowId: documentRow.id,
              position: 'before',
              shouldFocus: true,
            })
            menuOverlay.close()
          }}
        >
          <ListItemIcon>
            <VerticalAlignTop />
          </ListItemIcon>
          <ListItemText>Insert Row Above</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            addRow({
              rowId: documentRow.id,
              position: 'after',
              shouldFocus: true,
            })
            menuOverlay.close()
          }}
        >
          <ListItemIcon>
            <VerticalAlignBottom />
          </ListItemIcon>
          <ListItemText>Insert Row Below</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            deleteDocumentRows([documentRow.id])
            menuOverlay.close()
          }}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

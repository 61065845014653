import { LoadingButton } from '@mui/lab'
import { Button, Stack, Typography } from '@mui/material'
import { Document } from '@/types/documents'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import SplitDocumentImages from './SplitDocumentPages'
import SplitDocumentOptions from './SplitDocumentOptions'
import useSplitDocuments from './useSplitDocuments'

type SplitDocumentDialogProps = {
  document: Document
  overlay: OverlayState
  onDelete: () => void
}

export default function SplitDocumentDialog({
  document,
  overlay,
  onDelete,
}: SplitDocumentDialogProps) {
  const data = useSplitDocuments({
    document,
    onDelete,
  })

  const { isLoading, splitPoints, createDocuments, resetStates } = data

  const onClose = () => {
    overlay.close()
    resetStates()
  }

  return (
    <Dialog
      title="Split Document"
      maxWidth="xl"
      fullWidth={false}
      {...overlay}
      onClose={onClose}
    >
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            Click between the page images below to choose where to split the
            document. Each section will become a new document.
          </Typography>
          <SplitDocumentImages document={document} splitDocumentsData={data} />
          <SplitDocumentOptions
            pages={document.document_pages}
            splitDocumentsData={data}
          />
        </Stack>
      </DialogContent>
      <DialogFooter>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <LoadingButton
          disabled={!splitPoints.length}
          variant="contained"
          onClick={() => {
            createDocuments().then(() => {
              overlay.close()
            })
          }}
          loading={isLoading} // Just in case they reopen the dialog when the previous split is still in progress.
        >
          Confirm
        </LoadingButton>
      </DialogFooter>
    </Dialog>
  )
}

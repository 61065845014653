import { memo, useMemo } from 'react'
import debounce from 'lodash.debounce'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { DocumentView, WorkflowState } from '@/types/workflows'
import { useUpdateWorkflowState } from '@/service-library/hooks/workflow-states'
import queryKeys from '@/service-library/query-keys'
import { showErrorSnackbar } from '@/utils/snackbars'

type DocumentViews = Record<DocumentView, string>

export const documentViews: DocumentViews = {
  validation: 'Validation',
  comparison: 'Comparison',
  'split-document': 'Split Document',
}

type WorkflowStateDocumentViewsProps = {
  workflowState: WorkflowState
  detailQueryKey: QueryKey
}

function WorkflowStateDocumentViews({
  workflowState,
  detailQueryKey,
}: WorkflowStateDocumentViewsProps) {
  const { updateWorkflowState } = useUpdateWorkflowState({
    sideEffectQueryKeys: [queryKeys.workflows.lists()],
    detailQueryKey,
    onError: () => {
      showErrorSnackbar(
        'Unable to update document view preferences. Please try again later.',
      )
    },
  })

  const debouncedUpdateState = useMemo(
    () =>
      debounce((updatedState) => {
        if (
          !updatedState.allowed_document_views.includes(
            updatedState.default_document_view,
          )
        ) {
          updatedState.default_document_view =
            updatedState.allowed_document_views[0]
        }
        return updateWorkflowState(updatedState)
      }, 250),
    [updateWorkflowState],
  )

  const allowedDocumentViews = workflowState.allowed_document_views

  const cantUnselectOnlyOption = allowedDocumentViews.length === 1

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h6">Document Views</Typography>
        <Typography variant="caption" component="div" color="text.secondary">
          Configures which views are available when viewing a document in this
          workflow state.
        </Typography>
      </Box>
      <FormControl sx={{ maxWidth: 400 }}>
        <InputLabel id="allowed-document-views-id">Allowed Views</InputLabel>
        <Select
          size="small"
          displayEmpty
          labelId="allowed-document-views-id"
          label="Allowed Views"
          multiple
          value={allowedDocumentViews}
          onChange={(e) => {
            debouncedUpdateState({
              ...workflowState,
              allowed_document_views: e.target
                .value as WorkflowState['allowed_document_views'],
            })
          }}
        >
          {Object.entries(documentViews).map(([value, label]) => (
            <MenuItem
              key={value}
              disabled={
                cantUnselectOnlyOption &&
                allowedDocumentViews.includes(
                  value as WorkflowState['default_document_view'],
                )
              }
              value={value}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        value={workflowState.default_document_view}
        label="Default View"
        select
        variant="outlined"
        sx={{ maxWidth: 400, textTransform: 'capitalize' }}
        onChange={(e) => {
          updateWorkflowState({
            ...workflowState,
            default_document_view: e.target
              .value as WorkflowState['default_document_view'],
          })
        }}
      >
        {allowedDocumentViews.map((view) => (
          <MenuItem key={view} value={view}>
            {documentViews[view]}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  )
}

export default memo(WorkflowStateDocumentViews)

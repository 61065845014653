import { showErrorSnackbar } from '@/utils/snackbars'
import { createContext, ReactNode, useContext, useState } from 'react'

type FieldIdentifier = `${string}_${string}`

type FieldErrorContextValue = {
  fieldErrors: Record<FieldIdentifier, string>
  getFieldError: (fieldId: string, documentRowId: string | null) => string
  setFieldError: (
    fieldId: string | null,
    documentRowId: string | null,
    errorMsg: string,
  ) => void
}

const FieldErrorContext = createContext<FieldErrorContextValue>(
  {} as FieldErrorContextValue,
)

export const useFieldErrorContext = () => useContext(FieldErrorContext)

type FieldErrorProviderProps = {
  children: ReactNode
}

export default function FieldErrorProvider({
  children,
}: FieldErrorProviderProps) {
  const [fieldErrors, setFieldErrors] = useState<
    Record<FieldIdentifier, string>
  >({})

  const setFieldError = (
    fieldId: string | null,
    documentRowId: string | null,
    errorMsg: string,
  ) => {
    if (!documentRowId || !fieldId) return
    const identifier = `${fieldId}_${documentRowId}` as FieldIdentifier
    setFieldErrors((prev) => {
      if (!Object.keys(prev).length) return prev
      const updatedErrors = { ...prev }
      if (!errorMsg) {
        delete updatedErrors[identifier]
      } else {
        updatedErrors[identifier] = errorMsg
      }
      return updatedErrors
    })
    errorMsg &&
      showErrorSnackbar(
        'There was a problem updating a field. Please try again later.',
      )
  }

  const getFieldError = (fieldId: string, documentRowId: string | null) => {
    if (!documentRowId) return ''
    const identifier = `${fieldId}_${documentRowId}` as FieldIdentifier
    if (Object.hasOwn(fieldErrors, identifier)) return fieldErrors[identifier]
    return ''
  }

  return (
    <FieldErrorContext.Provider
      value={{
        fieldErrors,
        getFieldError,
        setFieldError,
      }}
    >
      {children}
    </FieldErrorContext.Provider>
  )
}

import {
  Dispatch,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import debounce from 'lodash.debounce'
import { ArrowDropDown, Clear } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Divider,
  List,
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'
import { LiveListEntity } from '@/types/live-lists'
import { EntrataVendor } from '@/types/entrata-vendor'

type SuggestedSearchesProps = {
  suggestedSearches: [string, string][]
  setInputValue: Dispatch<SetStateAction<string>>
}

function SuggestedSearches({
  suggestedSearches,
  setInputValue,
}: SuggestedSearchesProps) {
  return (
    <Box sx={{ pt: 1 }}>
      {suggestedSearches.map(([key, suggestion]) => (
        <MenuItem
          key={key}
          onClick={() => {
            setInputValue(suggestion || '')
          }}
          sx={{ py: 0.5 }}
        >
          <Typography variant="caption" color="text.secondary">
            Suggested Search: {suggestion}
          </Typography>
        </MenuItem>
      ))}

      {suggestedSearches.length > 0 && <Divider flexItem sx={{ mb: -1 }} />}
    </Box>
  )
}

type ListBoxProps = {
  children: ReactNode
  hasInputValue: boolean
  setInputValue: Dispatch<SetStateAction<string>>
  suggestedSearches: [string, string][]
}

const ListBox = forwardRef(
  (
    {
      children,
      hasInputValue,
      suggestedSearches,
      setInputValue,
      ...props
    }: ListBoxProps,
    ref: React.Ref<HTMLUListElement>,
  ) => {
    return (
      <List disablePadding {...props} ref={ref}>
        {!hasInputValue && (
          <SuggestedSearches
            suggestedSearches={suggestedSearches}
            setInputValue={setInputValue}
          />
        )}
        {children}
      </List>
    )
  },
)

type LiveListPickerFieldProps<T> = {
  onChange: (
    newValue: string | null,
    refId: string | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jsonObject?: Record<string, any> | null,
  ) => void
  value: LiveListEntity<T> | null
  setDisableArrowKeys: Dispatch<SetStateAction<boolean>>
  options: LiveListEntity<T>[]
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  hasNextPage?: boolean
  fetchNextPage: () => void
  isLoading?: boolean
  isInTable?: boolean
  openOnFocus?: boolean
  suggestedSearches?: [string, string][]
  noOptionsText?: string
} & Omit<TextFieldProps, 'onChange' | 'value'>

/***************************************
 * MARK: READ THIS!
 * This is coded to work directly for Entrata for the time being, but is also
 * meant to be a placeholder until we can build live lists correctly. When'
 * it is time to make live lists properly, start here.
 * *************************************/
export default function LiveListPickerField({
  value,
  setDisableArrowKeys,
  label,
  onChange,
  options,
  open,
  setOpen,
  searchValue,
  setSearchValue,
  hasNextPage,
  fetchNextPage,
  isLoading,
  suggestedSearches = [],
  openOnFocus = false,
  noOptionsText = 'No options',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isInTable, // Unused on purpose
  ...props
}: LiveListPickerFieldProps<EntrataVendor>) {
  const [enabled, setEnabled] = useState(false)

  const [inputValue, setInputValue] = useState(value?.entity_id || '')

  const handleOpen = (e: React.SyntheticEvent<Element, Event>) => {
    if (
      e.type !== 'mousedown' &&
      (e as unknown as KeyboardEvent).key !== 'ArrowDown' &&
      (e as unknown as KeyboardEvent).key !== 'ArrowUp'
    ) {
      setOpen(true)
      if (!enabled) setEnabled(true) // We fetch the first time the dialog opens
      setDisableArrowKeys(true)
    }
  }

  const clearingInInputRef = useRef(false)

  const onScroll: React.UIEventHandler<HTMLUListElement> = (event) => {
    const target = event.target as HTMLDivElement
    const bottom =
      Math.abs(target.clientHeight - (target.scrollHeight - target.scrollTop)) <
      5
    if (bottom && hasNextPage) {
      fetchNextPage()
    }
  }

  const debouncedUpdateValue = useMemo(
    () =>
      debounce((newValue: string) => {
        setSearchValue(newValue)
      }, 200),
    [setSearchValue],
  )

  // Debounce updating the search value
  useEffect(() => {
    if (inputValue !== searchValue) {
      debouncedUpdateValue(inputValue)
    }
  }, [debouncedUpdateValue, inputValue, searchValue])

  return (
    <Autocomplete<LiveListEntity<EntrataVendor>, false, true | false>
      disabled={props.disabled}
      fullWidth
      openOnFocus={openOnFocus}
      clearIcon={<Clear fontSize="inherit" />}
      popupIcon={<ArrowDropDown fontSize="inherit" />}
      loading={isLoading}
      value={value}
      options={options || []}
      disableClearable
      onChange={(_event, newValue, reason) => {
        setOpen(false)
        // If the user clears the input by using the keyboard, we don't want to
        // make a call yet because they might be typing a new value
        if (!newValue && reason === 'clear' && clearingInInputRef.current) {
          clearingInInputRef.current = false
        } else {
          onChange(newValue?.entity.vendor_name || '', null, newValue)
        }
      }}
      open={open}
      onOpen={handleOpen}
      onClose={() => {
        setOpen(false)
        setDisableArrowKeys(false)
        setSearchValue('')
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue, reason) => {
        if (!newInputValue && reason === 'input') {
          clearingInInputRef.current = true
        }
        setInputValue(newInputValue)
      }}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) =>
        option?.entity_id === value.entity_id
      }
      renderInput={(params) => {
        return (
          <TextField
            {...props}
            {...params}
            label={label}
            // TODO: Move this to the EntrataVendorCard somehow
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
          />
        )
      }}
      noOptionsText={noOptionsText}
      getOptionLabel={(option) => {
        return option.entity.vendor_name || '' // Return empty string if no vendor name
      }}
      renderOption={(props, option) => {
        const address = `${option.entity.address_line_1}, ${option.entity.city}, ${option.entity.state} ${option.entity.postal_code}`
        const hasAddress =
          option.entity.address_line_1 ||
          option.entity.city ||
          option.entity.state ||
          option.entity.postal_code
        return (
          <MenuItem
            {...props}
            key={props.id}
            value={option.entity_id}
            sx={{ alignItems: 'center' }}
          >
            {option.entity.vendor_name}{' '}
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ ml: 1, mt: '1px' }}
            >
              {hasAddress ? address : '(no address)'}
            </Typography>
          </MenuItem>
        )
      }}
      slotProps={{
        popper: {
          sx: {
            minWidth: 'fit-content',
            width: 'max-content',
            zIndex: (theme) => theme.zIndex.modal + 2,
            gap: 0,
          },
        },
        listbox: {
          onScroll,
          sx: {
            mt: -1,
          },
          component: ListBox,
          // @ts-expect-error -- Not sure how to type this since its so deeply nested
          hasInputValue: !!inputValue,
          suggestedSearches,
          setInputValue,
        },
      }}
    />
  )
}

import { useState } from 'react'
import { Delete, FiberManualRecord } from '@mui/icons-material'
import {
  CircularProgress,
  IconButton,
  List,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { RowValueFlag } from '@/types/flags'
import queryKeys from '@/service-library/query-keys'
import { useDeleteRowValueFlag } from '@/service-library/hooks/row-value-flags'
import FlagLongDescription from './FlagLongDescription'
import { flagDeleteMutateOptions } from './FlagListItem'
import useFlagColorMap from './useFlagColorMap'

type RowValueFlagsListProps = {
  rowValueFlags: RowValueFlag[]
}

export default function FlagsBulletList({
  rowValueFlags,
}: RowValueFlagsListProps) {
  const colorMap = useFlagColorMap()
  const [isDismissing, setIsDismissing] = useState(false)

  const { deleteRowValueFlag } = useDeleteRowValueFlag({
    sideEffectQueryKeys: [
      queryKeys.rowValueFlags.lists(),
      queryKeys.documents.lists(),
      queryKeys.documentRows.lists(),
      queryKeys.documentRowValues.lists(),
    ],
    ...flagDeleteMutateOptions,
    onSettled: () => setIsDismissing(false),
  })

  return (
    <List component="ul" sx={{ pl: 1 }}>
      {rowValueFlags.map((flag) => {
        return (
          <Stack key={flag.id} direction="row" alignItems="center">
            <Tooltip
              title={
                <FlagLongDescription longDescription={flag.long_description} />
              }
              disableInteractive
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                component="li"
              >
                <FiberManualRecord
                  sx={{
                    fontSize: 12,
                    flexShrink: 0,
                    color: colorMap[flag.flag_level].base,
                    transform: 'translateY(-1px)',
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    color: colorMap[flag.flag_level].base,
                    display: 'block',
                  }}
                >
                  {flag.short_description || flag.flag_type?.short_description}
                </Typography>
              </Stack>
            </Tooltip>
            {flag.flag_level !== 'error' && flag.flag_level !== 'failure' ? (
              !isDismissing ? (
                <Tooltip title="Dismiss">
                  <IconButton
                    edge="end"
                    size="small"
                    sx={{ transform: 'translateX(4px)' }} // Slight adjustment for positioning
                    onClick={() => {
                      setIsDismissing(true)
                      deleteRowValueFlag(flag.id)
                    }}
                  >
                    <Delete sx={{ fontSize: 16 }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <CircularProgress size={12} color="neutral" sx={{ m: 1 }} />
              )
            ) : undefined}
          </Stack>
        )
      })}
    </List>
  )
}

import {
  Background,
  Edge,
  Node,
  NodeChange,
  Panel,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react'
import { useCallback, useEffect, useState } from 'react'
import InfoTypeNode from './InfoTypeNode'
import { Box, darken, IconButton, lighten, useTheme } from '@mui/material'
import {
  ProjectLinkedModel,
  ProjectLinkedModelInfoType,
} from '@/types/project-linked-models'
import { ProjectModelVersionInfoType } from '@/types/project-models'
import '@xyflow/react/dist/style.css'
import { Fullscreen, FullscreenExit } from '@mui/icons-material'
import FullscreenContainer from '../fullscreen-container/FullscreenContainer'
import ModelNode from '../project-settings/ModelFieldNodes/ModelNode'
import useModelVersionNodes from './useModelVersionNodes'
import useModelVersionEdges from './useModelVersionEdges'
import useAutoLayout from './useAutoLayout'
import FieldNode from './FieldNode'
import {
  appendSubGridFieldsToBaseGridAndCleanFields,
  getBaseGrid,
  sortBySortOrder,
} from '@/utils/field-utils'
import { ProjectGrid } from '@/types/projects'
import OCRResultsNode from './OCRResultsNode'
import TableNamespaceNode from './TableNamespaceNode'
import FieldsProvider from './FieldsProvider'

type ModelOutputsDisplayProps = {
  projectLinkedModel: ProjectLinkedModel
  projectModelVersionInfoTypes: ProjectModelVersionInfoType[]
  projectGrids: ProjectGrid[]
  projectLinkedModelInfoTypes: ProjectLinkedModelInfoType[]
}

const nodeTypes = {
  infotype: InfoTypeNode,
  tableNamespace: TableNamespaceNode,
  model: ModelNode,
  field: FieldNode,
  ocrResults: OCRResultsNode,
}

export default function ModelVersionInputsOutputsDisplay({
  projectLinkedModel,
  projectModelVersionInfoTypes,
  projectGrids,
  projectLinkedModelInfoTypes,
}: ModelOutputsDisplayProps) {
  const theme = useTheme()
  const [isFullscreen, setIsFullscreen] = useState(false)
  const reactFlowInstance = useReactFlow()

  const baseGrid = getBaseGrid(projectGrids as ProjectGrid[]) as ProjectGrid
  const { project_grid_fields: fullBaseFields } =
    appendSubGridFieldsToBaseGridAndCleanFields({
      baseGrid,
      grids: projectGrids.filter((grid) => grid.id !== baseGrid.id) || [],
    })
  const fields = sortBySortOrder(fullBaseFields)

  const initialNodes = useModelVersionNodes({
    projectModelVersionInfoTypes,
    projectLinkedModel,
    projectGridFields: fields,
    projectLinkedModelInfoTypes,
  })

  const initialEdges = useModelVersionEdges({
    projectLinkedModel,
    projectModelVersionInfoTypes,
    projectGridFields: fields,
    projectLinkedModelInfoTypes,
  })

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes as Node[])
  const [edges, , onEdgesChange] = useEdgesState(initialEdges as Edge[])

  const updateNodesToAutoLayout = useAutoLayout({ setNodes })

  function handleNodesChange(nodeChanges: NodeChange[]) {
    onNodesChange(nodeChanges)

    // Re-calculate auto layout positions when dimensions change
    if (nodeChanges.some((nodeChange) => nodeChange.type === 'dimensions')) {
      updateNodesToAutoLayout()
    }
  }

  const fitView = useCallback(() => {
    setTimeout(() => {
      reactFlowInstance.fitView({
        padding: 0.15,
        duration: 200,
      })
    }, 200)
  }, [reactFlowInstance])

  useEffect(() => {
    if (reactFlowInstance) {
      fitView()
    }
  }, [fitView, reactFlowInstance])

  return (
    <FieldsProvider fields={fields}>
      <FullscreenContainer
        isFullscreen={isFullscreen}
        setIsFullscreen={setIsFullscreen}
      >
        <Box
          sx={{
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: 2,
            height: '100%',
            '& svg': {
              overflow: 'visible !important', // Something in MUI is messing with this, so this is a hack to fix it.
            },
          }}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={handleNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            style={{
              background:
                theme.palette.mode === 'dark'
                  ? theme.palette.background.paper
                  : darken(theme.palette.background.paper, 0.02),
              borderRadius: 8,
            }}
            minZoom={0.2}
          >
            <Background
              color={
                theme.palette.mode === 'dark'
                  ? lighten(theme.palette.background.paper, 0.1)
                  : darken(theme.palette.background.paper, 0.2)
              }
              size={2}
            />
            <Panel position="top-right">
              <IconButton
                sx={{
                  height: 36,
                  width: 36,
                }}
                onClick={() => {
                  setIsFullscreen((prev) => !prev)
                  fitView()
                }}
              >
                {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
              </IconButton>
            </Panel>
          </ReactFlow>
        </Box>
      </FullscreenContainer>
    </FieldsProvider>
  )
}

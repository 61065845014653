import { DocumentRow } from '@/types/documents'
import { Workflow, WorkflowState } from '@/types/workflows'
import getColumnFilterModeOptions from '@/utils/getColumnFilterModeOptions'
import getFormattedDateTimeString from '@/utils/getFormattedDateTimeString'
import { Box, Divider, Typography } from '@mui/material'
import { MRT_ColumnDef } from 'material-react-table'
import { ReactNode, useMemo } from 'react'
import { ProjectGrid } from '@/types/projects'
import { getBaseGrid, sortBySortOrder } from '@/utils/field-utils'
import { useProjectContext } from '../project-tables/ProjectProvider'
import CustomFilterComponent from '../workflows/CustomFilterComponent'
import useDocumentColumns from './useDocumentColumns'
import { useSelectedGridsContext } from '../workflows/SelectedGridsProvider'
import { ProjectGridField } from '@/types/fields'
import {
  CheckBoxOutlined,
  CheckBoxOutlineBlankOutlined,
} from '@mui/icons-material'
import ToggleDataLineageButton from '../workflows/ToggleDataLineageButton'
import PickerCell from './PickerCell'

type UseDocumentRowsColumnsOptions = {
  workflow: Workflow
  workflowState?: WorkflowState
}

export default function useDocumentRowsColumns({
  workflow,
  workflowState,
}: UseDocumentRowsColumnsOptions) {
  const { project } = useProjectContext()
  const { selectedGrids } = useSelectedGridsContext()

  const baseGrid = getBaseGrid(project.project_grids as ProjectGrid[])

  const documentColumns = useDocumentColumns({
    workflowState,
    showRowTypes: true,
  })

  const fields = useMemo<ProjectGridField[]>(
    () =>
      baseGrid?.id
        ? Object.values(selectedGrids).flatMap((grid) =>
            //TODO: Remove sorting for when the backend returns data sorted
            sortBySortOrder(grid.project_grid_fields),
          )
        : [],
    [baseGrid?.id, selectedGrids],
  )

  const valueTypes = useMemo<
    ('original' | 'rule' | 'manual' | 'final')[]
  >(() => {
    if (workflow !== undefined && workflow !== null) {
      if (workflow.code === 'labeling') {
        return ['original']
      }
      return ['original', 'rule', 'manual', 'final']
    }
    return []
  }, [workflow])

  const accessorFn = (
    row: Required<DocumentRow>,
    field: ProjectGridField,
    type: 'original' | 'rule' | 'manual' | 'final',
  ) => {
    const row_value = row.document_row_values?.find((value) => {
      return value.project_grid_field_id === field.id
    })

    if (row_value?.project_content_category_item) {
      return row_value.project_content_category_item.name || ''
    }

    switch (type) {
      case 'original':
        return row_value?.original_value || ''
      case 'rule':
        return row_value?.rule_value || ''
      case 'manual':
        return row_value?.manual_value || ''
      case 'final':
        return row_value?.final_value || ''
    }
  }

  const fieldColumns = useMemo<MRT_ColumnDef<Required<DocumentRow>>[]>(() => {
    const returnColumns: MRT_ColumnDef<Required<DocumentRow>>[] = []
    fields?.forEach((field) => {
      const subGroupIds: string[] = []
      valueTypes.forEach((columnValueType) => {
        const isLabelingWorkflow = valueTypes.length === 1
        const columnId =
          isLabelingWorkflow || columnValueType === 'final'
            ? field.id
            : `${field.id}-${columnValueType}`
        let columnSubGroupPosition = ''

        if (!isLabelingWorkflow && columnValueType === 'original') {
          columnSubGroupPosition = 'first'
        } else if (columnValueType === 'final') {
          columnSubGroupPosition = 'last'
        }

        if (columnValueType !== 'final') subGroupIds.push(columnId)

        const enableColumnActions =
          isLabelingWorkflow || columnValueType === 'final'

        const typeCode = field.project_grid_field_type.code
        const filterModeOptions = getColumnFilterModeOptions(typeCode)
        const typeCodeIsTimeRelated =
          typeCode === 'date' || typeCode === 'datetime' || typeCode === 'time'
        const typeCodeIsNumberRelated =
          typeCode === 'int' ||
          typeCode === 'decimal' ||
          typeCode === 'currency'
        const typeCodeIsCheckbox = typeCode === 'checkbox'

        returnColumns.push({
          id: columnId,
          accessorFn: (row) => accessorFn(row, field, columnValueType),
          enableColumnOrdering: enableColumnActions,
          enableColumnDragging: enableColumnActions,
          enableColumnFilter: enableColumnActions,
          enableColumnFilterModes:
            enableColumnActions && filterModeOptions.length > 1,
          enableSorting: enableColumnActions,
          columnFilterModeOptions:
            enableColumnActions && filterModeOptions.length > 1
              ? filterModeOptions
              : undefined,
          filterVariant:
            enableColumnActions && typeCodeIsCheckbox ? 'select' : undefined,
          filterSelectOptions:
            enableColumnActions && typeCodeIsCheckbox
              ? [
                  { label: 'Empty', value: 'empty-checkbox' },
                  { label: 'Checked', value: 'true' },
                  { label: 'Unchecked', value: 'false' },
                ]
              : undefined,
          Filter:
            enableColumnActions && typeCodeIsTimeRelated
              ? (props) => (
                  <CustomFilterComponent fieldTypeCode={typeCode} {...props} />
                )
              : undefined,
          meta: {
            columnType:
              !isLabelingWorkflow && columnValueType !== 'final'
                ? 'secondary'
                : 'primary',
            columnSubGroupPosition,
            subGroupIds: columnValueType === 'final' ? subGroupIds : [],
            field,
          },
          muiFilterTextFieldProps: {
            type: typeCodeIsNumberRelated ? 'number' : undefined,
            sx: typeCodeIsNumberRelated
              ? {
                  /* Chrome, Safari, Edge, Opera */
                  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
                    {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                  /* Firefox */
                  'input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                }
              : {},
          },
          header: field.name,
          Header: (
            <>
              {field.name}
              {!isLabelingWorkflow && columnValueType !== 'final' && (
                <Typography
                  variant="caption"
                  component="div"
                  color="secondary"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {columnValueType} Value
                </Typography>
              )}
            </>
          ),
          Cell: ({ cell, table, row, column }) => {
            let value: ReactNode = cell.getValue() as string

            if (field.project_grid_field_type.code === 'picker') {
              return (
                <PickerCell
                  value={value}
                  table={table}
                  row={row}
                  column={column}
                />
              )
            }

            if (!value) return

            if (typeCodeIsCheckbox) {
              if (value === 'TrueBox' || value === 'True') {
                value = <CheckBoxOutlined fontSize="small" />
              } else if (value === 'FalseBox' || value === 'False') {
                value = <CheckBoxOutlineBlankOutlined fontSize="small" />
              }
            } else if (columnValueType !== 'original') {
              if (typeCode === 'currency') {
                const formatter = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',

                  // These options are needed to round to whole numbers if that's what you want.
                  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
                })
                value = (
                  <Box sx={{ textAlign: 'right', width: '100%' }}>
                    {formatter.format(+value)}
                  </Box>
                )
              } else if (typeCodeIsTimeRelated) {
                // Format any date or time values so they are readable (they are stored in ISO format)
                value = getFormattedDateTimeString(value as string, typeCode)
              }
            }

            return value
          },
          renderColumnActionsMenuItems:
            columnValueType === 'final' && !isLabelingWorkflow
              ? ({ internalColumnMenuItems, ...rest }) => {
                  return [
                    ...internalColumnMenuItems,
                    <Divider key="divider-1" />,
                    <ToggleDataLineageButton
                      key="data-lineage-toggle"
                      {...rest}
                    />,
                  ]
                }
              : undefined,
        })
      })
    })
    return returnColumns
  }, [fields, valueTypes])

  const columns = useMemo<MRT_ColumnDef<Required<DocumentRow>>[]>(() => {
    return [...documentColumns, ...fieldColumns]
  }, [documentColumns, fieldColumns])

  return columns
}

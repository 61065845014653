import { OverlayState } from '@/hooks/useOverlay'
import { ProjectTag } from '@/types/project-tags'
import { Check } from '@mui/icons-material'
import {
  Box,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import TagChip from './TagChip'
import { useCreateProjectTag } from '@/service-library/hooks/project-tags'
import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { Project } from '@/types/projects'
import { showErrorSnackbar } from '@/utils/snackbars'
import { useProjectContext } from '../project-tables/ProjectProvider'
import generateUuid from '@/utils/generate-uuid'
import { useCreateDocProjectTag } from '@/service-library/hooks/doc-project-tags'
import queryKeys from '@/service-library/query-keys'
import usePermission from '@/hooks/usePermission'

type TagSelectMenuProps = {
  selectedProjectTags: ProjectTag[]
  onTagClick: (tag: ProjectTag) => void
  overlay: OverlayState
  searchText: string
  setSearchText: Dispatch<SetStateAction<string>>
  documentId?: string
  listQueryKey?: QueryKey
}

export default function TagSelectMenu({
  selectedProjectTags,
  onTagClick,
  overlay,
  searchText,
  setSearchText,
  documentId,
  listQueryKey,
}: TagSelectMenuProps) {
  const queryClient = useQueryClient()
  const { project, queryKey: projectQueryKey } = useProjectContext()

  const { hasEditingPermission } = usePermission()
  const canEditProject = hasEditingPermission('edit_projects', project.org_id)

  const visibleTags = project.tags?.filter((tag) => {
    return (
      !searchText || tag.name.toLowerCase().includes(searchText.toLowerCase())
    )
  })

  const visibleSelectedTags =
    visibleTags?.filter((tag) =>
      selectedProjectTags.some((t) => t.id === tag.id),
    ) || []

  const { createProjectTag } = useCreateProjectTag({
    sideEffectQueryKeys: [projectQueryKey, queryKeys.documents.all],
    onMutate: async (projectTag) => {
      await queryClient.cancelQueries({ queryKey: projectQueryKey })

      queryClient.setQueryData<Project>(projectQueryKey, (prev) =>
        prev
          ? {
              ...prev,
              tags: [...(prev.tags || []), projectTag],
            }
          : prev,
      )
    },
    onError: () => {
      showErrorSnackbar(
        'Failed to create tag. Please contact support if this problem persists.',
      )
    },
  })

  const { createDocProjectTag } = useCreateDocProjectTag({
    sideEffectQueryKeys: [queryKeys.documents.details()],
    listQueryKey,
  })

  const onSearchSubmit = () => {
    if (visibleTags?.length) {
      onTagClick(visibleTags[0])
    } else if (canEditProject && documentId) {
      createProjectTag({
        id: generateUuid(),
        color: 'blue',
        name: searchText,
        project_id: project.id,
      }).then((projectTag) => {
        createDocProjectTag({
          id: generateUuid(),
          document_id: documentId,
          project_tag_id: projectTag.id,
          project_tag: projectTag,
        })
      })
    }
  }

  return (
    <Menu
      id="tag-menu"
      anchorEl={overlay.anchorEl as Element}
      open={overlay.isOpen}
      onClose={() => {
        overlay.close()
      }}
      onTransitionExited={() => {
        setSearchText('')
      }}
    >
      <form
        // Wrapping with a form allows submitting by pressing enter
        tabIndex={-1}
        onSubmit={(e) => {
          e.preventDefault()
          onSearchSubmit?.()
        }}
      >
        <TextField
          autoFocus
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.code === 'Escape') return
            e.stopPropagation() // Prevents the text field to lose focus when typing
          }}
          placeholder="Search"
          variant="outlined"
          size="small"
          sx={{ mx: 2, mt: 1 }}
        />
      </form>
      <ListSubheader
        key="menu-subheader-category"
        sx={{ background: 'transparent', lineHeight: '1rem', mb: 1, mt: 2 }}
      >
        Tags
      </ListSubheader>
      {visibleTags?.length === 0 && (
        <MenuItem disabled>
          <i>None</i>
        </MenuItem>
      )}
      {visibleTags?.map((tag) => {
        const existingDocTag = selectedProjectTags.find((t) => t.id === tag.id)
        return (
          <MenuItem
            tabIndex={0}
            key={tag.id}
            onClick={() => {
              onTagClick(tag)
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ height: 24 }}
            >
              {existingDocTag ? (
                <Check fontSize="small" />
              ) : visibleSelectedTags.length > 0 ? (
                <Box
                  sx={{
                    width: 20,
                  }}
                />
              ) : null}
              <TagChip tag={tag} />
            </Stack>
          </MenuItem>
        )
      })}
      {!visibleTags?.length && searchText && documentId && (
        <MenuItem
          sx={{ mt: 1, px: 2.5 }}
          onClick={() => {
            createProjectTag({
              id: generateUuid(),
              color: 'blue',
              name: searchText,
              project_id: project.id,
            }).then((projectTag) => {
              createDocProjectTag({
                id: generateUuid(),
                document_id: documentId,
                project_tag_id: projectTag.id,
                project_tag: projectTag,
              })
            })
          }}
        >
          <Stack spacing={0.5}>
            <Typography variant="caption">Create New Tag:</Typography>
            <Box display="flex">
              <TagChip
                tag={{
                  name: searchText,
                  color: 'blue',
                }}
              />
            </Box>
          </Stack>
        </MenuItem>
      )}
    </Menu>
  )
}

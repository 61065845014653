/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState, useMemo, useRef, useEffect } from 'react'
import debounce from 'lodash.debounce'
import { DocumentRowValue } from '@/types/documents'
import { ProjectGridField } from '@/types/fields'
import { useCreateOrUpdateDocumentRowValueWithDocChange } from '@/hooks/doc-changes-wrapper-hooks'
import generateUuid from '@/utils/generate-uuid'
import { useDocumentCurrentStateViewContext } from '@/components/image-zoom-pan/providers/DocumentCurrentStateViewProvider'
import { useDocumentContext } from '@/components/image-zoom-pan/providers/DocumentProvider'
import { useSelectedWorkflowStateContext } from '@/components/workflows/SelectedWorkflowStateProvider'
import { useDocumentsChangeSetsContext } from './providers/DocumentsChangeSetsProvider'
import { useDocumentRowsContext } from './providers/DocumentRowsProvider'
import { useDocumentRowValuesContext } from './providers/DocumentRowValuesProvider'
import { useFieldErrorContext } from './providers/FieldErrorProvider'
import useGetRelevantWorkflowState from '@/hooks/useRelevantWorkflowState'

export type DataLineageValues = {
  original_value: string | null
  rule_value: string | null
  manual_value: string | null
  final_value: string | null
}

type UseFieldValueProps = {
  field?: ProjectGridField
  documentRowId?: string
}

type UseFieldValueReturn = {
  value: string
  dataLineageValues: DataLineageValues
  hasManualValue: boolean
  refId: string | null
  jsonValue: Record<string, any> | null
  onManualChange: (
    inputField: string | null,
    refId?: string | null,
    jsonObject?: Record<string, any> | null,
  ) => void
  onCategoryChange: (newCategoryId: string | null) => void
  clearValue: () => void
  documentRowValue?: DocumentRowValue
  wasAutoFilled: boolean
}

export default function useFieldValue({
  field,
  documentRowId,
}: UseFieldValueProps): UseFieldValueReturn {
  const { document } = useDocumentContext()
  const { baseRow } = useDocumentRowsContext()
  const fieldId = field?.id || ''
  // documentRow from context is second because there might be one for the base grid,
  // so we don't want to override the value passed for fields in table
  const rowId = documentRowId || baseRow?.id || null
  const { setFieldError } = useFieldErrorContext()
  const { queryKey, getFieldDocumentRowValue } = useDocumentRowValuesContext()
  const { documentCurrentStateView } = useDocumentCurrentStateViewContext()
  const documentsChangeSetsContextData = useDocumentsChangeSetsContext()

  const documentRowValue = getFieldDocumentRowValue(fieldId, rowId)
  const {
    manual_value = null,
    rule_value = null,
    original_value = null,
    final_value = null,
    rule_ref_id,
    original_ref_id,
    final_ref_id,
    project_content_category_item_id,
  } = documentRowValue ? documentRowValue : ({} as DocumentRowValue)

  const [internalValue, setInternalValue] = useState<string | null>(
    manual_value ?? null,
  )
  const [internalRefId, setInternalRefId] = useState<string | null>(
    final_ref_id ?? null,
  )

  const { selectedWorkflowState } = useSelectedWorkflowStateContext()
  const getRelevantWorkflowState = useGetRelevantWorkflowState()
  const state = selectedWorkflowState || getRelevantWorkflowState(document)

  const { createOrUpdateDocumentRowValue } =
    useCreateOrUpdateDocumentRowValueWithDocChange({
      documentId: document?.id,
      workflowId: state?.workflow_id || '',
      workflowStateId: state?.id || '',
      listQueryKey: queryKey,
      documentsChangeSetsContextData,
      onError: () => {
        setInternalValue(manual_value ?? final_value)
        setFieldError(fieldId, rowId, 'Unable to update field.')
      },
    })

  const createOrUpdateDocumentRowValueRef = useRef(
    createOrUpdateDocumentRowValue,
  )

  const updateValue = useCallback(
    function (
      newValues: {
        manual_value?: string | null
        project_content_category_item_id?: string | null
      },
      newRefId?: string | null,
      newJsonObject?: Record<string, any> | null,
    ) {
      const updatedValues = {
        manual_value: newValues.manual_value ?? null,
        project_content_category_item_id:
          newValues.project_content_category_item_id || null,
        manual_ref_id: newRefId || null,
        manual_value_json: newJsonObject || null,
      }

      const valueId = documentRowValue?.id || generateUuid()
      const updatedDocumentRowValue = {
        ...documentRowValue,
        id: valueId,
        project_grid_field_id: fieldId,
        document_row_id: rowId,
        ...updatedValues,
      } as DocumentRowValue
      if (!documentRowValue) {
        return createOrUpdateDocumentRowValueRef.current(
          updatedDocumentRowValue,
          'new',
        )
      }

      if (
        !original_value &&
        !rule_value &&
        updatedValues.manual_value === null &&
        !updatedValues.project_content_category_item_id
      ) {
        setInternalValue(null)
      }
      return createOrUpdateDocumentRowValueRef.current(
        updatedDocumentRowValue,
        'update',
      )
    },
    [documentRowValue, fieldId, rowId, original_value, rule_value],
  )

  // If we switch documents, update the ref
  useEffect(() => {
    createOrUpdateDocumentRowValueRef.current = createOrUpdateDocumentRowValue
  }, [document?.id, createOrUpdateDocumentRowValue])

  const debouncedHandleChange = useMemo(
    () =>
      debounce((newValue, newRefId, newJsonObject = null) => {
        setFieldError(fieldId, rowId, '')

        // We don't want to clear out the manual value if the final value is set, unless it's manual-only text field. If nothing is set
        // after the user leaves the field, the onBlur function will clear it.
        if (
          newValue === '' &&
          final_value &&
          (field?.project_grid_field_type.code !== 'text' ||
            field?.input_behavior !== 'manual_only')
        ) {
          return
        }
        updateValue({ manual_value: newValue }, newRefId, newJsonObject)
      }, 500),
    [
      field?.input_behavior,
      field?.project_grid_field_type.code,
      fieldId,
      final_value,
      rowId,
      setFieldError,
      updateValue,
    ],
  )

  const onCategoryChange = useCallback(
    (newCategoryId: string | null) => {
      setInternalValue(null)
      setFieldError(fieldId, rowId, '')
      updateValue({
        project_content_category_item_id: newCategoryId,
        manual_value: null, // retroactively clean out manual values, since they shouldn't be set for category pickers
      })
    },
    [rowId, fieldId, setFieldError, updateValue],
  )

  const onManualChange = useCallback(
    (
      newValue: string | null,
      newManualRefId?: string | null,
      jsonObject?: Record<string, any> | null,
    ) => {
      const isNoneOption = newValue === 'None' && newManualRefId === 'None'
      setInternalValue(isNoneOption ? '' : newValue)
      newManualRefId !== undefined &&
        setInternalRefId(isNoneOption ? null : newManualRefId)

      if (isNoneOption) {
        setFieldError(fieldId, rowId, '')
        updateValue({ manual_value: '' }, null, jsonObject)
      } else if (newValue !== null) {
        debouncedHandleChange(newValue as string, newManualRefId, jsonObject)
      } else {
        // When deleting or clearing the manual value, we want it to happen instantly
        setFieldError(fieldId, rowId, '')
        updateValue({}, newManualRefId, null)
      }
    },
    [debouncedHandleChange, rowId, fieldId, setFieldError, updateValue],
  )

  const clearValue = useCallback(() => {
    setInternalValue(null)
    setInternalRefId(null)
    setFieldError(fieldId, rowId, '')
    manual_value !== null && updateValue({})
  }, [rowId, fieldId, manual_value, setFieldError, updateValue])

  return {
    value:
      documentCurrentStateView === 'validation'
        ? internalValue ??
          final_value ??
          rule_value ??
          original_value ??
          project_content_category_item_id ??
          ''
        : final_value || '',
    dataLineageValues: {
      original_value,
      rule_value,
      manual_value,
      final_value,
    },
    hasManualValue: manual_value !== null,
    jsonValue:
      documentRowValue?.final_value_json ??
      documentRowValue?.manual_value_json ??
      (documentRowValue?.rule_value_json || null),
    refId:
      documentCurrentStateView === 'validation'
        ? internalRefId ??
          final_ref_id ??
          rule_ref_id ??
          original_ref_id ??
          null
        : final_ref_id,
    onManualChange,
    onCategoryChange,
    clearValue,
    documentRowValue,
    wasAutoFilled: !!rule_value && !original_value && !manual_value,
  }
}

import { useCallback, useEffect, useRef } from 'react'
import generateUuid from '@/utils/generate-uuid'
import useWebsocket, { UseWebsocketOptions } from './useWebsocket'

type RequestBody = {
  endpoint_path: string
  obj_ids: string[]
}[]

type UseSubscribeOptions = {
  onMessage: UseWebsocketOptions['onMessage']
  body?: RequestBody
}

export default function useSubscribe({ onMessage, body }: UseSubscribeOptions) {
  const bodyRef = useRef<RequestBody>()
  const websocket = useWebsocket({ onMessage })
  const websocketRef = useRef(websocket)

  const handleSubscription = useCallback(
    (
      requestBody: RequestBody,
      action: 'subscribe' | 'unsubscribe' = 'subscribe',
    ) => {
      // Checking for open state since we don't want to subscribe if the websocket is closing or closed
      if (websocket?.readyState === WebSocket.OPEN) {
        websocket.send(
          JSON.stringify({
            request_id: generateUuid(),
            route: '/v2/notify',
            method: action === 'subscribe' ? 'post' : 'delete',
            url: '/v2/notify/subscribe/by_core_id',
            body: requestBody,
          }),
        )
      }
    },
    [websocket],
  )

  useEffect(() => {
    if (websocketRef.current !== websocket) {
      websocketRef.current = websocket

      if (bodyRef.current && websocket) {
        handleSubscription(bodyRef.current)
      }
    }
  }, [handleSubscription, websocket])

  useEffect(() => {
    const oldBody = bodyRef.current
    if (JSON.stringify(body) !== JSON.stringify(oldBody)) {
      if (oldBody) {
        handleSubscription(oldBody, 'unsubscribe')
      }
      if (body) {
        handleSubscription(body)
      }
      bodyRef.current = body
    }
  }, [body, handleSubscription])

  return handleSubscription
}

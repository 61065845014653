import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Document } from '@/types/documents'
import { WorkflowState } from '@/types/workflows'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'

type DocumentCurrentStateViewProviderValue = {
  allowedDocumentCurrentStateViews: WorkflowState['allowed_document_views']
  defaultDocumentStateWorkflowView?: WorkflowState['default_document_view']
  documentCurrentStateView?: WorkflowState['default_document_view']
  setDocumentCurrentStateView: (
    view: WorkflowState['default_document_view'],
  ) => void
  currentState?: WorkflowState
}

const DocumentCurrentStateViewContext =
  createContext<DocumentCurrentStateViewProviderValue>(
    {} as DocumentCurrentStateViewProviderValue,
  )

export const useDocumentCurrentStateViewContext = () =>
  useContext(DocumentCurrentStateViewContext)

type DocumentCurrentStateViewProviderProps = {
  children: ReactNode
  document?: Document
}

export default function DocumentCurrentStateViewProvider({
  children,
  document,
}: DocumentCurrentStateViewProviderProps) {
  const { selectedWorkflow } = useSelectedWorkflowContext()

  const documentState = useMemo(
    () =>
      selectedWorkflow.workflow_states?.find((state) =>
        document?.workflow_states_ids?.includes(state.id),
      ),
    [document, selectedWorkflow.workflow_states],
  )

  const allowedDocumentCurrentStateViews = useMemo(
    () => documentState?.allowed_document_views || [],
    [documentState?.allowed_document_views],
  )

  const defaultDocumentStateWorkflowView = documentState?.default_document_view

  const [documentCurrentStateView, setDocumentCurrentStateView] = useState(
    defaultDocumentStateWorkflowView,
  )

  useEffect(() => {
    setDocumentCurrentStateView((prev) => {
      if (
        (!prev && defaultDocumentStateWorkflowView) ||
        (prev && !allowedDocumentCurrentStateViews.includes(prev))
      ) {
        return defaultDocumentStateWorkflowView
      }
      return prev
    })
  }, [allowedDocumentCurrentStateViews, defaultDocumentStateWorkflowView])

  return (
    <DocumentCurrentStateViewContext.Provider
      value={{
        allowedDocumentCurrentStateViews,
        defaultDocumentStateWorkflowView,
        documentCurrentStateView,
        setDocumentCurrentStateView,
        currentState: documentState,
      }}
    >
      {children}
    </DocumentCurrentStateViewContext.Provider>
  )
}

import { Box, Stack } from '@mui/material'
import { ProjectGridField } from '@/types/fields'
import { useDocumentComparisonDataContext } from '@/components//image-zoom-pan/providers/DocumentComparisonDataProvider'
import FieldTable, { ROW_HEIGHT } from './FieldTable'
import Field from './Field'

type FieldsComparisonProps = {
  groupedFields: ProjectGridField[][]
  side: 'left' | 'right'
}

export default function FieldsComparison({
  groupedFields,
  side,
}: FieldsComparisonProps) {
  const { rowsCountByGridId } = useDocumentComparisonDataContext()
  return (
    <Stack
      spacing={1}
      alignItems={side === 'left' ? 'flex-end' : undefined}
      sx={{
        width: '100%',
        p: 1,
      }}
    >
      {groupedFields.map((group, index) => {
        if (group.length === 0) return null
        const isTableField = group[0].fields
        let paddingBottom = undefined

        if (
          isTableField &&
          rowsCountByGridId &&
          group[0].sub_project_grid_id &&
          rowsCountByGridId[group[0].sub_project_grid_id]
        ) {
          const rowsCount = rowsCountByGridId[group[0].sub_project_grid_id]
          const mainRowsCount = rowsCount['main']
          const secondaryRowsCount = rowsCount['secondary']

          if (side === 'right' && mainRowsCount < secondaryRowsCount) {
            paddingBottom =
              (secondaryRowsCount - mainRowsCount) * ROW_HEIGHT -
              (mainRowsCount ? 0 : 79) // To account for the default height the table has when there aren't rows
          } else if (side === 'left' && mainRowsCount > secondaryRowsCount) {
            paddingBottom =
              (mainRowsCount - secondaryRowsCount) * ROW_HEIGHT -
              (secondaryRowsCount ? 0 : 79) // To account for the default height the table has when there aren't rows
          }
        }
        return isTableField ? (
          <Box
            key={index}
            sx={{ mx: -1, width: '100%', pb: `${paddingBottom}px` }}
          >
            <FieldTable tableField={group[0]} />
          </Box>
        ) : (
          group.map((field) => (
            <Box key={field.id} sx={{ width: '400px' }}>
              <Field field={field} />
            </Box>
          ))
        )
      })}
    </Stack>
  )
}

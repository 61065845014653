import { useMemo } from 'react'
import { Document, DocumentFilteringOption } from '@/types/documents'
import { useGetDocumentRows } from '@/service-library/hooks/document-rows'
import { useGetDocuments } from '@/service-library/hooks/documents'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'

export type UseDocumentGridOptions = {
  projectId: string
  projectGridIds: string[]
  queryBy: 'rows' | 'documents'
  workflowStateId?: string
  noFlags?: boolean
  flagCodes?: string[]
  filtering?: DocumentFilteringOption[]
  limit?: string
  ordering?: string[]
  orgIds?: string[] | false
  refetchInterval?: number
  teamIds?: string[]
  tagIds?: string[]
}

const mungeDocumentsToRows = (documents: Document[]) => {
  return documents.map((document) => ({
    document,
    document_id: document.id,
    id: 'filler-row-id',
    project_grid_id: 'filler-grid-id',
  }))
}

const getMetadataServiceId = (endpoint: 'document' | 'row', key: string) => {
  const prefix = endpoint === 'document' ? '' : 'document__'

  const mapping = {
    fileColId: `${prefix}name`,
    workflowStateColId: `${prefix}workflow_states__id`,
    sortByWorkflowState: `${prefix}workflow_states__sort_order`,
    organizationColId: `${prefix}owner_org__name`,
    submitterColId: `${prefix}created_by_user__name`,
    pageCountColId: `${prefix}page_count`,
    uploadColId: `${prefix}uploaded_at`,
    enteredAtColId: `${prefix}document_workflow_states__entered_at`,
    workflowStateAndEnteredAt: `${prefix}document_workflow_states__workflow_state_id__and__processing_started_at__isnull__and__entered_at`,
    workflowAndEnteredat: `${prefix}document_workflow_state__workflow_state__workflow_id__and__entered_at`,
    workflowStatesAndEnteredAt: `${prefix}document_workflow_states__workflow_state__id__in__and__entered_at`,
  }

  return mapping[key as keyof typeof mapping]
}

export const constructDynamicFilters = (
  endpoint: 'row' | 'document',
  fieldFilters: DocumentFilteringOption[] = [],
  workflowStateId?: string,
  workflowId?: string,
) => {
  const enteredAtFilter = fieldFilters.find((f) =>
    f.filterId.startsWith('enteredAtColId'),
  )
  const workflowStateFilter = fieldFilters.find((f) =>
    f.filterId.startsWith('workflowStateColId'),
  )
  const filters: Record<string, string> = {}
  fieldFilters.forEach(({ filterId, value }) => {
    // Doing this for backwards compatibility with old filter ids
    if (filterId === 'processedColId') return

    const metadataColumn = filterId.match(/.*ColId/)
    let filter = filterId
    let adjustedValue: string | null = value
    if (metadataColumn) {
      let endpointId = ''
      // FILTER BY ENTERED AT
      if (metadataColumn[0] === 'enteredAtColId' && workflowStateId) {
        endpointId = getMetadataServiceId(endpoint, 'workflowStateAndEnteredAt')
        adjustedValue = `${workflowStateId}|true|${value}` // processing_started_at__isnull is always true
      }
      // FILTER BY WORKFLOW ID AND ENTERED AT
      else if (
        metadataColumn[0] === 'enteredAtColId' &&
        workflowId &&
        !workflowStateFilter
      ) {
        endpointId = getMetadataServiceId(endpoint, 'workflowAndEnteredat')
        adjustedValue = `${workflowId}|${value}`
      }
      // FILTER BY BOTH WORKFLOW STATES AND ENTERED AT
      else if (metadataColumn[0] === 'enteredAtColId' && workflowStateFilter) {
        endpointId = getMetadataServiceId(
          endpoint,
          'workflowStatesAndEnteredAt',
        )
        adjustedValue = `${workflowStateFilter.value}|${value}`
      }
      // FILTER BY ANY OTHER METADATA COLUMN
      else {
        endpointId = getMetadataServiceId(endpoint, metadataColumn[0])
      }

      if (
        metadataColumn[0] === 'workflowStateColId' &&
        (enteredAtFilter || workflowStateId)
      ) {
        return
      }

      // filterIds include filter mode suffixes, so we append them after getting the endpointId
      filter = `${endpointId}${filterId.substring(metadataColumn[0].length)}`
    }
    filters[filter] = adjustedValue
  })

  // If we're filtering by the entire workflow, rather than a single workflow state...
  if (!enteredAtFilter && !workflowStateId && workflowId) {
    const filterKey = `${
      endpoint === 'row' ? 'document__' : ''
    }workflow_states__workflow_id`
    filters[filterKey] = workflowId
  }

  if (!enteredAtFilter && workflowStateId) {
    const filterKey = `${
      endpoint === 'row' ? 'document__' : ''
    }document_workflow_states__workflow_state_id__and__processing_started_at__isnull`
    filters[filterKey] = `${workflowStateId}|true` // processing_started_at__isnull is always true
  }
  return filters
}

export const constructFieldOrdering = (
  endpoint: 'row' | 'document',
  ordering?: string[],
) => {
  if (!ordering) return undefined

  const updatedOrdering = ordering.reduce<string[]>((acc, colId) => {
    let updatedColId = colId
    const isMetadataColumn = colId.endsWith('ColId')
    if (isMetadataColumn) {
      const metadataColId = colId[0] === '-' ? colId.substring(1) : colId

      // Doing this for backwards compatibility with old ordering logic
      if (metadataColId === 'processedColId') {
        return acc
      }

      let endpointId = getMetadataServiceId(endpoint, metadataColId)
      if (metadataColId === 'workflowStateColId') {
        endpointId = getMetadataServiceId(endpoint, 'sortByWorkflowState')
      }
      updatedColId = colId[0] === '-' ? `-${endpointId}` : endpointId
    }
    acc.push(updatedColId)
    return acc
  }, [])

  return updatedOrdering.join()
}

export default function useDocumentGrid({
  projectId,
  projectGridIds,
  queryBy,
  refetchInterval,
  workflowStateId,
  noFlags,
  flagCodes,
  filtering,
  limit = '40',
  ordering,
  orgIds,
  teamIds,
  tagIds,
}: UseDocumentGridOptions) {
  const { selectedWorkflow } = useSelectedWorkflowContext()

  const documentsFieldsOnly =
    'id,name,page_count,owner_org_id,uploaded_at,created_by_user,pre_process_completed_at,document_flags__count,document_rows__document_row_values__row_value_flags__count,document_workflow_states,project_tags_ids'
  const commonFilters = {
    no_flags: !flagCodes && noFlags ? 'true' : undefined,
    any_flag__flag_level__in: flagCodes?.join(),
    limit,
  }
  const { documentRows, ...documentRowsQuery } = useGetDocumentRows({
    filters: {
      project_grid_id__in: projectGridIds.join(),
      fields__include: 'document_row_values,document',
      document__fields__only: documentsFieldsOnly,
      document__document_workflow_states__fields__only:
        'entered_at,workflow_state_id',
      ...constructDynamicFilters(
        'row',
        filtering,
        workflowStateId,
        selectedWorkflow?.id,
      ),
      ordering: constructFieldOrdering('row', ordering),
      document__self_and_descendants_of_owner_org_id__in: orgIds
        ? orgIds.join()
        : undefined,
      document__team_id__in: teamIds?.join(),
      document__doc_project_tags__project_tag_id__in: tagIds?.join(),
      'api-consistency': 'weak', // the documents list endpoint has api-consistency=weak by default, so we don't need to add it there
      ...commonFilters,
    },
    refetchInterval,
    enabled: queryBy === 'rows',
  })

  const commonDocumentFilters = {
    project_id: projectId,
    ...constructDynamicFilters(
      'document',
      filtering,
      workflowStateId,
      selectedWorkflow?.id,
    ),
    self_and_descendants_of_owner_org_id__in: orgIds
      ? orgIds.join()
      : undefined,
    team_id__in: teamIds?.join(),
    doc_project_tags__project_tag_id__in: tagIds?.join(),
    ...commonFilters,
  }
  const { documents, ...documentsQuery } = useGetDocuments({
    filters: {
      ...commonDocumentFilters,
      fields__only: documentsFieldsOnly,
      document_workflow_states__fields__only: 'entered_at,workflow_state_id',
      ordering: constructFieldOrdering('document', ordering),
    },
    refetchInterval,
    enabled: queryBy === 'documents',
  })

  // To get the count of documents
  const { data } = useGetDocuments({
    paginator: 'page',
    filters: {
      ...commonDocumentFilters,
      fields__only: 'id',
      limit: '1',
    },
    refetchInterval,
    enabled: queryBy === 'documents',
  })

  const rows = useMemo(() => {
    return queryBy === 'rows' ? documentRows : mungeDocumentsToRows(documents)
  }, [documentRows, documents, queryBy])

  const documentGridQuery =
    queryBy === 'rows' ? documentRowsQuery : documentsQuery

  const rowsUniqueDocIds = useMemo(
    () => [...new Set(rows.map(({ document_id }) => document_id))],
    [rows],
  )

  return {
    rows,
    documentsCount: data?.pages[0].count || 0,
    rowsUniqueDocIds,
    ...documentGridQuery,
  }
}

import { Dispatch, SetStateAction, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Settings } from '@mui/icons-material'
import {
  Box,
  FormControlLabel,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  Switch,
} from '@mui/material'
import { WorkflowState } from '@/types/workflows'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import useLocalStorage from '@/hooks/useLocalStorage'
import useOverlay from '@/hooks/useOverlay'
import { useDocumentCount } from '@/components/document-count-provider/DocumentCountProvider'
import LeftBorderedBox from '@/components/validation/LeftBorderedBox'
import { getWorkflowStateGroupsBySortOrder } from '../workflow-states-page/helpers'
import { ThemeColor } from '@/theme/usePixydocsTheme'

type StateFilterProps = {
  workflowStates: WorkflowState[]
  setWorkflowState: (stateId: string) => void
  selectedWorkflowState?: WorkflowState
  showCount?: boolean
  miniMode?: boolean
}

type StateHeadingProps = {
  showInFlightIndicator: boolean
  setShowInFlightIndicator: Dispatch<SetStateAction<boolean>>
}

type StateItemProps = {
  label: string
  onClick: () => void
  miniMode: boolean
  color: ThemeColor
  isSelected?: boolean
  showMiniBorder?: boolean
  idleCount?: number | string
  showCount?: boolean
}

function StateItem({
  label,
  onClick,
  miniMode,
  color,
  isSelected,
  showMiniBorder,
  idleCount,
  showCount,
}: StateItemProps) {
  const theme = useTheme()
  return (
    <Tooltip
      title={label}
      placement="right"
      enterDelay={miniMode ? 0 : 5_000}
      disableHoverListener={!miniMode}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        role="button"
        onClick={onClick}
        sx={{
          minHeight: 28,
          position: 'relative',
          cursor: 'pointer',
          borderRadius: 1,
          px: !miniMode ? 0.5 : 0,
          pl: 0,
          background: isSelected ? theme.palette.action.selected : undefined,
          '&:hover': {
            background: theme.palette.action.hover,
            '& .description-button': {
              display: 'inline-flex',
            },
          },
        }}
      >
        <LeftBorderedBox color={color} showMiniBorder={showMiniBorder}>
          <Box sx={{ pl: 1.5 }}>
            {!miniMode && (
              <Typography variant="body2" component="div" noWrap>
                {label}
              </Typography>
            )}
          </Box>
        </LeftBorderedBox>

        {!miniMode && showCount && (
          <Typography component="span" color="textSecondary" variant="body2">
            {idleCount}
          </Typography>
        )}
      </Stack>
    </Tooltip>
  )
}

function StatesHeading({
  showInFlightIndicator,
  setShowInFlightIndicator,
}: StateHeadingProps) {
  const [showSettings, setShowSettings] = useState(false)
  const settingsOverlay = useOverlay()

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onMouseEnter={() => setShowSettings(true)}
      onMouseLeave={() => {
        settingsOverlay.isOpen && settingsOverlay.close()
        setShowSettings(false)
      }}
    >
      <Typography variant="body1">Workflow States</Typography>

      <IconButton
        onClick={settingsOverlay.open}
        size="small"
        // We use visibility to prevent the IconButton from jumping (height difference)
        sx={{ mr: -0.5, visibility: showSettings ? 'visible' : 'hidden' }}
      >
        <Settings fontSize="inherit" />
      </IconButton>

      {settingsOverlay.anchorEl && (
        <Popover
          open={settingsOverlay.isOpen}
          onClose={() => {
            setShowSettings(false)
            settingsOverlay.close()
          }}
          anchorEl={settingsOverlay.anchorEl as Element}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Box sx={{ p: 1, pl: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showInFlightIndicator}
                  onChange={() => setShowInFlightIndicator((prev) => !prev)}
                />
              }
              label="Show In-Flight Indicators"
            />
          </Box>
        </Popover>
      )}
    </Stack>
  )
}

export default function StateFilter({
  workflowStates,
  selectedWorkflowState,
  setWorkflowState,
  showCount,
  miniMode = false,
}: StateFilterProps) {
  const { projectId } = useParams()
  const theme = useTheme()
  const getThemeColor = useGetThemeColor()
  const [showInFlightIndicator, setShowInFlightIndicator] = useLocalStorage(
    `${projectId}-show-in-flight-indicator`,
    false,
  )

  const { countByWorkflowStateId } = useDocumentCount()

  const displayableWorkflowStates = workflowStates.filter(
    (state) => state.code !== 'processing',
  )
  const stateGroupsBySortOrder = getWorkflowStateGroupsBySortOrder(
    displayableWorkflowStates,
  )

  return (
    <Stack direction="column" spacing={1}>
      {!miniMode && (
        <>
          <StatesHeading
            showInFlightIndicator={showInFlightIndicator}
            setShowInFlightIndicator={setShowInFlightIndicator}
          />
          {workflowStates.length === 0 && (
            <Typography variant="body2" color="text.secondary">
              <i>No Workflow States</i>
            </Typography>
          )}
        </>
      )}

      <StateItem
        label="All States"
        isSelected={!selectedWorkflowState}
        miniMode={miniMode}
        onClick={() => setWorkflowState('')}
        color={theme.palette.mode === 'dark' ? 'white' : 'black'}
      />

      {stateGroupsBySortOrder.map((states) => {
        if (states.length === 0) return
        const workflowStateType = states[0].workflow_state_type
        return (
          <Stack key={states[0].id}>
            {!miniMode && workflowStateType && (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ display: 'block' }}
              >
                {workflowStateType.name}
              </Typography>
            )}
            {states.map((state) => {
              const idleCount = countByWorkflowStateId?.[state.id]
                ? countByWorkflowStateId?.[state.id].total -
                    countByWorkflowStateId?.[state.id].in_flight || '-'
                : '-'
              const isSelected = selectedWorkflowState?.id === state.id
              const color = getThemeColor(state.color)
              return (
                <StateItem
                  key={state.id}
                  label={state.name}
                  isSelected={isSelected}
                  miniMode={miniMode}
                  onClick={() => setWorkflowState(state.id)}
                  color={color}
                  showMiniBorder={
                    showInFlightIndicator &&
                    !!countByWorkflowStateId?.[state.id]?.in_flight
                  }
                  idleCount={idleCount}
                  showCount={showCount}
                />
              )
            })}
          </Stack>
        )
      })}
    </Stack>
  )
}

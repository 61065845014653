import useLocationHelpers from '@/hooks/useLocationHelpers'
import { ProjectLinkedModel } from '@/types/project-linked-models'
import { DeveloperBoard } from '@mui/icons-material'
import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

type ModelCardProps = {
  projectLinkedModel: ProjectLinkedModel
  modelName: string
  modelType: string
}

export default function ModelCard({
  projectLinkedModel,
  modelName,
  modelType,
}: ModelCardProps) {
  const { appendCurrentParamsToUrl } = useLocationHelpers()

  return (
    <Card sx={{ height: '100%' }}>
      <CardActionArea
        component={Link}
        to={`./${appendCurrentParamsToUrl(projectLinkedModel.id)}`}
        sx={{ height: '100%' }}
      >
        <Stack direction="row" spacing={1} sx={{ height: '100%' }}>
          <Box sx={{ p: 2, flexGrow: 1 }}>
            <Typography variant="overline" sx={{ lineHeight: 1 }}>
              {modelType}
            </Typography>
            <Typography variant="h6">{modelName}</Typography>
          </Box>
          <Stack
            sx={{
              width: 100,
              height: '100%',
              background: (theme) => theme.palette.grey[800],
              flexShrink: 0,
            }}
            alignItems="center"
            justifyContent="center"
          >
            <DeveloperBoard sx={{ fontSize: 48 }} />
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

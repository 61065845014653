// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RuleProperty = Record<string, any>
type Params = RuleProperty

export function getDefaultValue(property: RuleProperty) {
  if (Object.hasOwn(property, 'defaultValue')) return property.defaultValue
  switch (property.code) {
    case 'text':
    case 'select':
      return ''
    case 'multi-text':
    case 'multi-select':
    case 'multi-workflow-state-select':
      return []
    case 'rule-schema':
      return {}
    case 'integer':
    case 'decimal':
      return 0
    case 'slider':
      return 0
    case 'checkbox':
      return false
    default:
      return ''
  }
}

export function updateParams(params: Params, properties?: RuleProperty) {
  if (!properties) return params
  const updatedParams: Params = {}
  Object.entries(params).forEach(([key, value]) => {
    const format = properties[key].format
    if (format === 'uuid' && value === '') {
      updatedParams[key] = undefined
    } else {
      updatedParams[key] = value
    }
  })
  return updatedParams
}

import { ProjectGridFieldType } from '@/types/fields'
import { FormTextField } from '../forms'

type ListUrlFieldProps = {
  paramsSchema: ProjectGridFieldType['params_schema']
}

export default function ListUrlField({ paramsSchema }: ListUrlFieldProps) {
  return (
    <FormTextField
      required={paramsSchema.required.includes('list_url')}
      label="List URL"
      name="params.list_url"
      fullWidth
      placeholder="https://example.com"
      helperText="The URL to retrieve items to pick from."
    />
  )
}

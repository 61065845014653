import { Stack } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import Drawer from '@/components/drawer/Drawer'
import OrganizationsFilter from '@/components/filters/OrganizationsFilter'
import FlagsFilter from '@/components/filters/FlagsFilter'
import StateFilter from '@/components/filters/StateFilter'
import AllFieldsFilter from './AllFieldsFilter'
import ViewsFilter from './ViewsFilter'
import { useHotkeys } from 'react-hotkeys-hook'
import { WorkflowState } from '@/types/workflows'
import TagsFilter from '../filters/TagsFilter'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import TeamsFilter from '../filters/TeamsFilter'

type ProjectTablesFilterDrawerProps = {
  overlay: OverlayState
  workflowStates: WorkflowState[]
  selectedWorkflowState?: WorkflowState
  setWorkflowState: (workflowStateId: string) => void
  // Optional
  showAllFields?: boolean
}

export default function ProjectTablesFilterDrawer({
  workflowStates,
  selectedWorkflowState,
  setWorkflowState,
  overlay,
  showAllFields,
}: ProjectTablesFilterDrawerProps) {
  const featureFlags = useFeatureFlagContext()

  useHotkeys('alt+l', () => overlay.toggle(), [overlay.toggle])

  return (
    <Drawer
      overlay={overlay}
      showToggleButton
      showMiniDrawer
      title="Filters"
      miniDrawerChildren={
        <Stack alignItems="center" sx={{ pt: 1 }}>
          <StateFilter
            workflowStates={workflowStates}
            selectedWorkflowState={selectedWorkflowState}
            setWorkflowState={setWorkflowState}
            miniMode
          />
        </Stack>
      }
    >
      <Stack p={2} spacing={3} sx={{ overflowY: 'auto' }}>
        <StateFilter
          workflowStates={workflowStates}
          selectedWorkflowState={selectedWorkflowState}
          setWorkflowState={setWorkflowState}
          showCount
        />
        <ViewsFilter />
        <AllFieldsFilter showAllFields={showAllFields} />
        <FlagsFilter />
        {featureFlags.filter_by_tags && <TagsFilter />}
        <TeamsFilter />
        <OrganizationsFilter />
      </Stack>
    </Drawer>
  )
}

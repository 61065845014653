import { UseGetDetailOptions } from '@/service-library/core-hooks/useGetDetail'
import { useGetDocument } from '@/service-library/hooks/documents'
import { Document } from '@/types/documents'

type UseGetValidationDocumentOptions = UseGetDetailOptions<Document> & {
  onlyBasicData?: boolean
}
export default function useGetValidationDocument({
  onlyBasicData = false,
  ...options
}: UseGetValidationDocumentOptions) {
  const baseFilters: Record<string, string> = {
    fields__include:
      'uploaded_at,created_by_user,document_pages,languages_ids,workflow_states_ids',
    document_pages__fields__include: 'image_urls',
    document_rows__document_row_values__fields__include: 'document_row_id',
  }

  if (!onlyBasicData) {
    baseFilters['fields__include'] +=
      ',document_flags,document_workflow_states,project_tags_ids'
    baseFilters['document_workflow_states__fields__only'] =
      'entered_at,processing_started_at,workflow_state_id'
    baseFilters['document_flags__fields__include'] = 'flag_type'
  }

  const query = useGetDocument({
    ...options,
    filters: {
      ...baseFilters,
      ...options.filters,
    },
  })

  if (query.data && query.document) {
    query.data.document_pages =
      query.data?.document_pages?.sort(
        (a, b) => a.page_number - b.page_number,
      ) || []
    query.document.document_pages =
      query.document?.document_pages?.sort(
        (a, b) => a.page_number - b.page_number,
      ) || []
  }

  return query
}

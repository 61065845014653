import { useEffect, useRef, useState } from 'react'
import { useOutletContext as useSettingsLayoutContext } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import { Typography, Paper, Grid, Stack, Box, Button } from '@mui/material'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import { ProjectGridField } from '@/types/fields'
import PaginatedResponse from '@/types/paginated-response'
import { Project, ProjectGrid } from '@/types/projects'
import useOverlay from '@/hooks/useOverlay'
import { useUpdateProjectGridFields } from '@/service-library/hooks/project-grid-fields'
import { showErrorSnackbar } from '@/utils/snackbars'
import { getBaseGrid, sortBySortOrder } from '@/utils/field-utils'
import MovableList from '@/components/lists/MovableList'
import ProjectBreadcrumb from '@/components/project-tables/ProjectBreadcrumb'
import { FieldSettings } from '@/components/project-wizard/FieldSettings'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import AddFieldDialog from './AddFieldDialog'
import ProjectGridsProvider, {
  useProjectGridsContext,
} from './ProjectGridsProvider'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import CreateFieldDialog from '../create-field-dialog/CreateFieldDialog'

type FieldConfigurationContentProps = {
  project: Project
}

function FieldConfigurationContent({
  project,
}: FieldConfigurationContentProps) {
  const featureflags = useFeatureFlagContext()

  const settingsLayoutContext = useSettingsLayoutContext()
  const isSettings = !!settingsLayoutContext

  const { projectGrids, queryKey } = useProjectGridsContext()
  const baseGrid = getBaseGrid(projectGrids)

  // Saving this as an ID so when the field gets updated, we get the updated field object, rather
  // than having to update the selectedField as well.
  const [selectedFieldId, setSelectedFieldId] = useState<string | null>(null)

  const projectGridFields = projectGrids.flatMap(
    (grid) => grid.project_grid_fields,
  )

  // Sorts by sort order, and attaches sub-fields to tables
  const displayedProjectGridFields = sortBySortOrder(
    baseGrid?.project_grid_fields || [],
  ).map((field) => {
    return {
      ...field,
      fields: sortBySortOrder(
        projectGridFields.filter(
          (projectGridField) =>
            projectGridField.project_grid_id === field.sub_project_grid_id,
        ),
      ),
    }
  })

  const queryClient = useQueryClient()
  const { updateProjectGridFields } = useUpdateProjectGridFields({
    sideEffectQueryKeys: [queryKey],
    onMutate: (updatedFields) => {
      queryClient.setQueryData<InfiniteData<PaginatedResponse<ProjectGrid>>>(
        queryKey,
        (oldData) => {
          const targetGridId = updatedFields[0].project_grid_id
          const updatedPages = oldData?.pages.map((page) => {
            return {
              ...page,
              results: page.results.map((grid) => {
                if (grid.id === targetGridId) {
                  return {
                    ...grid,
                    project_grid_fields: updatedFields,
                  }
                }
                return grid
              }),
            }
          })
          return {
            pageParams: oldData?.pageParams || [],
            pages: updatedPages || [],
          }
        },
      )
    },
    onError: () => {
      showErrorSnackbar('Failed to update field order. Please try again later.')
    },
  })

  // We get the full object here instead of saving it to state so the state doesn't get out of sync.
  const selectedField =
    projectGridFields.find((field) => field.id === selectedFieldId) || null

  const tableFieldBeingAddedToRef = useRef<ProjectGridField | undefined>()
  const [projectGridBeingAddedTo, setGridBeingAddedTo] = useState<
    ProjectGrid | undefined
  >(baseGrid)
  const addFieldOverlay = useOverlay()
  const customFieldOverlay = useOverlay()

  const onTableFieldAdd = (field: ProjectGridField) => {
    tableFieldBeingAddedToRef.current = field
    addFieldOverlay.open()
    setGridBeingAddedTo(
      projectGrids.find((grid) => grid.id === field.sub_project_grid_id),
    )
  }

  // Always reset the grid being added to when the overlay is closed
  // It'll get set to the sub grid if they open it for a table
  useEffect(() => {
    if (!addFieldOverlay.isOpen && !customFieldOverlay.isOpen) {
      setGridBeingAddedTo(baseGrid)
    }
  }, [addFieldOverlay.isOpen, baseGrid, customFieldOverlay.isOpen])

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {/* Title and Button */}
      <Grid
        container
        spacing={2}
        sx={{
          paddingRight: 2,
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '100%',
        }}
      >
        <Grid item xs={12} md={6} sx={{ height: '100%' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Typography variant="h5" color="textPrimary">
              Fields
            </Typography>
            <Button
              startIcon={<Add />}
              onClick={() => {
                addFieldOverlay.open()
                tableFieldBeingAddedToRef.current = undefined
              }}
              variant="text"
            >
              Add Field
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {/* Field List and Field Settings */}
      <Grid
        container
        spacing={2}
        direction="row"
        sx={{
          paddingRight: 2,
          pt: 2,
          height: isSettings ? 'calc(100vh - 148px)' : 'calc(100vh - 330px)',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '100%',
        }}
      >
        <Grid item xs={12} md={6} sx={{ height: '100%' }}>
          <Paper sx={{ height: '100%' }} variant="outlined">
            <MovableList<ProjectGridField>
              dense
              data={displayedProjectGridFields}
              onChange={(items) => {
                updateProjectGridFields(
                  items.map((field, index) => ({
                    ...field,
                    sort_order: index,
                  })),
                )
              }}
              onSelectionChanged={(projectGridField) =>
                setSelectedFieldId(projectGridField.id)
              }
              selectedItem={selectedField}
              onNestedListAdd={onTableFieldAdd}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 0, height: '100%' }}>
          <Paper
            sx={{
              height: '100%',
              width: '100%',
              overflowY: 'auto',
              position: 'relative',
            }}
            variant="outlined"
          >
            {selectedField && (
              <FieldSettings
                key={selectedField.id} // Giving it a key makes it full re-mount when key changes
                field={selectedField}
                isSettings={isSettings}
                isTableField={selectedField.project_grid_id !== baseGrid?.id}
                projectId={project.id}
                projectGridFields={projectGridFields}
              />
            )}
          </Paper>
        </Grid>
      </Grid>

      {baseGrid &&
        projectGridBeingAddedTo &&
        featureflags.create_field_by_info_type && (
          <>
            <CreateFieldDialog
              overlay={addFieldOverlay}
              projectGrid={projectGridBeingAddedTo}
              onCustomFieldClick={() => {
                addFieldOverlay.close()
                customFieldOverlay.open()
              }}
            />
            <AddFieldDialog
              overlay={customFieldOverlay}
              baseGridId={baseGrid.id}
              isSettings={isSettings}
              parentField={tableFieldBeingAddedToRef.current}
              projectGridFields={projectGridFields}
            />
          </>
        )}

      {baseGrid?.id && !featureflags.create_field_by_info_type && (
        <AddFieldDialog
          overlay={addFieldOverlay}
          baseGridId={baseGrid.id}
          isSettings={isSettings}
          parentField={tableFieldBeingAddedToRef.current}
          projectGridFields={projectGridFields}
        />
      )}
    </Box>
  )
}

type FieldConfigurationProps = {
  project?: Project
}

export default function FieldConfiguration({
  project,
}: FieldConfigurationProps) {
  const { project: projectFromContext } = useProjectContext()
  return (
    <ProjectGridsProvider project={project || projectFromContext}>
      <ProjectBreadcrumb label="Fields" url="../fields" />
      <FieldConfigurationContent project={project || projectFromContext} />
    </ProjectGridsProvider>
  )
}

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { QueryKey } from '@tanstack/react-query'
import { ChangeSet, ChipAssignmentChange } from '@/types/doc-changes'
import { DocumentChip, DocumentRowValue } from '@/types/documents'
import {
  useUpdateChipAssignmentWithDocChange,
  useUpdateChipOverriddenDataWithDocChange,
} from '@/hooks/doc-changes-wrapper-hooks'
import { useGetDocumentChips } from '@/service-library/hooks/document-chips'
import { showErrorSnackbar } from '@/utils/snackbars'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import { useSelectedWorkflowStateContext } from '@/components/workflows/SelectedWorkflowStateProvider'
import { useDocumentsChangeSetsContext } from './DocumentsChangeSetsProvider'
import { useDocumentRowValuesContext } from './DocumentRowValuesProvider'
import { useFieldErrorContext } from './FieldErrorProvider'
import { useDocumentContext } from '@/components/image-zoom-pan/providers/DocumentProvider'
import useGetRelevantWorkflowState from '@/hooks/useRelevantWorkflowState'

export type FieldIdentifier = `${string}_${string}`

type DocumentChipsContextValue = {
  documentChips: DocumentChip[]
  hoveredChipId: string | null
  fieldsBeingUpdated: Record<FieldIdentifier, boolean>
  updateChipOverriddenData: (
    originalChip: DocumentChip,
    overrideWithChip: DocumentChip,
  ) => Promise<ChangeSet[] | undefined>
  updateFieldDocumentChips: (
    chips: DocumentChip[],
    updatedFieldId: string | null,
    updatedRowId: string | null,
  ) => Promise<ChangeSet[] | undefined>
  clearFieldDocumentChips: (
    chips: DocumentChip[],
  ) => Promise<ChangeSet[] | undefined>
  getFieldChips: (fieldId: string, documentRowId: string) => DocumentChip[]
  refetchDocumentChips: () => void
  setFieldsBeingUpdated: Dispatch<SetStateAction<Record<FieldIdentifier, true>>>
  setHoveredChipId: Dispatch<SetStateAction<string | null>>
  listQueryKey: QueryKey
}

const DocumentChipsContext = createContext<DocumentChipsContextValue>(
  {} as DocumentChipsContextValue,
)

export const useDocumentChipsContext = () => useContext(DocumentChipsContext)

type DocumentChipsProviderProps = {
  query: ReturnType<typeof useGetDocumentChips>
  children: ReactNode
  documentId?: string
}

export default function DocumentChipsProvider({
  query,
  children,
  documentId,
}: DocumentChipsProviderProps) {
  const { document } = useDocumentContext()
  const { fieldIdentifierRowValueMap, queryKey: valuesListQueryKey } =
    useDocumentRowValuesContext()

  const { selectedWorkflowState } = useSelectedWorkflowStateContext()
  const getRelevantWorkflowState = useGetRelevantWorkflowState()
  const state = selectedWorkflowState || getRelevantWorkflowState(document)

  const documentsChangeSetsContextData = useDocumentsChangeSetsContext()

  const [hoveredChipId, setHoveredChipId] = useState<string | null>(null)

  const [fieldsBeingUpdated, setFieldsBeingUpdated] = useState<
    Record<FieldIdentifier, true>
  >({})

  const fieldIdentifierRowValueMapRef = useRef(fieldIdentifierRowValueMap)

  useEffect(() => {
    fieldIdentifierRowValueMapRef.current = fieldIdentifierRowValueMap
  }, [fieldIdentifierRowValueMap])

  const { documentChips = [], refetch: refetchDocumentChips, queryKey } = query
  const { setFieldError } = useFieldErrorContext()

  const { updateChipsAssignment } = useUpdateChipAssignmentWithDocChange({
    documentId: documentId as string,
    workflowId: state?.workflow_id || '',
    workflowStateId: state?.id || '',
    listQueryKey: queryKey,
    valuesListQueryKey,
    documentsChangeSetsContextData,
    onError: () => {
      showErrorSnackbar(
        'There was a problem updating a field. Please try again later.',
      )
    },
  })

  const { updateChipOverriddenData } = useUpdateChipOverriddenDataWithDocChange(
    {
      documentId: documentId as string,
      workflowId: state?.workflow_id || '',
      workflowStateId: state?.id || '',
      listQueryKey: queryKey,
      documentsChangeSetsContextData,
      onError: () => {
        showErrorSnackbar(
          'There was a problem updating a chip. Please try again later.',
        )
      },
    },
  )

  const getFieldChips = (fieldId: string, documentRowId: string) => {
    return documentChips.filter(
      (chip) =>
        chip.project_grid_field_id === fieldId &&
        documentRowId === chip.document_row_id,
    )
  }

  const updateFieldDocumentChips = (
    /** The chips before updates */
    chips: DocumentChip[],
    /** The field ID to assign the chip to */
    updatedFieldId: string | null,
    /** The document row ID to assign the chip to */
    updatedRowId: string | null,
  ) => {
    const latestFieldIdentifierRowValueMap =
      fieldIdentifierRowValueMapRef.current

    const changesMap: Record<
      FieldIdentifier,
      {
        value?: DocumentRowValue
        updatedChips: DocumentChip[]
        chipsOperation: ChipAssignmentChange['op'][]
      }
    > = {}

    const valueChipChangesMapForReassignedChips: Record<
      string,
      ChipAssignmentChange[]
    > = {}
    setFieldError(updatedFieldId, updatedRowId, '')
    chips.forEach((chip) => {
      setFieldError(chip.project_grid_field_id, chip.document_row_id, '')

      // If the chip is assignment is the same, there is nothing to do
      if (
        chip.project_grid_field_id == updatedFieldId &&
        chip.document_row_id == updatedRowId
      )
        return

      // Save which fields are being updated so we can show it in the UI
      const existingFieldCompoundId: FieldIdentifier = `${chip.project_grid_field_id}_${chip.document_row_id}`
      const updatedFieldCompoundId: FieldIdentifier = `${updatedFieldId}_${updatedRowId}`

      let chipChangeStatus: 'unassigning' | 'assigning' | 'reassigning' =
        'assigning'

      if (!updatedFieldId && !updatedRowId) {
        chipChangeStatus = 'unassigning'
      } else if (
        chip.project_grid_field_id &&
        chip.document_row_id &&
        (chip.project_grid_field_id !== updatedFieldId ||
          chip.document_row_id !== updatedRowId)
      ) {
        chipChangeStatus = 'reassigning'
      }

      const fieldIdentifier =
        chipChangeStatus === 'unassigning'
          ? existingFieldCompoundId
          : updatedFieldCompoundId
      const updatedChip = {
        ...chip,
        project_grid_field_id: updatedFieldId,
        document_row_id: updatedRowId,
      }
      if (Object.hasOwn(changesMap, fieldIdentifier)) {
        changesMap[fieldIdentifier].updatedChips.push(updatedChip)
        changesMap[fieldIdentifier].chipsOperation.push(
          chipChangeStatus === 'unassigning' ? 'remove' : 'add',
        )
      } else {
        changesMap[fieldIdentifier] = {
          value: latestFieldIdentifierRowValueMap[fieldIdentifier],
          updatedChips: [updatedChip],
          chipsOperation: [
            chipChangeStatus === 'unassigning' ? 'remove' : 'add',
          ],
        }
      }

      if (chipChangeStatus === 'reassigning') {
        const rowValueId =
          latestFieldIdentifierRowValueMap[existingFieldCompoundId]?.id
        if (rowValueId) {
          if (
            Object.hasOwn(valueChipChangesMapForReassignedChips, rowValueId)
          ) {
            valueChipChangesMapForReassignedChips[rowValueId].push({
              op: 'remove',
              chip_id: chip.id,
            })
          } else {
            valueChipChangesMapForReassignedChips[rowValueId] = [
              {
                op: 'remove',
                chip_id: chip.id,
              },
            ]
          }
        }
      }

      const fieldIdsBeingUpdated: FieldIdentifier[] = []

      if (['unassigning', 'reassigning'].includes(chipChangeStatus)) {
        fieldIdsBeingUpdated.push(existingFieldCompoundId)
      }

      if (['assigning', 'reassigning'].includes(chipChangeStatus)) {
        fieldIdsBeingUpdated.push(updatedFieldCompoundId)
      }

      setFieldsBeingUpdated((prev) => ({
        ...prev,
        ...fieldIdsBeingUpdated.reduce<Record<string, boolean>>(
          (acc, compoundId) => {
            acc[compoundId] = true
            return acc
          },
          {},
        ),
      }))
    })

    const valueChangesForReassignedChips = Object.entries(
      valueChipChangesMapForReassignedChips,
    ).map(([rowValueId, chipChanges]) => ({
      op: 'update' as const,
      value_id: rowValueId,
      chips: chipChanges,
    }))

    return updateChipsAssignment(
      Object.values(changesMap),
      valueChangesForReassignedChips,
    )
  }

  const clearFieldDocumentChips = (chips: DocumentChip[]) => {
    return updateFieldDocumentChips(chips, null, null)
  }

  useNotifications({
    keys: [documentId],
    callback: ({ action }) => {
      if (
        documentId &&
        (action === 'document_workflow_state_status_changed' ||
          action === 'document_workflow_state_changed')
      ) {
        refetchDocumentChips()
      }
    },
  })

  return (
    <DocumentChipsContext.Provider
      value={{
        documentChips,
        hoveredChipId,
        fieldsBeingUpdated,
        updateChipOverriddenData,
        updateFieldDocumentChips,
        clearFieldDocumentChips,
        getFieldChips,
        refetchDocumentChips,
        setFieldsBeingUpdated,
        setHoveredChipId,
        listQueryKey: queryKey,
      }}
    >
      {children}
    </DocumentChipsContext.Provider>
  )
}

import { Box, Button, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import useOverlay from '@/hooks/useOverlay'
import TagSelectMenu from '../tags/TagSelectMenu'
import { useGetProjectTags } from '@/service-library/hooks/project-tags'
import TagChip from '../tags/TagChip'
import { useDocumentFiltersContext } from '../document-filters/DocumentFiltersProvider'
import { ProjectTag } from '@/types/project-tags'
import { useProjectContext } from '../project-tables/ProjectProvider'

export default function TagsFilter() {
  const { project } = useProjectContext()
  const overlay = useOverlay()
  const [searchText, setSearchText] = useState('')
  const { projectTags } = useGetProjectTags({
    filters: {
      limit: '1000',
      project_id: project.id,
    },
  })

  const filterContext = useDocumentFiltersContext()
  const { documentFilters, handleOnDocumentFilterChange } = filterContext

  const selectedTags = projectTags.filter(
    (tag) => documentFilters?.tagIds?.includes(tag.id) || false,
  )

  const onChangeHandler = (tag: ProjectTag) => {
    handleOnDocumentFilterChange((prev) => {
      if (!prev)
        return {
          flagCodes: [],
          orgIds: [],
          teamIds: [],
          noFlags: false,
          tagIds: [tag.id],
        }

      const newFilters = { ...prev }
      if (newFilters.tagIds.includes(tag.id)) {
        newFilters.tagIds = newFilters.tagIds.filter((id) => id !== tag.id)
      } else {
        newFilters.tagIds = [...newFilters.tagIds, tag.id]
      }
      return newFilters
    })
  }

  return (
    <Stack spacing={1}>
      <Typography variant="body1">Tags</Typography>
      <Box>
        <Button variant="text" onClick={overlay.open} sx={{ ml: -0.5 }}>
          Filter by Tag
        </Button>
      </Box>
      {selectedTags.map((tag) => (
        <Box key={tag.id} sx={{ width: 'min-content' }}>
          <TagChip tag={tag} />
        </Box>
      ))}

      <TagSelectMenu
        overlay={overlay}
        selectedProjectTags={selectedTags}
        onTagClick={onChangeHandler}
        searchText={searchText}
        setSearchText={setSearchText}
      />
    </Stack>
  )
}

import { Stack, Typography } from '@mui/material'

type NodeHeaderProps = {
  topLine: string
  bottomLine: string
}

export default function NodeHeader({ topLine, bottomLine }: NodeHeaderProps) {
  return (
    <Stack spacing={0.5}>
      <Typography
        component="p"
        variant="caption"
        color="text.secondary"
        sx={{
          lineHeight: 1,
        }}
        noWrap
      >
        {topLine}
      </Typography>

      <Typography
        component="p"
        variant="body2"
        sx={{
          lineHeight: 1.2,
        }}
        noWrap
      >
        {bottomLine}
      </Typography>
    </Stack>
  )
}

import { Card } from '@mui/material'
import { ProjectModelVersion } from '@/types/project-models'
import FieldAccuracyChart from '../data-visualization/FieldAccuracyChart'
import { useProjectContext } from '../project-tables/ProjectProvider'

type FieldAccuracyChartProps = {
  modelVersion: ProjectModelVersion
}

export default function FieldAccuracyChartCard({
  modelVersion,
}: FieldAccuracyChartProps) {
  const { project } = useProjectContext()
  return (
    <Card
      sx={{ borderRadius: 2, py: 2, px: 3, overflow: 'visible' }}
      elevation={0}
    >
      <FieldAccuracyChart
        modelVersion={modelVersion}
        height={240}
        project={project}
      />
    </Card>
  )
}

import { Stack, Typography, Box } from '@mui/material'
import CopyIDButton from '../copy-id-button/CopyIDButton'
import { ProjectGridField } from '@/types/fields'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'

type SuperUserFieldInfoProps = {
  field: ProjectGridField
}

export default function SuperUserFieldInfo({ field }: SuperUserFieldInfoProps) {
  return (
    <Stack
      spacing={1}
      sx={{
        p: 2,
        border: `dashed 1px ${SUPER_USER_ONLY_COLOR}`,
        borderRadius: 4,
      }}
    >
      <Typography color="textSecondary">Super User Only</Typography>
      <Box>
        <Box>
          <Typography variant="caption" color="textSecondary">
            Field ID: {field.id}
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color="textSecondary">
            Project Grid ID: {field.project_grid_id}
          </Typography>
        </Box>
      </Box>
      <Stack direction="row" spacing={1}>
        <CopyIDButton
          stringToCopy={field.id}
          label="Copy Field ID"
          isSuperUser
        />
        <CopyIDButton
          stringToCopy={field.project_grid_id}
          label="Copy Project Grid ID"
          isSuperUser
        />
      </Stack>
    </Stack>
  )
}

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { InfiniteData, QueryKey, useQueryClient } from '@tanstack/react-query'
import {
  DocFlagChange,
  NotificationChange,
  NotificationChipChange,
  NotificationRowChange,
  NotificationValueChange,
  TagChange,
  ValueFlagChange,
} from '@/types/doc-changes'
import {
  DocumentRow,
  DocumentRowValue,
  DocumentChip,
  Document,
} from '@/types/documents'
import { DocumentFlag, RowValueFlag, FlagType } from '@/types/flags'
import PaginatedResponse from '@/types/paginated-response'
import { showErrorSnackbar } from '@/utils/snackbars'
import { useGetFlagTypes } from '@/service-library/hooks/flag-types'
import { useQueryKeysMapContext } from '@/components/providers/QueryKeyProvider'
import { useDocumentsChangeSetsContext } from '@/components/validation/providers/DocumentsChangeSetsProvider'
import { FieldIdentifier } from '@/components/validation/providers/DocumentChipsProvider'
import { getRowsByGrid } from '@/components/validation/providers/DocumentRowsProvider'
import useSubscribe from './useSubscribe'

type FlagChange = DocFlagChange | ValueFlagChange
type Flag = DocumentFlag | RowValueFlag

function updateFlags(
  flagChanges: FlagChange[],
  existingFlags: Flag[],
  flagTypes: FlagType[],
  documentId?: string,
) {
  if (!flagChanges.length) return existingFlags

  const flagChangesMap = new Map(
    flagChanges.reduce<[string, FlagChange][]>(
      (acc, flagChange) => [...acc, [flagChange.id, flagChange]],
      [],
    ),
  )

  const updatedFlags = existingFlags.reduce<Flag[]>((acc, flag) => {
    const flagChange = flagChangesMap.get(flag.id)

    if (!flagChange) {
      acc.push(flag)
    } else {
      if (flagChange.op !== 'delete') {
        acc.push({
          ...flag,
          ...flagChange,
        })
      }
      flagChangesMap.delete(flag.id)
    }

    return acc
  }, [])

  if (flagChangesMap.size) {
    flagChangesMap.values().forEach((flagChange) => {
      if (flagChange.op === 'delete') return

      const flagType = flagTypes.find(
        ({ id }) => flagChange.flag_type_id === id,
      )
      const flag = documentId
        ? { ...flagChange, flag_type: flagType, document_id: documentId }
        : { ...flagChange, flag_type: flagType }
      updatedFlags.push(flag as Flag)
    })
  }

  return updatedFlags
}

function updateTagIds(currentTagIds: string[], tagChanges: TagChange[]) {
  const tagsToAdd = new Set<string>()
  const tagsToRemove = new Set<string>()

  tagChanges.forEach(({ op, project_tag_id }) => {
    ;(op === 'add' ? tagsToAdd : tagsToRemove).add(project_tag_id)
  })

  return [
    ...currentTagIds.filter((tagId) => !tagsToRemove.has(tagId)),
    ...[...tagsToAdd].filter((tagId) => !currentTagIds.includes(tagId)),
  ]
}

function requiresInvalidatingData({
  error,
  owner_org_id,
  values,
  rows,
  tags,
  doc_flags,
  id_changes,
  chips,
}: NotificationChange) {
  // If there are no changes or if there is an error, we want to refetch
  // in case something in the cache is wrong.
  return (
    !!error ||
    (!owner_org_id &&
      !values.length &&
      !rows.length &&
      !tags.length &&
      !doc_flags.length &&
      !id_changes.length &&
      !chips.length)
  )
}

function mergeObjectArrays<
  T,
  K extends keyof T,
  N extends keyof (T[K] extends Array<infer U> ? U : never),
>(
  prevData: T[],
  newData: T[],
  identifierKey: K,
  fieldDataThatNeedsMerge?: {
    keyInMainObject: K
    identifierKey: N
  },
) {
  const mergedItems = new Map()

  prevData.forEach((item) => mergedItems.set(item[identifierKey], item))

  newData.forEach((item) => {
    const existingItem = mergedItems.get(item[identifierKey])
    const newItem = {
      ...existingItem,
      ...item,
    }
    if (fieldDataThatNeedsMerge && existingItem) {
      const fieldKey = fieldDataThatNeedsMerge.keyInMainObject
      newItem[fieldKey] = mergeObjectArrays(
        existingItem[fieldKey],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        item[fieldKey] as any[],
        fieldDataThatNeedsMerge.identifierKey,
      )
    }
    mergedItems.set(item[identifierKey], newItem)
  })

  return Array.from(mergedItems.values())
}

type UseDocumentValidationSubscribeOptions = {
  documentId?: string
  documentQueryKey: QueryKey
  chipsListQueryKey: QueryKey
  rowsListQueryKey: QueryKey
  rowValuesListQueryKey: QueryKey
  setFieldsBeingUpdated?: Dispatch<
    SetStateAction<Record<FieldIdentifier, true>>
  >
}

export default function useDocumentValidationSubscribe({
  documentId,
  documentQueryKey,
  chipsListQueryKey,
  rowsListQueryKey,
  rowValuesListQueryKey,
  setFieldsBeingUpdated,
}: UseDocumentValidationSubscribeOptions) {
  const queryClient = useQueryClient()
  const [queryKeysMapContext] = useQueryKeysMapContext()
  const {
    documentsChangeSetIdsRef,
    documentLastChangeSetSentAt,
    setDocumentLastChangeSetSentAt,
  } = useDocumentsChangeSetsContext()
  const combinedNotificationChangeRef = useRef<NotificationChange>()
  const changeSetTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const { flagTypes } = useGetFlagTypes({
    filters: {
      limit: '100',
    },
  })

  const resetData = useCallback(() => {
    if (!documentId) return
    queryClient.invalidateQueries({ queryKey: documentQueryKey })
    queryClient.invalidateQueries({ queryKey: chipsListQueryKey })
    queryClient.invalidateQueries({ queryKey: rowsListQueryKey })
    queryClient.invalidateQueries({ queryKey: rowValuesListQueryKey })
    Object.entries(queryKeysMapContext).forEach(([mapKeys, mapQueryKey]) => {
      if (mapKeys.includes(documentId)) {
        queryClient.invalidateQueries({ queryKey: mapQueryKey })
      }
    })
    setFieldsBeingUpdated?.((prev) => (!Object.keys(prev).length ? prev : {}))
  }, [
    documentId,
    queryClient,
    documentQueryKey,
    chipsListQueryKey,
    rowsListQueryKey,
    rowValuesListQueryKey,
    queryKeysMapContext,
    setFieldsBeingUpdated,
  ])

  const updateDataCache = useCallback(
    async (data: NotificationChange) => {
      if (!documentId) return
      const {
        error,
        owner_org_id,
        values,
        rows,
        tags,
        doc_flags,
        id_changes,
        chips,
      } = data

      // If there are no changes or if there is an error, we want to refetch
      // in case something in the cache is wrong.
      if (requiresInvalidatingData(data)) {
        error && showErrorSnackbar(error)
        resetData()
        return
      }

      if (owner_org_id || doc_flags.length || tags.length) {
        // #region Update Document (Org and Flags)
        await queryClient.cancelQueries({ queryKey: documentQueryKey })
        queryClient.setQueryData<Document>(documentQueryKey, (prev) => {
          if (!prev) return prev
          return {
            ...prev,
            owner_org_id: owner_org_id || prev.owner_org_id,
            document_flags: doc_flags.length
              ? updateFlags(
                  doc_flags,
                  prev.document_flags || [],
                  flagTypes,
                  prev.id,
                )
              : prev.document_flags || [],
            project_tags_ids: updateTagIds(prev.project_tags_ids || [], tags),
          } as Document
        })
      }

      // #region Update Rows
      if (rows.length) {
        await queryClient.cancelQueries({ queryKey: rowsListQueryKey })

        queryClient.setQueryData<InfiniteData<PaginatedResponse<DocumentRow>>>(
          rowsListQueryKey,
          (old = { pages: [], pageParams: [] }) => {
            const rowChangesForRowsMap = new Map(
              rows.reduce<[string, NotificationRowChange][]>(
                (acc, rowChange) => [...acc, [rowChange.id, rowChange]],
                [],
              ),
            )

            const pages = old?.pages.map((page) => {
              if (!rowChangesForRowsMap.size) return page
              return {
                ...page,
                results: page.results.reduce<DocumentRow[]>(
                  (acc, storedRow) => {
                    const rowChange = rowChangesForRowsMap.get(storedRow.id)
                    if (!rowChange) {
                      acc.push(storedRow)
                    } else {
                      if (rowChange.op !== 'delete') {
                        acc.push({
                          ...storedRow,
                          ...rowChange,
                        } as DocumentRow)
                      }
                      rowChangesForRowsMap.delete(storedRow.id)
                    }
                    return acc
                  },
                  [],
                ),
              }
            })
            if (rowChangesForRowsMap.size) {
              const documentId = pages[pages.length - 1].results[0].document_id
              const newRows = []
              rowChangesForRowsMap.values().forEach((rowChange) => {
                if (rowChange.op === 'delete') return
                newRows.push({
                  ...rowChange,
                  project_grid_id: rowChange.grid_id,
                  document_id: documentId,
                } as DocumentRow)
              })

              pages[pages.length - 1] = {
                ...pages[pages.length - 1],
                results: [
                  ...pages[pages.length - 1].results,
                  ...rowChangesForRowsMap.values().map(
                    (rowChange) =>
                      ({
                        ...rowChange,
                        project_grid_id: rowChange.grid_id,
                        document_id: documentId,
                      } as DocumentRow),
                  ),
                ],
              }
            }
            return {
              ...old,
              pages,
            }
          },
        )

        const updatedRowsData =
          queryClient.getQueryData<
            InfiniteData<PaginatedResponse<DocumentRow>>
          >(rowsListQueryKey)

        const allRows =
          updatedRowsData?.pages?.reduce<DocumentRow[]>(
            (acc, page) => [...acc, ...page.results],
            [],
          ) ?? []

        if (allRows.length) {
          // Sort by row number just in case the cache is out of order
          const rowsByGrid = getRowsByGrid(
            allRows.sort((a, b) => a.row_number - b.row_number),
          )

          Object.entries(rowsByGrid).map(async ([gridId, rows]) => {
            const tableQueryKey =
              queryKeysMapContext[
                `table-rows-${allRows[0].document_id}-${gridId}`
              ]
            if (tableQueryKey) {
              await queryClient.cancelQueries({ queryKey: tableQueryKey })

              queryClient.setQueryData<
                InfiniteData<PaginatedResponse<DocumentRow>>
              >(tableQueryKey, (prev) =>
                prev
                  ? {
                      ...prev,
                      pages: [
                        {
                          ...prev.pages[0],
                          results: rows,
                        },
                      ],
                    }
                  : undefined,
              )
            }
          })
        }
      }

      const deletedRowsIds = rows
        .filter(({ op }) => op === 'delete')
        .map(({ id }) => id)

      if (values.length || deletedRowsIds.length || id_changes.length) {
        // #region Update Row Values
        await queryClient.cancelQueries({ queryKey: rowValuesListQueryKey })
        queryClient.setQueryData<
          InfiniteData<PaginatedResponse<DocumentRowValue>>
        >(rowValuesListQueryKey, (old = { pages: [], pageParams: [] }) => {
          const valueChangesForRowValuesMap = new Map(
            values.reduce<[string, NotificationValueChange][]>(
              (acc, valueChange) => [...acc, [valueChange.id, valueChange]],
              [],
            ),
          )

          const valueIdChangesMap = id_changes.reduce<Record<string, string>>(
            (acc, idChange) => {
              if (idChange.type === 'value') {
                // the old id would be the id sent in the request
                acc[idChange.old_id] = idChange.new_id
              }
              return acc
            },
            {},
          )

          const pages = old?.pages.map((page) => {
            if (
              !valueChangesForRowValuesMap.size &&
              !Object.keys(valueIdChangesMap).length
            )
              return page
            const updatedResults = page.results.map((storedValue) => {
              if (valueIdChangesMap[storedValue.id]) {
                storedValue.id = valueIdChangesMap[storedValue.id]
                delete valueIdChangesMap[storedValue.id]
              }
              const valueChange = valueChangesForRowValuesMap.get(
                storedValue.id,
              )
              if (!valueChange) return storedValue

              const updatedValue = {
                ...storedValue,
                ...valueChange,
                in_cache: false,
                row_value_flags: updateFlags(
                  valueChange.flags,
                  storedValue.row_value_flags || [],
                  flagTypes,
                ),
              } as DocumentRowValue
              valueChangesForRowValuesMap.delete(storedValue.id)

              return updatedValue
            })

            if (deletedRowsIds.length) {
              updatedResults.filter(
                ({ document_row_id }) =>
                  !deletedRowsIds.includes(document_row_id),
              )
            }

            return {
              ...page,
              results: updatedResults,
            }
          })
          if (valueChangesForRowValuesMap.size) {
            const newValues = valueChangesForRowValuesMap.values().map(
              (valueChange) =>
                ({
                  ...valueChange,
                  row_value_flags: updateFlags(
                    valueChange.flags,
                    [],
                    flagTypes,
                  ),
                } as DocumentRowValue),
            )
            pages[pages.length - 1] = {
              ...pages[pages.length - 1],
              results: [...pages[pages.length - 1].results, ...newValues],
            }
          }
          return {
            ...old,
            pages,
          }
        })
      }

      // #region Update Chips
      const chipFieldKeysThatChanged: Set<FieldIdentifier> = new Set()
      const chipsAssignmentMap: Record<
        string,
        Pick<DocumentChip, 'id' | 'project_grid_field_id' | 'document_row_id'>
      > = {}

      // Get the chip assignment changes from the value changes
      if (values.length) {
        const valueChangesMap = new Map(
          values.reduce<[string, NotificationValueChange][]>(
            (acc, valueChange) => [...acc, [valueChange.id, valueChange]],
            [],
          ),
        )

        const updatedRowValuesData = queryClient.getQueryData<
          InfiniteData<PaginatedResponse<DocumentRowValue>>
        >(rowValuesListQueryKey)

        updatedRowValuesData?.pages?.forEach((page) => {
          page.results.forEach(
            ({ id, project_grid_field_id, document_row_id }) => {
              const valueChange = valueChangesMap.get(id)
              if (!valueChange) return

              const valueChipIds = valueChange.doc_chip_ids

              // If the field doc_chips_ids was not included, it means the chips for the field didn't change.
              if (valueChipIds) {
                chipFieldKeysThatChanged.add(
                  `${project_grid_field_id}_${document_row_id}`,
                )
                if (valueChipIds.length) {
                  valueChipIds.forEach((id) => {
                    chipsAssignmentMap[id] = {
                      id,
                      project_grid_field_id,
                      document_row_id,
                    }
                  })
                }
              }
            },
          )
        })
      }

      if (
        chips.length ||
        chipFieldKeysThatChanged.size ||
        deletedRowsIds.length
      ) {
        const chipChangesMap = new Map(
          chips.reduce<[string, NotificationChipChange][]>(
            (acc, chipChange) => [...acc, [chipChange.id, chipChange]],
            [],
          ),
        )
        await queryClient.cancelQueries({ queryKey: chipsListQueryKey })
        queryClient.setQueryData<InfiniteData<PaginatedResponse<DocumentChip>>>(
          chipsListQueryKey,
          (old = { pages: [], pageParams: [] }) => {
            const pages = old?.pages.map((page) => {
              return {
                ...page,
                results: page.results.map((storedChip) => {
                  let updatedChip = storedChip
                  const chipChange = chipChangesMap.get(storedChip.id)
                  if (chipChange) {
                    updatedChip = {
                      ...storedChip,
                      overridden_data: chipChange.overridden_data,
                    }
                  }

                  const chipFieldKey =
                    `${storedChip.project_grid_field_id}_${storedChip.document_row_id}` as FieldIdentifier
                  if (
                    (chipFieldKeysThatChanged.has(chipFieldKey) &&
                      !chipsAssignmentMap[storedChip.id]) ||
                    (storedChip.document_row_id &&
                      deletedRowsIds.includes(storedChip.document_row_id))
                  ) {
                    return {
                      ...updatedChip,
                      project_grid_field_id: null,
                      document_row_id: null,
                    }
                  }
                  if (chipsAssignmentMap[storedChip.id]) {
                    return {
                      ...updatedChip,
                      ...chipsAssignmentMap[storedChip.id],
                    }
                  }
                  return updatedChip
                }),
              }
            })
            return {
              ...old,
              pages,
            }
          },
        )
      }
      setFieldsBeingUpdated?.((prev) => (!Object.keys(prev).length ? prev : {}))
    },
    [
      chipsListQueryKey,
      documentId,
      documentQueryKey,
      flagTypes,
      queryClient,
      queryKeysMapContext,
      resetData,
      rowValuesListQueryKey,
      rowsListQueryKey,
      setFieldsBeingUpdated,
    ],
  )

  const combineNotificationChanges = (newChange: NotificationChange) => {
    const prevChange = combinedNotificationChangeRef.current
    if (!prevChange) return newChange

    const invalidateData =
      requiresInvalidatingData(prevChange) ||
      requiresInvalidatingData(newChange)

    const combinedChange: NotificationChange = {
      change_set_ids: [
        ...prevChange.change_set_ids,
        ...newChange.change_set_ids,
      ],
      error: prevChange.error || newChange.error, // We want to keep the error, if it exists, to show the user that something went wrong.
      values: [],
      rows: [],
      tags: [],
      doc_flags: [],
      id_changes: [],
      chips: [],
    }

    if (!invalidateData) {
      combinedChange.owner_org_id =
        newChange.owner_org_id || prevChange.owner_org_id // We keep the new org id if it exists
      combinedChange.values = mergeObjectArrays(
        prevChange.values,
        newChange.values,
        'id',
        {
          keyInMainObject: 'flags',
          identifierKey: 'id',
        },
      )
      combinedChange.rows = mergeObjectArrays(
        prevChange.rows,
        newChange.rows,
        'id',
      )
      combinedChange.tags = mergeObjectArrays(
        prevChange.tags,
        newChange.tags,
        'project_tag_id',
      )
      combinedChange.doc_flags = mergeObjectArrays(
        prevChange.doc_flags,
        newChange.doc_flags,
        'id',
      )
      combinedChange.id_changes = [
        ...prevChange.id_changes,
        ...newChange.id_changes,
      ]
      combinedChange.chips = mergeObjectArrays(
        prevChange.chips,
        newChange.chips,
        'id',
      )
    }

    return combinedChange
  }

  const lastChangeSetSentAt = useMemo(
    () => (documentId ? documentLastChangeSetSentAt[documentId] : ''),
    [documentId, documentLastChangeSetSentAt],
  )

  useEffect(() => {
    if (!documentId) return

    setFieldsBeingUpdated?.((prev) => (Object.keys(prev).length ? {} : prev))
    if (documentsChangeSetIdsRef.current[documentId]) {
      delete documentsChangeSetIdsRef.current[documentId]
    }
    setDocumentLastChangeSetSentAt?.((prev) => {
      if (!prev[documentId]) return prev
      const updated = { ...prev }
      delete updated[documentId]
      return updated
    })
    combinedNotificationChangeRef.current = undefined
  }, [
    documentId,
    documentsChangeSetIdsRef,
    setDocumentLastChangeSetSentAt,
    setFieldsBeingUpdated,
  ])

  useEffect(() => {
    if (!documentId) return

    if (lastChangeSetSentAt) {
      if (changeSetTimeoutRef.current) {
        clearTimeout(changeSetTimeoutRef.current) // Clear any existing timeout
      }

      // If after 30 seconds of the last request, we have not received all the notifications
      // we just reset the data
      changeSetTimeoutRef.current = setTimeout(() => {
        if (documentsChangeSetIdsRef.current[documentId]?.size) {
          documentsChangeSetIdsRef.current[documentId] = new Set()

          setDocumentLastChangeSetSentAt?.((prev) => {
            if (!prev[documentId]) return prev
            const updated = { ...prev }
            delete updated[documentId]
            return updated
          })

          resetData()
        }
      }, 30000)
    }

    return () => {
      if (changeSetTimeoutRef.current) {
        clearTimeout(changeSetTimeoutRef.current)
      }
    }
  }, [
    documentId,
    documentsChangeSetIdsRef,
    lastChangeSetSentAt,
    resetData,
    setDocumentLastChangeSetSentAt,
  ])

  useSubscribe({
    onMessage: useCallback(
      async (event: MessageEvent) => {
        if (!documentId) return

        const data = JSON.parse(event.data)
        if (
          (data.obj_id && data.obj_id !== documentId) ||
          data.action !== '/v2/pd-rules/doc_changes|new' ||
          (data.stripped_partial_msg &&
            data.stripped_partial_msg.obj_id !== documentId)
        )
          return

        const currentNotificationChange = data.stripped_partial_msg
          ? {
              // We only keep the change ids, which should be there unless they are too long, which is not likely.
              // We leave the other data as empty, so it can be marked as requiring invalidation.
              change_set_ids: data.stripped_partial_msg.action_data
                .change_set_ids as string[],
              values: [],
              rows: [],
              tags: [],
              doc_flags: [],
              id_changes: [],
              chips: [],
            }
          : (data.action_data as NotificationChange)

        const documentsChangeSetIds = documentsChangeSetIdsRef.current
        if (documentsChangeSetIds[documentId]?.size) {
          const updatedChangeSetIds = documentsChangeSetIds[documentId]
          currentNotificationChange.change_set_ids.forEach((id) => {
            updatedChangeSetIds.delete(id)
          })
          // If not all of the changes were included in the notification, we need to update the combined change,
          // and wait until all of the changes are included.
          if (updatedChangeSetIds.size) {
            combinedNotificationChangeRef.current = combineNotificationChanges(
              currentNotificationChange,
            )
          } else {
            updateDataCache(
              combineNotificationChanges(currentNotificationChange),
            )
            combinedNotificationChangeRef.current = undefined
          }
        } else {
          updateDataCache(combineNotificationChanges(currentNotificationChange))
          combinedNotificationChangeRef.current = undefined
        }
      },
      [documentId, documentsChangeSetIdsRef, updateDataCache],
    ),
    body: documentId
      ? [
          {
            endpoint_path: '/v2/pd/documents',
            obj_ids: [documentId],
          },
        ]
      : undefined,
  })
}

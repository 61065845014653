import useGetThemeColor from '@/hooks/useGetThemeColor'
import { Box } from '@mui/material'

type TagChipProps = {
  tag?: { name: string; color: string }
  invisible?: boolean
}

export default function TagChip({ tag, invisible = false }: TagChipProps) {
  const getThemeColor = useGetThemeColor()
  return tag ? (
    <Box
      sx={{
        p: 0.25,
        px: 0.5,
        background: getThemeColor(tag.color),
        fontSize: 10,
        borderRadius: 1,
        height: 'fit-content',
        fontWeight: 'bold',
        textWrap: 'nowrap',
        visibility: invisible ? 'hidden' : 'visible',
      }}
    >
      {tag.name}
    </Box>
  ) : null
}

import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import { Document } from '@/types/documents'

export default function useGetRelevantWorkflowState() {
  const { selectedWorkflow } = useSelectedWorkflowContext()
  return (document?: Document) => {
    if (!document) return
    return selectedWorkflow?.workflow_states?.find(
      (state) =>
        document.workflow_states_ids?.includes(state.id) ||
        document.document_workflow_states?.some(
          (dws) => dws.workflow_state_id === state.id,
        ),
    )
  }
}

import { Namespace } from '@/types/info-types'
import { ProjectModelVersionInfoType } from '@/types/project-models'
import { Node } from '@xyflow/react'

type InputOutputInfoTypes = {
  inputInfoTypeNodes: Node[]
  outputInfoTypeNodes: Node[]
}

export function getInputOutputInfoTypes(
  infoTypeNodes: Node[],
): InputOutputInfoTypes {
  return infoTypeNodes.reduce<InputOutputInfoTypes>(
    (acc, infoTypeNode) => {
      if (infoTypeNode.data.flow === 'in') {
        acc.inputInfoTypeNodes.push(infoTypeNode)
      } else {
        acc.outputInfoTypeNodes.push(infoTypeNode)
      }
      return acc
    },
    {
      inputInfoTypeNodes: [],
      outputInfoTypeNodes: [],
    },
  )
}

export function getVersionInfoTypesByNamespace(
  versionInfoTypes: ProjectModelVersionInfoType[],
) {
  const namespaceMap: Record<
    string,
    {
      namespace: Namespace
      versionInfoTypes: ProjectModelVersionInfoType[]
    }
  > = {}
  versionInfoTypes.forEach((versionInfoType) => {
    const namespaceId = versionInfoType.info_type.namespace.id
    namespaceMap[namespaceId] ||= {
      namespace: versionInfoType.info_type.namespace,
      versionInfoTypes: [],
    }
    namespaceMap[namespaceId].versionInfoTypes.push(versionInfoType)
  })
  return namespaceMap
}

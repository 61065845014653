import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'
import { PubModel } from '@/types/pub-models'

const service = buildService<PubModel>({
  pathCategory: 'pub_models',
})

export function useGetPubModel({
  id,
  ...options
}: UseGetDetailOptions<PubModel>) {
  const query = useGetDetail<PubModel>({
    id,
    queryKey: queryKeys.pubModels.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    pubModel: query.data,
    ...query,
  }
}

export function useGetPubModels({
  filters,
  ...options
}: UseGetListOptions<PubModel> = {}) {
  const query = useGetList<PubModel>({
    filters,
    queryKey: queryKeys.pubModels.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    pubModels: query.allData,
    ...query,
  }
}

import { useEffect, useState } from 'react'
import throttle from 'lodash.throttle'
import { MRT_ColumnDef } from 'material-react-table'
import { Download } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Typography, Button, Stack, IconButton, Tooltip } from '@mui/material'
import { Document } from '@/types/documents'
import { WorkflowState } from '@/types/workflows'
import { OverlayState } from '@/hooks/useOverlay'
import { useArchiveDocuments } from '@/service-library/hooks/documents'
import { useGetDocumentWorkflowStates } from '@/service-library/hooks/document-workflow-states'
import { prettifyDate } from '@/utils/getFormattedDateTimeString'
import { handleDownloadOriginalDocument } from '@/utils/download-file'
import CopyIDButton from '@/components/copy-id-button/CopyIDButton'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import useZerapixTable from '@/components/zerapix-table/useZerapixTable'
import ZerapixTable from '@/components/zerapix-table/ZerapixTable'

type FailedDocumentsDialogProps = {
  count: number
  processingWorkflowState: WorkflowState
  overlay: OverlayState
}

const columns: MRT_ColumnDef<
  Partial<Document> & {
    id: string
    short_description: string
    long_description: string
  }
>[] = [
  {
    header: 'Document',
    accessorKey: 'name',
  },
  {
    header: 'Reason',
    Cell: ({ row }) => (
      <Tooltip
        title={
          row.original.long_description ||
          'Something went wrong when processing this document. Please reprocess the document or contact support.'
        }
        disableInteractive
        enterDelay={1000}
      >
        <Typography variant="body2">
          {row.original.short_description || 'An Error Occurred'}
        </Typography>
      </Tooltip>
    ),
  },
  {
    id: 'submitter',
    header: 'Submitter',
    accessorFn: (originalRow) => originalRow.created_by_user?.name || '',
  },
  {
    id: 'uploaded_at',
    header: 'Upload Date',
    accessorFn: (originalRow) =>
      prettifyDate(originalRow.uploaded_at as string),
  },
]

export default function FailedDocumentsDialog({
  count,
  processingWorkflowState,
  overlay,
}: FailedDocumentsDialogProps) {
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({})
  const [deletingType, setDeletingType] = useState<'all' | 'selected' | null>(
    null,
  )

  const {
    documentWorkflowStates = [],
    isLoading,
    refetch,
  } = useGetDocumentWorkflowStates({
    filters: {
      limit: '1000',
      workflow_state_id__in: processingWorkflowState.id,
      status__in: 'error',
      fields__include: 'document',
      document__fields__only: 'uploaded_at,id,name,created_by_user',
    },
    enabled: overlay.isOpen, // Only fetch the documents once the overlay is opened
  })

  const throttledRefetch = throttle(refetch, 1000)

  const documentsWithErrorDescription = documentWorkflowStates.map(
    ({ document_id, document, short_description, long_description }) => ({
      ...document,
      id: document_id,
      short_description,
      long_description,
    }),
  )

  const { archiveDocuments, isLoading: isArchiving } = useArchiveDocuments({
    onSuccess: (_response, archivedDocuments) => {
      if (archivedDocuments.length === documentsWithErrorDescription.length) {
        overlay.close()
      } else {
        refetch()
      }
      setDeletingType(null)
    },
  })

  const selectedDocuments = Object.keys(selectedRows)

  function handleDelete(type: 'all' | 'selected') {
    const documentsToDelete =
      type === 'all'
        ? documentsWithErrorDescription.map(({ id }) => id)
        : selectedDocuments
    setSelectedRows({})
    setDeletingType(type)
    archiveDocuments(documentsToDelete)
  }

  // Refetch failed documents since more documents may have failed since we opened the dialog
  useNotifications({
    keys: ['document_workflow_state_status_changed'],
    callback: () => overlay.isOpen && throttledRefetch(),
  })

  useEffect(() => {
    !documentsWithErrorDescription.length && !isLoading && overlay.close()
  }, [documentsWithErrorDescription.length, isLoading, overlay])

  const table = useZerapixTable({
    data: documentsWithErrorDescription,
    columns,
    enableTopToolbar: true,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 120,
      },
    },
    enableRowActions: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getRowId: (originalRow) => originalRow.id,
    muiTablePaperProps: {
      elevation: 0,
    },
    onRowSelectionChange: setSelectedRows,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <Stack direction="row" alignItems="center">
        <Tooltip title="Download" enterDelay={500}>
          <IconButton
            size="small"
            onClick={() => {
              handleDownloadOriginalDocument(row.id)
            }}
          >
            <Download />
          </IconButton>
        </Tooltip>
        <CopyIDButton asIconButton stringToCopy={row.id} size="small" />
      </Stack>
    ),
    renderTableActions: () => (
      <Stack direction="row" spacing={1}>
        <LoadingButton
          color="error"
          disabled={
            selectedDocuments.length === 0 ||
            (isArchiving && deletingType === 'all')
          }
          variant="text"
          loading={isLoading || (isArchiving && deletingType === 'selected')}
          onClick={() => {
            handleDelete('selected')
          }}
        >
          Delete Selected ({selectedDocuments.length})
        </LoadingButton>
        <LoadingButton
          color="error"
          disabled={isArchiving && deletingType === 'selected'}
          variant="text"
          loading={isLoading || (isArchiving && deletingType === 'all')}
          onClick={() => {
            handleDelete('all')
          }}
        >
          Delete All
        </LoadingButton>
      </Stack>
    ),
    state: {
      rowSelection: selectedRows,
      showSkeletons: isLoading,
    },
    localization: {
      toggleSelectRow: '',
    },
  })

  return (
    <Dialog
      title={`Failed Documents (${
        documentsWithErrorDescription.length || count
      })`}
      {...overlay}
      maxWidth="lg"
    >
      <DialogContent>
        <Typography sx={{ pb: 2 }}>
          These documents failed during processing. You can delete or reprocess
          them. If they continue to fail, please contact support.
        </Typography>
        <ZerapixTable table={table} />
      </DialogContent>

      <DialogFooter>
        <Button
          variant="text"
          onClick={() => {
            overlay.close()
          }}
        >
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

import { memo } from 'react'
import { Stack } from '@mui/material'
import { green, orange, red } from '@mui/material/colors'
import MetricHeading from './MetricHeading'
import PercentageBar from './PercentageBar'

type OverallSTPMetricProps = {
  averageOverall?: number
}

export const colorMap: [number, string][] = [
  [50, green['500']],
  [30, orange['A400']],
  [20, red['A400']],
]

function OverallSTPMetric({ averageOverall }: OverallSTPMetricProps) {
  const color =
    colorMap.find(([threshold]) => (averageOverall || 0) >= threshold)?.[1] ||
    'red'
  return (
    <Stack spacing={1}>
      <MetricHeading
        heading="Overall STP"
        tooltipMessage='The percentage of documents that move through the workflow to a "Done" state without stopping.'
      />
      <PercentageBar percentage={averageOverall} color={color} />
    </Stack>
  )
}

export default memo(OverallSTPMetric)

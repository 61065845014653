import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { DocProjectTag } from '@/types/project-tags'

const service = buildService<DocProjectTag>({
  pathCategory: 'doc_project_tags',
})

export function useGetDocProjectTag({
  id,
  ...options
}: UseGetDetailOptions<DocProjectTag>) {
  const query = useGetDetail<DocProjectTag>({
    id,
    queryKey: queryKeys.docProjectTags.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    docProjectTag: query.data,
    ...query,
  }
}

export function useCreateDocProjectTag(
  options: UseCreateDetailOptions<DocProjectTag> = {},
) {
  const mutation = useCreateDetail<DocProjectTag>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createDocProjectTag: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateDocProjectTag(
  options: UseUpdateDetailOptions<DocProjectTag> = {},
) {
  const mutation = useUpdateDetail<DocProjectTag>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateDocProjectTag: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDocProjectTag(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<DocProjectTag>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteDocProjectTag: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetDocProjectTags({
  filters,
  ...options
}: UseGetListOptions<DocProjectTag> = {}) {
  const query = useGetList<DocProjectTag>({
    filters,
    queryKey: queryKeys.docProjectTags.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    docProjectTags: query.allData,
    ...query,
  }
}

export function useUpdateDocProjectTags(
  options: UseUpdateListOptions<DocProjectTag> = {},
) {
  const mutation = useUpdateList<DocProjectTag>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateDocProjectTags: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDocProjectTags({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<DocProjectTag>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteDocProjectTags: mutation.mutateAsync,
    ...mutation,
  }
}

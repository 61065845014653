/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, memo, useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import { Keyboard, History } from '@mui/icons-material'
import {
  Stack,
  Alert,
  Tooltip,
  IconButton,
  TextFieldProps,
  Box,
  InputAdornment,
  Theme,
} from '@mui/material'
import { DocumentRow } from '@/types/documents'
import { ChangeSet } from '@/types/doc-changes'
import { ProjectGridField as TSField } from '@/types/fields'
import useTableHotKeys from '@/hooks/useTableHotKeys'
import FlagsBulletList from '@/components/flags/FlagsBulletList'
import { useDocumentComparisonDataContext } from '@/components/image-zoom-pan/providers/DocumentComparisonDataProvider'
import { useDocumentContext } from '@/components/image-zoom-pan/providers/DocumentProvider'
import TextInputField from '@/components/inputs/TextInputField'
import IntegerField from './fields/IntegerField'
import DecimalField from './fields/DecimalField'
import CheckboxField from './fields/CheckboxField'
import CurrencyField from './fields/CurrencyField'
import DateField from './fields/DateField'
import TimeField from './fields/TimeField'
import DateTimeField from './fields/DateTimeField'
import CategoryField from './fields/CategoryField'
import ListLookupField from './fields/ListLookupField'
import PhoneNumberTextField from './fields/PhoneNumberTextField'
import { useDocumentChipsContext } from './providers/DocumentChipsProvider'
import { useDocumentRowsContext } from './providers/DocumentRowsProvider'
import { useFieldErrorContext } from './providers/FieldErrorProvider'
import { useSelectedFieldContext } from './providers/SelectedFieldProvider'
import FieldUpdatingIndicator from './FieldUpdatingIndicator'
import FieldPortalProvider from './FieldPortalProvider'
import FieldWithChipsAndSuggestions from './FieldWithChipsAndSuggestions'
import FocusContainer from './FocusContainer'
import PickerField from './fields/PickerField'
import useFieldValue from './useFieldValue'
import EntrataLiveVendorList from './fields/EntrataLiveVendorList'
import { LiveListEntity } from '@/types/live-lists'
import { EntrataVendor } from '@/types/entrata-vendor'

type FieldProps = {
  field: TSField
  documentRow?: DocumentRow
  isInTable?: boolean
  fieldPosition?: {
    column: number
    row: number
  }
  tableGrid?: {
    columns: number
    rows: number
  }
  tableBodyEl?: HTMLTableSectionElement | null
  deleteDocumentRows?: (rowIds: string[]) => Promise<ChangeSet[] | undefined>
  insertDocumentRow?: (position: 'before' | 'after') => void
}

const fieldTypesThatAllowChipSuggestions = [
  'cc_num',
  'text',
  'phone',
  'int',
  'decimal',
  'currency',
]

function getFieldHeaderColor(
  fieldId: string,
  palette: Theme['palette'],
  differentFields?: Set<string>,
  isInTable?: boolean,
) {
  if (!differentFields || isInTable) {
    return undefined
  }

  const isDifferentField =
    differentFields.has('all') || differentFields.has(fieldId)

  const color = isDifferentField ? palette.error.main : palette.success.main
  return `${color} !important`
}

function Field({
  field,
  documentRow,
  isInTable,
  fieldPosition,
  tableGrid,
  tableBodyEl,
  deleteDocumentRows,
  insertDocumentRow,
}: FieldProps) {
  const { project_grid_field_type } = field
  const fieldCode = project_grid_field_type.code
  const focusContainerRef = useRef<HTMLDivElement>(null)

  const { setSelectedField, isSelectedField } = useSelectedFieldContext()
  const { isDisabled } = useDocumentContext()
  const { baseRow } = useDocumentRowsContext()
  const { getFieldError } = useFieldErrorContext()
  const { differentFieldsAnsRowsComparingRowValues } =
    useDocumentComparisonDataContext()

  // documentRowFromContext is second because there is always one for the base grid,
  // so we don't want to override the value passed for fields in table
  const currentDocumentRow = documentRow || baseRow

  const inputRef = useRef<HTMLInputElement>(null)
  const hadManualValueRef = useRef<boolean | null>(null)
  const [searchValue, setSearchValue] = useState('')

  const debounceSetValue = useMemo(() => debounce(setSearchValue, 200), [])

  const { getFieldChips, fieldsBeingUpdated, setHoveredChipId } =
    useDocumentChipsContext()

  const [hasSolidBackground, setHasSolidBackground] = useState(false)
  const [disableArrowKeys, setDisableArrowKeys] = useState(false)

  const isSelected =
    !!currentDocumentRow && isSelectedField(field.id, currentDocumentRow?.id)

  const fieldIsBeingUpdated =
    fieldsBeingUpdated[`${field.id}_${currentDocumentRow?.id}`]

  const fieldError = currentDocumentRow
    ? getFieldError(field.id, currentDocumentRow?.id)
    : ''

  const {
    value,
    refId,
    dataLineageValues,
    documentRowValue,
    hasManualValue,
    jsonValue,
    onManualChange,
    onCategoryChange,
    clearValue,
    wasAutoFilled,
  } = useFieldValue({
    field,
    documentRowId: currentDocumentRow?.id,
  })

  const chips = useMemo(
    () =>
      field && currentDocumentRow?.id
        ? getFieldChips(field.id, currentDocumentRow?.id)
        : [],
    [field, getFieldChips, currentDocumentRow?.id],
  )
  const row_value_flags = documentRowValue?.row_value_flags || []

  // This is memoized because this render logic could be potentially expensive when rendering lots of fields
  const renderedField = useMemo(() => {
    const listLookupFieldRule = field.project_grid_field_rules?.find(
      ({ rule }) => rule.code === 'list_lookup',
    )

    const commonProps = {
      value,
      color:
        field.input_behavior === 'manual_only'
          ? ('indigo' as const)
          : undefined,
      'data-focusid': documentRowValue?.id,
      disabled:
        isDisabled ||
        (fieldCode === 'picker'
          ? field.input_behavior === 'manual_not_allowed' ||
            (!field.metadata.data_list_column_id &&
              !field.params?.data_list_column_names)
          : field.input_behavior === 'manual_not_allowed'),
      variant: (isInTable ? 'standard' : 'filled') as TextFieldProps['variant'],
      size: 'small' as TextFieldProps['size'],
      sx: {
        width: '100%',
        '.Mui-disabled': {
          color: (theme: Theme) =>
            getFieldHeaderColor(
              field.id,
              theme.palette,
              differentFieldsAnsRowsComparingRowValues?.fieldIds,
              isInTable,
            ),
        },
      },
      onChange: (
        inputField: string | null,
        refId?: string | null,
        jsonObject?: Record<string, any> | null,
      ) => {
        if (field.input_behavior !== 'manual_only') {
          if (hadManualValueRef.current === null) {
            hadManualValueRef.current = hasManualValue
          }
          if (
            fieldTypesThatAllowChipSuggestions.includes(fieldCode) &&
            !listLookupFieldRule
          ) {
            const lastWord = inputField?.toString().split(' ').slice(-1)[0]
            debounceSetValue(lastWord || '')
          }
        }
        onManualChange(inputField, refId, jsonObject)
      },
      onClick: (e: React.MouseEvent) => {
        if (field.input_behavior === 'manual_not_allowed') {
          e.stopPropagation()
          focusContainerRef.current?.focus()
        }
      },
      inputRef,
      label: isInTable ? undefined : field.name,
      error: !!fieldError,
      helperText: fieldError,
      InputLabelProps: {
        shrink: true,
      },
      inputProps: isInTable
        ? {
            style: {
              padding: 0,
              opacity: wasAutoFilled ? 0.5 : 1,
            },
          }
        : {},
      InputProps: {
        disableUnderline: isInTable,
        startAdornment:
          field.input_behavior === 'manual_only' && fieldCode !== 'category' ? (
            <Tooltip title="Manual Entry Only" placement="top" enterDelay={500}>
              <InputAdornment
                position="start"
                sx={{
                  mt:
                    listLookupFieldRule || fieldCode === 'picker'
                      ? '-2px !important'
                      : undefined,
                  ml:
                    listLookupFieldRule || fieldCode === 'picker'
                      ? '4px !important'
                      : undefined,
                }}
              >
                <Keyboard />
              </InputAdornment>
            </Tooltip>
          ) : undefined,
        sx: {
          fontSize: isInTable ? 12 : undefined,
          borderBottom: (theme: Theme) =>
            `1px solid ${getFieldHeaderColor(
              field.id,
              theme.palette,
              differentFieldsAnsRowsComparingRowValues?.fieldIds,
              isInTable,
            )}`,
        },
      },
    }

    if (listLookupFieldRule) {
      return (
        <ListLookupField
          {...commonProps}
          value={
            value === null
              ? null
              : {
                  label: value,
                  id: refId || 'add-new-list-item',
                }
          }
          isShowingChips={isSelected}
          fieldRule={listLookupFieldRule}
          documentRowValue={documentRowValue}
          setHasSolidBackground={setHasSolidBackground}
          setDisableArrowKeys={setDisableArrowKeys}
          isInTable={isInTable}
        />
      )
    }

    switch (fieldCode) {
      case 'address':
      case 'cc_num':
      case 'text':
        return <TextInputField {...commonProps} />
      case 'phone':
        return <PhoneNumberTextField {...commonProps} />
      case 'int':
        return <IntegerField {...commonProps} />
      case 'decimal':
        return <DecimalField {...commonProps} />
      case 'currency':
        return <CurrencyField {...commonProps} />
      case 'date':
        return <DateField isInTable={isInTable} {...commonProps} />
      case 'time':
        return <TimeField isInTable={isInTable} {...commonProps} />
      case 'datetime':
        return <DateTimeField isInTable={isInTable} {...commonProps} />
      case 'category':
        return (
          <CategoryField
            {...commonProps}
            field={field}
            isInTable={isInTable}
            onChange={onCategoryChange}
            setDisableArrowKeys={setDisableArrowKeys}
          />
        )
      case 'checkbox':
        return (
          <CheckboxField
            {...commonProps}
            ref={inputRef}
            isSelected={isSelected}
            wasAutoFilled={wasAutoFilled}
            isInTable={isInTable}
          />
        )
      case 'picker':
        return (
          <PickerField
            {...commonProps}
            value={
              refId
                ? {
                    label: value,
                    id: refId,
                  }
                : value === '' && dataLineageValues.final_value === ''
                ? {
                    label: 'None',
                    id: 'None',
                  }
                : null
            }
            field={field}
            setDisableArrowKeys={setDisableArrowKeys}
            isInTable={isInTable}
          />
        )
      case 'live-list-picker':
        // Eventually we'll update this so it works generally for live list pickers.
        // For now, this is specific to entrata.
        return (
          <EntrataLiveVendorList
            jsonValue={jsonValue as LiveListEntity<EntrataVendor>}
            onManualChange={onManualChange}
          />
        )
      default: {
        return (
          <Alert severity="error" variant="outlined" sx={{ width: '100%' }}>
            Missing field type: {fieldCode}
          </Alert>
        )
      }
    }
  }, [
    field,
    value,
    documentRowValue,
    isDisabled,
    fieldCode,
    isInTable,
    fieldError,
    wasAutoFilled,
    differentFieldsAnsRowsComparingRowValues?.fieldIds,
    onManualChange,
    hasManualValue,
    debounceSetValue,
    refId,
    isSelected,
    onCategoryChange,
    dataLineageValues.final_value,
    jsonValue,
  ])

  useTableHotKeys({
    cellPosition: fieldPosition,
    documentRow: currentDocumentRow,
    tableGrid,
    tableBodyEl,
    deleteDocumentRows,
    insertDocumentRow,
    enabled: !!isInTable && isSelected && !disableArrowKeys,
  })

  const canRevert = hasManualValue && !isDisabled
  const cantHaveChips = field.input_behavior === 'manual_only'

  useEffect(() => {
    setHoveredChipId(null)
    setSearchValue('')
    hadManualValueRef.current = null
  }, [isSelected, setHoveredChipId])

  return (
    <FieldPortalProvider hidePortal={cantHaveChips}>
      <FocusContainer
        focusContainerRef={focusContainerRef}
        isSelectedField={isDisabled ? false : isSelected}
        isUserEditable={field.input_behavior !== 'manual_not_allowed'}
        onFocus={() => {
          !isDisabled &&
            currentDocumentRow &&
            setSelectedField({
              document_row_id: currentDocumentRow?.id,
              field,
            })
        }}
        onBlur={() => {
          setSelectedField(null)
          // We set the manual value to null if the field is empty and the final value is set, unless it's a manual-only text field.
          if (
            value === '' &&
            dataLineageValues.final_value &&
            (field?.project_grid_field_type.code !== 'text' ||
              field?.input_behavior !== 'manual_only')
          )
            clearValue()
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          flexGrow={1}
          alignItems={isInTable ? 'flex-start' : 'center'}
        >
          {cantHaveChips || isDisabled ? (
            <Stack flexGrow={1}>
              {renderedField}
              {row_value_flags.length > 0 && !isDisabled && (
                <FlagsBulletList rowValueFlags={row_value_flags} />
              )}
            </Stack>
          ) : (
            <>
              <FieldWithChipsAndSuggestions
                chips={chips}
                hasSolidBackground={hasSolidBackground}
                isSelected={isSelected}
                documentRowId={currentDocumentRow?.id}
                fieldId={field.id}
                searchValue={searchValue}
                onAssign={() => {
                  if (hadManualValueRef.current === false) {
                    clearValue()
                    setSearchValue('')
                  }
                }}
                onDelete={() => {
                  inputRef.current?.focus()
                }}
                isInTable={isInTable}
              >
                {renderedField}
                {fieldIsBeingUpdated && <FieldUpdatingIndicator />}
                {row_value_flags.length > 0 && (
                  <FlagsBulletList rowValueFlags={row_value_flags} />
                )}
              </FieldWithChipsAndSuggestions>
            </>
          )}

          {canRevert && (
            <Box sx={{ flexShrink: 0, py: isInTable ? 0 : 0.5 }}>
              <Tooltip
                title={
                  field.input_behavior === 'manual_only'
                    ? 'Clear'
                    : 'Revert to System Results'
                }
              >
                <IconButton
                  size="small"
                  tabIndex={-1}
                  onClick={() => {
                    inputRef.current?.focus()
                    clearValue()
                    setSearchValue('')
                    hadManualValueRef.current = null
                  }}
                  sx={{
                    mt: isInTable ? -0.5 : undefined,
                  }}
                >
                  <History fontSize={isInTable ? 'inherit' : 'medium'} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Stack>
      </FocusContainer>
    </FieldPortalProvider>
  )
}

export default memo(Field)

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { QueryKey } from '@tanstack/react-query'

type QueryKeysMapProviderValue = [
  Record<string, QueryKey>,
  Dispatch<SetStateAction<Record<string, QueryKey>>>,
]

const QueryKeysMapContext = createContext<QueryKeysMapProviderValue>(
  {} as QueryKeysMapProviderValue,
)

export const useQueryKeysMapContext = () => useContext(QueryKeysMapContext)

type QueryKeysMapProviderProps = {
  children: ReactNode
}

export default function QueryKeysMapProvider({
  children,
}: QueryKeysMapProviderProps) {
  const queryKeysMapState = useState<Record<string, QueryKey>>({})
  return (
    <QueryKeysMapContext.Provider value={queryKeysMapState}>
      {children}
    </QueryKeysMapContext.Provider>
  )
}

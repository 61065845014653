import { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  Container,
  Stack,
  Typography,
  ListItemButton,
  Card,
  Menu,
} from '@mui/material'
import ProjectBreadcrumb from '@/components/project-tables/ProjectBreadcrumb'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LargeHeading from '@/components/large-heading/LargeHeading'
import CopyIDButton from '@/components/copy-id-button/CopyIDButton'
import { ArrowBack, Delete } from '@mui/icons-material'
import useOverlay from '@/hooks/useOverlay'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import {
  useGetProjectModels,
  useUpdateProjectModel,
} from '@/service-library/hooks/project-models'
import LabeledData from '@/components/labeled-data/LabeledData'
import ChildWorkflowMenuBody from '@/components/navigation/ChildDataMenuBody'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import {
  useDeleteProjectLinkedModel,
  useGetProjectLinkedModel,
  useUpdateProjectLinkedModel,
} from '@/service-library/hooks/project-linked-models'
import ModelVersionSelect from '@/components/model-version-select/ModelVersionSelect'
import { showSuccessSnackbar } from '@/utils/snackbars'
import { ProjectLinkedModel } from '@/types/project-linked-models'
import queryKeys from '@/service-library/query-keys'
import { useGetPubModels } from '@/service-library/hooks/pub-models'
import { ProjectModel, ProjectModelVersion } from '@/types/project-models'
import { useProjectContext } from '../project-tables/ProjectProvider'
import ModelVersionInputsOutputsDisplay from '../model-inputs-outputs/ModelVersionInputsOutputsDisplay'
import { ReactFlowProvider } from '@xyflow/react'
import { useGetProjectGrids } from '@/service-library/hooks/project-grids'
import { useGetProjectLinkedModelInfoTypes } from '@/service-library/hooks/project-linked-model-info-types'
import QueryKeyProvider from '../model-inputs-outputs/QueryKeyProvider'

const labelMap: Record<string, string> = {
  OCR: 'OCR Model',
  NER: 'Labeling Model',
  Category: 'Category Model',
}

// This is where users can manage a ProjectLinkedModel.
export default function ModelPage() {
  const { id } = useParams()
  const { project } = useProjectContext()
  const navigate = useNavigate()
  const modelsOverlay = useOverlay()

  const isSuperUser = useIsSuperUser()
  const [demoMode] = useDemoModeContext()

  const [selectedModelId, setSelectedModelId] = useState<string | null>(null)

  const { projectLinkedModel, queryKey, isLoading } = useGetProjectLinkedModel({
    id: id as string,
    filters: {
      fields__include: 'project_model',
      project_model__fields__include:
        'project_model_type,project_model_versions,has_children',
      project_model__project_model_versions__fields__include:
        'project_model_version_info_types',
      project_model__project_model_versions__project_model_version_info_types__fields__include:
        'info_type',
      project_model__project_model_versions__project_model_version_info_types__info_type__fields__include:
        'id,name,namespace',
    },
  })

  // We fetch the project grids here instead of using them off project context project.project_grids
  // so we can get the info_type with the fields.
  const { projectGrids, isLoading: isLoadingGrids } = useGetProjectGrids({
    filters: {
      limit: '1000',
      project_id: project.id,
      fields__include: 'project_grid_fields',
      project_grid_fields__fields__include: 'info_type',
      project_grid_fields__info_type__fields__only: 'id,name',
    },
  })

  useEffect(() => {
    if (!selectedModelId && projectLinkedModel?.project_model?.has_children) {
      setSelectedModelId(projectLinkedModel.project_model_id)
    }
  }, [projectLinkedModel, selectedModelId])

  const { projectModels: childModels, queryKey: childModelsQueryKey } =
    useGetProjectModels({
      filters: {
        parent_model_id: projectLinkedModel?.project_model_id,
        fields__include: 'project_model_type,project_model_versions',
        project_model_versions__fields__include:
          'project_model_version_info_types',
        project_model_versions__project_model_version_info_types__fields__include:
          'info_type',
      },
      enabled: !!projectLinkedModel?.project_model?.has_children,
    })

  const {
    projectLinkedModelInfoTypes,
    queryKey: pLMInfoTypesQueryKey,
    isLoading: isLoadingLinkedInfoTypes,
  } = useGetProjectLinkedModelInfoTypes({
    filters: {
      project_linked_model_id: id as string,
    },
    enabled: !!projectLinkedModel,
  })

  const { updateProjectLinkedModel } = useUpdateProjectLinkedModel({
    detailQueryKey: queryKey,
    onSuccess: () => {
      showSuccessSnackbar('Model version updated')
    },
  })

  const { deleteProjectLinkedModel } = useDeleteProjectLinkedModel({
    sideEffectQueryKeys: [queryKeys.projectLinkedModels.all],
    onSuccess: () => {
      showSuccessSnackbar('Model removed from project')
    },
  })

  const { updateProjectModel } = useUpdateProjectModel({
    listQueryKey: childModels.length ? childModelsQueryKey : undefined,
    sideEffectQueryKeys: [queryKey],
    onSuccess: () => {
      showSuccessSnackbar('Model version updated')
    },
  })

  const { pubModels, isLoading: isLoadingPubModels } = useGetPubModels({
    filters: {
      limit: '1',
      project_model_id: projectLinkedModel?.project_model_id,
      fields__include: 'pub_model_versions',
    },
    enabled: !!projectLinkedModel,
  })

  if (!projectLinkedModel && !isLoading)
    return (
      <Container maxWidth="xl">
        <Alert severity="error">
          Model not found. Please contact Pixydocs Support if this is
          unexpected.
        </Alert>
      </Container>
    )

  if (
    !projectLinkedModel ||
    isLoadingPubModels ||
    isLoadingGrids ||
    isLoadingLinkedInfoTypes
  )
    return (
      <Stack alignItems="center" sx={{ mt: 8 }}>
        <Typography variant="h6" color="text.secondary">
          Loading...
        </Typography>
      </Stack>
    )

  const selectedModel = selectedModelId
    ? [projectLinkedModel?.project_model, ...childModels].find(
        (m) => m?.id === selectedModelId,
      )
    : projectLinkedModel?.project_model

  const pubModelVersions = pubModels[0]?.pub_model_versions || []
  const selectedProjectModelVersions = selectedModel?.project_model_versions

  const showAsPubModel =
    projectLinkedModel?.project_model?.project_id !== project.id

  const selectedVersionId = showAsPubModel
    ? projectLinkedModel.current_model_version_id || 'current'
    : selectedModel?.current_version_id

  const recommendedPubModelVersion = pubModelVersions.find(
    (v) => v.id === pubModels[0]?.recommended_version_id,
  )
  const recommendedPubModelProjectModelVersion =
    selectedProjectModelVersions?.find(
      (v) => v.id === recommendedPubModelVersion?.model_version_id,
    )

  const validVersions = showAsPubModel
    ? pubModelVersions?.map((version) => {
        const ver = selectedProjectModelVersions?.find(
          (v) => v.id === version.model_version_id,
        )
        return {
          ...ver,
          id: version.model_version_id,
          version: parseFloat(
            `${version.version[0]}.${version.version[1] || 0}`,
          ),
          training_status: 'completed',
          is_current: showAsPubModel
            ? recommendedPubModelProjectModelVersion?.id === ver?.id
            : ver?.is_current,
        } as ProjectModelVersion
      })
    : selectedProjectModelVersions?.filter(
        (v) => v.training_status === 'completed',
      )

  function handleUpdateCurrentVersion(versionId: string) {
    if (showAsPubModel) {
      updateProjectLinkedModel({
        ...projectLinkedModel,
        current_model_version_id: versionId === 'current' ? null : versionId,
      } as ProjectLinkedModel)
    } else {
      updateProjectModel({
        ...selectedModel,
        current_version_id: versionId === 'current' ? null : versionId,
      } as ProjectModel)
    }
  }

  let displayedVersion = validVersions?.find((v) => v.id === selectedVersionId)

  if (!displayedVersion && showAsPubModel && selectedVersionId === 'current') {
    displayedVersion = recommendedPubModelProjectModelVersion
  }

  return (
    <QueryKeyProvider queryKey={pLMInfoTypesQueryKey}>
      <Container maxWidth="xl">
        <ProjectBreadcrumb label="Model Library" url="../model-library" />
        <ProjectBreadcrumb
          label={projectLinkedModel.project_model?.name || ''}
        />

        <Button
          sx={{ ml: '-4px', mb: 2 }}
          startIcon={<ArrowBack />}
          component={Link}
          to="../model-library"
          variant="text"
        >
          Back to Model Library
        </Button>

        <LargeHeading
          heading={
            showAsPubModel
              ? pubModels[0]?.name
              : projectLinkedModel.project_model?.name
          }
          subHeading={
            labelMap[
              projectLinkedModel.project_model?.project_model_type?.code ||
                'ner'
            ]
          }
          actions={
            <>
              <Button
                variant="text"
                color="error"
                startIcon={<Delete />}
                onClick={() => {
                  deleteProjectLinkedModel(projectLinkedModel.id)
                  navigate('../model-library')
                }}
              >
                Remove from Library
              </Button>

              {isSuperUser && !demoMode && (
                <CopyIDButton
                  stringToCopy={projectLinkedModel.id}
                  isSuperUser
                />
              )}
            </>
          }
        />

        {!showAsPubModel && (
          <Stack direction="row">
            <Alert variant="outlined" severity="info" sx={{ mt: 1 }}>
              {pubModels.length > 0 ? (
                <>
                  This model is published, but is owned by this project. These
                  settings will only affect how this project uses the model, and
                  will not affect any other projects using the model.
                </>
              ) : (
                <>This model is managed by this project.</>
              )}
            </Alert>
          </Stack>
        )}

        <Stack spacing={3} sx={{ mt: 4 }}>
          <Box>
            <Typography variant="h6">Model Version</Typography>
            <Typography variant="body2" color="text.secondary">
              Select the model version to use during prediction.
            </Typography>
          </Box>

          {projectLinkedModel.project_model?.has_children ? (
            <Stack direction="row" spacing={2}>
              <ModelVersionSelect
                selectedVersionId={selectedVersionId || 'no-versions'}
                setSelectedVersionId={handleUpdateCurrentVersion}
                modelVersions={validVersions as ProjectModelVersion[]}
              />
              <Card>
                <ListItemButton
                  sx={{
                    px: 1,
                    pb: 0.5,
                    pt: 1,
                    borderRadius: 2,
                  }}
                  dense
                  onClick={modelsOverlay.open}
                >
                  <LabeledData
                    label="Project Model"
                    data={selectedModel?.name}
                  />
                </ListItemButton>
              </Card>
              <Menu
                id="models-menu"
                anchorEl={modelsOverlay.anchorEl as Element}
                open={modelsOverlay.isOpen}
                onClose={modelsOverlay.close}
              >
                <ChildWorkflowMenuBody
                  rootObject={projectLinkedModel.project_model}
                  objects={childModels}
                  subheader="Project Models"
                  noOptionsText="No Models Found"
                  onClick={(objectId) => {
                    modelsOverlay.close()
                    setSelectedModelId(objectId)
                  }}
                />
              </Menu>
            </Stack>
          ) : (
            <>
              <Box>
                <ModelVersionSelect
                  selectedVersionId={selectedVersionId || 'no-versions'}
                  setSelectedVersionId={handleUpdateCurrentVersion}
                  modelVersions={validVersions as ProjectModelVersion[]}
                  showNullOption={showAsPubModel}
                  nullOptionLabel={
                    recommendedPubModelProjectModelVersion
                      ? `Use Recommended Version (v${recommendedPubModelProjectModelVersion?.version}.0)`
                      : 'None'
                  }
                  showCurrentVersion={showAsPubModel}
                  currentVersionLabel="Recommended Version"
                />
              </Box>
              {showAsPubModel && selectedVersionId === 'current' && (
                <Typography variant="caption" color="text.secondary">
                  If a new recommended version of the model is released, it will
                  use the new version.
                </Typography>
              )}
            </>
          )}

          {displayedVersion && (
            <Stack spacing={1}>
              <Box>
                <Typography variant="h6">Inputs and Outputs</Typography>
                <Typography variant="body2" color="text.secondary">
                  These are the expected inputs and outputs for model version v
                  {displayedVersion.version}.
                </Typography>
              </Box>
              <Box
                sx={{
                  height: 500,
                }}
              >
                <ReactFlowProvider>
                  <ModelVersionInputsOutputsDisplay
                    key={displayedVersion.id}
                    projectLinkedModel={projectLinkedModel}
                    projectModelVersionInfoTypes={
                      displayedVersion?.project_model_version_info_types
                    }
                    projectGrids={projectGrids}
                    projectLinkedModelInfoTypes={projectLinkedModelInfoTypes}
                  />
                </ReactFlowProvider>
              </Box>
            </Stack>
          )}
        </Stack>
      </Container>
    </QueryKeyProvider>
  )
}

import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { Typography } from '@mui/material'

type NoParamsActionsDisplayProps = {
  action: WorkflowStateAction
}
const actionDescriptions = {
  run_rules_for_workflow_state:
    "Run all field and document rules for the document's current workflow state.",
  export_to_integrations: 'Export the document to all available integrations.',
  add_document_to_eligible_training_workflows:
    'Add the document to eligible training workflows if relevant changes have been made to the document.',
  entrata_add_default_line_item:
    'If no rows are present in the Items table, a default line item will be added matching the invoice total.',
  entrata_delete_rows_if_it_foots:
    'Delete any rows from any of the tables if it foots the invoice.',
}

export default function NoParamsActionsDisplay({
  action,
}: NoParamsActionsDisplayProps) {
  const textToDisplay =
    actionDescriptions[
      action.action_type?.code as keyof typeof actionDescriptions
    ]

  if (!textToDisplay) return null

  return (
    <Typography variant="body2" color="text.secondary">
      {textToDisplay}
    </Typography>
  )
}

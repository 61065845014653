import { useEffect, useState } from 'react'
import { Badge, Popover, PopoverProps, Stack } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { useDocumentContext } from '@/components/image-zoom-pan/providers/DocumentProvider'
import { useDocumentImagePageContext } from '@/components/image-zoom-pan/providers/DocumentImagePageProvider'
import DocumentThumbnail from './DocumentThumbnail'

type DocumentThumbnailsPopoverProps = {
  overlay: OverlayState
  anchorEl: HTMLElement | null
  layout?: 'right' | 'left' | 'top' | 'bottom'
} & Omit<PopoverProps, 'open'>

export default function DocumentThumbnailsPopover({
  overlay,
  anchorEl,
  layout,
  ...rest
}: DocumentThumbnailsPopoverProps) {
  const { document } = useDocumentContext()
  const iconIsAtTheTop = layout === 'bottom'
  const { imagePage, setImagePage, pageCount } = useDocumentImagePageContext()

  const [currentPageEl, setCurrentPageEl] = useState<HTMLImageElement | null>(
    null,
  )

  const onWheel = (event: React.WheelEvent | WheelEvent) => {
    if (event.metaKey) {
      if (event.deltaY > 0 && imagePage < pageCount) {
        setImagePage((prev) => prev + 1)
      } else if (event.deltaY < 0 && imagePage > 1) {
        setImagePage((prev) => prev - 1)
      }
    }
  }

  useEffect(() => {
    if (overlay.isOpen && currentPageEl) {
      currentPageEl.scrollIntoView({ block: 'center' })
    }
  }, [currentPageEl, overlay.isOpen])

  return (
    <Popover
      open={overlay.isOpen}
      anchorEl={(overlay.anchorEl as Element) || anchorEl}
      onClose={overlay.close}
      anchorOrigin={{
        vertical: iconIsAtTheTop ? 'bottom' : 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: iconIsAtTheTop ? 'top' : 'bottom',
        horizontal: 'center',
      }}
      sx={{ transform: `translateY(${iconIsAtTheTop ? 20 : -20}px)` }}
      onWheel={onWheel}
      onKeyUp={(event) => {
        if (event.key === 'Meta' && overlay.isOpen) {
          overlay.close()
        }
      }}
      slotProps={{
        paper: {
          elevation: 4,
          onMouseDown: (e) => e.preventDefault(),
          // zIndex used to match canvas zIndex
          sx: { p: 1, zIndex: 4 },
          ...rest.slotProps?.paper,
        },
      }}
      {...rest}
    >
      <Stack
        spacing={1}
        sx={{
          minWidth: 60,
          maxHeight: '90vh',
        }}
      >
        {document?.document_pages.map((page) => {
          return (
            <Badge
              key={page.id}
              badgeContent={page.page_number}
              color="primary"
              overlap="circular"
              component="div"
              max={10000} // Just in case we ever have a document that has more than 99 (default) pages
            >
              <DocumentThumbnail
                ref={
                  imagePage === page.page_number
                    ? (el) => setCurrentPageEl(el as HTMLImageElement)
                    : null
                }
                image_urls={page.image_urls}
                onClick={() => {
                  overlay.close()
                  setImagePage(() => page.page_number)
                }}
                sx={{
                  outline:
                    imagePage === page.page_number
                      ? (theme) => `2px solid ${theme.palette.primary.main}`
                      : 'none',
                  outlineOffset: '3px',
                  border: '1px solid #000000',
                  cursor: 'pointer',
                  ':hover': {
                    outline: (theme) =>
                      `2px solid ${theme.palette.secondary.main}`,
                  },
                }}
              />
            </Badge>
          )
        })}
      </Stack>
    </Popover>
  )
}

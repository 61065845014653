import { Fragment } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Add, Close } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { FormTextField } from '@/components/forms'

type TextToMatchFieldsProps = {
  required?: boolean
}

export default function TextToMatchFields({
  required,
}: TextToMatchFieldsProps) {
  const propertyId = 'strings_to_match'
  const { fields, append, remove } = useFieldArray({
    name: propertyId,
    rules: { required },
  })

  const { register } = useFormContext()

  return (
    <>
      <Button
        onClick={() => append({ value: '' })}
        startIcon={<Add />}
        sx={{
          width: 'fit-content',
        }}
        variant="text"
      >
        Add
      </Button>
      <Grid container rowSpacing={1} columnSpacing={0.5} alignItems="center">
        {fields.map((field, index) => {
          return (
            <Fragment key={field.id}>
              <Grid size={11}>
                <FormTextField
                  required={required}
                  variant="outlined"
                  fullWidth
                  {...register(`${propertyId}.${index}.value`, {
                    validate: (value) => value.trim().length > 0,
                  })}
                />
              </Grid>
              <Grid size={1}>
                <IconButton onClick={() => remove(index)} size="small">
                  <Close fontSize="inherit" />
                </IconButton>
              </Grid>
            </Fragment>
          )
        })}
      </Grid>
    </>
  )
}

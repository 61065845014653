import { HelpOutline } from '@mui/icons-material'
import { Stack, Tooltip, Typography } from '@mui/material'

type MetricHeadingProps = {
  heading: string
  tooltipMessage?: string
}

export default function MetricHeading({
  heading,
  tooltipMessage,
}: MetricHeadingProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="body1">{heading}</Typography>
      {tooltipMessage && (
        <Tooltip title={tooltipMessage}>
          <HelpOutline fontSize="small" sx={{ color: 'text.secondary' }} />
        </Tooltip>
      )}
    </Stack>
  )
}

import { memo } from 'react'
import { Theme, Typography } from '@mui/material'
import { TreeItem2, TreeItem2Props } from '@mui/x-tree-view/TreeItem2'
import { OrgTree } from '@/types/organizations'
import HighlightedSearchTerm from '@/components/highlighted-search-term/HighlightedSearchTerm'

export type OrganizationTreeItemProps = {
  orgTree: OrgTree
  searchTerm?: string
  SubOrgComponent?: React.ElementType
  subOrgComponentProps?: Record<string, unknown>
  getIsDisabled?: (id: string) => boolean
  getIsVisible?: (id: string) => boolean
} & Omit<TreeItem2Props, 'itemId'>

type OrganizationTreeItemLabel = {
  code: string | null
  name: string
  searchTerm?: string
  size?: 'small' | 'regular'
}

export function OrganizationTreeItemLabel({
  code,
  name,
  searchTerm,
  size = 'regular',
}: OrganizationTreeItemLabel) {
  return (
    <Typography noWrap variant={size === 'small' ? 'body2' : undefined}>
      <HighlightedSearchTerm text={name} searchTerm={searchTerm} />
      {code && (
        <Typography
          component="span"
          variant={size === 'small' ? 'caption' : 'body2'}
          color="textSecondary"
        >
          {' '}
          ({code})
        </Typography>
      )}
    </Typography>
  )
}

function OrganizationTreeItem({
  label,
  orgTree,
  searchTerm,
  getIsDisabled,
  getIsVisible,
  SubOrgComponent = OrganizationTreeItem,
  subOrgComponentProps,
  ...props
}: OrganizationTreeItemProps) {
  const { id, subOrganizations } = orgTree
  const { slotProps } = props
  const visible = getIsVisible ? getIsVisible(id) : true
  const disabled = getIsDisabled?.(id) || false

  return visible ? (
    <TreeItem2
      {...props}
      // disabled={getIsDisabled?.(id) || false} TODO: Update when a parent node state doesn't override this https://github.com/mui/mui-x/issues/11575
      itemId={id}
      label={
        label || (
          <OrganizationTreeItemLabel searchTerm={searchTerm} {...orgTree} />
        )
      }
      slotProps={{
        ...slotProps,
        root: {
          ...slotProps?.root,
        },
        iconContainer: {
          // @ts-expect-error -- sx is a valid prop
          sx: {
            display: subOrganizations.length ? undefined : 'none !important',
          },
        },
        content: {
          ...slotProps?.content,
          // @ts-expect-error -- sx is a valid prop
          sx: {
            p: '0px 8px',
            borderRadius: 2,
            opacity: disabled ? 0.38 : undefined,
            backgroundColor: disabled ? 'transparent' : undefined,
            ':hover': {
              backgroundColor: () => (disabled ? 'transparent' : undefined),
            },
            // @ts-expect-error -- sx is a valid prop
            ...slotProps?.content?.sx,
          },
        },
        groupTransition: {
          ...slotProps?.groupTransition,
          // @ts-expect-error -- sx is a valid prop
          sx: {
            ml: '15px', // default is 17px os to account for border, we subtract 2px.
            borderLeft: (theme: Theme) => `2px dashed ${theme.palette.divider}`,
            // @ts-expect-error -- sx is a valid prop
            ...slotProps?.groupTransition?.sx,
          },
        },
      }}
    >
      {subOrganizations.map((orgTree) => {
        return (
          <SubOrgComponent
            key={orgTree.id}
            orgTree={orgTree}
            searchTerm={searchTerm}
            getIsDisabled={getIsDisabled}
            getIsVisible={getIsVisible}
            {...props}
            {...subOrgComponentProps}
          />
        )
      })}
    </TreeItem2>
  ) : null
}

export default memo(OrganizationTreeItem)

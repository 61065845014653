import { ReactNode } from 'react'
import {
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
} from '@mui/icons-material'
import { Chip, Popover, Stack, Typography } from '@mui/material'
import { DocumentChip } from '@/types/documents'
import { OverlayState } from '@/hooks/useOverlay'
import { useGetDocumentChips } from '@/service-library/hooks/document-chips'
import { useDocumentChipsContext } from './providers/DocumentChipsProvider'

type AlternateChipsPopoverProps = {
  chip: DocumentChip
  overlay: OverlayState
  anchorPosition: { left: number; top: number }
}

export default function AlternateChipsPopover({
  chip,
  overlay,
  anchorPosition,
}: AlternateChipsPopoverProps) {
  const alternateChipIds = chip.related_chips_data?.alt_chip_ids
  const primaryChipId = chip.overridden_data
    ? chip.overridden_data.primary_chip_id
    : chip.related_chips_data?.primary_chip_id

  const { updateChipOverriddenData } = useDocumentChipsContext()

  const { documentChips } = useGetDocumentChips({
    filters: {
      id__in: alternateChipIds?.join(','),
    },
    enabled: !!alternateChipIds,
  })

  return (
    <Popover
      open={overlay.isOpen}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      onClose={overlay.close}
      slotProps={{
        paper: {
          elevation: 4,
          onMouseDown: (e) => e.preventDefault(),
          // zIndex used to match canvas zIndex
          sx: { p: 1, zIndex: 4 },
        },
        root: {
          slotProps: {
            backdrop: {
              onMouseDown: (e) => {
                // Prevent clicking backdrop from also causing selected field to lose focus [sc-7297]
                e.preventDefault()
              },
            },
          },
        },
      }}
    >
      <Stack spacing={0.5} sx={{ width: '100%' }}>
        <Typography color="text.secondary" variant="caption" sx={{ pb: 0.5 }}>
          Chip Alternatives
        </Typography>
        {documentChips?.map((altChip) => {
          const selected = altChip.id === primaryChipId
          let label: ReactNode = altChip.text
          if (label === 'TrueBox') {
            label = <CheckBoxOutlined fontSize="small" />
          } else if (label === 'FalseBox') {
            label = <CheckBoxOutlineBlankOutlined fontSize="small" />
          }
          return (
            <Chip
              disabled={selected}
              key={altChip.id}
              id={altChip.id}
              sx={{
                minWidth: '50px',
                width: 'fit-content',
                // Aligns checkbox and delete icons
                '.MuiChip-label': { display: 'flex' },
              }}
              label={label}
              clickable
              onClick={() => {
                updateChipOverriddenData(chip, altChip)
                overlay.close()
              }}
              onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                e.preventDefault()
              }
              color={selected ? 'secondary' : 'neutral'}
              variant={
                selected && chip.project_grid_field_id ? 'filled' : 'outlined'
              }
            />
          )
        })}
      </Stack>
    </Popover>
  )
}

import { useRef } from 'react'
import { ListItemButton, Tooltip, Typography } from '@mui/material'
import { WorkflowState } from '@/types/workflows'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import useOverlay from '@/hooks/useOverlay'
import { getIdleTime, prettifyDate } from '@/utils/getFormattedDateTimeString'
import LabeledData from '@/components/labeled-data/LabeledData'
import WorkflowStateMenuDropdown from '@/components/workflow-state-dropdown/WorkflowStateMenuDropdown'

type WorkflowStateSelectMenuProps = {
  selectedWorkflowState: WorkflowState
  workflowStates: WorkflowState[]
  onChange: (workflowState: WorkflowState) => void
  dense?: boolean
  hideLabel?: boolean
  selectedWorkflowStateEnteredAt?: string
}

export default function WorkflowStateSelectMenu({
  selectedWorkflowState,
  workflowStates,
  onChange,
  dense,
  hideLabel,
  selectedWorkflowStateEnteredAt,
}: WorkflowStateSelectMenuProps) {
  const getThemeColor = useGetThemeColor()
  const filteredWorkflowStates = workflowStates.filter(
    ({ code }) => code !== 'processing',
  )

  const overlay = useOverlay()
  const anchorRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <ListItemButton
        sx={{
          px: dense ? 1 : 2,
          pb: dense ? 0.5 : 1,
          pt: 1,
          borderRadius: 2,
        }}
        dense
        ref={anchorRef}
        onClick={overlay.toggle}
      >
        <LabeledData
          label="Workflow State"
          data={
            <Typography
              component="div"
              variant={dense ? 'caption' : 'body2'}
              color={getThemeColor(selectedWorkflowState?.color)}
              noWrap
            >
              <b>{selectedWorkflowState.name}</b>
              {selectedWorkflowStateEnteredAt && (
                <Tooltip
                  enterDelay={500}
                  title={`Entered Workflow State: ${prettifyDate(
                    selectedWorkflowStateEnteredAt,
                  )}`}
                >
                  <Typography
                    color="text.secondary"
                    variant="caption"
                    component="p"
                    noWrap
                    sx={{
                      lineHeight: 1,
                      fontSize: dense ? 11 : undefined,
                    }}
                  >
                    {getIdleTime(selectedWorkflowStateEnteredAt)}
                  </Typography>
                </Tooltip>
              )}
            </Typography>
          }
          hideLabel={hideLabel}
          dense={dense}
        />
      </ListItemButton>
      <WorkflowStateMenuDropdown
        overlay={overlay}
        anchorRef={anchorRef}
        workflowStates={filteredWorkflowStates}
        onChange={(state) => {
          onChange(state)
          overlay.close()
        }}
      />
    </>
  )
}

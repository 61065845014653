import { Node } from '@xyflow/react'

export const ocrResultsNode: Node = {
  id: 'ocrResults',
  type: 'ocrResults',
  data: {
    label: 'OCR Results',
    fields: [],
  },
  position: { x: 0, y: 0 },
  draggable: false,
  selectable: false,
}

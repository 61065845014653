import { Box } from '@mui/material'

export default function Bar({ color }: { color: string }) {
  return (
    <Box
      sx={{
        width: 4,
        background: color,
      }}
    />
  )
}

import { Box, Theme, Tooltip } from '@mui/material'
import { ProjectGridFieldType, ProjectGridField } from '@/types/fields'
import CurrencyField from '@/components/inputs/CurrencyField'
import DecimalField from '@/components/inputs/DecimalField'
import IntegerField from '@/components/inputs/IntegerField'
import TextInputField from '@/components/inputs/TextInputField'

type AggregateCellProps = {
  aggregateFunction: ProjectGridField['aggregate_function']
  code: ProjectGridFieldType['code']
  value: number | null
}

export default function AggregateCell({
  aggregateFunction,
  code,
  value,
}: AggregateCellProps) {
  const Field =
    value === null
      ? TextInputField
      : code === 'int'
      ? IntegerField
      : code === 'decimal'
      ? DecimalField
      : CurrencyField
  const commonProps = {
    disabled: true,
    variant: 'standard' as const,
    inputProps: {
      style: {
        textAlign: code === 'currency' ? ('right' as const) : undefined,
        padding: 0,
        opacity: 1,
      },
    },
    InputProps: {
      disableUnderline: true,
      sx: {
        fontSize: 12,
      },
    },
    sx: {
      width: '100%',
      '.Mui-disabled': {
        // Override the default disabled MUI text color
        WebkitTextFillColor: (theme: Theme) =>
          `${theme.palette.primary.main} !important`,
      },
    },
  }

  return (
    <Tooltip
      title={aggregateFunction}
      disableInteractive
      enterDelay={1000}
      placement={code === 'currency' ? 'bottom-end' : 'bottom-start'}
      componentsProps={{
        tooltip: {
          sx: { textTransform: 'capitalize' },
        },
      }}
    >
      <Box sx={{ width: '100%' }}>
        {/* Setting to have two decimals so DecimalField shows it like that when there are 0s, too. */}
        <Field
          value={value === null ? '' : value.toFixed(2)}
          {...commonProps}
        />
      </Box>
    </Tooltip>
  )
}

import { updateList } from '../request-wrappers'
import { ChangeSet } from '@/types/doc-changes'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import { DocumentsChangeSetsContextValue } from '@/components/validation/providers/DocumentsChangeSetsProvider'

export type UseCreateDocumentChangeSetsOptions = {
  documentsChangeSetsContextData?: DocumentsChangeSetsContextValue
} & UseUpdateListOptions<ChangeSet>
/**
 * MARK: Action Hook: useCreateDocumentChangeSets
 * Creates a new document change set.
 */
export function useCreateDocumentChangeSets({
  documentsChangeSetsContextData,
  onMutate,
  ...options
}: UseCreateDocumentChangeSetsOptions = {}) {
  const { documentsChangeSetIdsRef, setDocumentLastChangeSetSentAt } =
    documentsChangeSetsContextData || {}

  const mutation = useUpdateList<ChangeSet>({
    serviceFn: ({ items, ...serviceFnOptions }) =>
      updateList({
        url: '/v2/pd-rules/doc_changes/async-change',
        items,
        ...serviceFnOptions,
      }),
    onMutate: (changeSets) => {
      if (documentsChangeSetIdsRef) {
        const documentsChangeSetIds = documentsChangeSetIdsRef.current
        changeSets.forEach((changeSet) => {
          documentsChangeSetIds[changeSet.doc_id] ||= new Set()
          documentsChangeSetIds[changeSet.doc_id].add(changeSet.id)
          setDocumentLastChangeSetSentAt?.((prev) => ({
            ...prev,
            [changeSet.doc_id]: new Date(),
          }))
        })
      }

      onMutate?.(changeSets)
    },
    ...options,
  })

  return {
    createDocumentChangeSets: mutation.mutateAsync,
    ...mutation,
  }
}

import { ProjectModel } from '@/types/project-models'
import { Workflow } from '@/types/workflows'
import { DeveloperBoard } from '@mui/icons-material'
import {
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { useDeferredValue, useMemo, useState } from 'react'

type ChildDataMenuBodyProps = {
  rootObject: Workflow | ProjectModel
  objects: (Workflow | ProjectModel)[]
  noOptionsText: string
  subheader: string
  onClick: (objectId: string) => void
}

export default function ChildDataMenuBody({
  rootObject,
  objects,
  subheader,
  noOptionsText,
  onClick,
}: ChildDataMenuBodyProps) {
  const [searchValue, setSearchValue] = useState('')
  const deferredSearchValue = useDeferredValue(searchValue)

  const filteredWorkflows = useMemo(() => {
    return [rootObject, ...objects].filter((workflow) =>
      workflow.name.toLowerCase().includes(deferredSearchValue.toLowerCase()),
    )
  }, [deferredSearchValue, rootObject, objects])

  return (
    <div>
      <TextField
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search"
        fullWidth
        variant="outlined"
        size="small"
        autoFocus
        sx={{ px: 2, pt: 1, pb: 2 }}
      />
      <ListSubheader
        key="menu-subheader-data"
        sx={{
          background: (theme) =>
            theme.palette.mode === 'dark'
              ? '#3d3d3d'
              : 'theme.background.paper',
          lineHeight: '1.8rem',
        }}
      >
        {subheader}
      </ListSubheader>
      {filteredWorkflows.length === 0 && (
        <Typography sx={{ mx: 2, mb: 2 }} variant="caption">
          {noOptionsText}
        </Typography>
      )}
      {...filteredWorkflows.map((workflow) => {
        return (
          <MenuItem
            key={workflow.id}
            onClick={() => {
              onClick(workflow.id)
            }}
          >
            <ListItemIcon>
              <DeveloperBoard />
            </ListItemIcon>
            <ListItemText>{workflow.name}</ListItemText>
          </MenuItem>
        )
      })}
    </div>
  )
}

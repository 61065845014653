import { ListSubheader, MenuItem, Typography } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { WorkflowState } from '@/types/workflows'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import LeftBorderedBox from '@/components/validation/LeftBorderedBox'
import { getWorkflowStateGroupsBySortOrder } from '@/components/workflow-states-page/helpers'

type WorkflowStateDropdownProps = {
  overlay?: OverlayState
  workflowStates: WorkflowState[]
  onChange?: (workflowState: WorkflowState, isSelected: boolean) => void
  selectedWorkflowStateIds?: string[]
  dense?: boolean
  multiSelect?: boolean
}

// Using a hook to return a list which we need for textfield/select to work
export default function useGetWorkflowStateMenuItems({
  overlay,
  workflowStates,
  onChange,
  selectedWorkflowStateIds,
  dense,
  multiSelect,
}: WorkflowStateDropdownProps) {
  const getThemeColor = useGetThemeColor()
  const stateGroups = getWorkflowStateGroupsBySortOrder(workflowStates)
  return stateGroups.reduce<JSX.Element[]>((acc, states) => {
    const workflowStateType = states[0].workflow_state_type
    acc.push(
      <ListSubheader
        sx={{ lineHeight: dense ? '32px' : undefined }}
        key={workflowStateType?.id || 'unassigned'}
      >
        {workflowStateType ? workflowStateType.name : 'Unassigned'}
      </ListSubheader>,
    )

    acc.push(
      ...states.map((state) => {
        const color = getThemeColor(state.color)
        return (
          <MenuItem
            dense={dense}
            key={state.id}
            onClick={() => {
              onChange?.(state, !selectedWorkflowStateIds?.includes(state.id))
              if (!multiSelect) {
                overlay?.close()
              }
            }}
            selected={selectedWorkflowStateIds?.includes(state.id)}
            value={state.id}
          >
            <LeftBorderedBox color={color}>
              <Typography variant="body2" component="div">
                {state.name}
              </Typography>
            </LeftBorderedBox>
          </MenuItem>
        )
      }),
    )

    return acc
  }, [])
}

import { ReactNode, useRef } from 'react'
import { Stack } from '@mui/material'
import { DocumentChip } from '@/types/documents'
import ChipsResultsPopper, {
  ChipsResultsPopperProps,
} from './ChipsResultsPopper'
import FieldChips from './FieldChips'
import FieldMiniChips from './FieldMiniChips'
import FieldPopperWithPortal from './FieldPopperWithPortal'

type FieldWithChipsAndSuggestionsProps = {
  chips: DocumentChip[]
  children: ReactNode
  hasSolidBackground: boolean
  isSelected: boolean
  onDelete: () => void
  isInTable?: boolean
  documentRowId?: string
} & Omit<ChipsResultsPopperProps, 'anchorEl' | 'open' | 'documentRowId'>

export default function FieldWithChipsAndSuggestions({
  chips,
  children,
  hasSolidBackground,
  isSelected,
  searchValue,
  onDelete,
  isInTable,
  documentRowId,
  ...chipsResultsProps
}: FieldWithChipsAndSuggestionsProps) {
  const anchorElRef = useRef<HTMLDivElement>(null)

  return (
    <Stack flexGrow={1} spacing={0.5}>
      {/* keep children in div container to avoid losing focus [sc-9197] */}
      <div ref={anchorElRef}>{children}</div>
      {chips.length > 0 && (
        <>
          <FieldPopperWithPortal
            anchorEl={anchorElRef.current}
            open={isSelected}
            hasSolidBackground={hasSolidBackground}
            isInTable={isInTable}
          >
            <FieldChips chips={chips} onDelete={onDelete} />
          </FieldPopperWithPortal>
          <FieldMiniChips chips={chips} hidden={isSelected} />
        </>
      )}
      {documentRowId && searchValue.length > 0 && isSelected && (
        <ChipsResultsPopper
          open
          anchorEl={anchorElRef.current}
          documentRowId={documentRowId}
          searchValue={searchValue}
          {...chipsResultsProps}
        />
      )}
    </Stack>
  )
}

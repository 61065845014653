import useOverlay from '@/hooks/useOverlay'
import { ProjectTag } from '@/types/project-tags'
import { Delete, Edit, MoreVert } from '@mui/icons-material'
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import AddEditTagDialog from './AddEditTagDialog'
import { QueryKey } from '@tanstack/react-query'
import TagDeleteConfirmationDialog from './TagDeleteConfirmationDialog'
import { useGetDocProjectTags } from '@/service-library/hooks/doc-project-tags'
import { useDeleteProjectTag } from '@/service-library/hooks/project-tags'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'

type TagActionsMenuProps = {
  projectTag: ProjectTag
  listQueryKey: QueryKey
}

export default function TagActionsMenu({
  projectTag,
  listQueryKey,
}: TagActionsMenuProps) {
  const overlay = useOverlay()
  const editOverlay = useOverlay()
  const deleteConfirmationOverlay = useOverlay()

  const { data } = useGetDocProjectTags({
    paginator: 'offset',
    filters: {
      limit: '1',
      project_tag_id: projectTag.id,
      fields__only: 'id',
    },
  })
  const tagCount = data?.pages[0].count || 0

  const { deleteProjectTag } = useDeleteProjectTag({
    listQueryKey,
    onSuccess: () => {
      showSuccessSnackbar('Tag deleted successfully')
    },
    onError: () => {
      showErrorSnackbar(
        'Failed to delete tag. Please contact support if this error continues.',
      )
    },
  })

  return (
    <>
      <IconButton onClick={overlay.open} size="small">
        <MoreVert />
      </IconButton>
      <Menu
        id="tag-menu"
        anchorEl={overlay.anchorEl as Element}
        open={overlay.isOpen}
        onClose={overlay.close}
      >
        <MenuItem
          onClick={(e) => {
            editOverlay.open(e)
            overlay.close()
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            if (tagCount > 0) {
              deleteConfirmationOverlay.open(e)
            } else {
              deleteProjectTag(projectTag.id)
            }
            overlay.close()
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <Typography component="span" color="error">
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      <AddEditTagDialog
        overlay={editOverlay}
        projectTag={projectTag}
        listQueryKey={listQueryKey}
      />
      <TagDeleteConfirmationDialog
        overlay={deleteConfirmationOverlay}
        projectTag={projectTag}
        count={tagCount}
        onDelete={() => {
          deleteProjectTag(projectTag.id)
        }}
      />
    </>
  )
}

import { Fragment } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import WorkflowStateSelectMenu from '../workflow-state-select-menu/WorkflowStateSelectMenu'
import { Workflow, WorkflowState } from '@/types/workflows'
import WorkflowActionsMenu from './WorkflowActionsMenu'

type DocumentWorkflowsTableProps = {
  workflows: Workflow[]

  /** A map of workflow ID to the current workflow state to display */
  currentWorkflowStateMap: Record<string, WorkflowState>

  /** Callback that fires when a workflow state changes. */
  onWorkflowStateChange: (
    workflow: Workflow,
    workflowState: WorkflowState,
  ) => void

  /** Callback that fires when the user clicks "Remove from Workflow" in the actions menu. */
  onRemoveFromWorkflow: (workflow: Workflow) => void

  /** Callback that fires when the user confirms they want to delete the document(s). */
  onDeleteConfirm: () => void

  /** Set to true if there are multiple documents. */
  multipleDocuments?: boolean
}

export default function DocumentWorkflowsTable({
  workflows,
  currentWorkflowStateMap,
  onWorkflowStateChange,
  onRemoveFromWorkflow,
  onDeleteConfirm,
  multipleDocuments = false,
}: DocumentWorkflowsTableProps) {
  return (
    <Grid
      container
      sx={{
        '--Grid-borderWidth': '1px',
        borderTop: 'var(--Grid-borderWidth) solid',
        borderLeft: 'var(--Grid-borderWidth) solid',
        borderColor: 'divider',
        '& > div': {
          borderRight: 'var(--Grid-borderWidth) solid',
          borderBottom: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider',
        },
      }}
    >
      {workflows.map((workflow) => {
        if (!workflow.workflow_states) return null

        const selectedWorkflowState = currentWorkflowStateMap[workflow.id]

        if (!selectedWorkflowState) return null // If it isn't in this workflow, then bounce

        return (
          <Fragment key={workflow.id}>
            <Grid
              size={6}
              component={Stack}
              sx={{
                justifyContent: 'center',
                height: 53,
              }}
            >
              <Typography sx={{ px: 2 }}>{workflow.name}</Typography>
            </Grid>
            <Grid size={6}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ height: '100%' }}
              >
                <WorkflowStateSelectMenu
                  workflowStates={workflow.workflow_states}
                  selectedWorkflowState={selectedWorkflowState}
                  hideLabel={selectedWorkflowState.id === 'none'}
                  onChange={(newWorkflowState) =>
                    onWorkflowStateChange(workflow, newWorkflowState)
                  }
                />
                {selectedWorkflowState.id !== 'none' && (
                  <Box sx={{ flexShrink: 0, px: 1 }}>
                    <WorkflowActionsMenu
                      lastWorkflowState={
                        Object.values(currentWorkflowStateMap).filter(
                          ({ id }) => id !== 'none',
                        ).length === 1
                      }
                      onDeleteConfirm={() => onDeleteConfirm()}
                      onRemoveFromWorkflow={() =>
                        onRemoveFromWorkflow(workflow)
                      }
                      multipleDocuments={multipleDocuments}
                    />
                  </Box>
                )}
              </Stack>
            </Grid>
          </Fragment>
        )
      })}
    </Grid>
  )
}

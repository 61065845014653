import { useRef } from 'react'
import { ViewSidebar } from '@mui/icons-material'
import { Button, Menu, MenuItem } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import { useDocumentCurrentStateViewContext } from '@/components/image-zoom-pan/providers/DocumentCurrentStateViewProvider'
import { documentViews } from '@/components/workflow-state-page/WorkflowStateDocumentViews'

export default function DocumentViewControl() {
  const overlay = useOverlay()
  const anchorRef = useRef<HTMLButtonElement>(null)

  const {
    allowedDocumentCurrentStateViews,
    documentCurrentStateView,
    setDocumentCurrentStateView,
  } = useDocumentCurrentStateViewContext()

  if (
    allowedDocumentCurrentStateViews.length === 1 ||
    !documentCurrentStateView
  )
    return null

  return (
    <>
      <Button
        startIcon={<ViewSidebar />}
        onClick={overlay.toggle}
        ref={anchorRef}
        variant="text"
      >
        {documentViews[documentCurrentStateView]}
      </Button>
      <Menu
        open={overlay.isOpen}
        onClose={overlay.close}
        anchorEl={anchorRef.current}
        elevation={16}
      >
        {allowedDocumentCurrentStateViews.map((view) => (
          <MenuItem
            key={view}
            value={view}
            selected={view === documentCurrentStateView}
            onClick={() => {
              setDocumentCurrentStateView(view)
              overlay.close()
            }}
          >
            {documentViews[view]}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

import { Link as RouterLink } from 'react-router-dom'
import { BarChart, Settings } from '@mui/icons-material'
import {
  AppBar,
  IconButton,
  Link,
  Toolbar,
  Stack,
  useTheme,
  Tooltip,
  Typography,
  Button,
} from '@mui/material'
import Logo from '@/assets/Logo'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import BreadcrumbPortal from '@/components/breadcrumbs/BreadcrumbPortal'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import RootOrganizationPicker from '@/components/organizations/RootOrganizationPicker'
import TopBarPortal from '@/components/top-bar-portal/TopBarPortal'
import HelpMenu from './HelpMenu'
import ProfileMenu from './ProfileMenu'
import SuperUserMenu from './SuperUserMenu'

export default function AppTopBar() {
  const theme = useTheme()
  const isDark = theme.palette.mode === 'dark'
  const isSuperUser = useIsSuperUser()
  const [demoMode, setDemoMode] = useDemoModeContext()
  const isProd = import.meta.env.VITE_ENVIRONMENT === 'prod'

  return (
    <AppBar
      position="static"
      elevation={isDark ? 1 : 0}
      sx={{
        zIndex: theme.zIndex.drawer + 2,

        borderBottom: isDark ? 'none' : `1px solid ${theme.palette.divider}`,
        background: isDark ? undefined : theme.palette.background.paper,
      }}
    >
      <Toolbar
        component={Stack}
        direction="row"
        disableGutters
        justifyContent="space-between"
        variant="dense"
        sx={{ py: '2px' }}
      >
        <Stack direction="row">
          <Link ml={1} mb={2.5} to="/" component={RouterLink}>
            <Logo />
          </Link>
          <div style={{ alignSelf: 'center' }}>
            <BreadcrumbPortal />
          </div>
        </Stack>
        {/* Environment and Demo Mode info */}
        {(!isProd || (isSuperUser && !demoMode)) && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.5}
            sx={{
              border: `1px solid ${theme.palette.secondary.main}`,
              borderRadius: '4px',
              px: 1,
            }}
          >
            {!isProd && (
              <Typography
                sx={{ textTransform: 'uppercase', fontSize: '13px' }}
                color="neutral"
              >
                {import.meta.env.VITE_ENVIRONMENT}
              </Typography>
            )}
            {isSuperUser && !demoMode && (
              <>
                {!isProd && (
                  <Typography sx={{ pl: 0.75 }} color="neutral">
                    •
                  </Typography>
                )}
                <Button onClick={() => setDemoMode(true)} variant="text">
                  Turn On Demo Mode
                </Button>
              </>
            )}
          </Stack>
        )}

        <Stack direction="row" alignItems="center">
          <RootOrganizationPicker />
          <Tooltip title="Document Metrics">
            <IconButton
              component={RouterLink}
              to="/metrics/documents"
              size="small"
            >
              <BarChart />
            </IconButton>
          </Tooltip>
          <Tooltip title="Global Settings">
            <IconButton component={RouterLink} to="settings" size="small">
              <Settings />
            </IconButton>
          </Tooltip>
          <HelpMenu />
          <ProfileMenu />
          {isSuperUser && <SuperUserMenu />}
        </Stack>
      </Toolbar>
      <TopBarPortal />
    </AppBar>
  )
}

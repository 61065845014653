import { Divider } from '@mui/material'
import { WorkflowState } from '@/types/workflows'
import SplitDocumentView from '@/components/split-document/SplitDocumentView'
import useDocumentValidationSubscribe from '@/components/notifications/useDocumentValidationSubscribe'
import ComparisonView from '@/components/validation/ComparisonView'
import { useDocumentRowsContext } from '@/components/validation/providers/DocumentRowsProvider'
import { useDocumentChipsContext } from '@/components/validation/providers/DocumentChipsProvider'
import { useDocumentRowValuesContext } from '@/components/validation/providers/DocumentRowValuesProvider'
import Validation from '@/components/validation/Validation'
import ValidationOverlayHeader from './ValidationOverlayHeader'
import { ValidationOverlayContentContainerProps } from './ValidationOverlay'

type ValidationOverlayContentProps = ValidationOverlayContentContainerProps & {
  documentView?: WorkflowState['default_document_view']
}

export default function ValidationOverlayContent({
  dataQueries,
  overlay,
  documentsCount,
  documentIsInFlight,
  documentView,
  hasPreviousDocument,
  hasNextDocument,
  selectedIndex,
  goToPreviousDocument,
  goToNextAvailableDocumentOrClose,
  refetchTableData,
  resetValue,
  setUseOnlySelected,
}: ValidationOverlayContentProps) {
  const documentQueryKey = dataQueries.documentQuery.queryKey
  const { listQueryKey: chipsListQueryKey, setFieldsBeingUpdated } =
    useDocumentChipsContext()
  const { queryKey: rowsListQueryKey } = useDocumentRowsContext()
  const { queryKey: rowValuesListQueryKey } = useDocumentRowValuesContext()

  useDocumentValidationSubscribe({
    documentId: dataQueries.documentQuery.document?.id,
    documentQueryKey,
    chipsListQueryKey,
    rowsListQueryKey,
    rowValuesListQueryKey,
    setFieldsBeingUpdated,
  })

  return (
    <>
      <ValidationOverlayHeader
        documentView={documentView}
        documentQuery={dataQueries.documentQuery}
        onClose={() => {
          overlay.close()
          // clear doc param when closing overlay
          resetValue(false)
          refetchTableData()
          setUseOnlySelected(false)
        }}
        hasPreviousDocument={!documentIsInFlight && hasPreviousDocument}
        hasNextDocument={!documentIsInFlight && hasNextDocument}
        selectedIndex={selectedIndex}
        documentsCount={documentIsInFlight ? undefined : documentsCount}
        goToPreviousDocument={goToPreviousDocument}
        goToNextAvailableDocumentOrClose={goToNextAvailableDocumentOrClose}
      />
      <Divider />
      {documentView === 'validation' && (
        <Validation documentQuery={dataQueries.documentQuery} />
      )}
      {documentView === 'comparison' && (
        <ComparisonView
          documentQuery={dataQueries.documentQuery}
          key={dataQueries.documentQuery.document?.id} // This is to make it so it rerender when the document changes
        />
      )}
      {documentView === 'split-document' &&
        dataQueries.documentQuery.document && (
          <SplitDocumentView
            document={dataQueries.documentQuery.document}
            onDelete={() => {
              refetchTableData()
            }}
          />
        )}
    </>
  )
}

import useOverlay from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '../dialog'
import { Button, Card, Stack, Typography } from '@mui/material'
import { FormTextField, PixyDocsForm } from '../forms'
import { useForm } from 'react-hook-form'
import { useGetSmartAddress } from '@/services/hooks/useSmartAddress'
import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'

type SmartAddressEditDialogProps = {
  value?: string
  onClose: () => void
  handleSubmit: (value: string, keepEditing?: boolean) => void
}

const MIN_SEARCH_LENGTH = 5

export default function SmartAddressEditDialog({
  value = '',
  onClose,
  handleSubmit,
}: SmartAddressEditDialogProps) {
  const overlay = useOverlay({
    initiallyOpen: true,
  })

  // We parse out the parts for now, but we'll need to store the components at some point.
  const methods = useForm({
    defaultValues: {
      address: value,
    },
  })

  const enteredAddress = methods.watch('address')

  const [searchValue, setSearchValue] = useState('')
  const debounceUpdateValue = useMemo(() => debounce(setSearchValue, 250), [])

  useEffect(() => {
    debounceUpdateValue(enteredAddress)
  }, [debounceUpdateValue, enteredAddress])

  const searchEnabled = searchValue.length > MIN_SEARCH_LENGTH && overlay.isOpen
  const { address, isLoading } = useGetSmartAddress({
    filters: {
      address: searchValue,
    },
    refetchOnWindowFocus: false,
    enabled: searchEnabled,
  })

  const foundAddress = address?.us_address.single_line

  function handleClose() {
    onClose()
    overlay.close()
  }

  function onSubmit() {
    if (foundAddress) {
      handleSubmit(foundAddress, false)
    }
    handleClose()
  }

  return (
    <Dialog
      {...overlay}
      close={handleClose}
      title="Edit Smart Address"
      maxWidth="xs"
    >
      <PixyDocsForm methods={methods} onSubmit={onSubmit}>
        <DialogContent>
          <Stack spacing={4}>
            <FormTextField autoFocus name="address" label="Address" fullWidth />

            <Stack spacing={2}>
              <Card
                sx={{ p: 2, background: 'transparent' }}
                variant="outlined"
                color={foundAddress ? undefined : 'text.secondary'}
              >
                <Typography variant="caption" color="text.secondary">
                  Address Match
                </Typography>
                <Typography>
                  {searchEnabled &&
                    isLoading &&
                    searchValue.length > MIN_SEARCH_LENGTH &&
                    'Searching...'}

                  {foundAddress}

                  {((!isLoading && !foundAddress) || !searchEnabled) && (
                    <i>No address found</i>
                  )}
                  {/* {!isLoading && !foundAddress ? <i>No address found</i> : foundAddress)} */}
                </Typography>
              </Card>

              <Typography variant="body2">
                To help with accuracy, the information entered must match a real
                address. The matched address will be saved to the list.
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogFooter>
          <Button onClick={handleClose} variant="text">
            Cancel
          </Button>
          <Button type="submit" disabled={!foundAddress}>
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Button, CircularProgress, IconButton, Stack } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { DocumentPage } from '@/types/documents'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogFooter } from '@/components/dialog'

type SplitDocumentPagesProps = {
  imagePageIndex: number | null
  overlay: OverlayState
  pages: DocumentPage[]
  onClose: () => void
  setImagePageIndex: Dispatch<SetStateAction<number | null>>
  showArrows?: boolean
}

export default function FullscreenImageDialog({
  imagePageIndex,
  overlay,
  pages,
  onClose,
  setImagePageIndex,
  showArrows = false,
}: SplitDocumentPagesProps) {
  const [loaded, setLoaded] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const fullImagePage =
    imagePageIndex !== null ? pages[imagePageIndex] : undefined
  useHotkeys(
    'arrowLeft',
    () => {
      setImagePageIndex((prev) => (prev !== null ? prev - 1 : prev))
    },
    {
      enabled: showArrows && !!imagePageIndex,
    },
  )

  useHotkeys(
    'arrowRight',
    () => {
      setImagePageIndex((prev) => (prev !== null ? prev + 1 : prev))
    },
    {
      enabled:
        showArrows &&
        imagePageIndex !== null &&
        imagePageIndex < pages.length - 1,
    },
  )

  useEffect(() => {
    if (!fullImagePage?.image_urls.full) return
    const img = new Image()
    img.src = fullImagePage.image_urls.full

    if (img.complete) {
      setLoaded(true)
    } else {
      setLoaded(false)
      img.onload = () => setLoaded(true)
    }
  }, [fullImagePage?.image_urls.full])

  if (!fullImagePage) return null

  return (
    <Dialog
      title={`Page ${fullImagePage.page_number}${
        showArrows ? ` of ${pages.length}` : ''
      }`}
      {...overlay}
      onClose={onClose}
      fullScreen
    >
      <Stack
        ref={containerRef}
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction="row"
        sx={{
          background: 'black',
          height: 'calc(100vh - 128px)', // 128px is the height of the dialog header and footer
          textAlign: 'center',
        }}
      >
        {/* We don't show the arrows until we have a ref to the container to know how wide it is. */}
        {showArrows && containerRef.current && (
          <IconButton
            disabled={imagePageIndex === 0}
            size="large"
            onClick={() => {
              setImagePageIndex((prev) => (prev !== null ? prev - 1 : prev))
            }}
            sx={{ height: 'fit-content' }}
          >
            <KeyboardArrowLeft sx={{ fontSize: '55px' }} />
          </IconButton>
        )}
        {!loaded && (
          <Stack
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width:
                (containerRef.current?.clientHeight || 0) *
                (fullImagePage.width / fullImagePage.height),
            }}
          >
            <CircularProgress />
          </Stack>
        )}
        <img
          src={fullImagePage.image_urls.full}
          style={{
            objectFit: 'contain',
            height: '100%',
            display: !loaded ? 'none' : 'block',
          }}
        />

        {showArrows && containerRef.current && (
          <IconButton
            disabled={imagePageIndex === pages.length - 1}
            size="large"
            onClick={() => {
              setImagePageIndex((prev) => (prev !== null ? prev + 1 : prev))
            }}
            sx={{ height: 'fit-content' }}
          >
            <KeyboardArrowRight sx={{ fontSize: '55px' }} />
          </IconButton>
        )}
      </Stack>
      <DialogFooter>
        <Button onClick={onClose}>Close</Button>
      </DialogFooter>
    </Dialog>
  )
}

import { forwardRef } from 'react'
import {
  Checkbox,
  Stack,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material'

type CheckboxFieldProps = {
  isInTable: boolean | undefined
  isSelected: boolean
  onChange: (value: string) => void
  wasAutoFilled: boolean
  'data-focusid'?: string
} & Omit<TextFieldProps, 'onChange'>

const CheckboxField = forwardRef(
  (
    {
      error,
      helperText,
      isInTable,
      isSelected,
      onChange,
      label,
      value,
      wasAutoFilled,
      'data-focusid': dataFocusId,
    }: CheckboxFieldProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const theme = useTheme()

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked.toString()
      onChange(checked.charAt(0).toUpperCase().concat(checked.slice(1)))
    }

    return (
      <Stack
        justifyContent="center"
        sx={{
          minHeight: isInTable ? 'auto' : 48,
        }}
      >
        <Stack
          ref={ref}
          tabIndex={-1}
          alignItems="center"
          direction="row"
          sx={{
            borderRadius: '4px',
            border: isInTable ? undefined : '0.5px solid #878787',
            borderBottom:
              error && !isInTable
                ? `1px solid ${theme.palette.error.main}`
                : 'auto',
            '&:hover': {
              background:
                isSelected || isInTable
                  ? 'transparent'
                  : theme.palette.background.paper,
            },
            height: isInTable ? 'auto' : 48,
            position: 'relative',
            '&:after': {
              left: 0,
              bottom: 0,
              content: '""',
              position: 'absolute',
              right: 0,
              transform: 'scaleX(0)',
            },
            '&:focus-within:after': {
              borderBottom: isInTable
                ? ''
                : error
                ? `1px solid ${theme.palette.error.main}`
                : `2px solid ${theme.palette.primary.main}`,
              transform: 'scaleX(1)',
              transition: 'transform 100ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            },
          }}
        >
          <Checkbox
            checked={value === 'TrueBox' || value === 'True'}
            onChange={handleOnChange}
            color="neutral"
            size={isInTable ? 'small' : undefined}
            sx={{
              padding: isInTable ? 0.5 : 1,
              svg: {
                opacity: wasAutoFilled ? 0.5 : 1,
              },
            }}
            data-focusid={dataFocusId}
          />
          {label && <Typography>{label}</Typography>}
        </Stack>
        {error && (
          <Typography
            component="p"
            variant="caption"
            color="error"
            sx={{ m: isInTable ? undefined : '4px 14px 0' }}
          >
            {helperText}
          </Typography>
        )}
      </Stack>
    )
  },
)

export default CheckboxField

import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { Stack } from '@mui/material'
import AddProjectTagDisplay from './action-displays/AddProjectTagDisplay'
import ManageWorkflowAssignmentDisplay from './action-displays/ManageWorkflowAssignmentDisplay'
import ParamDisplay from './action-displays/ParamDisplay'
import CreateAuditTrailEntryDisplay from './action-displays/CreateAuditTrailEntryDisplay'
import MoveCleanDocumentsDisplay from './action-displays/MoveCleanDocumentsDisplay'
import RunPredictionModelDisplay from './action-displays/RunPredictionModelDisplay'
import AddDocumentFlagDisplay from './action-displays/AddDocumentFlagDisplay'
import TriggerEventDisplay from './action-displays/TriggerEventDisplay'
import RunPredictionLinkedModelDisplay from './action-displays/RunPredictionLinkedModelDisplay'
import RemoveFlagsOfSpecificTypeDisplay from './action-displays/RemoveFlagsOfSpecificTypeDisplay'
import NoParamsActionsDisplay from './action-displays/NoParamsActionsDisplay'
import SetFieldValueDisplay from './action-displays/SetFieldValueDisplay'
import ClearChipsFromPageDisplay from './action-displays/ClearChipsFromPageDisplay'
import SetOrganizationByListMatchDisplay from './action-displays/SetOrganizationByListMatchDisplay'
import EntrataRemoveInvalidTableRowsDisplay from './action-displays/EntrataRemoveInvalidTableRowsDisplay'

type ActionParamsDisplayProps = {
  action: WorkflowStateAction
}

const actionDisplayComponentMap = {
  manage_workflow_assignment: ManageWorkflowAssignmentDisplay,
  create_audit_trail_entry: CreateAuditTrailEntryDisplay,
  move_clean_docs_to_workflow_state: MoveCleanDocumentsDisplay,
  run_prediction_model: RunPredictionModelDisplay,
  add_document_flag: AddDocumentFlagDisplay,
  trigger_event: TriggerEventDisplay,
  run_prediction_linked_model: RunPredictionLinkedModelDisplay,
  add_project_tag: AddProjectTagDisplay,
  remove_flags_of_specific_type: RemoveFlagsOfSpecificTypeDisplay,
  set_field_value: SetFieldValueDisplay,
  clear_chips_from_page: ClearChipsFromPageDisplay,
  set_organization_by_list_match: SetOrganizationByListMatchDisplay,
  entrata_delete_bad_line_items: EntrataRemoveInvalidTableRowsDisplay,
}

export default function ActionParamsDisplay({
  action,
}: ActionParamsDisplayProps) {
  const actionCode = action.action_type?.code as
    | keyof typeof actionDisplayComponentMap
    | undefined
  const paramEntries = Object.entries(action.params).toSorted(
    (a, b) =>
      action.action_type?.params_schema.properties[a[0]].sort_order -
      action.action_type?.params_schema.properties[b[0]].sort_order,
  )
  if (paramEntries.length === 0) {
    return <NoParamsActionsDisplay action={action} />
  }
  if (actionCode && actionDisplayComponentMap[actionCode]) {
    const ActionDisplayComponent = actionDisplayComponentMap[actionCode]
    return <ActionDisplayComponent action={action} />
  }

  return (
    <Stack direction="column" spacing={1}>
      {paramEntries.map(([paramName, paramValue]) => {
        if (
          paramValue === null ||
          action.action_type?.params_schema.properties[paramName].code ===
            'rule-schema'
        )
          return null

        return (
          <ParamDisplay
            key={paramName}
            paramSchema={
              action.action_type?.params_schema.properties[paramName]
            }
            paramValue={paramValue}
          />
        )
      })}
    </Stack>
  )
}

import { Dispatch, SetStateAction } from 'react'
import { Delete, Download, Edit, Rule } from '@mui/icons-material'
import { ListItemIcon, MenuItem } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { useRunAllRulesWithDocChange } from '@/hooks/doc-changes-wrapper-hooks'
import useOverlay from '@/hooks/useOverlay'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import downloadFile from '@/utils/download-file'
import { showErrorSnackbar, showInfoSnackbar } from '@/utils/snackbars'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import BulkDocumentDialog from '@/components/bulk-document-dialog/BulkDocumentDialog'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import IconButtonMenu from '@/components/icon-button-menu/IconButtonMenu'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import DeleteDocumentsDialog from './DeleteDocumentsDialog'
import DownloadOriginalDocumentsDialog from './DownloadOriginalDocumentsDialog'
import { useSelectedWorkflowStateContext } from './SelectedWorkflowStateProvider'

type DocumentGridActionsMenuProps = {
  dataQueryKey: QueryKey
  selectedDocumentIds: string[]
  queryBy: 'rows' | 'documents'
  setSelectedRows: Dispatch<SetStateAction<Record<string, boolean>>>
  refetchDocuments: () => void
}

export default function DocumentGridActionsMenu({
  dataQueryKey,
  selectedDocumentIds,
  queryBy,
  setSelectedRows,
  refetchDocuments,
}: DocumentGridActionsMenuProps) {
  const menuOverlay = useOverlay()
  const bulkDocumentOverlay = useOverlay()
  const deleteDocsOverlay = useOverlay()
  const downloadOriginalDocsOverlay = useOverlay()

  const { selectedWorkflowState } = useSelectedWorkflowStateContext()

  const { runRules } = useRunAllRulesWithDocChange({
    documentIds: selectedDocumentIds,
    workflowId: selectedWorkflowState?.workflow_id || '',
    workflowStateId: selectedWorkflowState?.id || '',
    onError: () => {
      showErrorSnackbar('Documents validation failed. Please try again later.')
    },
  })

  const isSuperUser = useIsSuperUser()
  const [demoMode] = useDemoModeContext()

  const numberOfSelectedRows = selectedDocumentIds.length

  function handleClick(onClick: () => void) {
    menuOverlay.close()
    onClick()
  }

  // This is just a temporary solution for super users only
  async function bulkRunRules() {
    showInfoSnackbar('Validating Documents')
    setSelectedRows({})
    runRules(true)
  }

  useNotifications({
    keys: ['export_original_complete', 'export_original_failed'],
    callback: ({ action, export_response, error }) => {
      if (action === 'export_original_failed') {
        showErrorSnackbar(
          'Failed to download documents. Please try again later.',
        )
      } else {
        if (export_response) {
          downloadFile(
            export_response.file_url,
            export_response.file_name,
          ).catch(() => {
            showErrorSnackbar(
              'Failed to download documents. Please try again later.',
            )
          })
        }
        if (error === 'not-found') {
          showErrorSnackbar(
            'Some documents failed to be included in the download. Please contact support.',
          )
        }
      }
    },
  })

  return (
    <>
      <IconButtonMenu overlay={menuOverlay}>
        <MenuItem
          onClick={() => handleClick(bulkDocumentOverlay.open)}
          disabled={numberOfSelectedRows === 0}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Edit Documents{' '}
          {numberOfSelectedRows ? `(${numberOfSelectedRows})` : ''}
        </MenuItem>
        <MenuItem
          onClick={() => handleClick(deleteDocsOverlay.open)}
          disabled={numberOfSelectedRows === 0}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          Delete Documents{' '}
          {numberOfSelectedRows ? `(${numberOfSelectedRows})` : ''}
        </MenuItem>
        <MenuItem
          onClick={() => handleClick(downloadOriginalDocsOverlay.open)}
          disabled={numberOfSelectedRows === 0}
        >
          <ListItemIcon>
            <Download />
          </ListItemIcon>
          Download Original Files{' '}
          {numberOfSelectedRows ? `(${numberOfSelectedRows})` : ''}
        </MenuItem>
        {isSuperUser && !demoMode && selectedWorkflowState && (
          <MenuItem
            onClick={() => handleClick(bulkRunRules)}
            disabled={numberOfSelectedRows === 0}
            sx={{ color: SUPER_USER_ONLY_COLOR }}
          >
            <ListItemIcon>
              <Rule
                sx={{
                  color: SUPER_USER_ONLY_COLOR,
                }}
              />
            </ListItemIcon>
            Run Rules {numberOfSelectedRows ? `(${numberOfSelectedRows})` : ''}{' '}
            (Clear Cache)
          </MenuItem>
        )}
      </IconButtonMenu>

      {bulkDocumentOverlay.isOpen && (
        <BulkDocumentDialog
          dataQueryKey={dataQueryKey}
          documentIds={selectedDocumentIds}
          overlay={bulkDocumentOverlay}
          queryBy={queryBy}
          setSelectedRows={setSelectedRows}
        />
      )}

      <DeleteDocumentsDialog
        overlay={deleteDocsOverlay}
        documentIds={selectedDocumentIds}
        refetch={refetchDocuments}
        setSelectedRows={setSelectedRows}
      />

      <DownloadOriginalDocumentsDialog
        overlay={downloadOriginalDocsOverlay}
        documentIds={selectedDocumentIds}
        setSelectedRows={setSelectedRows}
      />
    </>
  )
}

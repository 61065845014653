import { useNavigate } from 'react-router-dom'
import { Dialog, DialogContent, DialogFooter } from '../dialog'
import { OverlayState } from '@/hooks/useOverlay'
import useLocationHelpers from '@/hooks/useLocationHelpers'
import { Button, Card, Stack } from '@mui/material'
import { themeColorOptions } from '@/theme/usePixydocsTheme'
import { SubmitHandler, useForm } from 'react-hook-form'
import generateUuid from '@/utils/generate-uuid'
import { FormTextField, PixyDocsForm } from '@/components/forms'
import PixyColorPicker from '../PixyColorPicker'
import { WorkflowState, WorkflowStateType } from '@/types/workflows'
import { useEffect } from 'react'
import {
  useCreateWorkflowStateWithDefaults,
  useUpdateWorkflowState,
} from '@/service-library/hooks/workflow-states'
import queryKeys from '@/service-library/query-keys'
import { QueryKey } from '@tanstack/react-query'

type NewWorkflowStateDialogProps = {
  overlay: OverlayState
  workflowId: string
  workflowState?: WorkflowState
  detailQueryKey?: QueryKey
  listQueryKey?: QueryKey
  workflowStateType?: WorkflowStateType
}

type FormValues = {
  name: string
  color: string
}

export default function AddEditWorkflowStateDialog({
  overlay,
  workflowId,
  workflowState,
  detailQueryKey,
  listQueryKey,
  workflowStateType,
}: NewWorkflowStateDialogProps) {
  const navigate = useNavigate()
  const { appendCurrentParamsToUrl } = useLocationHelpers()

  const { createWorkflowState } = useCreateWorkflowStateWithDefaults({
    listQueryKey,
    sideEffectQueryKeys: [queryKeys.workflows.lists()],
    onSuccess: (state) => {
      navigate(`../${appendCurrentParamsToUrl(state.id)}`)
    },
  })
  const { updateWorkflowState } = useUpdateWorkflowState({
    detailQueryKey,
    listQueryKey,
    sideEffectQueryKeys: [queryKeys.workflows.lists()],
  })

  const methods = useForm({
    defaultValues: {
      name: workflowState?.name || '',
      color: workflowState?.color || 'blue',
    },
  })

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    const id = generateUuid()
    if (workflowState) {
      updateWorkflowState({
        ...workflowState,
        name: values.name,
        color: values.color,
      })
    } else {
      createWorkflowState({
        id,
        code: id, // Defaulting code to UUID since they must be unique and we may not have a use for it later
        sort_order: 1000000,
        workflow_id: workflowId,
        name: values.name,
        color: values.color,
        default_document_view: 'validation',
        allowed_document_views: ['validation'],
        workflow_state_type_id: workflowStateType?.id,
        workflow_state_type: workflowStateType,
      })
    }

    overlay.close()
  }

  const { isSubmitSuccessful } = methods.formState
  useEffect(() => {
    methods.reset({
      name: workflowState?.name || '',
      color: workflowState?.color || 'blue',
    })
  }, [isSubmitSuccessful, methods, workflowState?.color, workflowState?.name])

  return (
    <Dialog
      title={workflowState ? 'Edit Workflow State' : 'New Workflow State'}
      {...overlay}
      maxWidth="xs"
    >
      <PixyDocsForm methods={methods} onSubmit={onSubmit}>
        <DialogContent>
          <Stack spacing={2}>
            <FormTextField
              autoFocus
              label="Name"
              fullWidth
              required
              {...methods.register('name', {
                validate: (value) => value.trim().length > 0,
              })}
            />

            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Card
                elevation={0}
                variant="outlined"
                sx={{ p: 2, alignSelf: 'center' }}
              >
                <PixyColorPicker
                  colors={themeColorOptions.filter(
                    (color) => !['red', 'orange'].includes(color),
                  )}
                  selectedColor={methods.watch('color')}
                  setSelectedColor={(color) => {
                    methods.setValue('color', color)
                  }}
                />
              </Card>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button type="submit" disabled={!methods.formState.isValid}>
            Submit
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}

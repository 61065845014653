import { Description } from '@mui/icons-material'
import { Card, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import Bar from './Bar'
import { Handle, Position } from '@xyflow/react'

export default function OCRResultsNode() {
  const theme = useTheme()
  return (
    <Tooltip
      title="OCR results are used as an input for labeling prediction."
      arrow
      placement="left"
      enterDelay={500}
    >
      <Card
        sx={{
          textAlign: 'start',
        }}
      >
        <Stack direction="row">
          <Bar color={theme.palette.teal.main} />
          <Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ p: 1, pr: 2 }}
            >
              <Description fontSize="small" />
              <Typography variant="body2">Document Text</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Handle type="source" position={Position.Right} />
      </Card>
    </Tooltip>
  )
}

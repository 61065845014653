import { useEffect } from 'react'
import { UseGetListOptions } from '@/service-library/core-hooks/useGetList'
import { useGetDocumentChips } from '@/service-library/hooks/document-chips'
import { DocumentChip } from '@/types/documents'

export default function useGetAllDocumentChips(
  options: UseGetListOptions<DocumentChip> = {},
) {
  const query = useGetDocumentChips({
    ...options,
    filters: {
      limit: '1000',
      ...options.filters,
    },
  })

  const { hasNextPage, isFetching, isRefetching, fetchNextPage } = query

  const isFetchingAll = hasNextPage || (isFetching && !isRefetching)

  useEffect(() => {
    if (
      (options.enabled || options.enabled === undefined) &&
      hasNextPage &&
      !isFetching
    ) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetching, options.enabled])

  return { isFetchingAll, ...query }
}

import { memo } from 'react'
import { Card, Stack, useTheme } from '@mui/material'
import { Handle, Position } from '@xyflow/react'
import { ProjectGridField } from '@/types/fields'
import Bar from './Bar'
import NodeHeader from './NodeHeader'
import {
  ProjectLinkedModel,
  ProjectLinkedModelInfoType,
} from '@/types/project-linked-models'
import { ProjectModelVersionInfoType } from '@/types/project-models'
import PlayPauseButton from './PlayPauseButton'

function InfoTypeNode({
  id,
  data,
}: {
  id: string
  data: {
    label: string
    showSourceHandle: boolean
    showTargetHandle: boolean
    versionInfoType: ProjectModelVersionInfoType
    field: ProjectGridField
    flow: 'in' | 'out'
    projectLinkedModel: ProjectLinkedModel
    projectLinkedModelInfoType?: ProjectLinkedModelInfoType
    hasMappingMap: Record<string, ProjectGridField>
  }
}) {
  const theme = useTheme()

  const {
    label,
    showSourceHandle,
    showTargetHandle,
    versionInfoType,
    flow,
    projectLinkedModel,
    projectLinkedModelInfoType,
  } = data

  return (
    <Card
      sx={{
        textAlign: 'start',
        width: '100%',
      }}
    >
      <Stack direction="row" spacing={1} sx={{ pr: 1 }}>
        <Bar
          color={
            flow === 'in' ? theme.palette.orange.main : theme.palette.blue.main
          }
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ py: 1, pr: 0.5, width: '100%' }}
        >
          <NodeHeader topLine="Info Type" bottomLine={label} />
          {flow === 'out' && (
            <PlayPauseButton
              nodeId={id}
              projectLinkedModelInfoType={projectLinkedModelInfoType}
              projectLinkedModel={projectLinkedModel}
              versionInfoType={versionInfoType}
            />
          )}
        </Stack>
      </Stack>
      {showSourceHandle && <Handle type="source" position={Position.Right} />}
      {showTargetHandle && <Handle type="target" position={Position.Left} />}
    </Card>
  )
}

export default memo(InfoTypeNode)

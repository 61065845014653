import useOverlay, { OverlayState } from '@/hooks/useOverlay'
import { useGetProjectModelTypes } from '@/service-library/hooks/project-model-types'
import { Add } from '@mui/icons-material'
import {
  Box,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material'
import AddCustomModelDialog from './AddCustomModelDialog'
import { useState } from 'react'
import { ProjectModelType } from '@/types/project-models'
import { useGetProjectModels } from '@/service-library/hooks/project-models'
import { useProjectContext } from '../project-tables/ProjectProvider'
import { ProjectLinkedModel } from '@/types/project-linked-models'
import { useCreateProjectLinkedModel } from '@/service-library/hooks/project-linked-models'
import generateUuid from '@/utils/generate-uuid'
import queryKeys from '@/service-library/query-keys'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import { OCR_MODEL_TYPE_ID } from '../model-page/helpers'
import { useGetPubModels } from '@/service-library/hooks/pub-models'

type AddModelMenuProps = {
  overlay: OverlayState
  anchorEl: Element | null
  projectLinkedModels: ProjectLinkedModel[]
}

export default function AddModelMenu({
  overlay,
  anchorEl,
  projectLinkedModels,
}: AddModelMenuProps) {
  const { project } = useProjectContext()
  const addCustomModelOverlay = useOverlay()
  const [modelType, setModelType] = useState<ProjectModelType | null>(null)

  const { pubModels } = useGetPubModels({
    filters: {
      limit: '1000',
      visible: 'public',
    },
  })

  const { projectModels } = useGetProjectModels({
    filters: {
      limit: '1000',
      project_id: project.id,
      parent_model_id__isnull: 'true',
      'project_model_type_id!': OCR_MODEL_TYPE_ID,
    },
  })

  const { createProjectLinkedModel } = useCreateProjectLinkedModel({
    sideEffectQueryKeys: [queryKeys.projectLinkedModels.lists()],
    onError: () => {
      showErrorSnackbar(
        'Failed to add model to project. Please contact Pixydocs support.',
      )
    },
    onSuccess: () => {
      showSuccessSnackbar('Model added to project successfully.')
    },
  })

  const unlinkedProjectModels = projectModels.filter(
    (projectModel) =>
      !projectLinkedModels.some(
        (projectLinkedModel) =>
          projectLinkedModel.project_model?.id === projectModel.id,
      ),
  )

  const { projectModelTypes } = useGetProjectModelTypes()
  const displayedModelTypes = projectModelTypes.filter(
    ({ code }) => code !== 'OCR',
  )

  // Filter out any we've already added to the library
  const displayedPubModels = pubModels.filter(
    ({ project_model_id }) =>
      !projectLinkedModels.some(
        (projectLinkedModel) =>
          projectLinkedModel.project_model_id === project_model_id,
      ),
  )

  function addProjectLinkedModel(projectModelId: string) {
    createProjectLinkedModel({
      id: generateUuid(),
      project_id: project.id,
      project_model_id: projectModelId,
    })
    overlay.close()
  }

  return (
    <>
      <Menu open={overlay.isOpen} onClose={overlay.close} anchorEl={anchorEl}>
        {/* TODO: Fetch and show PubModels */}

        {unlinkedProjectModels?.map((projectModel) => {
          return (
            <MenuItem
              key={projectModel.id}
              onClick={() => {
                addProjectLinkedModel(projectModel.id)
              }}
            >
              <Tooltip title="This model is managed by the project you are currently viewing, but is not in the project's model library.">
                <Box
                  sx={{
                    fontSize: 10,
                    mr: 1.5,
                    py: 0.25,
                    px: 0.5,
                    borderRadius: 1,
                    border: 1,
                    color: (theme) => theme.palette.secondary.main,
                  }}
                >
                  From Project
                </Box>
              </Tooltip>

              {projectModel.name}
            </MenuItem>
          )
        })}

        {displayedPubModels?.map((pubModel) => {
          return (
            <MenuItem
              key={pubModel.id}
              onClick={() => {
                addProjectLinkedModel(pubModel.project_model_id)
              }}
            >
              {pubModel.name}
            </MenuItem>
          )
        })}

        {unlinkedProjectModels.length > 0 && displayedModelTypes.length > 0 && (
          <Divider />
        )}

        {displayedModelTypes?.map((modelType) => (
          <MenuItem
            onClick={() => {
              addCustomModelOverlay.open()
              setModelType(modelType)
              overlay.close()
            }}
            key={modelType.id}
          >
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            Custom {modelType.code} Model
          </MenuItem>
        ))}
      </Menu>

      <AddCustomModelDialog
        overlay={addCustomModelOverlay}
        modelType={modelType}
      />
    </>
  )
}

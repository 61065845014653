/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack, Typography, useTheme } from '@mui/material'
import {
  MRT_ColumnDef,
  MRT_TableInstance,
  MRT_Column,
  MRT_Cell,
  MRT_Row,
  useMaterialReactTable,
  MRT_TableOptions,
} from 'material-react-table'
import { ReactNode, useCallback } from 'react'

export type ZerapixTableOptions<DataType extends Record<string, any>> = {
  columns: MRT_ColumnDef<DataType, any>[]
  data: MRT_TableOptions<DataType>['data']
  title?: ReactNode
  renderTableActions?: MRT_TableOptions<DataType>['renderTopToolbarCustomActions']
} & MRT_TableOptions<DataType>

export default function useZerapixTable<DataType extends Record<string, any>>({
  title,
  renderTableActions,
  displayColumnDefOptions = {},
  initialState = {},
  muiTableHeadCellProps = {},
  muiTableBodyCellProps = {},
  muiTableFooterCellProps = {},
  muiTableHeadRowProps = {},
  muiTableBodyRowProps = {},
  muiTableHeadProps = {},
  muiBottomToolbarProps = {},
  muiTopToolbarProps = {},
  ...options
}: ZerapixTableOptions<DataType>) {
  const theme = useTheme()

  const fixedColumnStyling = useCallback(
    ({
      muiTableHeadCellProps,
      muiTableBodyCellProps,
      muiTableFooterCellProps,
      ...rest
    }: Partial<MRT_ColumnDef<DataType>> = {}) => {
      const commonSxProps = {
        backgroundColor: `${theme.palette.background.paper} !important`,
        borderColor: theme.palette.divider,
      }
      return {
        size: 45,
        muiTableHeadCellProps: (props: {
          table: MRT_TableInstance<DataType>
          column: MRT_Column<DataType>
        }) => {
          const headCellProps =
            typeof muiTableHeadCellProps === 'function'
              ? muiTableHeadCellProps(props)
              : muiTableHeadCellProps
          return {
            ...headCellProps,
            sx: {
              ...commonSxProps,
              ...headCellProps?.sx,
            },
          }
        },
        muiTableBodyCellProps: (props: {
          cell: MRT_Cell<DataType>
          column: MRT_Column<DataType>
          row: MRT_Row<DataType>
          table: MRT_TableInstance<DataType>
        }) => {
          const bodyCellProps =
            typeof muiTableBodyCellProps === 'function'
              ? muiTableBodyCellProps(props)
              : muiTableBodyCellProps
          return {
            ...bodyCellProps,
            sx: {
              ...commonSxProps,
              ...bodyCellProps?.sx,
            },
          }
        },
        muiTableFooterCellProps: (props: {
          table: MRT_TableInstance<DataType>
          column: MRT_Column<DataType>
        }) => {
          const footerCellProps =
            typeof muiTableFooterCellProps === 'function'
              ? muiTableFooterCellProps(props)
              : muiTableFooterCellProps
          return {
            ...footerCellProps,
            sx: {
              ...commonSxProps,
              ...footerCellProps?.sx,
            },
          }
        },
        ...rest,
      }
    },
    [theme.palette.background.paper, theme.palette.divider],
  )

  const table = useMaterialReactTable<DataType>({
    renderTopToolbarCustomActions: (props) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        sx={{ px: 1, width: '100%' }}
      >
        <Typography variant="h6">{title}</Typography>
        {renderTableActions && renderTableActions(props)}
      </Stack>
    ),
    enableToolbarInternalActions: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enablePagination: false,
    enableSorting: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    positionToolbarAlertBanner: 'none',
    displayColumnDefOptions: {
      ...displayColumnDefOptions,
      'mrt-row-actions': {
        header: '',
        ...fixedColumnStyling(displayColumnDefOptions['mrt-row-actions']),
      },
      'mrt-row-select': {
        ...fixedColumnStyling(displayColumnDefOptions['mrt-row-select']),
      },
    },
    initialState: {
      density: 'compact',
      ...initialState,
    },
    layoutMode: 'grid',
    muiTableHeadRowProps: (props) => {
      const passedInProps =
        typeof muiTableHeadRowProps === 'function'
          ? muiTableHeadRowProps(props)
          : muiTableHeadRowProps
      return {
        ...passedInProps,
        sx: {
          backgroundColor: `${theme.palette.background.paper} !important`,
          borderColor: theme.palette.divider,
          ...passedInProps.sx,
        },
      }
    },
    muiTableBodyRowProps: (props) => {
      const passedInProps =
        typeof muiTableBodyRowProps === 'function'
          ? muiTableBodyRowProps(props)
          : muiTableBodyRowProps
      return {
        ...passedInProps,
        sx: {
          backgroundColor: `${theme.palette.background.paper} !important`,
          borderColor: theme.palette.divider,
          ...passedInProps.sx,
        },
      }
    },
    muiTableHeadCellProps: (props) => {
      const passedInProps =
        typeof muiTableHeadCellProps === 'function'
          ? muiTableHeadCellProps(props)
          : muiTableHeadCellProps
      return {
        ...passedInProps,
        sx: {
          borderColor: theme.palette.divider,
          backgroundColor: `${theme.palette.background.paper} !important`,
          //This is to prevent the header to overflow when the drag handle icon is added
          '&:last-child': {
            pr: initialState.density ? 'auto' : '0.7rem',
          },
          ...passedInProps.sx,
        },
      }
    },
    muiTableBodyCellProps: (props) => {
      const passedInProps =
        typeof muiTableBodyCellProps === 'function'
          ? muiTableBodyCellProps(props)
          : muiTableBodyCellProps
      return {
        ...passedInProps,
        sx: {
          backgroundColor: `${theme.palette.background.paper} !important`,
          borderColor: theme.palette.divider,
          ...passedInProps.sx,
        },
      }
    },
    muiTableFooterCellProps: (props) => {
      const passedInProps =
        typeof muiTableFooterCellProps === 'function'
          ? muiTableFooterCellProps(props)
          : muiTableFooterCellProps
      return {
        ...passedInProps,
        sx: {
          backgroundColor: `${theme.palette.background.paper} !important`,
          ...passedInProps.sx,
        },
      }
    },
    muiTableHeadProps: (props) => {
      const passedInProps =
        typeof muiTableHeadProps === 'function'
          ? muiTableHeadProps(props)
          : muiTableHeadProps
      return {
        ...passedInProps,
        sx: {
          backgroundColor: `${theme.palette.background.paper} !important`,
          ...passedInProps.sx,
        },
      }
    },
    muiBottomToolbarProps: (props) => {
      const passedInProps =
        typeof muiBottomToolbarProps === 'function'
          ? muiBottomToolbarProps(props)
          : muiBottomToolbarProps
      return {
        ...passedInProps,
        sx: {
          backgroundColor: `${theme.palette.background.paper} !important`,
          ...passedInProps.sx,
        },
      }
    },
    muiTopToolbarProps: (props) => {
      const passedInProps =
        typeof muiTopToolbarProps === 'function'
          ? muiTopToolbarProps(props)
          : muiTopToolbarProps
      return {
        ...passedInProps,
        sx: {
          backgroundColor: `${theme.palette.background.paper} !important`,
          ...passedInProps.sx,
        },
      }
    },
    ...options,
  })

  return table
}

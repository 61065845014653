import { memo } from 'react'
import { Box, Card, Divider, Stack, Typography, useTheme } from '@mui/material'
import { Handle, Position } from '@xyflow/react'
import { ProjectGridField } from '@/types/fields'
import Bar from './Bar'
import NodeHeader from './NodeHeader'
import TableItemsContainer from './TableItemsContainer'
import TableNodeItem from './TableNodeItem'

type FieldNodeProps = {
  data: {
    field: ProjectGridField
    hasMappingMap: Record<string, ProjectGridField>
  }
}

function FieldNode({ data }: FieldNodeProps) {
  const theme = useTheme()
  const { field, hasMappingMap } = data
  const isTableField = !!field.fields
  return (
    <Card
      sx={{
        textAlign: 'start',
        width: '100%',
      }}
    >
      {!isTableField && hasMappingMap[field.id] && (
        <Handle
          type="target"
          position={Position.Left}
          style={{ transform: 'translate(-3px, -16px)' }}
        />
      )}

      <Stack direction="column">
        <Stack direction="row" spacing={1} sx={{ pr: 1 }}>
          <Bar color={theme.palette.green.main} />
          <Box sx={{ py: 1 }}>
            <NodeHeader
              topLine={isTableField ? 'Table' : 'Field'}
              bottomLine={field.name}
            />
          </Box>
        </Stack>

        <Divider />

        {/* MARK: Header Field Info Type */}
        {!isTableField && (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              pr: 1,
            }}
          >
            <Bar color={theme.palette.green.main} />
            <Typography
              component="p"
              variant="caption"
              color="text.secondary"
              sx={{
                py: 0.5,
                lineHeight: 1.2,
                fontSize: 8,
              }}
              noWrap
            >
              <i>Info Type: {field.info_type?.name}</i>
            </Typography>
          </Stack>
        )}
      </Stack>

      {/* MARK: Table Fields */}
      {isTableField && (
        <Stack direction="row">
          <Bar color={theme.palette.green.main} />
          <TableItemsContainer>
            {field.fields?.map((tableField, index) => {
              return (
                <TableNodeItem
                  key={tableField.id}
                  topLine={`Info Type: ${tableField.info_type?.name}`}
                  bottomLine={tableField.name}
                  handleId={tableField.id}
                  handleType="target"
                  showHandle={!!hasMappingMap[tableField.id]}
                  index={index}
                />
              )
            })}
          </TableItemsContainer>
        </Stack>
      )}
    </Card>
  )
}

export default memo(FieldNode)

import { memo, useState } from 'react'
import { Button, Checkbox, Stack } from '@mui/material'
import OrganizationTreeItem, {
  OrganizationTreeItemLabel,
  OrganizationTreeItemProps,
} from './OrganizationTreeItem'

type OrganizationTreeItemWithCheckboxProps = Omit<
  OrganizationTreeItemProps,
  'onChange'
> & {
  onChange: (checked: boolean, orgId: string) => void
  checkboxIsDisabled?: boolean
  showOnlyButtonOnHover?: boolean
  getIsChecked?: (id: string) => boolean
  handleClickOnOnly?: (id: string) => void
}

function OrganizationTreeItemWithCheckbox({
  onChange,
  checkboxIsDisabled,
  showOnlyButtonOnHover,
  getIsChecked,
  handleClickOnOnly,
  ...props
}: OrganizationTreeItemWithCheckboxProps) {
  const { orgTree, searchTerm } = props
  const [showOnlyButton, setShowOnlyButton] = useState(false)
  const hasSubOrgs = orgTree.subOrganizations.length > 0

  return (
    <OrganizationTreeItem
      {...props}
      label={
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.25}
          onMouseEnter={
            showOnlyButtonOnHover && hasSubOrgs
              ? () => {
                  setShowOnlyButton(true)
                }
              : undefined
          }
          onMouseLeave={
            showOnlyButtonOnHover && hasSubOrgs
              ? () => {
                  setShowOnlyButton(false)
                }
              : undefined
          }
          sx={{
            cursor: hasSubOrgs ? 'pointer !important' : 'default !important',
          }}
        >
          <Checkbox
            color="neutral"
            disabled={checkboxIsDisabled || orgTree.inheritedSelection}
            checked={getIsChecked?.(orgTree.id) ?? orgTree.selected}
            size="small"
            sx={{
              p: 0.5,
              ml: -1.25,
            }}
            onChange={(_e, checked) => {
              onChange(checked, orgTree.id)
            }}
          />
          <OrganizationTreeItemLabel searchTerm={searchTerm} {...orgTree} />
          {showOnlyButton && (
            <Button
              size="small"
              variant="text"
              sx={{ ml: '8px !important', p: 0 }}
              onClick={(e) => {
                e.stopPropagation()
                handleClickOnOnly?.(orgTree.id)
              }}
            >
              Only
            </Button>
          )}
        </Stack>
      }
      SubOrgComponent={OrganizationTreeItemWithCheckbox}
      subOrgComponentProps={{
        checkboxIsDisabled,
        showOnlyButtonOnHover,
        onChange,
        getIsChecked,
        handleClickOnOnly,
      }}
      slotProps={{
        ...props.slotProps,
        content: {
          ...props.slotProps?.content,
          // @ts-expect-error -- sx is a valid prop
          sx: {
            columnGap: 1,
          },
        },
      }}
    />
  )
}

export default memo(OrganizationTreeItemWithCheckbox)

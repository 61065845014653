import { Typography } from '@mui/material'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import PrimaryTextWrapper from './PrimaryTextWrapper'

type EntrataRemoveInvalidTableRowsDisplayProps = {
  action: WorkflowStateAction
}

export default function EntrataRemoveInvalidTableRowsDisplay({
  action,
}: EntrataRemoveInvalidTableRowsDisplayProps) {
  const { fieldsMap } = useProjectContext()
  return (
    <Typography variant="body2" color="text.secondary">
      If a row does not have more than one valid value, it will be removed from
      the{' '}
      <PrimaryTextWrapper>
        {fieldsMap[action.params.table_id].name}
      </PrimaryTextWrapper>{' '}
      table.
    </Typography>
  )
}

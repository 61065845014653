import { Box } from '@mui/material'
import { Handle, HandleProps, Position } from '@xyflow/react'
import NodeHeader from './NodeHeader'

type TableNodeItemProps = {
  handleId: string
  handleType: HandleProps['type']
  index: number
  topLine: string
  bottomLine: string
  showHandle?: boolean
}

export default function TableNodeItem({
  handleId,
  handleType,
  showHandle = false,
  index,
  topLine,
  bottomLine,
}: TableNodeItemProps) {
  return (
    <Box
      sx={{
        pl: 1,
        pr: 2,
        height: 36,
      }}
    >
      {showHandle && (
        <Handle
          id={handleId}
          type={handleType}
          position={handleType === 'source' ? Position.Right : Position.Left}
          style={{
            top: 77,
            left: handleType === 'target' ? -3 : undefined,
            right: handleType === 'source' ? -3 : undefined,
            transform: `translateY(${48 * index}px)`,
          }}
        />
      )}
      <NodeHeader topLine={topLine} bottomLine={bottomLine} />
    </Box>
  )
}

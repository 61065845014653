import { useMemo, useRef } from 'react'
import { MRT_ColumnDef } from 'material-react-table'
import {
  Badge,
  Button,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { DocumentRow } from '@/types/documents'
import { ProjectGrid } from '@/types/projects'
import { WorkflowState } from '@/types/workflows'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import useOverlay from '@/hooks/useOverlay'
import useGetRelevantWorkflowState from '@/hooks/useRelevantWorkflowState'
import { useGetDocumentWorkflowStates } from '@/service-library/hooks/document-workflow-states'
import { getBaseGrid } from '@/utils/field-utils'
import getColumnFilterModeOptions from '@/utils/getColumnFilterModeOptions'
import { getIdleTime, prettifyDate } from '@/utils/getFormattedDateTimeString'
import { useOrganizationsContext } from '@/components/organizations/OrganizationsProvider'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import CustomFilterComponent from '@/components/workflows/CustomFilterComponent'
import DocumentRowIndicators from '@/components/workflows/DocumentRowIndicators'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import WorkflowStateMenuDropdown from '@/components/workflow-state-dropdown/WorkflowStateMenuDropdown'
import { ProjectTag } from '@/types/project-tags'
import DocProjectTagsList from '../tags/DocProjectTagsList'

type UseDocumentColumnsOptions = {
  workflowState?: WorkflowState
  showRowTypes?: boolean
}

export default function useDocumentColumns({
  workflowState,
  showRowTypes,
}: UseDocumentColumnsOptions) {
  const theme = useTheme()

  const workflowStateFilterOverlay = useOverlay()
  const workflowStateFilterButtonRef = useRef<HTMLButtonElement>(null)

  const { documentWorkflowStates = [] } = useGetDocumentWorkflowStates({
    filters: {
      limit: '1000',
      workflow_state_id: workflowState?.id,
      status__in: 'error,exporting',
    },
    enabled: workflowState?.code === 'ready_for_export',
  })

  const { selectedWorkflow } = useSelectedWorkflowContext()
  const workflowStates = useMemo(
    () =>
      selectedWorkflow?.workflow_states?.filter(
        (ws) => ws.code !== 'processing',
      ) || [],
    [selectedWorkflow?.workflow_states],
  )

  const { project } = useProjectContext()
  const baseGrid = getBaseGrid(project.project_grids as ProjectGrid[])

  const { organizations } = useOrganizationsContext()

  const organizationsMap: Record<string, string> = organizations.reduce(
    (acc, organization) => ({ ...acc, [organization.id]: organization.name }),
    {},
  )

  const getRelevantWorkflowState = useGetRelevantWorkflowState()

  const getThemeColor = useGetThemeColor()

  const columns = useMemo<MRT_ColumnDef<Required<DocumentRow>>[]>(() => {
    const cols = [
      {
        id: 'indicators',
        Cell: ({ row }) => {
          const documentWorkflowState = documentWorkflowStates.find(
            ({ document_id }) => row.original.document.id === document_id,
          )
          return workflowState ? (
            <DocumentRowIndicators
              workflowState={workflowState}
              documentWorkflowState={documentWorkflowState}
              document={row.original.document}
              showPrimaryIndicators={
                row.original.project_grid_id === baseGrid?.id
              }
            />
          ) : null
        },
        header: '',
        enableColumnOrdering: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableColumnFilterModes: false,
        enableResizing: false,
        enableSorting: false,
        minSize: 0,
        size: 40,
        muiTableHeadCellProps: {
          sx: {
            borderColor: theme.palette.divider,
            justifyContent: 'center',
            flex: '0 0 auto',
            padding: 0,
          },
        },
        muiTableBodyCellProps: {
          sx: {
            borderColor: theme.palette.divider,
            justifyContent: 'center',
            flex: '0 0 auto',
            padding: 0,
          },
        },
      },
      {
        id: 'fileColId',
        accessorFn: (row) => row.document.name,
        columnFilterModeOptions: getColumnFilterModeOptions('fileColId'),
        header: 'Document',
      },
      {
        id: 'organizationColId',
        accessorFn: (row) => organizationsMap[row.document.owner_org_id],
        columnFilterModeOptions:
          getColumnFilterModeOptions('organizationColId'),
        header: 'Organization',
      },
      {
        id: 'tagColId',
        accessorFn: (row) => {
          const tags =
            project.tags?.filter((tag) =>
              row.document.project_tags_ids?.includes(tag.id),
            ) || ([] as ProjectTag[])
          return tags ? (
            <DocProjectTagsList projectTags={tags} position="left" />
          ) : null
        },
        header: 'Tags',
        enableColumnOrdering: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        id: 'submitterColId',
        accessorFn: (row) => row.document.created_by_user?.name,
        columnFilterModeOptions: getColumnFilterModeOptions('submitterColId'),
        header: 'Submitter',
      },
      {
        id: 'pageCountColId',
        accessorFn: (row) => row.document.page_count,
        columnFilterModeOptions: getColumnFilterModeOptions('pageCountColId'),
        header: 'Page Count',
        muiFilterTextFieldProps: {
          type: 'number',
          sx: {
            /* Chrome, Safari, Edge, Opera */
            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
              {
                WebkitAppearance: 'none',
                margin: 0,
              },
            /* Firefox */
            'input[type=number]': {
              MozAppearance: 'textfield',
            },
          },
        },
      },
      {
        id: 'uploadColId',
        accessorFn: (row) => prettifyDate(row.document.uploaded_at),
        columnFilterModeOptions: getColumnFilterModeOptions('uploadColId'),
        Filter: (props) => (
          <CustomFilterComponent fieldTypeCode="datetime" {...props} />
        ),
        header: 'Upload Date',
      },
      {
        id: 'enteredAtColId',
        accessorFn: (row) => {
          const state = workflowState || getRelevantWorkflowState(row.document)
          return (
            row.document?.document_workflow_states?.find(
              ({ workflow_state_id }) => workflow_state_id === state?.id,
            )?.entered_at || ''
          )
        },
        Cell: ({ cell }) => {
          const value = cell.getValue() as string
          return (
            <Tooltip
              title={`Entered Workflow State: ${prettifyDate(value)}`}
              enterDelay={500}
            >
              <Typography variant="body2">{getIdleTime(value)}</Typography>
            </Tooltip>
          )
        },
        columnFilterModeOptions: getColumnFilterModeOptions('enteredAtColId'),
        Filter: (props) => (
          <CustomFilterComponent fieldTypeCode="datetime" {...props} />
        ),
        header: 'State Duration',
      },
    ] as MRT_ColumnDef<Required<DocumentRow>>[]

    if (!workflowState) {
      const workflowStateCol = {
        id: 'workflowStateColId',
        accessorFn: (row) => getRelevantWorkflowState(row.document),
        Cell: ({ cell }) => {
          const workflowState = cell.getValue() as WorkflowState
          return (
            <Stack direction="row" alignItems="center">
              <Typography
                variant="body2"
                sx={{
                  color: getThemeColor(workflowState?.color),
                }}
              >
                {workflowState?.name || 'No Workflow State'}
              </Typography>
            </Stack>
          )
        },
        columnFilterModeOptions:
          getColumnFilterModeOptions('workflowStateColId'),
        enableColumnFilterModes: false,
        Filter: ({ column }) => (
          <>
            <Badge
              badgeContent={
                ((column.getFilterValue() as string[]) || []).length
              }
              sx={{
                '& .MuiBadge-badge': {
                  right: -8,
                  top: 14,
                  height: 16,
                },
              }}
              color="primary"
            >
              <Button
                ref={workflowStateFilterButtonRef}
                variant="text"
                onClick={workflowStateFilterOverlay.open}
              >
                Filter by States
              </Button>
            </Badge>
            <WorkflowStateMenuDropdown
              overlay={workflowStateFilterOverlay}
              anchorRef={workflowStateFilterButtonRef}
              workflowStates={workflowStates}
              onChange={(workflowState) => {
                column.setFilterValue((prev: string[] = []) => {
                  if (!prev.includes(workflowState.id)) {
                    return [...prev, workflowState.id]
                  }
                  return prev.filter((id) => id !== workflowState.id)
                })
              }}
              selectedWorkflowStateIds={
                (column.getFilterValue() as string[]) || []
              }
              dense
              multiSelect
            />
          </>
        ),
        header: 'Workflow State',
      } as MRT_ColumnDef<Required<DocumentRow>>
      // Insert this column in the second index
      cols.splice(2, 0, workflowStateCol)
    }

    return cols
  }, [
    baseGrid?.id,
    documentWorkflowStates,
    getRelevantWorkflowState,
    getThemeColor,
    organizationsMap,
    project.tags,
    theme.palette.divider,
    workflowState,
    workflowStateFilterOverlay,
    workflowStates,
  ])

  if (showRowTypes) {
    return [
      ...columns.slice(0, 1),
      {
        id: 'rowOrBaseId',
        enableColumnOrdering: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableColumnFilterModes: false,
        enableSorting: false,
        Cell: ({ row }) => {
          const projectGrid = project.project_grids?.find(
            ({ id }) => id === row.original.project_grid_id,
          )
          const { sub_project_grid_fields = [] } = projectGrid || {}
          const label =
            sub_project_grid_fields.length > 0
              ? sub_project_grid_fields[0].name
              : 'Document'
          return (
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{
                fontSize: 10,
                borderRadius: 1,
                border: `solid 1px ${theme.palette.divider}`,
                px: 0.25,
              }}
            >
              {label}
            </Typography>
          )
        },
        header: 'Row Type',
      } as MRT_ColumnDef<Required<DocumentRow>>,
      ...columns.slice(1),
    ].filter(Boolean) as MRT_ColumnDef<Required<DocumentRow>>[]
  }

  return columns
}

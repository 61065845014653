import useGetList, {
  UseGetListOptions,
} from '@/service-library/core-hooks/useGetList'
import { getList } from '@/service-library/request-wrappers'
import { EntrataVendor } from '@/types/entrata-vendor'

/**
 * MARK: Custom Hook: Get Entrata Vendors
 * Retrieves Entrata Vendors via their getVendorDetails endpoint.
 */
export default function useGetEntrataVendors(
  options: UseGetListOptions<EntrataVendor>,
) {
  const query = useGetList<EntrataVendor>({
    queryKey: ['relay-entrata-getVendorDetails', options.filters],
    serviceFn: (serviceFnOptions) => {
      return getList<EntrataVendor>({
        url: `/v2/relay-entrata/imports/vendor_details`,
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    entrataVendors: query.allData || [],
    ...query,
  }
}

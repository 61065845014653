import {
  useState,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
  RefObject,
} from 'react'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import PaginatedResponse from '@/types/paginated-response'
import { DocumentChip, DocumentRow } from '@/types/documents'
import { useCreateRowsAtTheBottomWithDocChange } from '@/hooks/doc-changes-wrapper-hooks'
import { OverlayState } from '@/hooks/useOverlay'
import generateUuid from '@/utils/generate-uuid'
import { focusOnField } from '@/utils/row-values'
import { showErrorSnackbar, showInfoSnackbar } from '@/utils/snackbars'
import { useDocumentContext } from '@/components/image-zoom-pan/providers/DocumentProvider'
import {
  getIntersectingChips,
  Position,
  Size,
  convertMouseEventPoint,
  rotatePoint,
  getChipsInRangeSelection,
  getChipsOnRows,
} from '@/components/image-zoom-pan/image-zoom-pan-helpers'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import { useQueryKeysMapContext } from '@/components/providers/QueryKeyProvider'
import { useSelectedWorkflowStateContext } from '@/components/workflows/SelectedWorkflowStateProvider'
import { useDocumentsChangeSetsContext } from './providers/DocumentsChangeSetsProvider'
import { useDocumentChipsContext } from './providers/DocumentChipsProvider'
import { useDocumentRowValuesContext } from './providers/DocumentRowValuesProvider'
import { useSelectedFieldContext } from './providers/SelectedFieldProvider'

type UseDocumentChipActionsOptions = {
  canvasSize: Size
  canvasRef: RefObject<HTMLCanvasElement>
  chipsOverlay: OverlayState
  currentPageChips: DocumentChip[]
  drawBoxes: boolean
  image: HTMLImageElement
  rotationDegree: number
  setAnchorPosition: Dispatch<
    SetStateAction<{
      left: number
      top: number
    }>
  >
}

export default function useDocumentChipActions({
  canvasSize,
  canvasRef,
  chipsOverlay,
  currentPageChips,
  drawBoxes,
  image,
  rotationDegree,
  setAnchorPosition,
}: UseDocumentChipActionsOptions) {
  const [clickedChipsIds, setClickedChipsIds] = useState<string[]>([])

  const queryClient = useQueryClient()
  const { gridsMap } = useProjectContext()
  const { document, isDisabled } = useDocumentContext()
  const { queryKey: documentRowValuesQueryKey, fieldIdentifierRowValueMap } =
    useDocumentRowValuesContext()
  const documentsChangeSetsContextData = useDocumentsChangeSetsContext()
  const [queryKesMap] = useQueryKeysMapContext()
  const { selectedWorkflowState } = useSelectedWorkflowStateContext()
  const { selectedField, selectedFieldChips, isSelectedField } =
    useSelectedFieldContext()
  const { updateFieldDocumentChips, clearFieldDocumentChips } =
    useDocumentChipsContext()

  const popoverChips = useMemo(
    () => currentPageChips.filter(({ id }) => clickedChipsIds.includes(id)),
    [clickedChipsIds, currentPageChips],
  )

  const shouldNotAllowRangeSelection =
    selectedField &&
    (selectedField.field.project_grid_field_type.code === 'checkbox' ||
      selectedField.field.input_behavior === 'manual_only')

  function handleClickedPolygon(
    targetChip: DocumentChip,
    revealField: boolean,
  ) {
    if (revealField) {
      const rowValueId =
        fieldIdentifierRowValueMap[
          `${targetChip.project_grid_field_id}_${targetChip.document_row_id}`
        ]?.id
      if (rowValueId) {
        focusOnField(rowValueId)
      }
      return
    }
    // If it's already selected for the selected field, attach it.
    // If it's already attached to a field, but we don't have a selected field, unattach it.
    if (
      (!selectedField && targetChip.project_grid_field_id) ||
      isSelectedField(
        targetChip.project_grid_field_id,
        targetChip.document_row_id,
      )
    ) {
      clearFieldDocumentChips([targetChip])
    } else if (selectedField) {
      if (
        selectedField.field.project_grid_field_type.code === 'checkbox' &&
        selectedFieldChips.length
      ) {
        clearFieldDocumentChips([selectedFieldChips[0]])
      }
      updateFieldDocumentChips(
        [targetChip],
        selectedField.field.id,
        selectedField.document_row_id,
      )
    }
  }

  const getRotatedPointForEvent = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
      if (
        (canvasSize.width === 0 && canvasSize.height === 0) ||
        image === null ||
        selectedField?.field.input_behavior === 'manual_only' ||
        !drawBoxes ||
        event.shiftKey ||
        isDisabled
      ) {
        return
      }
      const canvas = canvasRef.current

      if (canvas) {
        const clickedPoint = convertMouseEventPoint({ event, canvas })

        return rotatePoint({
          point: clickedPoint,
          rotationDegree,
          image,
        })
      }
      return
    },
    [
      canvasRef,
      canvasSize.height,
      canvasSize.width,
      drawBoxes,
      image,
      isDisabled,
      rotationDegree,
      selectedField?.field.input_behavior,
    ],
  )

  const onCanvasClick = (
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    chipsToUse?: DocumentChip[],
    handleClickedPolygonOverrideFn?: (
      targetChip: DocumentChip | null,
      point: Position,
    ) => void,
  ) => {
    // We need to handle for rotation, so let's rotate the POINT instead of anything else,
    // so our math can act as if the image is not rotated. We add the image height or width
    // or both depending on the rotation degree since we also translate the canvas when we rotate.
    const rotatedPoint = getRotatedPointForEvent(event)
    if (!rotatedPoint) return

    const selectedFieldIsCheckbox =
      selectedField?.field.project_grid_field_type.code === 'checkbox'

    const clickedChips = getIntersectingChips({
      image,
      point: rotatedPoint,
      chips: chipsToUse || currentPageChips,
      additionalCondition: !selectedField
        ? () => true
        : selectedFieldIsCheckbox
        ? (chip) => chip._text === 'FalseBox' || chip._text === 'TrueBox'
        : (chip) => chip._text !== 'FalseBox' && chip._text !== 'TrueBox',
    })

    if (clickedChips.length > 1) {
      if (event.altKey) {
        handleClickedPolygon(
          clickedChips[clickedChips.length - 1],
          event.altKey,
        )
      } else {
        chipsOverlay.open()
        setAnchorPosition({ left: event.clientX, top: event.clientY })
        setClickedChipsIds(clickedChips.map(({ id }) => id))
      }
    } else if (clickedChips.length === 1) {
      handleClickedPolygonOverrideFn
        ? handleClickedPolygonOverrideFn(clickedChips[0], rotatedPoint)
        : handleClickedPolygon(clickedChips[0], event.altKey)
    } else {
      handleClickedPolygonOverrideFn?.(null, rotatedPoint)
    }
  }

  const onRightCanvasClick = (
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    onChipClick: (documentChip: DocumentChip) => void,
  ) => {
    // We need to handle for rotation, so let's rotate the POINT instead of anything else,
    // so our math can act as if the image is not rotated. We add the image height or width
    // or both depending on the rotation degree since we also translate the canvas when we rotate.
    const rotatedPoint = getRotatedPointForEvent(event)

    if (!rotatedPoint) return

    const clickedChips = getIntersectingChips({
      image,
      point: rotatedPoint,
      chips: currentPageChips,
    })

    if (clickedChips.length) {
      const lastChip = clickedChips[clickedChips.length - 1]
      if ((lastChip.related_chips_data?.alt_chip_ids?.length || 0) > 1) {
        setAnchorPosition({ left: event.clientX, top: event.clientY })
        onChipClick(lastChip)
      }
    }
  }

  const { createDocumentRows } = useCreateRowsAtTheBottomWithDocChange({
    documentId: document?.id,
    workflowId: selectedWorkflowState.workflow_id,
    workflowStateId: selectedWorkflowState.id,
    rowValuesListQueryKey: documentRowValuesQueryKey,
    documentsChangeSetsContextData,
    onError: () => {
      showErrorSnackbar('Unable to add row(s). Please try again later.')
    },
  })

  const updateChipsAndCreateRowsIfNeeded = useCallback(
    async (selectedChipRows: DocumentChip[][]) => {
      if (!document?.id || !selectedField) return

      const rowsQueryKey =
        queryKesMap[
          `table-rows-${document.id}-${selectedField.field.project_grid_id}`
        ]

      await queryClient.cancelQueries({ queryKey: rowsQueryKey })
      const documentRowsPagesForGrid:
        | InfiniteData<PaginatedResponse<DocumentRow>>
        | undefined = queryClient.getQueryData(rowsQueryKey)

      let tableDocumentRows: DocumentRow[] = []

      if (documentRowsPagesForGrid) {
        tableDocumentRows =
          documentRowsPagesForGrid?.pages?.reduce<DocumentRow[]>(
            (acc, page) => [...acc, ...page.results],
            [],
          ) ?? []
      }

      const currentRowIndex = tableDocumentRows?.findIndex(
        ({ id }) => id === selectedField.document_row_id,
      )

      const numberOfAvailableRowsToPopulate =
        tableDocumentRows.length - currentRowIndex

      const numberOfMissingRows =
        selectedChipRows.length - numberOfAvailableRowsToPopulate

      // If we don't have enough rows available, create new ones first.
      if (numberOfMissingRows > 0) {
        showInfoSnackbar('Creating new rows to accommodate selection.')

        const newRows = Array.from({ length: numberOfMissingRows }).map(
          (_, index) => {
            return {
              id: generateUuid(),
              row_number:
                (tableDocumentRows.at(-1)?.row_number || 0) + 1 + index,
              project_grid_id: selectedField.field.project_grid_id as string,
              document_id: document.id as string,
            }
          },
        )

        const tableFieldIds =
          gridsMap[
            selectedField.field.project_grid_id
          ]?.project_grid_fields.map((field) => field.id) || []

        // We want to await, so we make sure this call is made first so that the cache can be updated.
        await createDocumentRows(newRows, tableFieldIds, rowsQueryKey)
        tableDocumentRows.push(...newRows)
      }

      selectedChipRows.forEach((chips, index) => {
        const targetDocumentRow = tableDocumentRows[currentRowIndex + index]
        if (targetDocumentRow) {
          updateFieldDocumentChips(
            chips,
            selectedField.field.id,
            targetDocumentRow.id,
          )
        }
      })
    },
    [
      createDocumentRows,
      document?.id,
      gridsMap,
      queryClient,
      queryKesMap,
      selectedField,
      updateFieldDocumentChips,
    ],
  )

  const onRangeSelection = useCallback(
    (
      rangeCoordinates: { origin: Position; end: Position },
      selectionMode: 'selecting' | 'deselecting' | 'multi-selecting',
    ) => {
      const { origin, end } = rangeCoordinates

      const chipsInSelection = getChipsInRangeSelection({
        image,
        selectionMode,
        chips: currentPageChips,
        polygon: [
          { x: origin.x, y: origin.y },
          { x: end.x, y: origin.y },
          { x: end.x, y: end.y },
          { x: origin.x, y: end.y },
        ],
        selectedField,
      })

      if (selectionMode === 'selecting' && selectedField) {
        updateFieldDocumentChips(
          chipsInSelection,
          selectedField.field.id,
          selectedField.document_row_id,
        )
      } else if (
        selectionMode === 'multi-selecting' &&
        canvasRef.current &&
        document?.id &&
        selectedField
      ) {
        const selectedChipRows = getChipsOnRows(
          chipsInSelection,
          canvasRef.current,
        )
        updateChipsAndCreateRowsIfNeeded(selectedChipRows)
      } else {
        clearFieldDocumentChips(chipsInSelection)
      }
    },
    [
      image,
      currentPageChips,
      selectedField,
      canvasRef,
      document?.id,
      updateFieldDocumentChips,
      updateChipsAndCreateRowsIfNeeded,
      clearFieldDocumentChips,
    ],
  )

  const onSaveSmartSelection = useCallback(
    (chips: DocumentChip[], polygons: Position[][]) => {
      if (!document?.id || !selectedField) return

      let remainingChips = [...chips]
      const selectedChipsRows: DocumentChip[][] = []

      polygons.forEach((polygon) => {
        const selectedChipsRow = getChipsInRangeSelection({
          image,
          selectionMode: 'multi-selecting-enabling',
          chips: remainingChips,
          polygon,
          selectedField,
          checkForAreaPercentage: true,
        })
        selectedChipsRows.push(selectedChipsRow)
        const rowChipsIds = selectedChipsRow.map(({ id }) => id)

        remainingChips = remainingChips.filter(
          (chip) => !rowChipsIds.includes(chip.id),
        )
      })

      updateChipsAndCreateRowsIfNeeded(selectedChipsRows)
    },
    [document?.id, image, selectedField, updateChipsAndCreateRowsIfNeeded],
  )

  return {
    popoverChips,
    shouldNotAllowRangeSelection,
    handleClickedPolygon,
    onCanvasClick,
    onRangeSelection,
    onRightCanvasClick,
    onSaveSmartSelection,
  }
}

import { createContext, ReactNode, useContext, useMemo } from 'react'
import { DocumentRow } from '@/types/documents'
import { useGetDocumentRows } from '@/service-library/hooks/document-rows'
import { getBaseGrid } from '@/utils/field-utils'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'

type DocumentRowsProviderValue = ReturnType<typeof useGetDocumentRows> & {
  baseRow?: DocumentRow
  rowsByGrid: Record<string, DocumentRow[]>
}

const DocumentRowsContext = createContext<DocumentRowsProviderValue>(
  {} as DocumentRowsProviderValue,
)

export const useDocumentRowsContext = () => useContext(DocumentRowsContext)

type DocumentRowProviderProps = {
  query: ReturnType<typeof useGetDocumentRows>
  children: ReactNode
}

export function getRowsByGrid(documentRows: DocumentRow[]) {
  return documentRows.reduce<Record<string, DocumentRow[]>>((map, row) => {
    ;(map[row.project_grid_id] ||= []).push(row)
    return map
  }, {})
}

export default function DocumentRowsProvider({
  query,
  children,
}: DocumentRowProviderProps) {
  const { project } = useProjectContext()
  const baseGrid = getBaseGrid(project.project_grids || [])

  const { documentRows } = query

  const rowsByGrid = useMemo(() => getRowsByGrid(documentRows), [documentRows])

  return (
    <DocumentRowsContext.Provider
      value={{
        baseRow: rowsByGrid[baseGrid?.id || '']?.[0], // There should only be one base row
        rowsByGrid,
        ...query,
      }}
    >
      {children}
    </DocumentRowsContext.Provider>
  )
}

import { ReactNode } from 'react'
import {
  Menu,
  MenuItem,
  MenuProps,
  ListItemIcon,
  ListItemText,
  Link,
} from '@mui/material'
import useOverlay, { OverlayState } from '@/hooks/useOverlay'
import {
  Delete,
  Link as LinkIcon,
  ContentCopy,
  OpenInNew,
  Download,
  Rule,
  Description,
} from '@mui/icons-material'
import { useRunAllRulesWithDocChange } from '@/hooks/doc-changes-wrapper-hooks'
import useLocationHelpers from '@/hooks/useLocationHelpers'
import { useArchiveDocuments } from '@/service-library/hooks/documents'
import queryKeys from '@/service-library/query-keys'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import { handleDownloadOriginalDocument } from '@/utils/download-file'
import {
  showErrorSnackbar,
  showInfoSnackbar,
  showSuccessSnackbar,
} from '@/utils/snackbars'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import { useDocumentsChangeSetsContext } from '@/components/validation/providers/DocumentsChangeSetsProvider'
import WorkflowStateDescriptionDialog from '@/components/workflow-state-page/WorkflowStateDescriptionDialog'
import copyToClipboard from './copy-to-clipboard'
import { useDocumentContext } from '../image-zoom-pan/providers/DocumentProvider'
import useRelevantWorkflowState from '@/hooks/useRelevantWorkflowState'
import { useSelectedWorkflowContext } from '../workflows/SelectedWorkflowProvider'
import { Document } from '@/types/documents'

type DocumentActionsMenuProps = {
  /** Overlay state. */
  overlay: OverlayState

  /** ID the project belongs to. */
  projectId: string

  basicOptions?: boolean

  /** Target document ID. */
  documentId?: string

  /** Callback that runs before the document is deleted. */
  onBeforeDelete?: () => void

  /** Callback that runs after the document is successfully deleted. */
  onDelete?: () => void

  /** Any additional menu items can be passed in as children. */
  children?: ReactNode
} & Partial<MenuProps>

export default function DocumentActionsMenu({
  overlay,
  children,
  projectId,
  basicOptions = false,
  documentId,
  onBeforeDelete,
  onDelete,
  ...rest
}: DocumentActionsMenuProps) {
  const { document } = useDocumentContext()
  const { getCurrentPathWithParams } = useLocationHelpers()
  const { selectedWorkflow } = useSelectedWorkflowContext()
  const getRelevantWorkflowState = useRelevantWorkflowState()
  const relevantWorkflowState = getRelevantWorkflowState(document as Document)
  const documentsChangeSetsContextData = useDocumentsChangeSetsContext()
  const isSuperUser = useIsSuperUser()
  const [demoMode] = useDemoModeContext()

  const documentationOverlay = useOverlay()

  const { archiveDocuments } = useArchiveDocuments({
    sideEffectQueryKeys: [
      queryKeys.documents.all, // dashboard, validation, or document dialog
      queryKeys.documentRows.lists(), // dashboard
      queryKeys.documentWorkflowStates.details(), // count
    ],
    onSuccess: () => {
      showSuccessSnackbar('Document Deleted')
      onDelete?.()
    },
    onError: () => {
      showErrorSnackbar('Failed to delete document. Please try again later.')
    },
  })

  const { runRules } = useRunAllRulesWithDocChange({
    documentIds: documentId ? [documentId] : [],
    workflowId: selectedWorkflow.id,
    workflowStateId: relevantWorkflowState?.id || '',
    documentsChangeSetsContextData,
    onError: () => {
      showErrorSnackbar('Document validation failed. Please try again later.')
    },
  })

  const currentPath = getCurrentPathWithParams()

  const pathToDocument = `${currentPath}${
    currentPath.includes('document_id')
      ? ''
      : currentPath.includes('?')
      ? `&document_id=${documentId}`
      : `?document_id=${documentId}`
  }`

  function handleDeleteDocument() {
    if (!documentId) return
    onBeforeDelete?.()
    archiveDocuments([documentId])

    overlay.close()
  }

  function handleReRunRules(forceClearCache = false) {
    if (!documentId) return
    showInfoSnackbar('Validating Document')
    runRules(forceClearCache)
    overlay.close()
  }

  return (
    <>
      <Menu
        open={overlay.isOpen}
        onClose={overlay.close}
        anchorEl={overlay.anchorEl as Element}
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        {...rest}
      >
        {children}

        {relevantWorkflowState && (
          <MenuItem
            onClick={() => {
              if (!documentId) return
              documentationOverlay.open()
              overlay.close()
            }}
          >
            <ListItemIcon>
              <Description />
            </ListItemIcon>
            <ListItemText>Workflow State Documentation</ListItemText>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            if (!documentId) return
            copyToClipboard(`${window.location.host}${pathToDocument}`)
            showInfoSnackbar('Document link copied to clipboard')
            overlay.close()
          }}
        >
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText>Copy Link</ListItemText>
        </MenuItem>

        <MenuItem
          component={Link}
          target="_blank"
          href={pathToDocument}
          onClick={overlay.close}
        >
          <ListItemIcon>
            <OpenInNew />
          </ListItemIcon>
          <ListItemText>Open in New Tab</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            if (!documentId) return
            handleDownloadOriginalDocument(documentId)
            overlay.close()
          }}
        >
          <ListItemIcon>
            <Download />
          </ListItemIcon>
          <ListItemText>Download</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            if (!documentId) return
            copyToClipboard(documentId)
            showInfoSnackbar('Document ID copied to clipboard')
            overlay.close()
          }}
        >
          <ListItemIcon>
            <ContentCopy />
          </ListItemIcon>
          <ListItemText>Copy ID</ListItemText>
        </MenuItem>

        {!basicOptions && relevantWorkflowState && (
          <MenuItem
            onClick={() => {
              handleReRunRules()
            }}
          >
            <ListItemIcon>
              <Rule />
            </ListItemIcon>
            <ListItemText>Re-Run Rules</ListItemText>
          </MenuItem>
        )}

        {isSuperUser && relevantWorkflowState && !demoMode && (
          <MenuItem
            onClick={() => {
              handleReRunRules(true)
            }}
            sx={{ color: SUPER_USER_ONLY_COLOR }}
          >
            <ListItemIcon>
              <Rule
                sx={{
                  color: SUPER_USER_ONLY_COLOR,
                }}
              />
            </ListItemIcon>
            Re-Run Rules (Clear Cache)
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            handleDeleteDocument()
          }}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      {relevantWorkflowState && (
        <WorkflowStateDescriptionDialog
          overlay={documentationOverlay}
          projectId={projectId}
          workflowState={relevantWorkflowState}
        />
      )}
    </>
  )
}

import { useEffect, useRef, useState } from 'react'
import { ProjectTag } from '@/types/project-tags'
import { Stack, Typography } from '@mui/material'
import TagChip from './TagChip'

type DocProjectTagsListProps = {
  projectTags: ProjectTag[]
  position?: 'left' | 'right'
  collapse?: boolean
}

export default function DocProjectTagsList({
  projectTags,
  position = 'right',
  collapse = false,
}: DocProjectTagsListProps) {
  const tagListRef = useRef<HTMLDivElement>(null)
  const collapsedCountRef = useRef<HTMLDivElement>(null)
  const [visibleItems, setVisibleItems] = useState<ProjectTag[]>([])
  const [countPosition, setCountPosition] = useState(0)

  const collapsedCount = projectTags.length - visibleItems.length

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (!tagListRef.current) return
      const listChildren = tagListRef.current?.children
      const listWidth = tagListRef.current?.clientWidth

      let currentWidth = 0
      let visibleCount = 0
      for (const item of listChildren) {
        const itemWidth = item.clientWidth
        const style = window.getComputedStyle(item)
        const marginLeft = style.getPropertyValue('margin-left')
        const marginLeftValue = parseFloat(marginLeft)

        if (currentWidth + itemWidth + marginLeftValue <= listWidth) {
          currentWidth += itemWidth + marginLeftValue
          visibleCount++
        } else {
          setCountPosition(currentWidth)
          break
        }
      }

      setVisibleItems(projectTags.slice(0, visibleCount))
    })
    if (tagListRef.current && collapse) observer.observe(tagListRef.current)
    return () => {
      observer.disconnect()
    }
  }, [collapse, projectTags])

  useEffect(() => {
    if (!collapse) {
      setVisibleItems(projectTags)
      setCountPosition(0)
    }
  }, [collapse, projectTags])

  return (
    <Stack
      direction={position === 'left' ? 'row' : 'row-reverse'}
      alignItems="center"
      spacing={0.5}
      ref={tagListRef}
      sx={{ width: '100%', position: 'relative' }}
    >
      {projectTags.map((tag, index) => (
        <TagChip
          key={tag.id + index}
          tag={tag}
          invisible={!visibleItems.find((i) => i.id === tag.id)}
        />
      ))}

      {collapse && (
        <Typography
          component="p"
          variant="caption"
          color="text.secondary"
          ref={collapsedCountRef}
          sx={{
            visibility: collapsedCount > 0 ? 'visible' : 'hidden',
            position: 'absolute',
            right: position === 'right' ? countPosition + 24 : undefined,
            left: position === 'left' ? countPosition + 24 : undefined,
            transform: 'translateY(2px)',
          }}
        >
          +{collapsedCount}
        </Typography>
      )}
    </Stack>
  )
}

import { memo, ReactNode } from 'react'
import { Typography } from '@mui/material'
import { LogEntry } from '@/types/log-entries'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import { useGetOrganization } from '@/service-library/hooks/organizations'
import { useGetProjectModel } from '@/service-library/hooks/project-models'
import { useGetWorkflowStateEvent } from '@/service-library/hooks/workflow-state-events'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import { useWorkflowsContext } from '@/components/workflows-provider/WorkflowsProvider'

const codes = {
  EVENT_TRIGGERED:
    'The {event_name} event for {state_name} for {workflow_name} was triggered.',
  EVENT_PROCESSED:
    'The {event_name} event for {state_name} for {workflow_name} was processed.',
  MODEL_PREDICTION_COMPLETE:
    'The {model_name} model using version {model_version} ran prediction.',
  CHIP_UNASSIGNED:
    'A chip with text "{chip_text}" was removed from {field_name}.',
  CHIP_ASSIGNED: 'A chip with text "{chip_text}" was assigned to {field_name}.',
  CHIP_ASSIGNED_TO_DIFFERENT_ROW:
    'A chip with text "{chip_text}" was assigned to {field_name} on a different row.',
  MANUAL_VALUE_SET: '{field_name} was manually set to {manual_value}.',
  MANUAL_VALUE_REMOVED: "{field_name}'s manual value was removed.",
  MANUAL_VALUE_SET_IN_TABLE:
    '{field_name} was manually set to {manual_value} in a row in {parent_field_name}.',
  MANUAL_VALUE_REMOVED_IN_TABLE:
    "{field_name}'s manual value was removed from a row in {parent_field_name}.",
  ORG_CHANGE: 'Organization was set to {org_name}.',
}

function EventLogEntry({ code, ref_objs, long_description }: LogEntry) {
  const getThemeColor = useGetThemeColor()
  const { workflows } = useWorkflowsContext()
  const workflowStateEventId = ref_objs.find(
    (obj) => obj.key === 'event_name',
  )?.obj_id
  const { workflowStateEvent, isLoading } = useGetWorkflowStateEvent({
    id: workflowStateEventId || '',
    filters: {
      fields__only: 'id,name,workflow_state_id',
    },
  })

  if (!workflowStateEvent) return <>{isLoading ? '' : long_description}</>

  const workflow = workflows.find((workflow) =>
    workflow.workflow_states?.find(
      (state) => state.id === workflowStateEvent?.workflow_state_id,
    ),
  )
  const state = workflow?.workflow_states?.find(
    (state) => state.id === workflowStateEvent?.workflow_state_id,
  )

  const longDescription = codes[code as keyof typeof codes]

  const formattedDescription = longDescription.split(' ').map((word, index) => {
    let newWord: ReactNode = word
    switch (word) {
      case '{event_name}':
        newWord = workflowStateEvent?.name || ''
        break
      case '{state_name}':
        newWord = state ? (
          <Typography
            color={getThemeColor(state.color)}
            component="span"
            variant="inherit"
          >
            {state.name || ''}
          </Typography>
        ) : null
        break
      case '{workflow_name}':
        newWord = <b>{workflow?.name || ''}</b>
    }
    if (index !== longDescription.split(' ').length - 1) {
      // Adding a space after the word
      return <>{newWord} </>
    }
    return newWord
  })

  return <>{formattedDescription.map((word) => word)}</>
}

function PredictionLogEntry({ code, ref_objs, long_description }: LogEntry) {
  const { projectModel, isLoading } = useGetProjectModel({
    id: ref_objs.find((obj) => obj.key === 'model_name')?.obj_id || '',
    filters: {
      fields__only: 'name,id',
    },
  })

  if (!projectModel) return <>{isLoading ? '' : long_description}</>

  let longDescription = codes[code as keyof typeof codes]
  longDescription = longDescription.replaceAll(
    '{model_name}',
    projectModel?.name || '',
  )

  const version = +(
    ref_objs.find((obj) => obj.key === 'model_version')?.obj.version || 0
  )

  longDescription = longDescription.replaceAll(
    '{model_version}',
    `${Number.isInteger(version) ? `${version}.0` : version}`,
  )

  return <>{longDescription}</>
}

function ChipLogEntry({ code, ref_objs, long_description }: LogEntry) {
  const { fieldsMap } = useProjectContext()
  const fieldName =
    fieldsMap[ref_objs.find((obj) => obj.key === 'field_name')?.obj_id || '']
      ?.name || ''

  if (!fieldName) return <>{long_description}</>

  let longDescription = codes[code as keyof typeof codes]
  longDescription = longDescription.replaceAll(
    '{chip_text}',
    ref_objs.find((obj) => obj.key === 'chip_text')?.obj.text || '',
  )
  longDescription = longDescription.replaceAll('{field_name}', fieldName)
  return <>{longDescription}</>
}

function ManualValueLogEntry({ code, ref_objs, long_description }: LogEntry) {
  const { fieldsMap } = useProjectContext()
  const fieldName =
    fieldsMap[ref_objs.find((obj) => obj.key === 'field_name')?.obj_id || '']
      ?.name || ''

  if (!fieldName) return <>{long_description}</>

  let longDescription = codes[code as keyof typeof codes]
  longDescription = longDescription.replaceAll('{field_name}', fieldName)
  longDescription = longDescription.replaceAll(
    '{manual_value}',
    ref_objs.find((obj) => obj.key === 'manual_value')?.obj.value || '',
  )

  if (
    code === 'MANUAL_VALUE_SET_IN_TABLE' ||
    code === 'MANUAL_VALUE_REMOVED_IN_TABLE'
  ) {
    const parentFieldName =
      fieldsMap[
        ref_objs.find((obj) => obj.key === 'parent_field_name')?.obj_id || ''
      ]?.name || ''
    longDescription = longDescription.replaceAll(
      '{parent_field_name}',
      parentFieldName,
    )
    if (!parentFieldName) return <></>
  }
  return <>{longDescription}</>
}

function OrgLogEntry({ code, ref_objs, long_description }: LogEntry) {
  const { organization, isLoading } = useGetOrganization({
    id: ref_objs.find((obj) => obj.key === 'org_name')?.obj_id || '',
    filters: {
      fields__only: 'name,id',
    },
  })

  if (!organization) return <>{isLoading ? '' : long_description}</>

  let longDescription = codes[code as keyof typeof codes]

  longDescription = longDescription.replaceAll('{org_name}', organization.name)
  return <>{longDescription}</>
}

function EntryLongDescription(entry: LogEntry) {
  const { code, long_description } = entry
  if (code === 'EVENT_TRIGGERED' || code === 'EVENT_PROCESSED') {
    return <EventLogEntry {...entry} />
  }
  if (code === 'MODEL_PREDICTION_COMPLETE') {
    return <PredictionLogEntry {...entry} />
  }
  if (
    code === 'CHIP_UNASSIGNED' ||
    code === 'CHIP_ASSIGNED' ||
    code === 'CHIP_ASSIGNED_TO_DIFFERENT_ROW'
  ) {
    return <ChipLogEntry {...entry} />
  }
  if (
    code === 'MANUAL_VALUE_SET' ||
    code === 'MANUAL_VALUE_REMOVED' ||
    code === 'MANUAL_VALUE_SET_IN_TABLE' ||
    code === 'MANUAL_VALUE_REMOVED_IN_TABLE'
  ) {
    return <ManualValueLogEntry {...entry} />
  }

  if (code === 'ORG_CHANGE') {
    return <OrgLogEntry {...entry} />
  }
  return <>{long_description}</>
}

export default memo(EntryLongDescription)

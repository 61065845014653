import { ProjectTag } from '@/types/project-tags'
import { Dialog, DialogContent, DialogFooter } from '../dialog'
import { OverlayState } from '@/hooks/useOverlay'
import { FormTextField, PixyDocsForm } from '../forms'
import { Button, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import PixyColorPicker from '../PixyColorPicker'
import { themeColorOptions } from '@/theme/usePixydocsTheme'
import { useProjectContext } from '../project-tables/ProjectProvider'
import {
  useCreateProjectTag,
  useUpdateProjectTag,
} from '@/service-library/hooks/project-tags'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import { QueryKey } from '@tanstack/react-query'
import { useEffect } from 'react'

type AddEditTagDialogProps = {
  overlay: OverlayState
  listQueryKey: QueryKey
  projectTag?: ProjectTag
}

export default function AddEditTagDialog({
  overlay,
  listQueryKey,
  projectTag,
}: AddEditTagDialogProps) {
  const { project } = useProjectContext()
  const { createProjectTag } = useCreateProjectTag({
    listQueryKey,
    onError: () => {
      showErrorSnackbar(
        'Failed to create tag. Please contact support if this problem persists.',
      )
    },
    onSuccess: () => {
      showSuccessSnackbar('Tag Created')
    },
  })

  const { updateProjectTag } = useUpdateProjectTag({
    listQueryKey,
    onError: () => {
      showErrorSnackbar(
        'Failed to update tag. Please contact support if this problem persists.',
      )
    },
    onSuccess: () => {
      showSuccessSnackbar('Tag Updated')
    },
  })

  const methods = useForm({
    defaultValues: {
      name: projectTag?.name || '',
      color: projectTag?.color || 'blue',
    },
    mode: 'onChange', // triggers validation onChange
  })

  const { isDirty, isValid, errors } = methods.formState
  const nameError = errors.name

  function onSubmit(values: { name: string; color: string }) {
    if (projectTag) {
      updateProjectTag({
        ...projectTag,
        name: values.name,
        color: values.color,
      })
    } else {
      createProjectTag({
        id: generateUuid(),
        name: values.name,
        color: values.color,
        project_id: project.id,
      })
    }
    overlay.close()
  }

  useEffect(() => {
    if (!overlay.isOpen) {
      methods.reset(projectTag)
    }
  }, [methods, overlay.isOpen, projectTag])

  return (
    <Dialog {...overlay} title="Document Tag" maxWidth="xs">
      <PixyDocsForm methods={methods} onSubmit={onSubmit}>
        <DialogContent>
          <Stack spacing={4}>
            <FormTextField
              autoFocus
              helperText={nameError ? nameError.message : ''}
              error={!!nameError}
              label="Tag Name"
              fullWidth
              required
              requiredErrorText="Name cannot be empty."
              {...methods.register('name', {
                validate: (value) => {
                  if (!value.trim().length) return 'Name cannot be empty.'

                  if (
                    project.tags?.some(
                      ({ id, name }) =>
                        name.toLowerCase() === value.toLowerCase().trim() &&
                        id !== project.id,
                    )
                  )
                    return 'Name must be unique.'

                  return true
                },
              })}
            />
            <PixyColorPicker
              colors={themeColorOptions.filter(
                (color) => !['red', 'orange', 'grey', 'yellow'].includes(color),
              )}
              selectedColor={methods.watch('color')}
              setSelectedColor={(color) => {
                methods.setValue('color', color, {
                  shouldDirty: true,
                })
              }}
            />
          </Stack>
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isDirty || !isValid}>
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}

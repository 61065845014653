import { createContext, ReactNode, useContext } from 'react'
import { QueryKey } from '@tanstack/react-query'

const QueryKeyContext = createContext<QueryKey>([] as QueryKey)

export const useQueryKeyContext = () => useContext(QueryKeyContext)

type QueryKeyProviderProps = {
  queryKey: QueryKey
  children: ReactNode
}

export default function QueryKeyProvider({
  queryKey,
  children,
}: QueryKeyProviderProps) {
  return (
    <QueryKeyContext.Provider value={queryKey}>
      {children}
    </QueryKeyContext.Provider>
  )
}

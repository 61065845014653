import queryKeys from '../query-keys'
import {
  OrgWorkflowMetricReport,
  OrgWorkflowMetricReportAverageStp,
} from '@/types/workflow-metric-reports'
import useGet, { UseGetOptions } from '../core-hooks/useGet'
import { getDetail } from '../request-wrappers'

export function useGetWorkflowMetricReport({
  filters,
  ...options
}: UseGetOptions<OrgWorkflowMetricReport> = {}) {
  const query = useGet<OrgWorkflowMetricReport>({
    filters,
    queryKey: queryKeys.orgWorkflowMetricReports.list({ filters }, [
      'grouped-by-workflow-state',
    ]),
    serviceFn: (serviceFnOptions) => {
      return getDetail<OrgWorkflowMetricReport>({
        url: '/v2/pd/org_workflow_metric_reports/grouped-by-workflow-state',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    orgWorkflowMetricReport: query.data,
    ...query,
  }
}

export function useGetWorkflowMetricReportAverageStp({
  filters,
  ...options
}: UseGetOptions<OrgWorkflowMetricReportAverageStp> = {}) {
  const query = useGet<OrgWorkflowMetricReportAverageStp>({
    filters,
    queryKey: queryKeys.orgWorkflowMetricReports.list({ filters }, [
      'average-overall-stp',
    ]),
    serviceFn: (serviceFnOptions) => {
      return getDetail<OrgWorkflowMetricReportAverageStp>({
        url: '/v2/pd/org_workflow_metric_reports/average-overall-stp',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    overallStpReport: query.data,
    ...query,
  }
}

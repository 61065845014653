import { useEffect, useMemo, useRef } from 'react'
import { Add } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import { MRT_ColumnDef } from 'material-react-table'
import {
  Typography,
  IconButton,
  Tooltip,
  Link,
  useTheme,
  ThemeProvider,
  createTheme,
  Stack,
  Theme,
} from '@mui/material'
import { DocumentRow } from '@/types/documents'
import { ProjectGridField as TSField } from '@/types/fields'
import {
  useCreateRowsAtTheBottomWithDocChange,
  useDeleteDocumentRowsWithDocChange,
  useInsertDocumentRowWithDocChange,
} from '@/hooks/doc-changes-wrapper-hooks'
import useFixedTableHeader from '@/hooks/useFixedTableHeader'
import useLocalStorage from '@/hooks/useLocalStorage'
import { useGetDocumentRows } from '@/service-library/hooks/document-rows'
import { getAggregateValue, numberFieldTypes } from '@/utils/field-utils'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import ZerapixTable from '@/components/zerapix-table/ZerapixTable'
import { useScrollContainer } from '@/components/scroll-container/ScrollContainer'
import { useDocumentComparisonDataContext } from '@/components/image-zoom-pan/providers/DocumentComparisonDataProvider'
import { useDocumentContext } from '@/components/image-zoom-pan/providers/DocumentProvider'
import { useQueryKeysMapContext } from '@/components/providers/QueryKeyProvider'
import { useSelectedWorkflowStateContext } from '@/components/workflows/SelectedWorkflowStateProvider'
import useZerapixTable from '@/components/zerapix-table/useZerapixTable'
import { useDocumentsChangeSetsContext } from './providers/DocumentsChangeSetsProvider'
import { useDocumentChipsContext } from './providers/DocumentChipsProvider'
import { useDocumentRowsContext } from './providers/DocumentRowsProvider'
import { useDocumentRowValuesContext } from './providers/DocumentRowValuesProvider'
import { useFieldErrorContext } from './providers/FieldErrorProvider'
import Field from './Field'
import FieldTableMenu from './FieldTableMenu'
import FieldTableRowActionsMenu from './FieldTableRowActionsMenu'
import AggregateCell from './AggregateCell'
import useGetRelevantWorkflowState from '@/hooks/useRelevantWorkflowState'

type FieldTableProps = {
  tableField: TSField
}

export const ROW_HEIGHT = 32

type GetFieldColorObjParam = {
  palette: Theme['palette']
  fieldId?: string
  rowId?: string
  differentFieldsAnsRowsComparingRowValues?: {
    fieldIds?: Set<string>
    rowIds?: Set<string>
  }
  isForFieldName?: boolean
}

function getFieldColor({
  palette,
  fieldId,
  rowId,
  differentFieldsAnsRowsComparingRowValues,
  isForFieldName,
}: GetFieldColorObjParam) {
  if (!differentFieldsAnsRowsComparingRowValues) {
    return undefined
  }

  const { fieldIds, rowIds } = differentFieldsAnsRowsComparingRowValues

  const isDifferentField =
    fieldIds?.has('all') ||
    (isForFieldName
      ? fieldIds?.has(fieldId as string)
      : rowIds?.has(rowId as string))

  const color = isDifferentField ? palette.error.main : palette.success.main
  return `${color} !important`
}

function FieldTableContent({ tableField }: FieldTableProps) {
  const theme = useTheme()

  const [columnSizing, setColumnSizing] = useLocalStorage(
    `columns-sizing-${tableField.id}`,
    {},
  )

  const { isDisabled, document } = useDocumentContext()
  const { getFieldError } = useFieldErrorContext()
  const { listQueryKey: chipsListQueryKey } = useDocumentChipsContext()
  const { rowsByGrid } = useDocumentRowsContext()
  const { queryKey: documentRowValuesQueryKey } = useDocumentRowValuesContext()
  const { differentFieldsAnsRowsComparingRowValues } =
    useDocumentComparisonDataContext()
  const documentsChangeSetsContextData = useDocumentsChangeSetsContext()

  const {
    documentRows = [],
    queryKey,
    isLoading,
  } = useGetDocumentRows({
    filters: {
      limit: '2500',
      project_grid_id: tableField.sub_project_grid_id as string,
      document_id: document?.id as string,
    },
    initialListData: tableField.sub_project_grid_id
      ? rowsByGrid[tableField.sub_project_grid_id]
      : undefined,
    staleTime: 1000,
    enabled: !!document,
  })

  const [, setQueryKeysMap] = useQueryKeysMapContext()

  useEffect(() => {
    if (document?.id && tableField.sub_project_grid_id) {
      setQueryKeysMap((prev) => {
        // We need to include the document id since we have views where we have two documents
        const tableKey = `table-rows-${document.id}-${tableField.sub_project_grid_id}`
        if (JSON.stringify(prev[tableKey]) === JSON.stringify(queryKey))
          return prev

        return {
          ...prev,
          [tableKey]: queryKey,
        }
      })
    }
  }, [document?.id, queryKey, setQueryKeysMap, tableField.sub_project_grid_id])

  const { selectedWorkflowState } = useSelectedWorkflowStateContext()
  const getRelevantWorkflowState = useGetRelevantWorkflowState()
  const state = selectedWorkflowState || getRelevantWorkflowState(document)

  const { createDocumentRows } = useCreateRowsAtTheBottomWithDocChange({
    documentId: document?.id,
    workflowId: state?.workflow_id || '',
    workflowStateId: state?.id || '',
    listQueryKey: queryKey,
    rowValuesListQueryKey: documentRowValuesQueryKey,
    documentsChangeSetsContextData,
    onError: () => {
      showErrorSnackbar('Unable to add row. Please try again later.')
    },
  })

  const { insertDocumentRow } = useInsertDocumentRowWithDocChange({
    documentId: document?.id,
    workflowId: state?.workflow_id || '',
    workflowStateId: state?.id || '',
    listQueryKey: queryKey,
    rowValuesListQueryKey: documentRowValuesQueryKey,
    documentsChangeSetsContextData,
    onError: () => {
      showErrorSnackbar('Unable to add row. Please try again later.')
    },
  })

  const { deleteDocumentRows } = useDeleteDocumentRowsWithDocChange({
    documentId: document?.id,
    workflowId: state?.workflow_id || '',
    workflowStateId: state?.id || '',
    listQueryKey: queryKey,
    chipsListQueryKey,
    rowValuesListQueryKey: documentRowValuesQueryKey,
    documentsChangeSetsContextData,
    onError: () => {
      showErrorSnackbar('Failed to delete rows. Please try again later.')
    },
  })

  const cellToFocusRef = useRef<HTMLInputElement | HTMLDivElement | null>(null)
  const containerRef = useScrollContainer()
  const { tableContainerRef, tableHeadRef, tableHeadRowRef } =
    useFixedTableHeader({
      containerRef,
    })
  const tableBodyRef = useRef<HTMLTableSectionElement>(null)
  const timerRef = useRef<NodeJS.Timeout>()

  const { documentRowValues } = useDocumentRowValuesContext()

  const fieldsMap: Record<string, TSField> = useMemo(() => {
    const tableFields = tableField.fields || []
    return tableFields.reduce(
      (acc, field) => ({ ...acc, [field.id]: field }),
      {},
    )
  }, [tableField.fields])

  const tableFieldIds = useMemo(() => Object.keys(fieldsMap), [fieldsMap])

  const aggregateValuesMap = useMemo(() => {
    const valuesMap: Record<string, string[]> =
      tableField.fields?.reduce(
        (acc, field) =>
          field.aggregate_function !== 'none' &&
          numberFieldTypes.includes(field.project_grid_field_type.code)
            ? { ...acc, [field.id]: [] }
            : acc,
        {},
      ) || {}
    documentRowValues.forEach(({ project_grid_field_id, final_value }) => {
      if (
        final_value &&
        project_grid_field_id in fieldsMap &&
        fieldsMap[project_grid_field_id].aggregate_function !== 'none'
      ) {
        valuesMap[project_grid_field_id].push(final_value)
      }
    })
    return valuesMap
  }, [documentRowValues, fieldsMap, tableField.fields])

  const columns = useMemo<MRT_ColumnDef<DocumentRow>[]>(() => {
    return Object.values(fieldsMap).map((field, index) => ({
      id: field.id,
      header: field.name,
      accessorKey: field.id as keyof DocumentRow,
      size: numberFieldTypes.includes(field.project_grid_field_type.code)
        ? 100
        : undefined,
      Footer: () =>
        field.aggregate_function !== 'none' ? (
          <AggregateCell
            aggregateFunction={field.aggregate_function}
            value={
              aggregateValuesMap[field.id]?.length
                ? getAggregateValue({
                    values: aggregateValuesMap[field.id],
                    aggregateFunction: field.aggregate_function,
                  })
                : null
            }
            code={field.project_grid_field_type.code}
          />
        ) : null,
      Cell: ({ row, table }) => (
        <Field
          field={field}
          documentRow={row.original}
          isInTable
          fieldPosition={{
            column: index + 2,
            row: row.index + 1,
          }}
          tableGrid={{
            columns: table.options.columns.length,
            rows: table.options.data.length,
          }}
          tableBodyEl={tableBodyRef.current}
          insertDocumentRow={(position) => {
            const newRowId = generateUuid()
            return insertDocumentRow(
              [
                {
                  id: newRowId,
                  row_number:
                    row.original.row_number + (position === 'before' ? 0 : 1),
                  project_grid_id: tableField.sub_project_grid_id as string,
                  document_id: document?.id as string,
                },
              ],
              tableFieldIds,
            )
          }}
          deleteDocumentRows={deleteDocumentRows}
        />
      ),
    }))
  }, [
    aggregateValuesMap,
    deleteDocumentRows,
    fieldsMap,
    document,
    insertDocumentRow,
    tableField.sub_project_grid_id,
    tableFieldIds,
  ])

  const addRow = ({
    rowId,
    position,
    shouldFocus = false,
  }: {
    rowId?: string
    position?: 'before' | 'after'
    shouldFocus?: boolean
  } = {}) => {
    const newRowId = generateUuid()
    if (!rowId || !position) {
      const lastItem = documentRows.slice(-1)[0]
      if (!tableField.sub_project_grid_id || !document?.id) return
      createDocumentRows(
        [
          {
            id: newRowId,
            row_number: lastItem?.row_number + 1 || 1,
            project_grid_id: tableField.sub_project_grid_id as string,
            document_id: document?.id as string,
          },
        ],
        tableFieldIds,
      )
    } else {
      const row = documentRows.find((row) => row.id === rowId)
      if (!row) return
      insertDocumentRow(
        [
          {
            id: newRowId,
            row_number: row.row_number + (position === 'before' ? 0 : 1),
            project_grid_id: tableField.sub_project_grid_id as string,
            document_id: document?.id as string,
          },
        ],
        tableFieldIds,
      )
    }
    if (shouldFocus) {
      setTimeout(() => {
        if (!cellToFocusRef.current) {
          let targetRow = rowId
            ? tableBodyRef.current?.querySelector(`tr[id="${rowId}"]`)
            : tableBodyRef.current?.querySelector('tr:last-child')

          if (position === 'before')
            targetRow = targetRow?.previousElementSibling
          if (position === 'after') targetRow = targetRow?.nextElementSibling

          cellToFocusRef.current = targetRow?.querySelector(
            `td:nth-child(2) input`,
          ) as HTMLInputElement
          if ((cellToFocusRef.current as HTMLInputElement).disabled) {
            cellToFocusRef.current = targetRow?.querySelector(
              `td:nth-child(2) div.focus-container`,
            ) as HTMLDivElement
          }
        }
        cellToFocusRef.current?.focus({ preventScroll: true })
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
          cellToFocusRef.current = null
        }, 2000)
      }, 100)
    }
  }

  const rows = [...documentRows].sort((a, b) => a.row_number - b.row_number)

  const table = useZerapixTable<DocumentRow>({
    title: tableField.name,
    columns,
    data: rows,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '',
        size: 50,
        muiTableBodyCellProps: {
          sx: {
            borderRight: `1px solid ${theme.palette.divider}`,
            justifyContent: 'center',
            p: 0,
            '& button': {},
          },
        },
        muiTableHeadCellProps: {
          sx: {
            '& button': {},
          },
        },
        muiTableFooterCellProps: {
          sx: {
            background:
              theme.palette.mode === 'dark'
                ? `${theme.palette.background.paper}10`
                : grey[100],
          },
        },
      },
    },
    enableColumnResizing: true,
    enableKeyboardShortcuts: false,
    enableRowActions: !isDisabled,
    enableRowVirtualization: rows.length > 60,
    enableSorting: false,
    enableToolbarInternalActions: !isDisabled,
    getRowId: (row) => row.id,
    localization: {
      noRecordsToDisplay: (
        <Typography
          variant="caption"
          component="span"
          color="text.secondary"
          textAlign="center"
        >
          {isDisabled ? (
            'No Rows'
          ) : (
            <i>
              <Link
                component="button"
                onClick={() => {
                  addRow({ shouldFocus: true })
                }}
                underline="hover"
              >
                <i>Add a row</i>
              </Link>{' '}
              to begin entering data.
            </i>
          )}
        </Typography>
      ) as unknown as string,
    },
    muiTableBodyProps: () => ({
      ref: tableBodyRef,
    }),
    muiTableBodyCellProps: ({ row, column }) => {
      const fieldError = getFieldError(column.id, row.id)
      return {
        tabIndex: -1,
        sx: {
          alignItems: 'flex-start',
          borderRight: `1px solid ${theme.palette.divider}`,
          borderBottom: isDisabled
            ? `1px solid ${getFieldColor({
                palette: theme.palette,
                rowId: row.id,
                differentFieldsAnsRowsComparingRowValues,
              })}`
            : fieldError
            ? `1px solid ${theme.palette.error.main} !important`
            : undefined,
          p: '2px 4px',
          '> div': {
            width: '100%',
          },
          '&:focus-within': {
            borderBottom: isDisabled
              ? undefined
              : `1px solid ${
                  fieldsMap[column.id]?.input_behavior === 'manual_only'
                    ? theme.palette.indigo.main
                    : theme.palette.primary.main
                }`,
          },
        },
      }
    },
    muiTableFooterCellProps: () => ({
      sx: {
        p: 0.5,
        background:
          theme.palette.mode === 'dark'
            ? `${theme.palette.background.paper}10`
            : grey[100],
        opacity: 100,
      },
    }),
    muiTableBodyRowProps: ({ row }) => ({
      id: row.id,
      sx: {
        minHeight: ROW_HEIGHT,
        height: 'fit-content',
      },
      hover: !differentFieldsAnsRowsComparingRowValues,
    }),
    muiTableContainerProps: () => ({
      ref: tableContainerRef,
    }),
    muiTableHeadProps: () => ({
      ref: tableHeadRef,
      sx: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
      },
    }),
    muiTableHeadCellProps: ({ column }) => ({
      sx: {
        color: getFieldColor({
          palette: theme.palette,
          fieldId: column.id,
          differentFieldsAnsRowsComparingRowValues,
          isForFieldName: true,
        }),
      },
    }),
    muiTableHeadRowProps: () => ({
      ref: tableHeadRowRef,
    }),
    muiTablePaperProps: () => ({
      elevation: 0,
    }),
    onColumnSizingChange: setColumnSizing,
    renderRowActions: ({ row }) => {
      return (
        <FieldTableRowActionsMenu
          documentRow={row.original}
          addRow={addRow}
          deleteDocumentRows={deleteDocumentRows}
        />
      )
    },
    renderToolbarInternalActions: () => (
      <Stack direction="row" alignItems="center">
        <Tooltip title="Add Row">
          <IconButton
            onClick={() => {
              addRow({ shouldFocus: true })
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault() // Prevent the onClick from firing
                addRow()
              }
            }}
          >
            <Add />
          </IconButton>
        </Tooltip>
        <FieldTableMenu
          tableHasRows={!!documentRows.length}
          onDeleteAllRows={() => {
            deleteDocumentRows(documentRows.map(({ id }) => id))
          }}
        />
      </Stack>
    ),
    rowVirtualizerOptions: { overscan: 20 },
    state: {
      columnSizing,
      showSkeletons: isLoading,
    },
  })

  return <ZerapixTable<DocumentRow> table={table} />
}

export default function FieldTable(props: FieldTableProps) {
  const theme = useTheme()
  // This is the easiest way to get the table cards to reflect the same elevation without making
  // tons of the subcomponents have background "transparent"
  const tableTheme = useMemo(() => {
    return createTheme(theme, {
      palette: {
        mode: theme.palette.mode,
        background: {
          default: theme.palette.mode === 'dark' ? '#3a3a3a' : '#fff',
          paper: theme.palette.mode === 'dark' ? '#3a3a3a' : '#fff',
        },
      },
    })
  }, [theme])

  return (
    <ThemeProvider theme={tableTheme}>
      <FieldTableContent {...props} />
    </ThemeProvider>
  )
}

import queryKeys from '../query-keys'
import { UserInteractionMetricReport } from '@/types/workflow-metric-reports'
import useGet, { UseGetOptions } from '../core-hooks/useGet'
import { getDetail } from '../request-wrappers'

export function useGetUserInteractionMetricReport({
  filters,
  ...options
}: UseGetOptions<UserInteractionMetricReport> = {}) {
  const query = useGet<UserInteractionMetricReport>({
    filters,
    queryKey: queryKeys.userInteractionMetricReports.list({ filters }, [
      'grouped-by-workflow-state',
    ]),
    serviceFn: (serviceFnOptions) => {
      return getDetail<UserInteractionMetricReport>({
        url: '/v2/pd/user_interaction_metric_reports/grouped-by-workflow-state',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    userInteractionMetricReport: query.data,
    ...query,
  }
}

import { Close } from '@mui/icons-material'
import { Button, IconButton, List, ListItem, Stack } from '@mui/material'
import { Organization } from '@/types/organizations'
import useOverlay from '@/hooks/useOverlay'
import { useDocumentFiltersContext } from '@/components/document-filters/DocumentFiltersProvider'
import { OrganizationTreeItemLabel } from '@/components/organizations-tree/OrganizationTreeItem'
import OrganizationsSelectDialog from './OrganizationsFilterDialog'

type OrganizationsFilterListProps = {
  isFetching: boolean
  organizations: Organization[]
  filterContext: ReturnType<typeof useDocumentFiltersContext>
}

export default function OrganizationsFilterList({
  isFetching,
  organizations,
  filterContext,
}: OrganizationsFilterListProps) {
  const organizationSelectOverlay = useOverlay()
  const { documentFilters, includeFilter, handleOnDocumentFilterChange } =
    filterContext

  const currentOrgsInFilter =
    includeFilter.orgs && documentFilters
      ? organizations.filter(({ id }) => documentFilters.orgIds.includes(id))
      : []

  const removeOrgFromFilter = (id: string) => {
    handleOnDocumentFilterChange((prev) => {
      if (!prev) return prev

      const newFilters = { ...prev }
      newFilters.orgIds = newFilters.orgIds.filter((orgId) => orgId !== id)
      if (!newFilters.orgIds.length) {
        newFilters.orgIds = organizations.map(({ id }) => id)
      }
      return newFilters
    })
  }

  return (
    <Stack>
      <Button
        variant="text"
        onClick={organizationSelectOverlay.open}
        sx={{ width: 'fit-content', ml: -0.5 }}
      >
        Filter By Organization
      </Button>
      {organizationSelectOverlay.isOpen && (
        <OrganizationsSelectDialog
          isFetching={isFetching}
          overlay={organizationSelectOverlay}
          title="Filter by Organization"
          organizations={organizations}
          filterContext={filterContext}
        />
      )}
      <List dense>
        {currentOrgsInFilter.map((org) => (
          <ListItem key={org.id} sx={{ columnGap: 0.5 }}>
            <OrganizationTreeItemLabel {...org} size="small" />
            <IconButton
              onClick={() => {
                removeOrgFromFilter(org.id)
              }}
              size="small"
            >
              <Close fontSize="inherit" />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}

import MovableList, { BaseDataType } from './MovableList'
import {
  Button,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  // Tooltip,
} from '@mui/material'
// import BuildCircleIcon from '@mui/icons-material/BuildCircle'
import { AddOutlined, DragIndicator } from '@mui/icons-material'
import getDataTypeIcon from '@/utils/get-data-type-icon'
import { ProjectGridField } from '@/types/fields'

export type NestedMovableListItemProps<DataType> = {
  dense: boolean
  name: string
  Icon: ReturnType<typeof getDataTypeIcon>
  handleName: string
  data: DataType[]
  onAdd: () => void
  onClick: () => void
  selectedItem: DataType | null
  selected: boolean
  onSelectionChanged: (item: DataType) => void
  onChange: (items: DataType[]) => void
  disableCreation: boolean
  field: DataType
}

export default function NestedMovableListItem<DataType extends BaseDataType>({
  dense,
  name,
  Icon,
  handleName,
  data,
  onAdd,
  onClick,
  selectedItem,
  onSelectionChanged,
  onChange,
  disableCreation,
  field,
  // rules, TODO: Add back when this is ready
  ...rest
}: NestedMovableListItemProps<DataType>) {
  return (
    <div>
      <ListItem
        sx={{ bgcolor: 'background.paper' }}
        divider
        onClick={onClick}
        {...rest}
        secondaryAction={
          <Stack alignItems="center">
            <DragIndicator
              fontSize={dense ? 'small' : 'medium'}
              className={handleName}
              sx={{ cursor: 'grab', ':active': { cursor: 'grabbing' } }}
            />
          </Stack>
        }
      >
        {Icon && (
          <ListItemIcon sx={{ minWidth: 32 }}>
            <Icon fontSize={dense ? 'small' : 'medium'} />
          </ListItemIcon>
        )}
        <ListItemText primary={name} />
        {(field as unknown as ProjectGridField).project_grid_field_type.code !==
          'bar_chart' && (
          <Button
            onClick={(e) => {
              e.stopPropagation()
              onAdd()
            }}
            startIcon={<AddOutlined />}
            disabled={!!disableCreation}
            variant="text"
            // TODO: Add back when rules for grids is ready
            // sx={{ mr: 1 }}
          >
            Add Column
          </Button>
        )}
        {/* TODO: Add back when rules for grids is ready
       {!!rules && (
          <Tooltip
            title={rules > 1 ? `${rules} rules` : `${rules} rule`}
            placement="top"
          >
            <BuildCircleIcon />
          </Tooltip>
        )} */}
      </ListItem>
      {data?.length > 0 && (
        <Collapse in timeout="auto" unmountOnExit sx={{ paddingLeft: 8 }}>
          <MovableList
            dense={dense}
            data={data}
            onChange={onChange}
            onSelectionChanged={onSelectionChanged}
            selectedItem={selectedItem}
          />
        </Collapse>
      )}
    </div>
  )
}

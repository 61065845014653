import { useState } from 'react'
import { Container, Stack } from '@mui/material'
import { Organization } from '@/types/organizations'
import {
  useGetWorkflowMetricReport,
  useGetWorkflowMetricReportAverageStp,
} from '@/service-library/hooks/org-workflow-metric-reports'
import { useGetUserInteractionMetricReport } from '@/service-library/hooks/user-interaction-metric-reports'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import ChartCard from './ChartCard'
import { getYesterday } from './helpers'
import MetricBarChart from './MetricBarChart'
import MetricFiltersBar from './MetricFiltersBar'
import OverallSTPMetric from './OverallSTPMetric'

export type DateRange = {
  startDate: Date
  endDate: Date
}

const tooltipMap = {
  stp: 'The percentage of documents for each workflow state that enter and and then leave the workflow state without stopping.',
  idleTime:
    'The average time documents spend in each workflow state. If a document automatically exits a state immediately after entering, it is considered "straight-through processed" and does not count toward idle time. Only documents that spend time in a state contribute to the average for that state.',
  humanInteraction:
    'The average time taken by a human to resolve a document in each workflow state.',
}

export default function ProjectMetricsDashboard() {
  const { rootOrganization } = useRootOrganization()
  const { selectedWorkflow } = useSelectedWorkflowContext()

  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>(rootOrganization)

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({
    startDate: getYesterday(),
    endDate: getYesterday(),
  })

  const commonOptions = {
    filters: {
      org_and_descendants_for_id: selectedOrganization.id,
      workflow_id: selectedWorkflow.id,
      period_started_at__range: `${selectedDateRange.startDate.toISOString()},${selectedDateRange.endDate.toISOString()}`,
    },
    enabled: !!selectedWorkflow && !!selectedOrganization,
  }

  const { orgWorkflowMetricReport } = useGetWorkflowMetricReport(commonOptions)

  const { overallStpReport } =
    useGetWorkflowMetricReportAverageStp(commonOptions)

  const { userInteractionMetricReport } =
    useGetUserInteractionMetricReport(commonOptions)

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Stack spacing={2}>
        <MetricFiltersBar
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
        />

        <OverallSTPMetric
          averageOverall={overallStpReport?.doc_stp_average_overall}
        />

        <ChartCard
          heading="Straight-Through Processing"
          headingTooltip={tooltipMap.stp}
        >
          <MetricBarChart
            report={orgWorkflowMetricReport}
            reportKey="doc_stp_average"
          />
        </ChartCard>

        <ChartCard heading="Idle Time" headingTooltip={tooltipMap.idleTime}>
          <MetricBarChart
            report={orgWorkflowMetricReport}
            reportKey="doc_idle_average"
          />
        </ChartCard>

        <ChartCard
          heading="Human Interaction"
          headingTooltip={tooltipMap.humanInteraction}
        >
          <MetricBarChart
            report={userInteractionMetricReport}
            reportKey="user_interaction"
          />
        </ChartCard>
      </Stack>
    </Container>
  )
}

import { Menu } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { WorkflowState } from '@/types/workflows'
import useGetWorkflowStateMenuItems from './useGetWorkflowStateMenuItems'

type WorkflowStateMenuDropdownProps = {
  overlay: OverlayState
  anchorRef: React.RefObject<HTMLButtonElement | HTMLDivElement>
  workflowStates: WorkflowState[]
  onChange: (workflowState: WorkflowState, isSelected: boolean) => void
  selectedWorkflowStateIds?: string[]
  dense?: boolean
  multiSelect?: boolean
}

export default function WorkflowStateMenuDropdown({
  overlay,
  anchorRef,
  ...rest
}: WorkflowStateMenuDropdownProps) {
  const workflowStateMenuItems = useGetWorkflowStateMenuItems({
    overlay,
    ...rest,
  })
  return (
    <Menu
      open={overlay.isOpen}
      onClose={overlay.close}
      anchorEl={anchorRef.current}
      slotProps={{
        list: {
          disablePadding: true,
        },
      }}
    >
      {workflowStateMenuItems}
    </Menu>
  )
}

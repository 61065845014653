import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '../dialog'
import { Button, Stack, Typography } from '@mui/material'
import { ProjectTag } from '@/types/project-tags'
import TagChip from '../tags/TagChip'

type TagDeleteConfirmationDialogProps = {
  overlay: OverlayState
  projectTag: ProjectTag
  count: number
  onDelete: () => void
}

export default function TagDeleteConfirmationDialog({
  overlay,
  projectTag,
  count,
  onDelete,
}: TagDeleteConfirmationDialogProps) {
  return (
    <Dialog
      {...overlay}
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h6">Delete Tag</Typography>
          <TagChip tag={projectTag} />
        </Stack>
      }
    >
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            This tag has {count} documents associated with it.
          </Typography>
          <Typography>
            This action cannot be undone. Are you sure you want to delete this
            tag?
          </Typography>
        </Stack>
      </DialogContent>
      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            onDelete()
            overlay.close()
          }}
        >
          Delete
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

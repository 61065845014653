import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Edit, Delete, ChevronRight, ExpandMore } from '@mui/icons-material'
import {
  Stack,
  Typography,
  Tooltip,
  IconButton,
  Card,
  Button,
  Box,
} from '@mui/material'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { QueryKey } from '@tanstack/react-query'
import { DataList } from '@/types/data-lists'
import { Organization } from '@/types/organizations'
import useHorizontalDrag from '@/hooks/useHorizontalDrag'
import useOverlay from '@/hooks/useOverlay'
import useAllOrganizations from '@/services/hooks/useAllOrganizations'
import { useDeleteDataList } from '@/service-library/hooks/data-lists'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import { createOrganizationMap, getNodesToExpand } from '@/utils/organization'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import CopyIDButton from '@/components/copy-id-button/CopyIDButton'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import { OrgTreeSkeleton } from '@/components/organizations-tree/OrganizationTreeSearchSkeleton'
import OrganizationTreeItem from '@/components/organizations-tree/OrganizationTreeItem'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import AddEditDataListDialog from './AddEditDataListDialog'
import DataListData from './DataListData'
import DeleteDataListDialog from './DeleteDataListDialog'
import { customDataListTypeID } from './DataListList'
import ListIsInheritingMessage from './ListIsInheritingMessage'
import ViewEditDataListDescriptionDialog from './ViewEditDataListDescriptionDialog'

type DataListDetailsProps = {
  parentDataList?: DataList
  dataList?: DataList
  selectedOrg: Organization | null
  setSelectedOrg: Dispatch<SetStateAction<Organization | null>>
  detailQueryKey: QueryKey
  rootDetailQueryKey: QueryKey
}

export default function DataListDetails({
  parentDataList,
  dataList,
  selectedOrg,
  setSelectedOrg,
  detailQueryKey,
  rootDetailQueryKey,
}: DataListDetailsProps) {
  const [demoMode] = useDemoModeContext()
  const isSuperUser = useIsSuperUser()
  const navigate = useNavigate()
  const editOverlay = useOverlay()
  const deleteOverlay = useOverlay()
  const descriptionOverlay = useOverlay()

  const { rootOrganization } = useRootOrganization()

  const {
    contentRef,
    dragDividerRef,
    dragDividerContainerRef,
    resizableContainerWith,
    bind,
  } = useHorizontalDrag({
    identifier: `data-list-orgs-width-${rootOrganization.id}`,
    defaultWidth: 350,
    minLeftSideWidth: 150,
    minRightSideWidth: 150,
  })

  const { organizations, isFetchingAll } = useAllOrganizations({
    filters: {
      self_and_descendants_for_id: rootOrganization.id,
    },
    refetchOnWindowFocus: false,
  })

  const isCustomList =
    customDataListTypeID === parentDataList?.data_list_type_id
  const rootOrgIsSelected = selectedOrg?.id === rootOrganization.id

  const isInheritingFromParent =
    selectedOrg &&
    dataList &&
    parentDataList &&
    selectedOrg?.id !== dataList?.org_id &&
    !isFetchingAll

  const organizationMap = useMemo(
    () => (isFetchingAll ? {} : createOrganizationMap(organizations)),
    [isFetchingAll, organizations],
  )
  const [showOrgTree, setShowOrgTree] = useState(true)
  const [expanded, setExpanded] = useState([rootOrganization.id])

  const { deleteDataList } = useDeleteDataList({
    onError: () => {
      showErrorSnackbar('Unable to delete list. Please try again later.')
    },
    onSuccess: () => {
      showSuccessSnackbar('List Deleted')
      navigate('../', { replace: true })
    },
  })

  function handleDeleteDataList() {
    if (!dataList) return
    deleteDataList(dataList.id)
  }

  useEffect(() => {
    if (
      !isFetchingAll &&
      selectedOrg &&
      selectedOrg.id !== rootOrganization.id &&
      !expanded.includes(selectedOrg.parent_org_id || '')
    ) {
      setExpanded(getNodesToExpand(selectedOrg.id, organizationMap))
    }
  }, [
    expanded,
    isFetchingAll,
    organizationMap,
    rootOrganization.id,
    selectedOrg,
  ])

  useEffect(() => {
    if (!selectedOrg && organizations[0]) {
      setSelectedOrg(organizations[0])
    }
  }, [organizations, selectedOrg, setSelectedOrg])

  return (
    <Stack spacing={2} ref={contentRef}>
      {parentDataList && (
        <div>
          <Typography variant="caption" color="text.secondary">
            {isCustomList ? 'Custom List' : 'System Managed List'}
          </Typography>
          <Stack
            sx={{ flexShrink: 0 }}
            direction="row"
            spacing={2}
            alignItems="center"
          >
            <Typography variant="h4">{parentDataList?.name}</Typography>
            <Stack direction="row" spacing={1}>
              {parentDataList.is_name_editable_in_ui && (
                <>
                  <Tooltip title="Edit">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={editOverlay.open}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <AddEditDataListDialog
                    overlay={editOverlay}
                    dataList={parentDataList}
                    rootDetailQueryKey={rootDetailQueryKey}
                  />
                </>
              )}
              {rootOrgIsSelected && parentDataList.is_deletable_in_ui && (
                <>
                  <Tooltip title="Delete">
                    <IconButton
                      color="error"
                      size="small"
                      onClick={deleteOverlay.open}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <DeleteDataListDialog
                    overlay={deleteOverlay}
                    dataList={parentDataList}
                    onDelete={handleDeleteDataList}
                  />
                </>
              )}

              <Button onClick={descriptionOverlay.open}>
                View Documentation
              </Button>
              <ViewEditDataListDescriptionDialog
                overlay={descriptionOverlay}
                dataList={parentDataList}
                rootDetailQueryKey={rootDetailQueryKey}
              />
              {!demoMode && isSuperUser && (
                <>
                  <CopyIDButton
                    stringToCopy={parentDataList.id}
                    isSuperUser
                    label="Copy Root Data List ID"
                  />
                  {dataList?.id &&
                    !rootOrgIsSelected &&
                    !isInheritingFromParent && (
                      <CopyIDButton
                        stringToCopy={dataList.id}
                        isSuperUser
                        label="Copy Child Data List ID"
                      />
                    )}
                </>
              )}
            </Stack>
          </Stack>
        </div>
      )}
      <Stack
        spacing={2}
        direction="row"
        sx={{
          height: 'calc(100vh - 210px)',
          position: 'relative',
        }}
      >
        {showOrgTree && (
          <Stack direction="row" ref={dragDividerContainerRef}>
            <Card
              elevation={0}
              sx={{
                width: resizableContainerWith,
                overflow: 'auto',
                p: 2,
                flexShrink: 0,
              }}
            >
              <Typography
                variant="body2"
                sx={{ pl: 1.25, pb: 1 }}
                color="text.secondary"
              >
                Organizations
              </Typography>
              {isFetchingAll ? (
                <OrgTreeSkeleton />
              ) : (
                <SimpleTreeView
                  slots={{
                    collapseIcon: ExpandMore,
                    expandIcon: ChevronRight,
                  }}
                  expandedItems={expanded}
                  selectedItems={selectedOrg?.id || rootOrganization.id}
                  onSelectedItemsChange={(_e, id) => {
                    setSelectedOrg(
                      organizations.find((org) => org.id === id) || null,
                    )
                  }}
                  onExpandedItemsChange={(_e, ids) => {
                    setExpanded(ids)
                  }}
                >
                  <OrganizationTreeItem
                    orgTree={organizationMap[rootOrganization.id]}
                  />
                </SimpleTreeView>
              )}
            </Card>
            <Box
              ref={dragDividerRef}
              sx={{
                width: 4,
                flexShrink: 0,
                transition: '0.1s',
                cursor: 'ew-resize',
                borderRadius: 40,
                '&:hover': {
                  background: (theme) => theme.palette.primary.main,
                },
              }}
              {...bind()}
            />
          </Stack>
        )}

        {parentDataList && dataList && (
          <>
            {isInheritingFromParent ? (
              <ListIsInheritingMessage
                parentOrg={
                  organizations.find(
                    ({ id }) => id === dataList.org_id,
                  ) as Organization
                }
                dataList={dataList}
                selectedOrg={selectedOrg}
                setSelectedOrg={setSelectedOrg}
                detailQueryKey={detailQueryKey}
              />
            ) : (
              <DataListData
                parentDataList={parentDataList}
                dataList={dataList}
                showOrgTree={showOrgTree}
                setShowOrgTree={setShowOrgTree}
              />
            )}
          </>
        )}
      </Stack>
    </Stack>
  )
}

import { useMemo, useState } from 'react'
import { Add } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { DocProjectTag, ProjectTag } from '@/types/project-tags'
import useOverlay from '@/hooks/useOverlay'
import usePermission from '@/hooks/usePermission'
import {
  useCreateDocProjectTag,
  useDeleteDocProjectTag,
  useGetDocProjectTags,
} from '@/service-library/hooks/doc-project-tags'
import queryKeys from '@/service-library/query-keys'
import generateUuid from '@/utils/generate-uuid'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import DocProjectTagsList from './DocProjectTagsList'
import TagSelectMenu from './TagSelectMenu'

type EditableDocProjectTagsListProps = {
  documentId: string
}

export default function EditableDocProjectTagsList({
  documentId,
}: EditableDocProjectTagsListProps) {
  const { project } = useProjectContext()
  const overlay = useOverlay()

  const [searchText, setSearchText] = useState('')

  const { hasEditingPermission } = usePermission()
  const canEditProject = hasEditingPermission('edit_projects', project.org_id)

  const { docProjectTags, queryKey } = useGetDocProjectTags({
    filters: {
      limit: '1000',
      document_id: documentId,
      fields__include: 'project_tag',
    },
  })

  const { createDocProjectTag } = useCreateDocProjectTag({
    sideEffectQueryKeys: [queryKeys.documents.all],
    listQueryKey: queryKey,
  })

  const { deleteDocProjectTag } = useDeleteDocProjectTag({
    sideEffectQueryKeys: [queryKeys.documents.all],
    listQueryKey: queryKey,
  })

  const projectTagIdsAddedToDoc = docProjectTags.map(
    ({ project_tag_id }) => project_tag_id,
  )
  const projectTagsAddedToDoc = useMemo(
    () =>
      project.tags?.filter(({ id }) => projectTagIdsAddedToDoc.includes(id)) ||
      [],
    [project.tags, projectTagIdsAddedToDoc],
  )

  if (project.tags?.length === 0) {
    return null
  }

  const onUpdateDocProjectTag = (
    projectTag: ProjectTag,
    docTag?: DocProjectTag,
  ) => {
    if (docTag) {
      deleteDocProjectTag(docTag.id)
    } else {
      createDocProjectTag({
        id: generateUuid(),
        document_id: documentId,
        project_tag_id: projectTag.id,
        project_tag: projectTag,
      })
    }
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'calc(100% - 120px) auto',
        maxWidth: '100%',
        minWidth: 0,
        columnGap: 1,
      }}
    >
      <DocProjectTagsList projectTags={projectTagsAddedToDoc} collapse />
      <Button
        startIcon={<Add />}
        variant="text"
        onClick={overlay.open}
        sx={{ flexShrink: 0 }}
      >
        Add Tag
      </Button>

      <TagSelectMenu
        overlay={overlay}
        selectedProjectTags={projectTagsAddedToDoc}
        onTagClick={(tag: ProjectTag) => {
          onUpdateDocProjectTag(
            tag,
            docProjectTags.find((docTag) => docTag.project_tag_id === tag.id),
          )
        }}
        searchText={searchText}
        setSearchText={setSearchText}
        documentId={canEditProject ? documentId : undefined}
        listQueryKey={queryKey}
      />
    </Box>
  )
}

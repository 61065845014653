import { Divider, Stack } from '@mui/material'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import TopBarNavigation from '@/components/top-bar-portal/TopBarNavigation'
import FileUploadButton from '@/components/upload-files/FileUploadButton'
import { useFileUploadContext } from '@/components/upload-files/FileUploadProvider'
import DownloadExportButton from '@/components/workflows/DownloadExportButton'
import ProjectIndicators from './ProjectIndicators'
import WorkflowTabs from './WorkflowTabs'
import WorkflowSelector from './WorkflowSelector'
import { useSelectedWorkflowContext } from '../workflows/SelectedWorkflowProvider'

export default function Navigation() {
  const { project } = useProjectContext()

  const { handleFilesSelectedToUpload } = useFileUploadContext()

  const { selectedWorkflow } = useSelectedWorkflowContext()

  if (!selectedWorkflow) return null

  return (
    <TopBarNavigation>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ px: 0.5, pr: 1, py: 0.5, position: 'relative' }}
        spacing={1}
      >
        <Stack direction="row">
          <WorkflowSelector />
        </Stack>

        <Divider sx={{ height: 16 }} orientation="vertical" />

        <WorkflowTabs />

        <div style={{ flexGrow: 1 }} />

        <ProjectIndicators />
        <DownloadExportButton projectId={project.id} />
        <FileUploadButton multiple onFileUpload={handleFilesSelectedToUpload} />
      </Stack>
    </TopBarNavigation>
  )
}

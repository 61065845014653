import {
  Autocomplete,
  TextField,
  ListItemButton,
  AutocompleteProps,
  TextFieldProps,
  Typography,
} from '@mui/material'
import { Organization } from '@/types/organizations'
import { getOrgName } from '@/utils/organization'
import { Dispatch, SetStateAction } from 'react'

type SearchAutocomplete = AutocompleteProps<
  Organization,
  false,
  true | false,
  false,
  'div'
>

type OrganizationSearchInputProps = {
  organizations: Organization[]
  inputValue: SearchAutocomplete['inputValue']
  setInputValue: Dispatch<SetStateAction<string>>
  setSelected: (org: Organization) => void
  autoFocus?: boolean
  label?: string
  isLoading?: boolean
  helperText?: string
  placeholder?: string
  selected?: Organization
  variant?: TextFieldProps['variant']
} & Omit<Partial<SearchAutocomplete>, 'onChange'>

export default function OrganizationSearchInput({
  organizations,
  setInputValue,
  setSelected,
  autoFocus,
  helperText,
  isLoading = false,
  label = 'Search Organizations',
  placeholder = 'Search Organizations...',
  selected,
  variant = 'filled',
  ...props
}: OrganizationSearchInputProps) {
  return (
    <Autocomplete<Organization, false, true | false, false>
      value={selected ?? null}
      autoHighlight
      options={organizations}
      clearOnBlur={false} // This is true by default if freeSolo is false
      disableClearable
      loading={isLoading}
      onChange={(_e, org) => {
        if (org) setSelected(org)
      }}
      onInputChange={(_e, value, reason) => {
        if (reason !== 'reset') {
          setInputValue(value)
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          helperText={helperText}
          label={label}
          placeholder={placeholder}
          variant={variant}
        />
      )}
      getOptionLabel={(org) => getOrgName(org)}
      renderOption={(props, org) => {
        return (
          <ListItemButton component="li" {...props} key={org.id} dense>
            <Typography noWrap>
              {getOrgName(org)}
              {org.code && (
                <Typography
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {' '}
                  ({org.code})
                </Typography>
              )}
            </Typography>
          </ListItemButton>
        )
      }}
      {...props}
    />
  )
}

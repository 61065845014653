import { Node } from '@xyflow/react'

export function getHeightOfNodeGroup(nodes: Node[]) {
  if (nodes.length === 0) return 0
  const firstNodeY = nodes[0].position.y
  const lastNode = nodes[nodes.length - 1]
  const lastNodeY = lastNode.position.y + (lastNode.measured?.height || 0)
  return lastNodeY - firstNodeY
}

export function getNodesByType(nodes: Node[]): Record<string, Node[]> {
  return nodes.reduce((acc, node) => {
    if (!node.type) return acc
    acc[node.type] ||= []
    acc[node.type].push(node)
    return acc
  }, {} as Record<string, Node[]>)
}

export function getWidestNodeFromGroup(nodes: Node[]) {
  return nodes.reduce<Node>((acc, node) => {
    if (!node.measured) return acc
    if (
      !acc.measured ||
      (node.measured.width || 0) > (acc.measured.width || 0)
    ) {
      return node
    }
    return acc
  }, {} as Node)
}

export function getDynamicDistanceBetweenGroups(
  nodeGroup1: Node[],
  nodeGroup2: Node[],
  minimum = 10,
  maximum = Infinity,
  multiplier = 10,
): number {
  const nodeGroup1Height = getHeightOfNodeGroup(nodeGroup1)
  const nodeGroup2Height = getHeightOfNodeGroup(nodeGroup2)

  const distance =
    multiplier *
      Math.sqrt(
        Math.max(nodeGroup1Height, nodeGroup2Height) -
          Math.min(nodeGroup1Height, nodeGroup2Height),
      ) +
    minimum

  return Math.min(distance, maximum)
}

import { Add } from '@mui/icons-material'
import { Card, CardActionArea, Stack, Typography } from '@mui/material'
import AddModelMenu from './AddModelMenu'
import useOverlay from '@/hooks/useOverlay'
import { useRef } from 'react'
import { ProjectLinkedModel } from '@/types/project-linked-models'

type NewModelCardProps = {
  projectLinkedModels: ProjectLinkedModel[]
}

export default function NewModelCard({
  projectLinkedModels,
}: NewModelCardProps) {
  const addOverlay = useOverlay()
  const anchorElRef = useRef<HTMLParagraphElement>(null)
  return (
    <Card sx={{ height: '100%', minHeight: 88 }}>
      <CardActionArea sx={{ height: '100%' }} onClick={addOverlay.open}>
        <Stack
          direction="row"
          spacing={1}
          sx={{ p: 2, flexGrow: 1, alignItems: 'center' }}
        >
          <Add sx={{ color: 'text.secondary' }} fontSize="large" />
          <Typography variant="h5" color="text.secondary" ref={anchorElRef}>
            Add Model
          </Typography>
        </Stack>
      </CardActionArea>

      <AddModelMenu
        overlay={addOverlay}
        anchorEl={anchorElRef.current}
        projectLinkedModels={projectLinkedModels}
      />
    </Card>
  )
}

import { ListItem } from '@mui/material'
import {
  ProjectDocumentRuleType,
  ProjectGridFieldRuleType,
} from '@/types/rules'
import { useGetProjectGridFields } from '@/service-library/hooks/project-grid-fields'
import { FormAutocomplete } from '@/components/forms'
import { ProjectGridField } from '@/types/fields'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'

type DependentFieldsAutocompleteProps = {
  ruleType: ProjectGridFieldRuleType | ProjectDocumentRuleType
  fieldId?: string
  nonGridFields?: ProjectGridField[] // Add rule dialog doesn't pass the fields
}

export default function DependentFieldsAutocomplete({
  ruleType,
  fieldId,
  nonGridFields,
}: DependentFieldsAutocompleteProps) {
  const { project } = useProjectContext()

  const isComparisonSumRule = ruleType.code === 'flag_comparison_sum'

  const options = nonGridFields?.filter(({ id, project_grid_field_type }) => {
    if (
      isComparisonSumRule &&
      (id === fieldId ||
        !['currency', 'int', 'decimal'].includes(project_grid_field_type.code))
    ) {
      return false
    }
    return true
  })

  const { projectGridFields } = useGetProjectGridFields({
    filters: {
      project_id: project.id,
      fields__only: 'id,name',
      'project_grid_field_type__code__in!': 'grid',
      'id!': isComparisonSumRule ? fieldId : undefined,
      project_grid_field_type__code__in: isComparisonSumRule
        ? 'currency,int,decimal'
        : undefined,
      limit: '1000',
    },
    enabled: !nonGridFields && !!ruleType?.uses_dependent_fields,
  })
  return (
    <FormAutocomplete
      name="dependent_fields"
      label={ruleType.dependent_fields_label}
      helperText={ruleType.dependent_fields_description}
      options={options || projectGridFields}
      multiple
      autoHighlight
      disableCloseOnSelect
      noOptionsText="No fields available."
      textFieldProps={{
        variant: 'filled',
        InputLabelProps: { shrink: true },
      }}
      required={ruleType.code !== 'doc_run_py' && ruleType.code !== 'run_py'}
      isOptionEqualToValue={(option, value) => option?.id === value.id}
      getOptionLabel={(option) => option?.name || ''}
      // This is so we can choose the key, otherwise it throws a duplicate key error when we have duplicates
      renderOption={(props, option) => {
        return (
          <ListItem {...props} key={option.id} dense>
            {option.name}
          </ListItem>
        )
      }}
    />
  )
}

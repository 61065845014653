/* eslint-disable @typescript-eslint/no-explicit-any */
import { useWatch } from 'react-hook-form'
import { Alert, TextFieldProps } from '@mui/material'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import MultiSelectWorkflowStatesAndList from '@/components/rules/MultiWorkflowStateSelectAndList'
import PropertyTextField from './PropertyTextField'
import PropertyCheckbox from './PropertyCheckbox'
import PropertySelectFromForeignKey from './PropertySelectFromForeignKey'
import PropertySlider from './PropertySlider'
import PropertyIntegerField from './PropertyIntegerField'
import PropertyDecimalField from './PropertyDecimalField'
import PropertyMultiSelectFromForeignKey from './PropertyMultiSelectFromForeignKey'
import PropertySelect from './PropertySelect'

export type PropertyFieldProps = {
  propertyId: string
  property: Record<string, any>
  required?: boolean
  variant?: TextFieldProps['variant']
  dependencyValues?: Record<string, string>
}

export default function PropertyField({
  propertyId,
  property,
  required,
  variant,
  dependencyValues,
}: PropertyFieldProps) {
  const { project } = useProjectContext()
  const { rootOrganization } = useRootOrganization()
  const dependentPropertyToShowField =
    property.display?.show?.dependent_property
  const dependentFieldValue = useWatch({
    name: dependentPropertyToShowField,
    disabled: !dependentPropertyToShowField,
  })

  if (
    dependentPropertyToShowField &&
    !property.display.show.valid_property_values.includes(dependentFieldValue)
  )
    return null

  if (property.hidden) return null
  const commonProps = {
    propertyId,
    property,
    required: dependentPropertyToShowField
      ? property.display.show.required
      : required,
    variant,
    dependencyValues: {
      ...dependencyValues,
      current_org_id: rootOrganization.id,
      current_project_id: project.id,
    },
  }

  switch (property.code) {
    case 'text':
    case 'regex':
    case 'text-area':
      return (
        <PropertyTextField
          {...commonProps}
          multiline={property.code === 'text-area'}
        />
      )

    case 'integer':
      return <PropertyIntegerField {...commonProps} />

    case 'decimal':
      return <PropertyDecimalField {...commonProps} />

    case 'multi-select':
      return <PropertyMultiSelectFromForeignKey {...commonProps} />

    case 'select':
      if (property.options) {
        return <PropertySelect {...commonProps} />
      }
      return <PropertySelectFromForeignKey {...commonProps} />

    case 'slider':
      return <PropertySlider {...commonProps} />

    case 'checkbox':
      return <PropertyCheckbox {...commonProps} />

    case 'multi-workflow-state-select':
      return (
        <MultiSelectWorkflowStatesAndList
          formFieldId={propertyId}
          label={property.display.label}
          description={property.display.description}
        />
      )

    case 'python':
      return null

    // TODO: SUpport this field type
    case 'rule-schema':
      return null

    default: {
      return (
        <Alert severity="error" variant="outlined" sx={{ width: '100%' }}>
          Missing property code: {property.code}
        </Alert>
      )
    }
  }
}

import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Slider,
  Stack,
  Tooltip,
} from '@mui/material'
import { Document } from '@/types/documents'
import useSplitDocuments from './useSplitDocuments'
import { Refresh } from '@mui/icons-material'

type SplitDocumentOptionsProps = {
  pages: Document['document_pages']
  splitDocumentsData: ReturnType<typeof useSplitDocuments>
}

export default function SplitDocumentOptions({
  pages,
  splitDocumentsData,
}: SplitDocumentOptionsProps) {
  const {
    deleteOriginal,
    pagesPerSplit,
    splitPoints,
    setDeleteOriginal,
    setPagesPerSplit,
    setSplitPoints,
  } = splitDocumentsData

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ pl: 1 }}>
        <Stack direction="row" spacing={2}>
          <Slider
            value={pagesPerSplit}
            min={1}
            max={9}
            onChange={(_, value) => setPagesPerSplit(value as number)}
            sx={{ width: 150 }}
          />
          <Button
            variant="text"
            onClick={() => {
              const points = []
              for (
                let i = pagesPerSplit;
                i < pages.length;
                i += pagesPerSplit
              ) {
                points.push(i)
              }
              setSplitPoints(points)
            }}
            sx={{
              minWidth: '160px',
            }}
          >
            Split Every {pagesPerSplit} Page{pagesPerSplit > 1 ? 's' : ''}
          </Button>
        </Stack>

        <Tooltip title="Clear all split points">
          {/*  Added to prevent console log warning when button is disabled */}
          <div>
            <IconButton
              disabled={!splitPoints.length}
              onClick={() => {
                setSplitPoints([])
              }}
            >
              <Refresh />
            </IconButton>
          </div>
        </Tooltip>

        <FormControlLabel
          label="Delete this document"
          control={
            <Checkbox
              color={deleteOriginal ? 'error' : 'neutral'}
              sx={{ padding: 1 }}
              checked={deleteOriginal}
              onChange={(event, checked) => setDeleteOriginal(checked)}
            />
          }
          slotProps={{
            typography: { color: deleteOriginal ? 'error' : 'neutral' },
          }}
          sx={{ mx: 0, width: 'fit-content' }}
        />
      </Stack>
    </>
  )
}

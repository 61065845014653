import { useEffect, useMemo, useRef } from 'react'
import {
  Alert,
  Stack,
  Tooltip,
  Typography,
  Link,
  FormControlLabel,
  Radio,
  MenuItem,
} from '@mui/material'
import { ProjectGridField } from '@/types/fields'
import PaginatedResponse from '@/types/paginated-response'
import { ProjectGrid } from '@/types/projects'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import queryKeys from '@/service-library/query-keys'
import {
  aggregateFunctionOptions,
  numberFieldTypes,
  validateFieldName,
} from '@/utils/field-utils'
import getDataTypeIcon from '@/utils/get-data-type-icon'
import { notNull } from '@/utils/typescript-utils'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import SuperUserFieldInfo from '@/components/super-user-field-info/SuperUserFieldInfo'
import { useWorkflowsContext } from '@/components/workflows-provider/WorkflowsProvider'
import useCSVHeaderField from './useCSVHeaderField'
import { Link as LinkComponent } from 'react-router-dom'
import { useUpdateProjectGridFieldWithDefaults } from '@/service-library/hooks/project-grid-fields'
import { showErrorSnackbar } from '@/utils/snackbars'
import { FormSelect, FormTextField, PixyDocsForm } from '../forms'
import { useForm } from 'react-hook-form'
import FormRadioGroup from '../forms/FormRadioGroup'
import PickerListInfo from './PickerListInfo'
import FieldSettingsFooter from './FieldSettingsFooter'
import { useProjectGridsContext } from '../project-settings/ProjectGridsProvider'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import ListUrlField from '../project-settings/ListUrlField'

type FieldSettingsProps = {
  field: ProjectGridField
  isSettings: boolean
  isTableField: boolean
  projectId: string
  projectGridFields: ProjectGridField[]
}

export const FieldSettings = ({
  field,
  isTableField,
  projectId,
  projectGridFields,
}: FieldSettingsProps) => {
  const isSuperUser = useIsSuperUser()
  const { workflows } = useWorkflowsContext()

  const fieldNamesToCheck = useMemo(
    () =>
      projectGridFields
        .map((gridField) =>
          gridField.id !== field.id ? gridField.name.toLowerCase() : null,
        )
        .filter(notNull),
    [field.id, projectGridFields],
  )

  const queryClient = useQueryClient()
  const { queryKey } = useProjectGridsContext()
  const { updateProjectGridField, isLoading } =
    useUpdateProjectGridFieldWithDefaults({
      sideEffectQueryKeys: [queryKey, queryKeys.projects.details()],
      onMutate: (updatedField) => {
        queryClient.setQueryData<InfiniteData<PaginatedResponse<ProjectGrid>>>(
          queryKey,
          (oldData) => {
            const updatedPages = oldData?.pages.map((page) => {
              return {
                ...page,
                results: page.results.map((grid) => {
                  if (grid.id === updatedField.project_grid_id) {
                    return {
                      ...grid,
                      project_grid_fields: grid.project_grid_fields.map(
                        (gridField) => {
                          if (gridField.id === updatedField.id) {
                            return updatedField
                          }
                          return gridField
                        },
                      ),
                    }
                  }
                  return grid
                }),
              }
            })
            return {
              pageParams: oldData?.pageParams || [],
              pages: updatedPages || [],
            }
          },
        )
      },
      onError: () => {
        showErrorSnackbar('Failed to update field. Please try again later.')
      },
    })

  const [demoMode] = useDemoModeContext()

  const {
    csvHeaderValue = '',
    saveNameChange,
    isCsvHeaderLoading,
  } = useCSVHeaderField({ projectId, field })

  const Icon = getDataTypeIcon(field.project_grid_field_type.code)

  const defaultValues = {
    ...field,
    params: {
      ...field.params,
      default_prediction_context_columns:
        field.params?.default_prediction_context_columns || [], // We do this so dirty fields is not comparing undefined to []
    },
    csv_header_name: csvHeaderValue,
  }

  const methods = useForm<
    Omit<ProjectGridField, 'included_grid_fields' | 'fields'> & {
      csv_header_name: string
    }
  >({
    defaultValues,
    mode: 'onChange', // triggers validation onChange
  })

  const {
    formState: { errors },
    register,
    reset,
    resetField,
    watch,
  } = methods

  const savedCsvHeaderName = watch('csv_header_name')

  const checkedCSVHeaderRef = useRef(false)
  useEffect(() => {
    if (
      !savedCsvHeaderName &&
      !!csvHeaderValue &&
      !checkedCSVHeaderRef.current
    ) {
      resetField('csv_header_name', { defaultValue: csvHeaderValue })
      checkedCSVHeaderRef.current = true
    }
  }, [csvHeaderValue, resetField, savedCsvHeaderName])

  function onSubmit(
    formValues: ProjectGridField & { csv_header_name: string },
  ) {
    const { csv_header_name } = formValues

    // @ts-expect-error -- Not sure why this is a problem
    if (csv_header_name) delete formValues.csv_header_name
    updateProjectGridField(formValues)

    if (csv_header_name !== csvHeaderValue) {
      saveNameChange(csv_header_name)
    }

    reset({
      ...formValues,
      csv_header_name,
    })
  }

  const notGrid = field.project_grid_field_type.code !== 'grid'

  return (
    <PixyDocsForm
      methods={methods}
      onSubmit={onSubmit}
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{ py: 2, flexGrow: 1 }}
      >
        <Stack sx={{ px: 2, pb: 2 }} spacing={3}>
          {!demoMode && isSuperUser && <SuperUserFieldInfo field={field} />}

          {field.has_category_model && (
            <Alert variant="outlined" severity="info">
              This field has a category model attached to it.
              {workflows.length > 0 && (
                <>
                  {' '}
                  <LinkComponent
                    to={`../../dashboard?workflow=${
                      workflows.find(
                        ({ project_model_id }) =>
                          project_model_id === field.project_model_id,
                      )?.id
                    }`}
                    target="_blank"
                  >
                    <Link component="span">Click here</Link>
                  </LinkComponent>{' '}
                  to view it.
                </>
              )}
            </Alert>
          )}

          <Stack direction="row" spacing={1} alignItems="center">
            {Icon ? (
              <Tooltip title={field.project_grid_field_type.name}>
                <Icon />
              </Tooltip>
            ) : null}
            <Typography variant="h6">Settings</Typography>
          </Stack>

          {/* MARK: Field Name */}
          <FormTextField
            label="Name"
            autoFocus
            error={!!errors.name}
            InputLabelProps={{ shrink: true }}
            helperText="Must be unique."
            sx={{ maxWidth: 300 }}
            required
            {...register('name', {
              validate: (value) => validateFieldName(value, fieldNamesToCheck),
            })}
          />

          {/* MARK: Picker Columns */}
          {field.project_grid_field_type.code === 'picker' && (
            <PickerListInfo
              // @ts-expect-error -- We know this field will have the needed data at this point
              field={field}
            />
          )}

          {/* MARK: Live List Picker */}
          {field.project_grid_field_type.code === 'live-list-picker' && (
            <ListUrlField
              paramsSchema={field.project_grid_field_type.params_schema}
            />
          )}

          {/* MARK: Aggregate Function for number fields */}
          {isTableField &&
            numberFieldTypes.includes(field.project_grid_field_type.code) && (
              <FormSelect
                name="aggregate_function"
                label="Show Aggregate"
                helperText="If set, shows the result of the selected aggregate function at the bottom of the table."
                sx={{ maxWidth: 300 }}
                variant="filled"
              >
                {aggregateFunctionOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </FormSelect>
            )}

          {/* MARK: Input Behavior for Manual Values */}
          {notGrid && (
            <>
              <FormRadioGroup name="input_behavior">
                <Typography sx={{ mb: 1 }}>Manual Values</Typography>
                <FormControlLabel
                  value="manual_allowed"
                  control={<Radio sx={{ mt: -1 }} />}
                  label={
                    <Stack>
                      <Typography>Allow manual value</Typography>
                      <Typography color="textSecondary" variant="caption">
                        This field&apos;s value can be automatically populated
                        from the document, but the user can override it
                        manually.
                      </Typography>
                    </Stack>
                  }
                  sx={{
                    alignItems: 'flex-start',
                    width: 'fit-content',
                  }}
                />
                <FormControlLabel
                  value="manual_not_allowed"
                  control={<Radio sx={{ mt: -1 }} />}
                  label={
                    <Stack>
                      <Typography>Do not allow manual value</Typography>
                      <Typography color="textSecondary" variant="caption">
                        The field&apos;s value can only be populated from the
                        document. The user cannot manually override the value.
                      </Typography>
                    </Stack>
                  }
                  sx={{
                    alignItems: 'flex-start',
                    width: 'fit-content',
                  }}
                />
                <FormControlLabel
                  value="manual_only"
                  control={<Radio sx={{ mt: -1 }} />}
                  label={
                    <Stack>
                      <Typography>Manual value only</Typography>
                      <Typography color="textSecondary" variant="caption">
                        The field&apos;s value cannot be populated from the
                        document. It must be entered manually.
                      </Typography>
                    </Stack>
                  }
                  sx={{
                    alignItems: 'flex-start',
                    width: 'fit-content',
                  }}
                />
              </FormRadioGroup>
            </>
          )}

          {/* MARK: CSV Header Name */}
          {notGrid && (
            <FormTextField
              name="csv_header_name"
              label="CSV Header Name"
              disabled={isCsvHeaderLoading}
              InputLabelProps={{ shrink: true }}
              sx={{ maxWidth: 300 }}
            />
          )}
        </Stack>
      </Stack>

      <FieldSettingsFooter field={field} isSaving={isLoading} />
    </PixyDocsForm>
  )
}

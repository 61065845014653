import { useGetWorkflowStateEvent } from '@/service-library/hooks/workflow-state-events'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import PrimaryTextWrapper from './PrimaryTextWrapper'
import { useGetCustomVariable } from '@/service-library/hooks/custom-variables'
import TagChip from '@/components/tags/TagChip'
import { useGetProjectTags } from '@/service-library/hooks/project-tags'
import { ReactNode } from 'react'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import { useGetFlagTypes } from '@/service-library/hooks/flag-types'

type TriggerEventDisplayProps = {
  action: WorkflowStateAction
}

export default function TriggerEventDisplay({
  action,
}: TriggerEventDisplayProps) {
  const theme = useTheme()
  const { fieldsMap } = useProjectContext()
  const { workflowStateEvent } = useGetWorkflowStateEvent({
    id: action.params.event_id,
    filters: {
      fields__only: 'id,name',
    },
  })

  const condition = action.params.condition

  const { customVariable } = useGetCustomVariable({
    id: action.params.org_custom_variable_id || '',
    enabled: condition === 'org_custom_variable_is',
  })

  const { projectTags, isLoading: isLoadingProjectTags } = useGetProjectTags({
    filters: {
      id__in: action.params.project_tag_ids?.join(),
    },
    enabled: ['has_tag', 'does_not_have_specific_tag'].includes(condition),
  })

  const { flagTypes, isLoading: isLoadingFlagTypes } = useGetFlagTypes({
    filters: {
      id__in: action.params.flag_type_ids?.join() || '',
    },
    enabled: [
      'has_specific_flag_type',
      'does_not_have_specific_flag_type',
    ].includes(condition),
  })

  if (!workflowStateEvent) return null

  const fieldId = action.params.field_id_with_flag

  const messageMap: Record<string, ReactNode> = {
    none: '.',
    has_flags: ' if the document has any flags.',
    has_no_flags: ' if the document has no flags.',
    // DEPRECATED
    has_flag_type: (
      <>
        <span>
          {' '}
          if the{' '}
          {action.params.field_id_with_flag ? (
            <>
              <PrimaryTextWrapper>{fieldsMap[fieldId].name}</PrimaryTextWrapper>{' '}
              field
            </>
          ) : (
            'document'
          )}{' '}
          has the following flag class.
        </span>
        <Box style={{ color: theme.palette.warning.main }}>
          Note: This condition is deprecated. Please use &quot;Has flag
          type&quot; instead.
        </Box>{' '}
      </>
    ),
    // DEPRECATED
    does_not_have_flag_type: (
      <>
        <span>
          {' '}
          if the{' '}
          {action.params.field_id_with_flag ? (
            <>
              <PrimaryTextWrapper>{fieldsMap[fieldId].name}</PrimaryTextWrapper>{' '}
              field{' '}
            </>
          ) : (
            'document'
          )}{' '}
          <b>does not</b> have the following flag class:
        </span>
        <Box style={{ color: theme.palette.warning.main }}>
          Note: This condition is deprecated. Please use &quot;Does not have
          flag type&quot; instead.
        </Box>{' '}
      </>
    ),
    has_specific_flag_type: (
      <span>
        {' '}
        if the{' '}
        {action.params.field_id_with_flag ? (
          <>
            <PrimaryTextWrapper>{fieldsMap[fieldId].name}</PrimaryTextWrapper>{' '}
            field
          </>
        ) : (
          'document'
        )}{' '}
        has the following flag type, regardless of whether it is a warning or an
        error:
      </span>
    ),
    does_not_have_specific_flag_type: (
      <span>
        {' '}
        if the{' '}
        {action.params.field_id_with_flag ? (
          <>
            <PrimaryTextWrapper>{fieldsMap[fieldId].name}</PrimaryTextWrapper>{' '}
            field
          </>
        ) : (
          'document'
        )}{' '}
        <b>does not</b> have the following flag type:
      </span>
    ),
    org_custom_variable_is: ` if the document's organization's custom variable matches the configured value:`,
    has_tag: ' if the document has the following tag:',
    does_not_have_specific_tag: (
      <span>
        {' '}
        if the document <b>does not</b> have the following tag:
      </span>
    ),
    has_any_tag: ' if the document has any tags.',
    has_no_tags: (
      <span>
        {' '}
        if the document <b>does not</b> have any tags.
      </span>
    ),
  }

  return (
    <>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
        Trigger the{' '}
        <PrimaryTextWrapper>{workflowStateEvent.name}</PrimaryTextWrapper> event
        {messageMap[condition]}
      </Typography>

      {/* // TODO: When we eventually switch this action to use actual flag types instead of relying on flag class,
    // make this show the actual flag. */}
      {(condition === 'has_flag_type' ||
        condition === 'does_not_have_flag_type') && (
        <Typography variant="body2">
          <b>Flag Class: </b>
          {action.params.flag_type_code}
        </Typography>
      )}

      {(condition === 'has_specific_flag_type' ||
        condition === 'does_not_have_specific_flag_type') && (
        <>
          <Typography variant="body2">
            <b>Flag Type{flagTypes.length > 1 ? 's' : ''}: </b>
          </Typography>
          {isLoadingFlagTypes ? (
            <Skeleton width={150} />
          ) : (
            flagTypes.map((flagType) => (
              <Typography key={flagType.id} variant="body2">
                {flagType?.short_description}{' '}
              </Typography>
            ))
          )}
        </>
      )}

      {condition === 'org_custom_variable_is' && (
        <>
          <Typography variant="body2">
            <b>Custom Variable: </b>
            {customVariable?.name}
          </Typography>
          <Typography variant="body2">
            <b>Value: </b>
            {action.params.org_custom_variable_value}
          </Typography>
        </>
      )}

      {(condition === 'has_tag' ||
        condition === 'does_not_have_specific_tag') && (
        <Stack spacing={1} mt={1}>
          {isLoadingProjectTags ? (
            <Skeleton variant="rounded" width={70} height={20} />
          ) : (
            projectTags.map((projectTag) => (
              <Box key={projectTag.id} sx={{ display: 'flex' }}>
                <TagChip tag={projectTag} />
              </Box>
            ))
          )}
        </Stack>
      )}
    </>
  )
}

import { useTheme } from '@mui/material'
import { Dispatch, ReactNode, SetStateAction } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

type FullscreenContainerProps = {
  isFullscreen: boolean
  setIsFullscreen: Dispatch<SetStateAction<boolean>>
  children: ReactNode
}

export default function FullscreenContainer({
  isFullscreen,
  setIsFullscreen,
  children,
}: FullscreenContainerProps) {
  const theme = useTheme()

  useHotkeys(
    'esc',
    () => {
      setIsFullscreen(false)
    },
    {
      enabled: isFullscreen,
    },
  )

  return (
    <div
      style={{
        position: isFullscreen ? 'fixed' : 'relative',
        top: 0,
        left: 0,
        height: isFullscreen ? '100vh' : '100%',
        width: isFullscreen ? '100vw' : '100%',
        background: theme.palette.background.paper,
        zIndex: isFullscreen ? 10001 : 0,
      }}
    >
      {children}
    </div>
  )
}

import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'
import { WorkflowStateType } from '@/types/workflows'

const service = buildService<WorkflowStateType>({
  pathCategory: 'workflow_state_types',
})

export function useGetWorkflowStateType({
  id,
  ...options
}: UseGetDetailOptions<WorkflowStateType>) {
  const query = useGetDetail<WorkflowStateType>({
    id,
    queryKey: queryKeys.workflowStateTypes.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    workflowStateType: query.data,
    ...query,
  }
}

export function useGetWorkflowStateTypes({
  filters,
  ...options
}: UseGetListOptions<WorkflowStateType> = {}) {
  const query = useGetList<WorkflowStateType>({
    filters,
    queryKey: queryKeys.workflowStateTypes.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    workflowStateTypes: query.allData,
    ...query,
  }
}

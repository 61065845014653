import ModelCard from './ModelCard'
import { Container, Stack, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { HelpOutlineOutlined } from '@mui/icons-material'
import { useGetProjectLinkedModels } from '@/service-library/hooks/project-linked-models'
import { useGetPubModels } from '@/service-library/hooks/pub-models'
import ProjectBreadcrumb from '../project-tables/ProjectBreadcrumb'
import { useProjectContext } from '../project-tables/ProjectProvider'
import NewModelCard from './NewModelCard'

export default function ModelLibrary() {
  const { project } = useProjectContext()
  const { projectLinkedModels, isLoading } = useGetProjectLinkedModels({
    filters: {
      project_id: project.id,
      fields__include: 'project_model,current_model_version,created_at',
      project_model__fields__include: 'project_model_type',
    },
  })

  const displayedProjectLinkedModels = projectLinkedModels
    .filter(
      (projectLinkedModel) =>
        projectLinkedModel.project_model?.project_model_type?.code !== 'OCR',
    )
    // Sort them by first added to last added
    .toSorted((a, b) =>
      new Date(a.created_at || '') > new Date(b.created_at || '') ? -1 : 0,
    )

  const modelIdsThatArePublished = displayedProjectLinkedModels
    .filter(({ project_model }) => project_model?.project_id !== project.id)
    .map(({ project_model_id }) => project_model_id)

  const { pubModels } = useGetPubModels({
    filters: {
      project_model_id__in: modelIdsThatArePublished.join(','),
      fields__only: 'id,name,project_model_id',
    },
    enabled: modelIdsThatArePublished.length > 0,
  })

  const projectModelNamesByLinkedModelId = displayedProjectLinkedModels.reduce<
    Record<string, string>
  >((acc, linkedModel) => {
    const modelName = modelIdsThatArePublished.includes(
      linkedModel.project_model_id,
    )
      ? pubModels.find(
          (model) => model.project_model_id === linkedModel.project_model_id,
        )?.name
      : linkedModel.project_model?.name

    acc[linkedModel.id] = modelName as string
    return acc
  }, {})

  return (
    <Container maxWidth="xl">
      <ProjectBreadcrumb label="Model Library" url="../model-library" />

      <Stack direction="column" spacing={2}>
        <Stack direction="row" alignItems="center" sx={{ pl: 1 }} spacing={1}>
          <Typography variant="h4">Model Library</Typography>
          <Tooltip title="The Model Library is where you manage what models are available to this project. Models are only available for use in your project if they are added here.">
            <HelpOutlineOutlined sx={{ color: 'primary.main' }} />
          </Tooltip>
        </Stack>

        <Grid container spacing={2}>
          {displayedProjectLinkedModels.map((projectLinkedModel) => (
            <Grid key={projectLinkedModel.id} size={{ xs: 12, md: 6, lg: 4 }}>
              <ModelCard
                key={projectLinkedModel.id}
                projectLinkedModel={projectLinkedModel}
                modelName={
                  projectModelNamesByLinkedModelId[projectLinkedModel.id]
                }
                modelType={
                  projectLinkedModel.project_model?.project_model_type
                    ?.name as string
                }
              />
            </Grid>
          ))}
          {!isLoading && (
            <Grid size={{ xs: 12, md: 6, lg: 4 }}>
              <NewModelCard projectLinkedModels={projectLinkedModels} />
            </Grid>
          )}
        </Grid>
      </Stack>
    </Container>
  )
}

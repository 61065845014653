import { MRT_VisibilityState } from 'material-react-table'
import { FormControlLabel, Checkbox } from '@mui/material'
import { OnFieldChange } from './AllFieldsFilter'

type MetadataFieldsFilterProps = {
  columnVisibility: MRT_VisibilityState
  onFieldChange: OnFieldChange
}

export type MetadataField = {
  id: string
  name: string
}

const metadataFields = [
  { id: 'workflowStateColId', name: 'Workflow State' },
  { id: 'organizationColId', name: 'Organization' },
  { id: 'tagColId', name: 'Tags' },
  { id: 'submitterColId', name: 'Submitter' },
  { id: 'pageCountColId', name: 'Page Count' },
  { id: 'uploadColId', name: 'Upload Date' },
  { id: 'enteredAtColId', name: 'State Duration' },
]

export default function MetadataFieldsFilter({
  columnVisibility,
  onFieldChange,
}: MetadataFieldsFilterProps) {
  return (
    <>
      {metadataFields.map(({ id, name }) => (
        <FormControlLabel
          key={id}
          label={name}
          control={
            <Checkbox
              sx={{ padding: 0 }}
              color="neutral"
              checked={!!columnVisibility[id]}
              onChange={({ target }) =>
                onFieldChange({ fieldId: id, checked: target.checked })
              }
            />
          }
          componentsProps={{ typography: { variant: 'body2' } }}
          sx={{ mx: '0 !important', columnGap: 0.5, width: 'fit-content' }}
        />
      ))}
    </>
  )
}

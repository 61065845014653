import { useEffect, useRef, useState } from 'react'
import { Badge, Box, ButtonBase, Stack, useTheme } from '@mui/material'
import { Document } from '@/types/documents'
import useOverlay from '@/hooks/useOverlay'
import FullscreenImageDialog from './FullscreenImageDialog'
import ImageCard from './ImageCard'
import useSplitDocuments from './useSplitDocuments'

type SplitDocumentPagesProps = {
  document: Document
  splitDocumentsData: ReturnType<typeof useSplitDocuments>
  isSplitView?: boolean
  useFullImage?: boolean
}

export const THUMBNAIL_TILE_WIDTH = 240
export const FULL_TILE_WIDTH = 620

function SplitUnderline({
  splitPoint,
  distanceToNextSplitPoint,
  pointIsSelected,
  useFullImage,
}: {
  splitPoint: number
  distanceToNextSplitPoint: number
  pointIsSelected: boolean
  useFullImage?: boolean
}) {
  const tileWidth = useFullImage ? FULL_TILE_WIDTH : THUMBNAIL_TILE_WIDTH
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 8,
        left: splitPoint * tileWidth + splitPoint * 20,
        width:
          distanceToNextSplitPoint * tileWidth +
          distanceToNextSplitPoint * 20 -
          20,
        height: 8,
        transition: 'all 0.1s ease-in-out',
        border: pointIsSelected ? '1px solid' : 0,
        borderTop: 'none',
      }}
    />
  )
}

export default function SplitDocumentPages({
  document,
  splitDocumentsData,
  isSplitView = false,
  useFullImage,
}: SplitDocumentPagesProps) {
  const theme = useTheme()
  const fullImageOverlay = useOverlay()
  const imagesContainerRef = useRef<HTMLDivElement>(null)
  const { pagesToDelete, splitPoints, setPagesToDelete, setSplitPoints } =
    splitDocumentsData
  const [fullImagePageIndex, setFullImagePageIndex] = useState<number | null>(
    null,
  )

  const pages = document.document_pages

  function onCloseFullImageDialog() {
    fullImageOverlay.close()
    if (fullImagePageIndex !== null) {
      if (isSplitView) {
        const childElement =
          imagesContainerRef.current?.children[fullImagePageIndex]
        childElement?.scrollIntoView({ inline: 'center' })
      }
      setFullImagePageIndex(null)
    }
  }

  useEffect(() => {
    if (imagesContainerRef.current) {
      imagesContainerRef.current.scrollLeft = 0
    }
    setFullImagePageIndex(null)
  }, [document.id])

  return (
    <>
      <Stack
        direction="row"
        sx={{
          position: 'relative',
          overflowX: 'auto',
          overflowY: 'visible',
          pb: 3.5,
        }}
        ref={imagesContainerRef}
      >
        {pages.map((page, index) => {
          const splitPoint = index + 1
          const splitPointIndex = splitPoints.findIndex(
            (point) => point === splitPoint,
          )

          const pointIsSelected = splitPointIndex !== -1

          const distanceToNextSplitPoint = pointIsSelected
            ? (splitPoints[splitPointIndex + 1] || pages.length) -
              splitPoints[splitPointIndex]
            : 0

          // Get width based on larger page dimension
          return (
            <Stack key={page.id} direction="row">
              <Badge
                key={page.id}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={page.page_number}
                color="primary"
                component="div"
                max={10000} // Just in case we ever have a document that has more than 99 (default) pages
                slotProps={{
                  badge: () => ({
                    sx: { transform: 'scale(1) translate(-20%, -20%)' }, // Override default badge transform position
                  }),
                }}
              >
                <ImageCard
                  page={page}
                  pagesToDelete={pagesToDelete}
                  setPagesToDelete={setPagesToDelete}
                  useFullImage={useFullImage}
                  onShowFullScreenImage={() => {
                    setFullImagePageIndex(index)
                    fullImageOverlay.open()
                  }}
                />
              </Badge>
              {index < pages.length - 1 && (
                <ButtonBase
                  sx={{
                    width: 20,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'divider',
                    },
                  }}
                  onClick={() => {
                    if (!pointIsSelected) {
                      setSplitPoints(
                        [...splitPoints, splitPoint].toSorted((a, b) => a - b),
                      )
                    } else {
                      setSplitPoints(
                        splitPoints.filter((p) => p !== splitPoint),
                      )
                    }
                  }}
                >
                  <Box
                    sx={{
                      width: 4,
                      height: '100%',
                      borderRadius: 4,
                      transition: 'all 0.1s ease-in-out',
                      background: pointIsSelected
                        ? theme.palette.secondary.main
                        : undefined,
                    }}
                  />
                </ButtonBase>
              )}
              {pointIsSelected && (
                <SplitUnderline
                  splitPoint={splitPoint}
                  distanceToNextSplitPoint={distanceToNextSplitPoint}
                  pointIsSelected={pointIsSelected}
                  useFullImage={useFullImage}
                />
              )}
            </Stack>
          )
        })}
        {splitPoints.length > 0 && (
          <SplitUnderline
            splitPoint={0}
            distanceToNextSplitPoint={splitPoints[0]}
            pointIsSelected
            useFullImage={useFullImage}
          />
        )}
      </Stack>
      <FullscreenImageDialog
        key={document.id}
        imagePageIndex={fullImagePageIndex}
        overlay={fullImageOverlay}
        pages={pages}
        onClose={onCloseFullImageDialog}
        setImagePageIndex={setFullImagePageIndex}
        showArrows={isSplitView}
      />
    </>
  )
}

import { useGetDocProjectTags } from '@/service-library/hooks/doc-project-tags'
import { ProjectTag } from '@/types/project-tags'
import { Typography } from '@mui/material'

type TagCountProps = {
  projectTag: ProjectTag
}

export default function TagCount({ projectTag }: TagCountProps) {
  const query = useGetDocProjectTags({
    paginator: 'offset',
    filters: {
      limit: '1',
      project_tag_id: projectTag.id,
      fields__only: 'id',
    },
  })
  const { data } = query
  return <Typography variant="body2">{data?.pages[0].count}</Typography>
}

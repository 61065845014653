import { Document } from '@/types/documents'
import {
  Alert,
  Box,
  Card,
  IconButton,
  List,
  ListItemButton,
  Stack,
} from '@mui/material'
import ScrollContainer from '../scroll-container/ScrollContainer'
import { useEffect, useRef, useState } from 'react'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { useGetDocuments } from '@/service-library/hooks/documents'
import { Link, useLocation } from 'react-router-dom'

type ChildDocumentsCardProps = {
  document: Document
  isSplitting: boolean
}

export default function ChildDocumentsCard({
  document,
  isSplitting,
}: ChildDocumentsCardProps) {
  const location = useLocation()
  const scrollContainerRef = useRef<HTMLElement>(null)
  const [collapsed, setCollapsed] = useState(false)

  // True by default so we check on initial render if there are child documents.
  const [initialCheck, setInitialCheck] = useState(true)
  const [waitingForChildDocs, setWaitingForChildDocs] = useState(false)

  const { documents: childDocuments, isFetched } = useGetDocuments({
    filters: {
      limit: '1000',
      parent_id: document.id,
      fields__only: 'id,name',
    },
    refetchInterval: 2000,
    enabled: waitingForChildDocs || initialCheck,
  })

  useEffect(() => {
    if (isFetched && initialCheck) {
      setInitialCheck(false)
    }
  }, [initialCheck, isFetched])

  useEffect(() => {
    if (isSplitting) {
      setWaitingForChildDocs(true)
    }
  }, [isSplitting])

  useEffect(() => {
    if (childDocuments.length > 0 && waitingForChildDocs) {
      setWaitingForChildDocs(false)
    }
  }, [childDocuments.length, waitingForChildDocs])

  if (childDocuments.length === 0) {
    return null
  }

  return (
    <Card
      sx={{ position: 'absolute', bottom: 8, right: 8, zIndex: 2 }}
      elevation={4}
      component={Stack}
    >
      <Box sx={{ p: 2, pb: collapsed ? 2 : 0 }}>
        <Alert
          variant="outlined"
          action={
            <IconButton
              size="small"
              color="success"
              onClick={() => {
                setCollapsed((prev) => !prev)
              }}
            >
              {collapsed ? (
                <KeyboardArrowUp fontSize="small" />
              ) : (
                <KeyboardArrowDown fontSize="small" />
              )}
            </IconButton>
          }
        >
          This document has been split.
        </Alert>
      </Box>
      <ScrollContainer
        sx={{ maxHeight: collapsed ? 0 : 280, position: 'relative' }}
        componentProps={{
          ref: scrollContainerRef,
        }}
      >
        <List dense>
          {childDocuments.map((doc) => (
            <ListItemButton
              key={doc.id}
              dense
              component={Link}
              to={`${location.pathname}?document_id=${doc.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {doc.name}
            </ListItemButton>
          ))}
        </List>
      </ScrollContainer>
    </Card>
  )
}

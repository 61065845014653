import { createContext, ReactNode, useContext, useMemo } from 'react'
import { QueryKey } from '@tanstack/react-query'
import { ProjectGridField } from '@/types/fields'
import { Project, ProjectGrid } from '@/types/projects'
import { getBaseGrid } from '@/utils/field-utils'

type ProjectProviderValue = {
  project: Project
  queryKey: QueryKey
  fieldsMap: Record<
    string,
    ProjectGridField & { parentField: ProjectGridField | null }
  >
  gridsMap: Record<string, ProjectGrid>
}

const ProjectContext = createContext<ProjectProviderValue>(
  {} as ProjectProviderValue,
)

export const useProjectContext = () => useContext(ProjectContext)

type ProjectProviderProps = {
  children: ReactNode
  project: Project
  queryKey: QueryKey
}

export default function ProjectProvider({
  children,
  project,
  queryKey,
}: ProjectProviderProps) {
  const baseGrid = getBaseGrid(project.project_grids || [])

  const gridsMap = useMemo(
    () =>
      project.project_grids?.reduce<Record<string, ProjectGrid>>(
        (acc, grid) => {
          acc[grid.id] = grid
          return acc
        },
        {},
      ) || {},
    [project.project_grids],
  )

  const fieldsMap = useMemo(() => {
    const fields =
      project.project_grids?.flatMap(
        ({ project_grid_fields }) => project_grid_fields,
      ) || []

    return fields.reduce<
      Record<
        string,
        ProjectGridField & { parentField: ProjectGridField | null }
      >
    >((acc, field) => {
      acc[field.id] = {
        ...field,
        parentField:
          field.project_grid_id === baseGrid?.id
            ? null
            : gridsMap[field.project_grid_id].sub_project_grid_fields[0],
      }
      return acc
    }, {})
  }, [baseGrid?.id, gridsMap, project.project_grids])

  return (
    <ProjectContext.Provider value={{ project, queryKey, fieldsMap, gridsMap }}>
      {children}
    </ProjectContext.Provider>
  )
}

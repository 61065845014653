import generateUuid from './generate-uuid'

export function createDocumentRowValuesForCache(
  rowId: string,
  fieldIds: string[],
) {
  return fieldIds.map((fieldId) => ({
    id: generateUuid(),
    original_value: null,
    rule_value: null,
    manual_value: null,
    final_value: null,
    project_grid_field_id: fieldId,
    document_row_id: rowId,
    rule_ref_id: null,
    final_ref_id: null,
    manual_ref_id: null,
    original_ref_id: null,
    rule_value_json: {},
    manual_value_json: {},
    final_value_json: {},
    project_content_category_item_id: null,
    project_content_category_item: null,
    row_value_flags: [],
    metadata: {},
    ref_id: null,
    in_cache: true,
  }))
}

export function focusOnField(rowValueId: string) {
  let cellToFocus: HTMLInputElement | HTMLDivElement | null =
    document.querySelector(`[data-focusid="${rowValueId}"] input`)

  if (cellToFocus) {
    if ('disabled' in cellToFocus && cellToFocus.disabled) {
      cellToFocus = cellToFocus.closest('div.focus-container') || cellToFocus
    }
    cellToFocus.focus()
  }
  return cellToFocus
}

import {
  Box,
  ListItemButton,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material'
import useRecentDocuments from './useRecentDocumentIds'
import { useGetDocuments } from '@/service-library/hooks/documents'
import { Document } from '@/types/documents'
import { useEffect } from 'react'

type RecentDocumentsListProps = {
  onClick: (docId: string) => void
}

export default function RecentDocumentsList({
  onClick,
}: RecentDocumentsListProps) {
  const [recentDocumentIds, setRecentDocumentIds] = useRecentDocuments()

  const { documents, isLoading, isFetched } = useGetDocuments({
    filters: {
      id__in: recentDocumentIds.join(','),
      fields__only: 'id,name',
    },
    enabled: recentDocumentIds.length > 0,
    keepPreviousData: true,
  })

  const sortedDocuments = recentDocumentIds
    .map((docId) => {
      return documents.find((d) => d.id === docId) as Document
    })
    .filter(Boolean)

  useEffect(() => {
    if (recentDocumentIds.length === 0 || !isFetched) return
    // Remove any documents that couldn't be found from recent document IDs
    const foundDocumentIds = sortedDocuments.map((doc) => doc.id)
    if (foundDocumentIds.length < recentDocumentIds.length) {
      setRecentDocumentIds(foundDocumentIds)
    }
  }, [isFetched, recentDocumentIds, setRecentDocumentIds, sortedDocuments])

  if (!recentDocumentIds.length) return null

  return (
    <div>
      <Typography variant="caption" color="text.secondary" sx={{ px: 2 }}>
        Recent Documents
      </Typography>

      {isLoading && (
        <Box sx={{ px: 2, pb: 2 }}>
          <Skeleton width={300} />
          <Skeleton width={250} />
          <Skeleton width={200} />
          <Skeleton width={225} />
          <Skeleton width={175} />
        </Box>
      )}

      {!isLoading &&
        sortedDocuments.map((doc) => {
          return (
            <Tooltip key={doc.id} title={doc.name} enterDelay={2000}>
              <ListItemButton onClick={() => onClick(doc.id)}>
                <Typography noWrap>{doc.name}</Typography>
              </ListItemButton>
            </Tooltip>
          )
        })}
    </div>
  )
}

export function notNull<T>(val: T | null): val is T {
  return val !== null
}

export function isValidUUID(value?: string) {
  if (!value) return false

  const uuidPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

  return uuidPattern.test(value)
}

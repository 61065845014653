import { Dispatch, Fragment, SetStateAction, useMemo, useRef } from 'react'
import { Check, Close, PlaylistRemove, Refresh } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  IconButton,
  Popper,
  Stack,
  Tooltip,
} from '@mui/material'
import { blue, pink } from '@mui/material/colors'
import { DocumentChip } from '@/types/documents'
import useLocalStorage from '@/hooks/useLocalStorage'
import { Position } from './image-zoom-pan-helpers'
import { selectionBoxColorMap } from './ImageZoomPan'

type SmartChipsSelectionProps = {
  chips: DocumentChip[]
  chipsIdsToIgnore: string[]
  breakpoints: Position[]
  imageIsBig: boolean
  rotationDegree: number
  smartSelectFrameBounds: {
    x: number
    y: number
    width: number
    height: number
  }
  onSave: () => void
  setBreakpoints: Dispatch<SetStateAction<Position[]>>
  setChipsIdsToIgnore: Dispatch<SetStateAction<string[]>>
}

export default function SmartChipsSelection({
  chips,
  chipsIdsToIgnore,
  breakpoints,
  imageIsBig,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  rotationDegree, // TODO: Figure out rotation
  smartSelectFrameBounds,
  onSave,
  setBreakpoints,
  setChipsIdsToIgnore,
}: SmartChipsSelectionProps) {
  const anchorElRef = useRef<HTMLDivElement>(null)
  const [lastBreakpointsUsed, setLastBreakpointsUsed] = useLocalStorage<
    Position[]
  >('smart-chips-selection-breakpoints', [])

  const validLastBreakpoints = useMemo(() => {
    const breakpointsYCoordinates = breakpoints.map(
      (breakpoint) => breakpoint.y,
    )
    return lastBreakpointsUsed.filter(
      (lastBreakpoint) =>
        !breakpointsYCoordinates.includes(lastBreakpoint.y) &&
        lastBreakpoint.y >= smartSelectFrameBounds.y &&
        lastBreakpoint.y <=
          smartSelectFrameBounds.y + smartSelectFrameBounds.height,
    )
  }, [
    breakpoints,
    lastBreakpointsUsed,
    smartSelectFrameBounds.height,
    smartSelectFrameBounds.y,
  ])

  const assignedChipsIdsNotAlreadyIgnored = useMemo(() => {
    return chips
      .filter(
        (chip) => chip.document_row_id && !chipsIdsToIgnore.includes(chip.id),
      )
      .map((chip) => chip.id)
  }, [chips, chipsIdsToIgnore])

  return (
    <Stack>
      <Box
        ref={anchorElRef}
        sx={{
          position: 'absolute',
          top: smartSelectFrameBounds.y,
          left: smartSelectFrameBounds.x,
          width: smartSelectFrameBounds.width,
          height: smartSelectFrameBounds.height,
          transition: 'all 0.1s ease-in-out',
          border: `5px solid ${selectionBoxColorMap['multi-selecting-enabling']}`,
          borderRadius: 2,
          pointerEvents: 'none',
        }}
      />
      {breakpoints.map((breakpoint) => {
        return (
          <Fragment key={`${breakpoint.x}-${breakpoint.y}`}>
            <Box
              sx={{
                position: 'absolute',
                top: breakpoint.y,
                left: smartSelectFrameBounds.x,
                width: smartSelectFrameBounds.width,
                height: 3,
                transition: 'all 0.1s ease-in-out',
                borderBottom: `5px solid ${pink['A400']}`,
                boxShadow: '0 0 16px #0007',
                pointerEvents: 'none',
              }}
            />

            <Button
              size="small"
              variant="contained"
              sx={{
                position: 'absolute',
                top: breakpoint.y - 10,
                left: smartSelectFrameBounds.x - 20,
                width: 20,
                minWidth: 0,
              }}
              onClick={() => {
                setBreakpoints((prev) =>
                  prev.filter((b) => b.y !== breakpoint.y),
                )
              }}
            >
              <Close fontSize="inherit" />
            </Button>
          </Fragment>
        )
      })}

      {anchorElRef.current && (
        <Popper
          disablePortal
          open
          anchorEl={anchorElRef.current}
          modifiers={[
            {
              name: 'flip',
              enabled: false,
            },
            {
              name: 'preventOverflow',
              enabled: false,
            },
          ]}
          placement="right-end"
          sx={{ p: 1 }}
        >
          <Stack spacing={1}>
            {assignedChipsIdsNotAlreadyIgnored.length > 0 && (
              <Tooltip title="Deselect Chips In Use" placement="right">
                <Card sx={{ borderRadius: '50%' }}>
                  <IconButton
                    size={imageIsBig ? 'large' : 'medium'}
                    onClick={() => {
                      setChipsIdsToIgnore((prev) => [
                        ...prev,
                        ...assignedChipsIdsNotAlreadyIgnored,
                      ])
                    }}
                  >
                    <PlaylistRemove fontSize="inherit" />
                  </IconButton>
                </Card>
              </Tooltip>
            )}
            {validLastBreakpoints.length > 0 && (
              <Tooltip title="Reuse Breakpoints" placement="right">
                <Card sx={{ borderRadius: '50%' }}>
                  <IconButton
                    size={imageIsBig ? 'large' : 'medium'}
                    onClick={() => {
                      setBreakpoints((prev) => [
                        ...prev,
                        ...validLastBreakpoints,
                      ])
                    }}
                  >
                    <Refresh fontSize="inherit" />
                  </IconButton>
                </Card>
              </Tooltip>
            )}
            <Tooltip title="Save" placement="right">
              <Card
                sx={{
                  borderRadius: '50%',
                  background: blue['A200'],
                }}
              >
                <IconButton
                  size={imageIsBig ? 'large' : 'medium'}
                  onClick={() => {
                    breakpoints.length && setLastBreakpointsUsed(breakpoints)
                    onSave()
                  }}
                >
                  <Check fontSize="inherit" />
                </IconButton>
              </Card>
            </Tooltip>
          </Stack>
        </Popper>
      )}
    </Stack>
  )
}

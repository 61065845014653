import { List } from '@mui/material'
import { Draggable } from 'react-smooth-dnd'
import getDataTypeIcon from '@/utils/get-data-type-icon'
import { DraggableList } from './DraggableList'
import MovableListItem from './MovableListItem'
import NestedMovableListItem from './NestedMovableListItem'
import { FieldTypeName } from '@/types/fields'

export type BaseDataType = {
  id: string
  name: string
  contextual_type?: string | null
  data_list_column_type?: {
    postgres_data_type: string
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields?: any[]
  project_grid_field_type?: {
    code: FieldTypeName
    postgres_data_type: string
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  project_grid_field_rules?: any[]
}

export type MovableListProps<DataType> = {
  data: DataType[]
  onChange: (items: DataType[]) => void
  onSelectionChanged: (item: DataType) => void
  selectedItem: DataType | null
  dense?: boolean
  disableCreation?: boolean
  gridField?: boolean
  onNestedListAdd?: (field: DataType) => void
}

const contextualTypeLabelMap = {
  category_axis: '(Category Axis)',
  value_axis: '(Value Axis)',
}

export default function MovableList<DataType extends BaseDataType>({
  data,
  onChange,
  onSelectionChanged,
  selectedItem,
  dense = false,
  disableCreation = false,
  gridField = true,
  onNestedListAdd,
}: MovableListProps<DataType>) {
  const itemClicked = (item: DataType) => {
    onSelectionChanged(item)
  }

  return (
    <List
      dense={dense}
      sx={{
        paddingTop: 0,
        paddingBottom: 0,
        overflow: 'auto',
        maxHeight: '100%',
      }}
    >
      <DraggableList data={data} onChange={onChange}>
        {data.map((field) => {
          if (
            gridField &&
            field.project_grid_field_type?.postgres_data_type === 'grid'
          ) {
            return (
              // @ts-expect-error -- Draggable can have children
              <Draggable key={field.id}>
                <NestedMovableListItem<DataType>
                  dense={dense}
                  name={field.name}
                  Icon={getDataTypeIcon(field.project_grid_field_type.code)}
                  handleName="drag-handle"
                  data={field?.fields || []}
                  key={field.id}
                  selected={selectedItem?.id === field.id}
                  selectedItem={selectedItem}
                  onSelectionChanged={onSelectionChanged}
                  onChange={onChange}
                  onClick={() => itemClicked(field)}
                  onAdd={() => onNestedListAdd?.(field)}
                  disableCreation={disableCreation}
                  field={field}
                  // rules={field.project_grid_field_rules?.length} TODO: Add back when rules for grids is ready
                />
              </Draggable>
            )
          }
          const Icon = getDataTypeIcon(
            (gridField
              ? field.project_grid_field_type?.code
              : field.data_list_column_type
                  ?.postgres_data_type) as FieldTypeName,
          )

          let fieldName = field.name
          if (field.contextual_type) {
            fieldName += ` ${
              contextualTypeLabelMap[
                field.contextual_type as keyof typeof contextualTypeLabelMap
              ]
            }`
          }

          return (
            // @ts-expect-error -- Draggable can have children
            <Draggable key={field.id}>
              <MovableListItem
                dense={dense}
                name={fieldName}
                Icon={Icon}
                key={field.id}
                selected={selectedItem?.id === field.id}
                onClick={() => itemClicked(field)}
                handleName="drag-handle"
              />
            </Draggable>
          )
        })}
      </DraggableList>
    </List>
  )
}

import { Container } from '@mui/material'
import ProjectBreadcrumb from '../project-tables/ProjectBreadcrumb'
import TagsList from './TagsList'

export default function TagsPage() {
  return (
    <Container maxWidth="xl">
      <ProjectBreadcrumb label="Document Tags" url="../tags" />

      <TagsList />
    </Container>
  )
}

import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { ProjectLinkedModelInfoType } from '@/types/project-linked-models'

const service = buildService<ProjectLinkedModelInfoType>({
  pathCategory: 'project_linked_model_info_types',
})

export function useGetProjectLinkedModelInfoType({
  id,
  ...options
}: UseGetDetailOptions<ProjectLinkedModelInfoType>) {
  const query = useGetDetail<ProjectLinkedModelInfoType>({
    id,
    queryKey: queryKeys.projectLinkedModelInfoTypes.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectLinkedModelInfoType: query.data,
    ...query,
  }
}

export function useCreateProjectLinkedModelInfoType(
  options: UseCreateDetailOptions<ProjectLinkedModelInfoType> = {},
) {
  const mutation = useCreateDetail<ProjectLinkedModelInfoType>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createProjectLinkedModelInfoType: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateProjectLinkedModelInfoType(
  options: UseUpdateDetailOptions<ProjectLinkedModelInfoType> = {},
) {
  const mutation = useUpdateDetail<ProjectLinkedModelInfoType>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateProjectLinkedModelInfoType: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectLinkedModelInfoType(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<ProjectLinkedModelInfoType>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteProjectLinkedModelInfoType: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetProjectLinkedModelInfoTypes({
  filters,
  ...options
}: UseGetListOptions<ProjectLinkedModelInfoType> = {}) {
  const query = useGetList<ProjectLinkedModelInfoType>({
    filters,
    queryKey: queryKeys.projectLinkedModelInfoTypes.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectLinkedModelInfoTypes: query.allData,
    ...query,
  }
}

export function useUpdateProjectLinkedModelInfoTypes(
  options: UseUpdateListOptions<ProjectLinkedModelInfoType> = {},
) {
  const mutation = useUpdateList<ProjectLinkedModelInfoType>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateProjectLinkedModelInfoTypes: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectLinkedModelInfoTypes({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<ProjectLinkedModelInfoType>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteProjectLinkedModelInfoTypes: mutation.mutateAsync,
    ...mutation,
  }
}

import { Typography } from '@mui/material'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import PrimaryTextWrapper from './PrimaryTextWrapper'

type SetFieldValueDisplayProps = {
  action: WorkflowStateAction
}

const operationMap = {
  add: 'Add',
  replace: 'Replace',
  clear: 'Clear',
}

export default function SetFieldValueDisplay({
  action,
}: SetFieldValueDisplayProps) {
  const { fieldsMap } = useProjectContext()
  const method = action.params.method as keyof typeof operationMap
  const fieldName = (
    <PrimaryTextWrapper>
      {fieldsMap[action.params.project_grid_field_id].name}
    </PrimaryTextWrapper>
  )

  const valueHasMultipleLines = action.params.value.includes('\n')
  const value = (
    <PrimaryTextWrapper
      sx={{
        whiteSpace: 'pre-wrap',
        display: valueHasMultipleLines ? 'block' : 'inline-block',
        ml: valueHasMultipleLines ? 2 : undefined,
      }}
    >
      {action.params.value}
    </PrimaryTextWrapper>
  )

  return (
    <Typography variant="body2" color="text.secondary">
      <PrimaryTextWrapper>{operationMap[method]}</PrimaryTextWrapper>{' '}
      {method === 'add' && (
        <span>
          &quot;{value}&quot; to the manual value in the {fieldName} field.
        </span>
      )}
      {method === 'replace' && (
        <span>
          the manual value in the {fieldName} field with &quot;{value}&quot;.
        </span>
      )}
      {method === 'clear' && (
        <span>the manual value from the {fieldName} field.</span>
      )}
    </Typography>
  )
}

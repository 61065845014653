import { Typography, Stack } from '@mui/material'
import { ReactNode } from 'react'

type LargeHeadingProps = {
  heading: ReactNode
  subHeading?: ReactNode
  actions?: ReactNode
}

export default function LargeHeading({
  heading,
  subHeading,
  actions,
}: LargeHeadingProps) {
  return (
    <div>
      {subHeading && (
        <Typography variant="subtitle2" color="text.secondary">
          {subHeading}
        </Typography>
      )}
      <Typography variant="h4">{heading}</Typography>

      {actions && (
        <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 1, ml: '-3px' }}>
          {actions}
        </Stack>
      )}
    </div>
  )
}

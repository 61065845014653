import { useEffect, useMemo, useState } from 'react'
import { ChevronRight, ExpandMore } from '@mui/icons-material'
import {
  Alert,
  Box,
  List,
  ListSubheader,
  Stack,
  ListItemButton,
} from '@mui/material'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { Organization } from '@/types/organizations'
import {
  createOrganizationMap,
  getFilteredOrgsNodesData,
  getNodesToExpand,
  getOrgName,
} from '@/utils/organization'
import OrganizationSearchInput from '@/components/organizations/OrganizationSearchInput'
import OrganizationTreeItem, {
  OrganizationTreeItemLabel,
} from '@/components/organizations-tree/OrganizationTreeItem'
import { useRecentOrganizationsContext } from '@/components/organizations/RecentOrganizationsProvider'

type OrganizationTreeSearchProps = {
  organizations: Organization[]
  rootTreeOrgId: string
  selectedOrgId: string
  selectedOrganization: Organization
  setSelectedOrgId: React.Dispatch<React.SetStateAction<string>>
  allNodeIsIncluded?: boolean
  disabledOrgIds?: string[]
  showRecentOrgs?: boolean
}

export default function OrganizationTreeSearch({
  organizations,
  rootTreeOrgId,
  selectedOrgId,
  selectedOrganization,
  setSelectedOrgId,
  allNodeIsIncluded,
  disabledOrgIds,
  showRecentOrgs = true,
}: OrganizationTreeSearchProps) {
  const [recentOrgs] = useRecentOrganizationsContext()
  const [inputValue, setInputValue] = useState('')

  const organizationMap = useMemo(
    () => createOrganizationMap(organizations),
    [organizations],
  )

  const defaultExpanded = useMemo(
    () =>
      selectedOrgId === rootTreeOrgId || selectedOrgId === 'multiple'
        ? [rootTreeOrgId]
        : getNodesToExpand(selectedOrgId, organizationMap),
    [selectedOrgId, organizationMap, rootTreeOrgId],
  )

  const [expanded, setExpanded] = useState(defaultExpanded)

  const { visibleOrgIds, nodesToExpand } = useMemo(
    () =>
      getFilteredOrgsNodesData({
        organizations,
        organizationMap,
        defaultExpanded,
        searchedValue: inputValue,
      }),

    [defaultExpanded, inputValue, organizationMap, organizations],
  )

  useEffect(() => {
    if (inputValue) {
      setExpanded(nodesToExpand)
    }
  }, [inputValue, nodesToExpand])

  return (
    <Stack direction="row" spacing={2} sx={{ minHeight: '300px' }}>
      <Stack sx={{ width: '300px' }} spacing={3}>
        {selectedOrgId && (
          <Box sx={{ pl: 1.75 }}>
            <OrganizationSearchInput
              autoFocus
              inputValue={inputValue}
              disableCloseOnSelect={false}
              organizations={organizations}
              selected={selectedOrganization}
              setSelected={(org) => {
                setSelectedOrgId(org.id)
                setExpanded(getNodesToExpand(org.id, organizationMap))
              }}
              setInputValue={setInputValue}
              getOptionDisabled={(option) =>
                !!disabledOrgIds?.includes(option.id)
              }
            />
          </Box>
        )}
        {showRecentOrgs && recentOrgs.length > 0 && (
          <List
            disablePadding
            subheader={
              <ListSubheader sx={{ lineHeight: 'initial', pb: 1 }}>
                Recent Organizations
              </ListSubheader>
            }
          >
            {recentOrgs.map((org) => (
              <ListItemButton
                key={org.id}
                disabled={!!disabledOrgIds?.includes(org.id)}
                onClick={() => {
                  setSelectedOrgId(org.id)
                  setExpanded(getNodesToExpand(org.id, organizationMap))
                }}
              >
                <OrganizationTreeItemLabel
                  code={org.code}
                  name={getOrgName(org)}
                />
              </ListItemButton>
            ))}
          </List>
        )}
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        {visibleOrgIds.length ? (
          <SimpleTreeView
            slots={{
              collapseIcon: ExpandMore,
              expandIcon: ChevronRight,
            }}
            expandedItems={
              !inputValue && !expanded.length ? [rootTreeOrgId] : expanded
            }
            selectedItems={selectedOrgId}
            onSelectedItemsChange={(_e, id) => {
              if (!id || disabledOrgIds?.includes(id)) return
              setSelectedOrgId(id)
            }}
            onExpandedItemsChange={(_e, ids) => {
              setExpanded(ids)
            }}
          >
            {allNodeIsIncluded && visibleOrgIds.includes('all') && (
              <TreeItem itemId="all" label="All Organizations" />
            )}
            <OrganizationTreeItem
              orgTree={organizationMap[rootTreeOrgId]}
              searchTerm={inputValue}
              getIsVisible={(id: string) => visibleOrgIds.includes(id)}
              getIsDisabled={
                disabledOrgIds
                  ? (id: string) => disabledOrgIds.includes(id)
                  : undefined
              }
            />
          </SimpleTreeView>
        ) : (
          <Alert severity="info">No results found.</Alert>
        )}
      </Box>
    </Stack>
  )
}

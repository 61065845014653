import {
  useCreateProjectLinkedModelInfoType,
  useUpdateProjectLinkedModelInfoType,
} from '@/service-library/hooks/project-linked-model-info-types'
import { Pause, PlayArrow } from '@mui/icons-material'
import { IconButton, useTheme } from '@mui/material'
import { useQueryKeyContext } from './QueryKeyProvider'
import {
  ProjectLinkedModel,
  ProjectLinkedModelInfoType,
} from '@/types/project-linked-models'
import generateUuid from '@/utils/generate-uuid'
import { ProjectModelVersionInfoType } from '@/types/project-models'
import { useReactFlow } from '@xyflow/react'
import { useFieldsContext } from './FieldsProvider'
import { ProjectGridField } from '@/types/fields'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import PaginatedResponse from '@/types/paginated-response'

type PlayPauseButtonProps = {
  nodeId: string
  projectLinkedModel: ProjectLinkedModel
  versionInfoType: ProjectModelVersionInfoType
  projectLinkedModelInfoType?: ProjectLinkedModelInfoType
}

export default function PlayPauseButton({
  nodeId,
  projectLinkedModel,
  versionInfoType,
  projectLinkedModelInfoType,
}: PlayPauseButtonProps) {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const pLMInfoTypesQueryKey = useQueryKeyContext()

  const fields = useFieldsContext()
  let field: ProjectGridField | undefined
  fields.forEach((f) => {
    if (f.info_type?.id === versionInfoType.info_type.id) {
      field = f
    } else if (f.fields) {
      f.fields.forEach((tableField) => {
        if (tableField.info_type?.id === versionInfoType.info_type.id) {
          field = tableField
        }
      })
    }
  })

  const { createProjectLinkedModelInfoType } =
    useCreateProjectLinkedModelInfoType({
      listQueryKey: pLMInfoTypesQueryKey,
    })

  const { updateProjectLinkedModelInfoType } =
    useUpdateProjectLinkedModelInfoType({
      listQueryKey: pLMInfoTypesQueryKey,
    })

  const reactFlowInstance = useReactFlow()

  function handlePlayPauseClick() {
    if (projectLinkedModelInfoType) {
      updateProjectLinkedModelInfoType({
        ...projectLinkedModelInfoType,
        use: !projectLinkedModelInfoType.use,
      })
    } else {
      createProjectLinkedModelInfoType({
        id: generateUuid(),
        project_linked_model_id: projectLinkedModel.id,
        info_type_id: versionInfoType.info_type.id,
        use: false,
      })
    }

    const newIsUsed = projectLinkedModelInfoType
      ? !projectLinkedModelInfoType.use
      : false
    reactFlowInstance.updateNode(nodeId, (node) => {
      if (node.type === 'infotype') {
        return {
          data: {
            ...node.data,
            projectLinkedModelInfoType: {
              ...projectLinkedModelInfoType,
              use: newIsUsed,
            },
          },
        }
      } else {
        const allPLMInfoTypes =
          queryClient.getQueryData<
            InfiniteData<PaginatedResponse<ProjectLinkedModelInfoType>>
          >(pLMInfoTypesQueryKey)
        const combined =
          allPLMInfoTypes?.pages.reduce<ProjectLinkedModelInfoType[]>(
            (acc, page) => {
              return [...acc, ...page.results]
            },
            [],
          ) || []
        return {
          data: {
            ...node.data,
            projectLinkedModelInfoTypes: combined.map((plmInfoType) => {
              if (plmInfoType.info_type_id === versionInfoType.info_type.id) {
                return {
                  ...plmInfoType,
                  use: newIsUsed,
                }
              }
              return plmInfoType
            }),
          },
        }
      }
    })

    if (!field) return

    const edgeId = `${versionInfoType.id}-${field.id}`
    reactFlowInstance.updateEdge(edgeId, {
      animated: newIsUsed,
      style: {
        stroke: newIsUsed ? undefined : theme.palette.divider,
        strokeWidth: newIsUsed ? undefined : 1,
        strokeDasharray: newIsUsed ? undefined : '5',
      },
    })
  }

  if (!field) return null

  // If .use is not defined, then it is used. We only disable it if explicitly set to false.
  const isUsed = projectLinkedModelInfoType?.use === false ? false : true

  return (
    <IconButton
      size="small"
      sx={{ p: 0, height: 16, width: 16 }}
      onClick={() => handlePlayPauseClick()}
    >
      {isUsed ? (
        <Pause sx={{ fontSize: 10 }} />
      ) : (
        <PlayArrow sx={{ fontSize: 10 }} />
      )}
    </IconButton>
  )
}

import { useEffect, useMemo, useRef, useState } from 'react'
import { MRT_ColumnDef } from 'material-react-table'
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { Add, Delete, Edit } from '@mui/icons-material'
import useOverlay from '@/hooks/useOverlay'
import { ImporterEmail } from '@/types/importer-emails'
import useSizeObserver from '@/hooks/useSizeObserver'
import {
  useDeleteImporterEmail,
  useGetImporterEmails,
} from '@/service-library/hooks/importer-emails'
import { showErrorSnackbar } from '@/utils/snackbars'
import { useOrganizationsContext } from '@/components/organizations/OrganizationsProvider'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import ZerapixTable from '@/components/zerapix-table/ZerapixTable'
import useZerapixTable from '@/components/zerapix-table/useZerapixTable'
import AddEditImporterEmailDialog from './AddEditImporterEmailDialog'
import DeleteImporterEmailDialog from './DeleteImporterEmailDialog'

const columns: MRT_ColumnDef<ImporterEmail>[] = [
  {
    header: 'Organization',
    Cell: ({ row }) => {
      const organization = row.original.org
      return (
        <Stack direction="row" spacing={1}>
          <Typography variant="body2">{organization?.name}</Typography>
          {!organization?.parent_org_id && (
            <Typography variant="body2" color="text.secondary">
              (Root)
            </Typography>
          )}
        </Stack>
      )
    },
  },
  {
    accessorKey: 'email',
    header: 'Email',
    size: 400,
    enableClickToCopy: true,
  },
]

export default function EmailSource() {
  const addEditEmailOverlay = useOverlay()
  const deleteEmailOverlay = useOverlay()
  const containerRef = useRef<HTMLDivElement>(null)
  const { height } = useSizeObserver({ containerRef })

  const { project } = useProjectContext()
  const {
    importerEmails = [],
    isLoading,
    queryKey,
  } = useGetImporterEmails({
    filters: { project_id: project.id, fields__include: 'org', limit: '1000' },
    enabled: !!project.id,
  })

  const {
    organizations,
    isLoading: organizationsIsLoading,
    isFetchingAll: isFetchingAllOrgs,
  } = useOrganizationsContext()

  const [importerEmail, setImporterEmail] = useState<ImporterEmail>()

  const { deleteImporterEmail } = useDeleteImporterEmail({
    listQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar('Unable to delete email. Please try again later.')
    },
  })

  const existingOrgIds = useMemo(
    () =>
      importerEmails.length > 0
        ? importerEmails.map((importerEmail) => importerEmail.org_id)
        : [],
    [importerEmails],
  )
  const disabledAddEmail = organizations.length <= existingOrgIds.length

  function handleOnDelete() {
    importerEmail && deleteImporterEmail(importerEmail.id)
    deleteEmailOverlay.close()
  }

  useEffect(() => {
    if (
      !addEditEmailOverlay.isOpen &&
      !deleteEmailOverlay.isOpen &&
      importerEmail
    )
      setImporterEmail(undefined)
  }, [addEditEmailOverlay.isOpen, deleteEmailOverlay.isOpen, importerEmail])

  const table = useZerapixTable<ImporterEmail>({
    title: (
      <Stack>
        <Typography variant="h6">Upload Email Addresses</Typography>
        <Typography variant="caption" color="textSecondary">
          Forward emails to this email address to automatically process email
          attachments.
        </Typography>
      </Stack>
    ),
    columns,
    data: importerEmails,
    initialState: {
      density: 'comfortable',
    },
    enableColumnResizing: true,
    enableRowActions: true,
    enableStickyHeader: true,
    localization: {
      noRecordsToDisplay: 'No emails have been added.',
    },
    muiTableBodyRowProps: {
      hover: false,
    },
    muiTableContainerProps: {
      sx: { maxHeight: height - 100 },
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => {
      return [
        <MenuItem
          key="edit"
          onClick={() => {
            addEditEmailOverlay.open()
            setImporterEmail(row.original)
            closeMenu()
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>,
        <MenuItem
          key="delete"
          onClick={() => {
            deleteEmailOverlay.open()
            setImporterEmail(row.original)
            closeMenu()
          }}
        >
          <ListItemIcon>
            <Delete color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: 'inherit', color: 'error' }}
          >
            Delete
          </ListItemText>
        </MenuItem>,
      ]
    },
    renderTableActions: () => (
      <Tooltip
        title={
          disabledAddEmail
            ? 'Emails have been added for all available organizations'
            : ''
        }
        enterDelay={1000}
      >
        <span>
          <Button
            disabled={organizationsIsLoading || disabledAddEmail}
            onClick={addEditEmailOverlay.open}
            startIcon={<Add />}
            variant="text"
          >
            New Email
          </Button>
        </span>
      </Tooltip>
    ),
    state: {
      showSkeletons: isLoading,
    },
  })

  return (
    <Box
      ref={containerRef}
      sx={{ height: '100%', overflow: 'auto', px: 2, pt: 2, minHeight: '0' }}
    >
      <ZerapixTable<ImporterEmail> table={table} />
      <AddEditImporterEmailDialog
        disabledOrgIds={existingOrgIds}
        importerEmail={importerEmail}
        isFetchingOrgs={isFetchingAllOrgs}
        listQueryKey={queryKey}
        organizations={organizations}
        overlay={addEditEmailOverlay}
        project={project}
      />
      {importerEmail && (
        <DeleteImporterEmailDialog
          importerEmail={importerEmail}
          overlay={deleteEmailOverlay}
          onDelete={handleOnDelete}
        />
      )}
    </Box>
  )
}

import { ReactNode, useEffect, useState } from 'react'
import { Button, ButtonBase, ListItemButton, Typography } from '@mui/material'
import { Organization } from '@/types/organizations'
import useOverlay from '@/hooks/useOverlay'
import { getOrgName } from '@/utils/organization'
import LabeledData from '@/components/labeled-data/LabeledData'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import OrganizationTreeSearch from '@/components/organizations-tree/OrganizationTreeSearch'
import OrganizationTreeSearchSkeleton from '@/components/organizations-tree/OrganizationTreeSearchSkeleton'
import OrganizationWithParent from './OrganizationWithParent'
import useOrganizationTypeName from './useOrganizationTypeName'
import { ArrowDropDown } from '@mui/icons-material'

type OrganizationPickerWithDialogProps = {
  isFetching: boolean
  currentOrganization: Organization
  organizations: Organization[]
  rootTreeOrgId: string
  onSave: (newOrganization: Organization) => void
  allNodeIsIncluded?: boolean
  dense?: boolean
  disabledOrgIds?: string[]
  label?: string
  title?: ReactNode
  showParentOrg?: boolean
  showRecentOrgs?: boolean
  variant?: 'standard' | 'outlined'
}

export default function OrganizationPickerWithDialog({
  isFetching,
  currentOrganization,
  organizations,
  rootTreeOrgId,
  onSave,
  allNodeIsIncluded,
  dense,
  disabledOrgIds,
  title = 'Choose Organization',
  showParentOrg = false,
  showRecentOrgs = true,
  variant = 'standard',
}: OrganizationPickerWithDialogProps) {
  const organizationSelectOverlay = useOverlay()
  const [selectedOrgId, setSelectedOrgId] = useState(currentOrganization.id)
  const selectedOrg = organizations.find(
    (org) => org.id === selectedOrgId,
  ) as Organization

  const onClose = () => {
    setSelectedOrgId(currentOrganization.id)
    organizationSelectOverlay.close()
  }

  useEffect(() => {
    setSelectedOrgId(currentOrganization.id)
  }, [currentOrganization.id])

  const organizationTypeName =
    useOrganizationTypeName({
      organization: currentOrganization,
    }) || 'Organization'

  return (
    <>
      {variant === 'outlined' ? (
        <ButtonBase
          onClick={organizationSelectOverlay.open}
          sx={{
            border: (theme) =>
              `1px solid ${
                theme.palette.mode === 'dark'
                  ? theme.palette.grey[700]
                  : theme.palette.grey[400]
              }`,
            borderRadius: '4px',
            pl: 1.5,
            pr: 1,
            height: 40,
            minWidth: 150,
            position: 'relative',
            justifyContent: 'space-between',
            alignItems: 'center',
            '&:hover': {
              borderColor: (theme) => theme.palette.neutral.main,
            },
          }}
        >
          <Typography
            component="span"
            variant="caption"
            color="text.secondary"
            sx={{
              position: 'absolute',
              top: -10,
              left: 7,
              background: (theme) => theme.palette.background.default,
              px: '5px',
              width: 'fit-content',
            }}
          >
            Organization
          </Typography>
          <Typography component="div" variant="body1">
            {currentOrganization.name}
          </Typography>
          <ArrowDropDown />
        </ButtonBase>
      ) : (
        <ListItemButton
          sx={{
            px: 1,
            pb: 0.5,
            pt: 1,
            borderRadius: 2,
          }}
          dense
          onClick={organizationSelectOverlay.open}
        >
          {showParentOrg && currentOrganization.parent_org_id ? (
            <OrganizationWithParent
              organization={currentOrganization}
              organizations={organizations}
              dense={dense}
            />
          ) : (
            <LabeledData
              label={organizationTypeName}
              data={getOrgName(currentOrganization)}
              dense={dense}
            />
          )}
        </ListItemButton>
      )}

      {organizationSelectOverlay.isOpen && (
        <Dialog
          title={title}
          {...organizationSelectOverlay}
          onClose={onClose}
          maxWidth="md"
        >
          <DialogContent>
            {isFetching ? (
              <OrganizationTreeSearchSkeleton />
            ) : (
              <OrganizationTreeSearch
                allNodeIsIncluded={allNodeIsIncluded}
                disabledOrgIds={disabledOrgIds}
                organizations={organizations}
                rootTreeOrgId={rootTreeOrgId}
                selectedOrgId={selectedOrgId}
                selectedOrganization={selectedOrg}
                setSelectedOrgId={setSelectedOrgId}
                showRecentOrgs={showRecentOrgs}
              />
            )}
          </DialogContent>
          <DialogFooter>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={selectedOrgId === currentOrganization.id}
              onClick={() => {
                organizationSelectOverlay.close()
                onSave(selectedOrg)
              }}
            >
              Save
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  )
}

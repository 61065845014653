import useLocalStorage from '@/hooks/useLocalStorage'
import { EntrataVendor } from '@/types/entrata-vendor'
import {
  Box,
  Button,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import Grid2 from '@mui/material/Grid2'
import { Fragment } from 'react'
import { useProjectGridFieldsContext } from './ProjectGridFieldsProvider'
import useFieldValue from './useFieldValue'
import EntrataLiveVendorList from './fields/EntrataLiveVendorList'
import { ProjectGridField } from '@/types/fields'
import { LiveListEntity } from '@/types/live-lists'
import { History } from '@mui/icons-material'

const minimalVendorValuesMap: [string, keyof EntrataVendor][] = [
  ['Vendor Name', 'vendor_name'],
  ['Vendor Location', 'location_name'],
  ['Default GL Account Number', 'default_gl_account_number'],
  ['Default GL Account Name', 'default_gl_account_name'],
  ['Default Routing Tag', 'default_routing_tag'],
  ['Address Line 1', 'address_line_1'],
  ['City', 'city'],
  ['State', 'state'],
  ['Postal Code', 'postal_code'],
  ['Country', 'country'],
]

const vendorValuesMap: [string, keyof EntrataVendor][] = [
  ['Vendor Name', 'display_name'],
  ['Vendor ID', 'vendor_id'],
  ['Vendor Location', 'location_name'],
  ['Vendor Location ID', 'location_id'],
  ['Default GL Account Number', 'default_gl_account_number'],
  ['Default GL Account Name', 'default_gl_account_name'],
  ['Default Routing Tag ID', 'default_routing_tag_id'],
  ['Default Routing Tag', 'default_routing_tag'],
  ['Status', 'vendor_status'],
  ['Capture Invoice Total Only', 'is_capture_invoice_total_only'],
  ['Require PO for Invoice', 'require_po_for_invoice'],
  ['Payment Term ID', 'payment_term_id'],
  ['Payment Term', 'payment_term'],
  ['Default Remittance ID', 'default_remittance_id'],
  ['Default Remittance', 'default_remittance'],
  ['Default Remittance Type', 'default_remittance_type'],
  ['Address Line 1', 'address_line_1'],
  ['Address Line 2', 'address_line_2'],
  ['City', 'city'],
  ['State', 'state'],
  ['Postal Code', 'postal_code'],
  ['Country', 'country'],
]

export default function EntrataVendorCard() {
  const [minimalDetails, setMinimalDetails] = useLocalStorage(
    'entrata-vendor-view-show-more',
    true,
  )

  const fields = useProjectGridFieldsContext()
  const invoiceVendorField = fields.find(
    (field) => field.name === 'Invoice Vendor',
  ) as ProjectGridField
  const { jsonValue, onManualChange } = useFieldValue({
    field: invoiceVendorField,
  })

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1}>
        <EntrataLiveVendorList
          jsonValue={jsonValue as LiveListEntity<EntrataVendor>}
          onManualChange={onManualChange}
          openOnFocus
        />

        {jsonValue && (
          <Box sx={{ flexShrink: 0, py: 0.5 }}>
            <Tooltip
              title={
                invoiceVendorField.input_behavior === 'manual_only'
                  ? 'Clear'
                  : 'Revert to System Results'
              }
            >
              <IconButton
                size="small"
                tabIndex={-1}
                onClick={() => {
                  onManualChange(null)
                }}
              >
                <History fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Stack>
      <Grid2 container>
        {(minimalDetails ? minimalVendorValuesMap : vendorValuesMap).map(
          ([label, key]) => {
            let displayedValue = jsonValue?.entity[key]
            if (typeof displayedValue === 'boolean') {
              displayedValue = displayedValue ? 'True' : 'False'
            }
            return (
              <Fragment key={key}>
                <Grid2 size={6}>
                  <Typography variant="body2" color="text.secondary">
                    {label}:
                  </Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography
                    variant="body2"
                    color={displayedValue ? undefined : 'text.secondary'}
                  >
                    {invoiceVendorField && (displayedValue || 'None')}
                    {!invoiceVendorField && <Skeleton width={100} />}
                  </Typography>
                </Grid2>
              </Fragment>
            )
          },
        )}
      </Grid2>
      <Box>
        <Button
          variant="text"
          onClick={() => setMinimalDetails((prev) => !prev)}
          sx={{ fontSize: 12, ml: '-5px' }}
        >
          Show {minimalDetails ? 'More' : 'Less'}
        </Button>
      </Box>
    </Stack>
  )
}

import { ProjectGridField } from '@/types/fields'
import { Stack, Card, Box, Button, Typography } from '@mui/material'
import DeleteFieldDialog from '../project-settings/DeleteFieldDialog'
import { useDeleteProjectGridFieldsWithDefaults } from '@/service-library/hooks/project-grid-fields'
import { showErrorSnackbar } from '@/utils/snackbars'
import useOverlay from '@/hooks/useOverlay'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import PaginatedResponse from '@/types/paginated-response'
import { ProjectGrid } from '@/types/projects'
import { useProjectGridsContext } from '../project-settings/ProjectGridsProvider'
import SavingIndicator from '../SavingIndicator'
import { useFormContext } from 'react-hook-form'
import queryKeys from '@/service-library/query-keys'

const nameMap: Record<string, string> = {
  name: 'Name',
  needed: 'Required',
  input_behavior: 'Manual Values',
  data_list_column_names: 'List Columns',
  default_prediction_context_columns: 'Default Prediction Context Columns',
  csv_header_name: 'CSV Header Name',
  aggregate_function: 'Show Aggregate',
  list_url: 'List URL',
}

type FieldSettingsFooterProps = {
  field: ProjectGridField
  isSaving: boolean
}

const columnsHaveChanged = (colNames: string[], newColNames: string[]) => {
  return (
    colNames.length !== newColNames.length ||
    JSON.stringify(colNames) !== JSON.stringify(newColNames)
  ) // We care about the order of the columns
}

export default function FieldSettingsFooter({
  field,
  isSaving,
}: FieldSettingsFooterProps) {
  const deleteOverlay = useOverlay()

  const { queryKey } = useProjectGridsContext()
  const queryClient = useQueryClient()
  const { deleteProjectGridFields } = useDeleteProjectGridFieldsWithDefaults({
    sideEffectQueryKeys: [queryKey, queryKeys.projects.details()],
    onMutate: ([fieldId]) => {
      queryClient.setQueryData<InfiniteData<PaginatedResponse<ProjectGrid>>>(
        queryKey,
        (oldData) => {
          const updatedPages = oldData?.pages.map((page) => {
            return {
              ...page,
              results: page.results.map((grid) => {
                return {
                  ...grid,
                  project_grid_fields: grid.project_grid_fields.filter(
                    (gridField) => {
                      return gridField.id !== fieldId
                    },
                  ),
                }
              }),
            }
          })
          return {
            pageParams: oldData?.pageParams || [],
            pages: updatedPages || [],
          }
        },
      )
    },
    onError: () => {
      showErrorSnackbar('Failed to delete field. Please try again later.')
    },
  })

  function onDeleteField(field: ProjectGridField) {
    deleteProjectGridFields([field.id])
    deleteOverlay.close()
  }

  const methods = useFormContext()
  const { reset, formState, watch } = methods

  const names = new Set(Object.keys(formState.dirtyFields))

  if (names.has('params')) {
    names.delete('params')
    ;[
      'data_list_column_names',
      'default_prediction_context_columns',
      'list_url',
    ].forEach((key) => {
      const defaultValues = formState.defaultValues?.params?.[key] || []
      const currentValues = watch(`params.${key}`, [])

      if (columnsHaveChanged(defaultValues, currentValues)) {
        names.add(key)
      } else if (names.has(key)) {
        names.delete(key)
      }
    })
  }
  const dirtyFieldNames = Array.from(names)

  return (
    <Stack
      component={Card}
      sx={{
        position: 'sticky',
        bottom: 0,
        px: 2,
        py: 1,
        zIndex: 1,
        width: '100%',
        flexShrink: 0,
      }}
      direction="row"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Button
          variant="text"
          color="error"
          onClick={() => {
            deleteOverlay.open()
          }}
          disabled={!field.user_can_delete}
        >
          Delete
        </Button>

        {formState.isDirty && (
          <Typography variant="caption" color="secondary">
            Changes drafted for{' '}
            {dirtyFieldNames
              // Get the mapped name
              .map((name) => nameMap[name] || '')
              // Filter out any that don't have a mapped name
              .filter((name) => {
                if (!name) {
                  // eslint-disable-next-line no-console
                  console.error(
                    'No mapped name for drafted field. You may need to add a drafted field name to the nameMap for:',
                    name,
                  )
                }
                return !!name
              })
              .join(', ')}
          </Typography>
        )}

        <SavingIndicator saving={isSaving} />
      </Stack>

      <Box sx={{ flexGrow: 1 }}></Box>
      <Stack direction="row" spacing={2}>
        <Button
          onClick={() => {
            reset()
          }}
          variant="text"
          disabled={!formState.isDirty}
        >
          Reset
        </Button>
        <Button
          type="submit"
          disabled={!formState.isDirty || !formState.isValid}
        >
          Save
        </Button>
      </Stack>

      <DeleteFieldDialog
        name={field.name.trim()}
        onDelete={() => onDeleteField(field)}
        overlay={deleteOverlay}
      />
    </Stack>
  )
}

import { Fragment } from 'react'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { Typography } from '@mui/material'
import PrimaryTextWrapper from './PrimaryTextWrapper'

type ClearChipsFromPageDisplayProps = {
  action: WorkflowStateAction
}

export default function ClearChipsFromPageDisplay({
  action,
}: ClearChipsFromPageDisplayProps) {
  const stringsToMatch = action.params.strings_to_match as Record<
    'value',
    string
  >[]
  return (
    <Typography variant="body2" color="text.secondary">
      If the page contains
      {stringsToMatch.map(({ value }, index) => {
        return (
          <Fragment key={index}>
            {' '}
            {!!index && index === stringsToMatch.length - 1 && 'and '}
            <PrimaryTextWrapper>{value}</PrimaryTextWrapper>
            {!!index && stringsToMatch.length > 2 && ','}
          </Fragment>
        )
      })}{' '}
      any chips on the page assigned to fields become unassigned.
    </Typography>
  )
}

import { WorkflowStateAction } from '@/types/workflow-state-actions'
import ListColumnSelect from './ListColumnSelect'
import { Stack, Tooltip, Typography } from '@mui/material'
import TargetFieldSelect from './TargetFieldSelect'
import { useProjectContext } from '../project-tables/ProjectProvider'
import { useGetProjectGridFields } from '@/service-library/hooks/project-grid-fields'
import { useFormContext } from 'react-hook-form'
import { InfoOutlined } from '@mui/icons-material'
import PropertyField from '../project-settings/params-schema-property-fields/PropertyField'

type AddressComponentMatchFieldsProps = {
  action: WorkflowStateAction
}

export default function SetOrgByListMatchFields({
  action,
}: AddressComponentMatchFieldsProps) {
  const { project } = useProjectContext()
  const { projectGridFields = [] } = useGetProjectGridFields({
    filters: {
      limit: '1000',
      project_id: project.id,
      fields__only: 'id,name,project_grid_field_type',
      project_grid_field_type__fields__only: 'id,code',
    },
  })
  const { watch } = useFormContext()

  const targetFieldName = watch('target_field_name')

  const { project_grid_field_type } =
    projectGridFields.find(({ name }) => name === targetFieldName) || {}
  const isAddressField = project_grid_field_type?.code === 'address'

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionType = action.action_type as Record<string, any>
  return (
    <>
      <Stack spacing={2} sx={{ mt: 1 }}>
        <Typography variant="caption">{actionType.description}</Typography>
        {/* Data List Picker */}
        <PropertyField
          propertyId="data_list_id"
          property={actionType.params_schema.properties.data_list_id}
          required
          variant="outlined"
        />
        <ListColumnSelect required paramName="data_list_column_name" />
        <TargetFieldSelect required projectGridFields={projectGridFields} />

        {/* Flag Level Picker */}
        <PropertyField
          propertyId="flag_level"
          property={actionType.params_schema.properties.flag_level}
          required
          variant="outlined"
        />

        {isAddressField && (
          <Stack spacing={1.5}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Address Component Match</Typography>
              <Tooltip
                title="If the selected list is set up with each part of the
                address as a separate column, you can map the columns to the
                appropriate fields below. Each field is optional. Each column
                set below must successfully match for the address match to be
                successful."
              >
                <InfoOutlined />
              </Tooltip>
            </Stack>
            <ListColumnSelect
              paramName="address_street_column_name"
              label="Street Column"
              includeNoneOption
            />
            <ListColumnSelect
              paramName="address_city_column_name"
              label="City Column"
              includeNoneOption
            />
            <ListColumnSelect
              paramName="address_state_column_name"
              label="State Column"
              includeNoneOption
            />
            <ListColumnSelect
              paramName="address_zip_column_name"
              label="Zip Column"
              includeNoneOption
            />
            <ListColumnSelect
              paramName="address_secondary_number_column_name"
              label="Secondary Number Column"
              includeNoneOption
            />
            <ListColumnSelect
              paramName="address_secondary_designator_column_name"
              label="Secondary Designator Column"
              includeNoneOption
            />
          </Stack>
        )}
      </Stack>
    </>
  )
}

import { createContext, ReactNode, useContext, useMemo } from 'react'
import { DocumentRowValue } from '@/types/documents'
import { RowValueFlag } from '@/types/flags'
import { useGetDocumentRowValues } from '@/service-library/hooks/document-row-values'
import { getFlagsCount } from '@/utils/flags-utils'
import { FlagsCount } from '@/components/flags/useFlagPriorityColor'
import { useDocumentCurrentStateViewContext } from '@/components/image-zoom-pan/providers/DocumentCurrentStateViewProvider'

type DocumentRowValuesContextValue = {
  getFieldDocumentRowValue: (
    fieldId: string,
    documentRowId: string | null,
  ) => DocumentRowValue | undefined
  fieldIdentifierRowValueMap: Record<string, DocumentRowValue>
  fieldsWithFlags: string[]
  fieldKeysWithFlagsCount: Record<string, FlagsCount>
  rowValueFlags: RowValueFlag[]
} & ReturnType<typeof useGetDocumentRowValues>

type DocumentRowValuesProviderProps = {
  children: ReactNode
  query: ReturnType<typeof useGetDocumentRowValues>
}

const DocumentRowValuesContext = createContext<DocumentRowValuesContextValue>(
  {} as DocumentRowValuesContextValue,
)

export const useDocumentRowValuesContext = () =>
  useContext(DocumentRowValuesContext)

export default function DocumentRowValuesProvider({
  query,
  children,
}: DocumentRowValuesProviderProps) {
  const { documentCurrentStateView } = useDocumentCurrentStateViewContext()

  const { documentRowValues, queryKey, ...rest } = query

  const getFieldDocumentRowValue = (
    fieldId: string,
    documentRowId: string | null,
  ) => {
    if (!documentRowId) return undefined
    return documentRowValues.find(
      (documentRowValue) =>
        documentRowValue.document_row_id === documentRowId &&
        documentRowValue.project_grid_field_id === fieldId,
    )
  }

  const rowValueFlags = useMemo(
    () =>
      documentRowValues.flatMap(({ row_value_flags }) => row_value_flags || []),
    [documentRowValues],
  )

  const fieldIdentifierRowValueMap = useMemo(
    () =>
      documentRowValues.reduce<Record<string, DocumentRowValue>>(
        (acc, rowValue) => {
          const { document_row_id, project_grid_field_id } = rowValue
          acc[`${project_grid_field_id}_${document_row_id}`] = rowValue
          return acc
        },
        {},
      ),
    [documentRowValues],
  )

  const { fieldsWithFlags, fieldKeysWithFlagsCount } = useMemo(() => {
    if (documentCurrentStateView !== 'validation')
      return { fieldsWithFlags: [], fieldKeysWithFlagsCount: {} }
    const tempFieldsWithFlags: string[] = []
    const tempFieldKeysWithFlagsCount: Record<string, FlagsCount> = {}
    documentRowValues.forEach(
      ({ document_row_id, row_value_flags, project_grid_field_id }) => {
        if (row_value_flags?.length) {
          const fieldKey = `${project_grid_field_id}_${document_row_id}`
          tempFieldKeysWithFlagsCount[fieldKey] = {
            rowsFlags: getFlagsCount(row_value_flags),
          }
          !tempFieldsWithFlags.includes(project_grid_field_id) &&
            tempFieldsWithFlags.push(project_grid_field_id)
        }
      },
    )
    return {
      fieldsWithFlags: tempFieldsWithFlags,
      fieldKeysWithFlagsCount: tempFieldKeysWithFlagsCount,
    }
  }, [documentCurrentStateView, documentRowValues])

  return (
    <DocumentRowValuesContext.Provider
      value={{
        queryKey,
        documentRowValues,
        rowValueFlags,
        fieldIdentifierRowValueMap,
        fieldsWithFlags,
        fieldKeysWithFlagsCount,
        getFieldDocumentRowValue,
        ...rest,
      }}
    >
      {children}
    </DocumentRowValuesContext.Provider>
  )
}

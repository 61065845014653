import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '../dialog'
import { Button, MenuItem, Stack } from '@mui/material'
import { ProjectModel, ProjectModelType } from '@/types/project-models'
import { useCreateProjectModelWithDefaults } from '@/service-library/hooks/project-models'
import generateUuid from '@/utils/generate-uuid'
import { useEffect } from 'react'
import { useProjectContext } from '../project-tables/ProjectProvider'
import queryKeys from '@/service-library/query-keys'
import { useGetDataLists } from '@/service-library/hooks/data-lists'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'
import { FormTextField, PixyDocsForm } from '../forms'
import { useForm } from 'react-hook-form'

type AddCustomModelDialogProps = {
  overlay: OverlayState
  modelType: ProjectModelType | null
}

export default function AddCustomModelDialog({
  overlay,
  modelType,
}: AddCustomModelDialogProps) {
  const { project } = useProjectContext()
  const { rootOrganization } = useRootOrganization()

  const isCategoryModel = modelType?.code === 'Category'

  const { dataLists } = useGetDataLists({
    filters: {
      limit: '1000',
      org_id: rootOrganization.id,
    },
    enabled: isCategoryModel,
  })

  const { createProjectModelWithDefaults } = useCreateProjectModelWithDefaults({
    sideEffectQueryKeys: [queryKeys.projectLinkedModels.all],
    onSuccess: () => {
      overlay.close()
    },
  })

  const methods = useForm({
    defaultValues: {
      name: '',
      data_list_id: '',
    },
  })

  function onSubmit(values: { name: string; data_list_id: string }) {
    createProjectModelWithDefaults({
      id: generateUuid(),
      project_id: project.id,
      target_accuracy: '0.90',
      name: values.name,
      project_model_type_id: modelType?.id || '',
      is_content_category_whole_document: false, // FIXME -- How do we get this if the field isn't attached yet?
      is_content_category_rows: false, // FIXME -- Don't category models need the list?
      data_list_id: values.data_list_id || null,
    } as ProjectModel)
    overlay.close()
  }

  useEffect(() => {
    if (!overlay.isOpen) methods.reset()
  }, [methods, overlay.isOpen])

  return (
    <Dialog
      {...overlay}
      title={`Create Custom ${modelType?.name} Model`}
      maxWidth="xs"
    >
      <PixyDocsForm methods={methods} onSubmit={onSubmit}>
        <DialogContent>
          <Stack spacing={2}>
            <FormTextField name="name" label="Name" autoFocus required />

            {isCategoryModel && (
              <FormTextField
                select
                name="data_list_id"
                id="demo-simple-select"
                label="List"
                required
              >
                {dataLists.map((list) => (
                  <MenuItem key={list.id} value={list.id}>
                    {list.name}
                  </MenuItem>
                ))}
              </FormTextField>
            )}
          </Stack>
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button type="submit">Create</Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}

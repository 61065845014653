import { useGetDocumentRowValues } from '@/service-library/hooks/document-row-values'
import { useGetDocumentRows } from '@/service-library/hooks/document-rows'
import { isValidUUID } from '@/utils/typescript-utils'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import useGetAllDocumentChips from './useGetAllDocumentChips'
import useGetValidationDocument from './useGetValidationDocument'

type UseGetValidationDocumentOptions = {
  documentId?: string
  onlyBasicData?: boolean
  enabled?: boolean
}
export default function useGetValidationDocDataQueries({
  documentId,
  onlyBasicData = false,
  enabled,
}: UseGetValidationDocumentOptions) {
  const { gridsMap } = useProjectContext()

  const documentQuery = useGetValidationDocument({
    id: documentId || '',
    onlyBasicData,
    enabled,
    retry: (failureCount, error) => {
      if (error?.response?.status === 404) return false
      return failureCount < 3
    },
  })

  const documentChipsQuery = useGetAllDocumentChips({
    filters: {
      limit: '1000',
      document_id: documentId,
      chip_set_id: documentQuery.document?.used_chip_set_id || undefined,
      fields__include: 'related_chips_data',
    },
    enabled: !!documentQuery.document?.used_chip_set_id, // if there is not an assigned chip set, we don't need to fetch chips
  })

  const documentRowsQuery = useGetDocumentRows({
    filters: {
      limit: ((Object.keys(gridsMap).length - 1) * 2500 + 1).toString(),
      document_id: documentId,
    },
    enabled: isValidUUID(documentId) && enabled, // basic validation to not trigger unnecessary calls
  })

  const documentRowValuesQuery = useGetDocumentRowValues({
    filters: {
      document_row__document_id: documentId,
      limit: '10000',
      ...(onlyBasicData
        ? {}
        : {
            fields__include: 'row_value_flags',
            row_value_flags__fields__include: 'flag_type',
          }),
    },
    enabled: isValidUUID(documentId) && enabled, // basic validation to not trigger unnecessary calls
  })

  return {
    documentQuery,
    documentChipsQuery,
    documentRowsQuery,
    documentRowValuesQuery,
  }
}

import useLocalStorage from '@/hooks/useLocalStorageUIDotDev'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'

export default function useRecentDocumentIds() {
  const { rootOrganization } = useRootOrganization()

  return useLocalStorage<string[]>(
    `recent-documents-${rootOrganization.id}`,
    [] as string[],
  )
}

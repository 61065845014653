import { MenuItem, Stack, TextField } from '@mui/material'
import { DateRange } from './ProjectMetricsDashboard'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import DatePicker from '../data-visualization/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { getYesterday } from './helpers'

type DateRangeFilterProps = {
  selectedDateRange: DateRange
  setSelectedDateRange: Dispatch<SetStateAction<DateRange>>
}

export default function DateRangeFilter({
  selectedDateRange,
  setSelectedDateRange,
}: DateRangeFilterProps) {
  const [value, setValue] = useState('2weeks')

  useEffect(() => {
    setSelectedDateRange(() => {
      const startDate = getYesterday()
      const endDate = getYesterday()

      switch (value) {
        case '1day':
          break
        case '2days':
          startDate.setDate(startDate.getDate() - 1)
          break
        case '3days':
          startDate.setDate(startDate.getDate() - 2)
          break
        case '1week':
          startDate.setDate(startDate.getDate() - 6)
          break
        case '2weeks':
          startDate.setDate(startDate.getDate() - 13)
          break
        case '1month':
          startDate.setMonth(startDate.getMonth() - 1)
          break
        default:
          break
      }

      return {
        startDate,
        endDate,
      }
    })
  }, [setSelectedDateRange, value])

  return (
    <Stack direction="row" spacing={1}>
      <TextField
        value={value}
        select
        label="Date Range"
        onChange={(e) => setValue(e.target.value)}
        size="small"
        variant="outlined"
        sx={{ height: 40 }}
      >
        <MenuItem value="1day">Yesterday</MenuItem>
        <MenuItem value="2days">2 days</MenuItem>
        <MenuItem value="3days">3 days</MenuItem>
        <MenuItem value="1week">1 Week</MenuItem>
        <MenuItem value="2weeks">2 Weeks</MenuItem>
        <MenuItem value="1month">1 Month</MenuItem>
        <MenuItem value="custom">Custom</MenuItem>
      </TextField>

      {value === 'custom' && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start"
            slotProps={{
              textField: {
                variant: 'outlined',
              },
            }}
            onChange={(date) => {
              setSelectedDateRange((prev) => {
                return {
                  ...prev,
                  startDate: new Date(date),
                }
              })
            }}
            value={selectedDateRange.startDate}
            maxDate={selectedDateRange.endDate}
            sx={{
              width: 160,
            }}
          />
          <DatePicker
            label="End"
            slotProps={{
              textField: {
                variant: 'outlined',
              },
            }}
            onChange={(date) => {
              setSelectedDateRange((prev) => {
                return {
                  ...prev,
                  endDate: new Date(date),
                }
              })
            }}
            value={selectedDateRange.endDate}
            minDate={selectedDateRange.startDate}
            maxDate={getYesterday()}
            sx={{
              width: 160,
            }}
          />
        </LocalizationProvider>
      )}
    </Stack>
  )
}

import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Stack, Typography } from '@mui/material'
import { WorkflowState } from '@/types/workflows'
import useLocationHelpers from '@/hooks/useLocationHelpers'
import queryKeys from '@/service-library/query-keys'
import {
  useGetWorkflowStates,
  useUpdateWorkflowStates,
} from '@/service-library/hooks/workflow-states'
import ProjectBreadcrumb from '@/components/project-tables/ProjectBreadcrumb'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import WorkflowStatePage from '@/components/workflow-state-page/WorkflowStatePage'
import WorkflowStatesList from './WorkflowStatesList'
import { useGetWorkflowStateTypes } from '@/service-library/hooks/workflow-state-types'
import { sortBySortOrder } from '@/utils/field-utils'
import { DropResult } from 'react-smooth-dnd'
import { getWorkflowStatesByType } from './helpers'

export default function WorkflowStatesPage() {
  const { id } = useParams()
  const navigate = useNavigate()

  const { selectedWorkflow } = useSelectedWorkflowContext()
  const { appendCurrentParamsToUrl } = useLocationHelpers()

  const [isDragging, setIsDragging] = useState(false)

  const { workflowStates: savedWorkflowStates, queryKey } =
    useGetWorkflowStates({
      filters: {
        limit: '1000',
        workflow_id: selectedWorkflow.id,
        fields__include: 'excluded_project_grid_fields_ids,workflow_state_type', // including since we will use it as initialData
      },
      staleTime: 0, // Prevent firing off this request since we have initialData
      initialListData: selectedWorkflow.workflow_states,
    })

  const { workflowStateTypes } = useGetWorkflowStateTypes()
  const sortedWorkflowStateTypes = sortBySortOrder(workflowStateTypes)

  const displayableWorkflowStates = useMemo(() => {
    return (
      savedWorkflowStates
        ?.filter(({ code }) => code !== 'processing')
        .toSorted((a, b) => a.sort_order - b.sort_order) || []
    )
  }, [savedWorkflowStates])

  const [statesByType, setStatesByType] = useState<
    Record<string, WorkflowState[]>
  >({})

  useEffect(() => {
    setStatesByType(getWorkflowStatesByType(displayableWorkflowStates))
  }, [displayableWorkflowStates])

  const selectedState = displayableWorkflowStates.find(
    (state) => state.id === id,
  )

  const { updateWorkflowStates } = useUpdateWorkflowStates({
    listQueryKey: queryKey,
    sideEffectQueryKeys: [queryKeys.workflows.all],
    useSortOrder: true,
  })

  const readyForUpdateRef = useRef(false)
  const onDrop = (
    { removedIndex, addedIndex, payload }: DropResult,
    code = 'unassigned',
  ) => {
    if (removedIndex === null && addedIndex === null) return
    const { workflowState } = payload
    const statesForCode = [...(statesByType[code] || [])]

    if (removedIndex !== null) {
      statesForCode.splice(removedIndex, 1)
    }
    if (addedIndex !== null) {
      const workflowStateType = workflowStateTypes.find(
        (type) => type.code === code,
      )
      statesForCode.splice(addedIndex, 0, {
        ...workflowState,
        workflow_state_type: workflowStateType,
        workflow_state_type_id: workflowStateType?.id || null,
      })
    }

    readyForUpdateRef.current = true
    setStatesByType((prev) => ({
      ...prev,
      [code]: statesForCode,
    }))
  }

  useEffect(() => {
    if (readyForUpdateRef.current) {
      let updatedStateList = sortedWorkflowStateTypes.reduce<WorkflowState[]>(
        (acc, type) => {
          return [...acc, ...(statesByType[type.code] || [])]
        },
        [...(statesByType.unassigned || [])],
      )
      updatedStateList = updatedStateList.map((state, index) => ({
        ...state,
        sort_order: index,
      }))
      updateWorkflowStates(updatedStateList)
    }
  }, [statesByType, sortedWorkflowStateTypes, updateWorkflowStates])

  useEffect(() => {
    if (!id && displayableWorkflowStates[0]?.id)
      navigate(appendCurrentParamsToUrl(displayableWorkflowStates[0]?.id))
  }, [appendCurrentParamsToUrl, displayableWorkflowStates, id, navigate])

  return (
    <>
      <ProjectBreadcrumb label="Workflow States" url="../../workflow-states" />
      <Stack
        direction="row"
        spacing={6}
        sx={{ m: -2, mt: -3, overflow: 'auto', mr: 2 }}
      >
        <Stack
          spacing={1}
          flexShrink={0}
          sx={{
            width: '320px',
            minWidth: 0,
            overflowY: 'auto',
            px: 2,
            pb: 2,
            pt: 3,
          }}
        >
          <Typography variant="h5">{selectedWorkflow.name}</Typography>

          <Box>
            {statesByType.unassigned?.length > 0 && (
              <WorkflowStatesList
                workflowStates={statesByType.unassigned}
                onDrop={onDrop}
                isDragging={isDragging}
                setIsDragging={setIsDragging}
                listQueryKey={queryKey}
              />
            )}
            {sortedWorkflowStateTypes.map((type) => (
              <WorkflowStatesList
                key={type.id}
                workflowStates={statesByType[type.code]}
                workflowStateType={type}
                onDrop={(dropResult) => onDrop(dropResult, type.code)}
                isDragging={isDragging}
                setIsDragging={setIsDragging}
                listQueryKey={queryKey}
              />
            ))}
          </Box>
        </Stack>

        {id && (
          <Box sx={{ overflowY: 'auto', pr: 6, pb: 2, pt: 3, flexGrow: 1 }}>
            <WorkflowStatePage
              workflowStateId={id}
              workflowState={selectedState}
              listQueryKey={queryKey}
            />
          </Box>
        )}
      </Stack>
    </>
  )
}

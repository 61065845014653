import { useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'
import { Add } from '@mui/icons-material'
import { Stack, Button, Theme } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { defaultOption } from './AddEditObjectNodeDialog'
import PropertyDataTypeValue from './PropertyDataTypeValue'
import generateUuid from '@/utils/generate-uuid'

type PropertiesTableProps = {
  name: string
  isInSubTable?: boolean
}

export default function PropertiesTable({
  name,
  isInSubTable,
}: PropertiesTableProps) {
  const { fields, append, remove } = useFieldArray({
    name,
  })

  const sxProps = isInSubTable
    ? {
        width: '100%',
        borderLeft: (theme: Theme) => `1px solid ${theme.palette.divider}`,
        pl: 1,
        ml: 1,
        mr: 0,
      }
    : {}

  useEffect(() => {
    if (!fields.length)
      append(
        { ...defaultOption, id: generateUuid() },
        {
          shouldFocus: false,
        },
      )
  }, [fields, append])

  return (
    <Stack
      spacing={2}
      mx={2}
      sx={{
        ...sxProps,
      }}
    >
      <Grid container alignItems="center" rowSpacing={1.5}>
        {!isInSubTable && (
          <>
            <Grid size={3} sx={{ textAlign: 'center' }}>
              Data Type
            </Grid>
            <Grid size={7} sx={{ textAlign: 'center' }}>
              Name
            </Grid>
            <Grid size={1} sx={{ textAlign: 'center' }}>
              List of Items
            </Grid>
            <Grid size={1} />
          </>
        )}

        {fields.map((item, index) => {
          return (
            <PropertyDataTypeValue
              key={item.id}
              name={name}
              index={index}
              disableDelete={fields.length === 1}
              onDelete={() => remove(index)}
              isInSubTable={isInSubTable}
            />
          )
        })}
      </Grid>
      <Button
        onClick={() => {
          append(
            { ...defaultOption, id: generateUuid() },
            {
              shouldFocus: false,
            },
          )
        }}
        startIcon={<Add />}
        sx={{ width: 'fit-content' }}
        variant="text"
      >
        Add Property
      </Button>
    </Stack>
  )
}

import { Container, Draggable, OnDropCallback } from 'react-smooth-dnd'
import { WorkflowState, WorkflowStateType } from '@/types/workflows'
import {
  Box,
  IconButton,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import { Add, DragIndicator } from '@mui/icons-material'
import useLocationHelpers from '@/hooks/useLocationHelpers'
import { Link, useParams } from 'react-router-dom'
import { Dispatch, SetStateAction } from 'react'
import useOverlay from '@/hooks/useOverlay'
import AddEditWorkflowStateDialog from './AddEditWorkflowStateDialog'
import { useSelectedWorkflowContext } from '../workflows/SelectedWorkflowProvider'
import { QueryKey } from '@tanstack/react-query'

type WorkflowStatesListProps = {
  onDrop: OnDropCallback
  isDragging: boolean
  setIsDragging: Dispatch<SetStateAction<boolean>>
  workflowStates?: WorkflowState[]
  workflowStateType?: WorkflowStateType
  listQueryKey?: QueryKey
}

export default function WorkflowStatesList({
  onDrop,
  isDragging,
  setIsDragging,
  workflowStates = [],
  workflowStateType,
  listQueryKey,
}: WorkflowStatesListProps) {
  const theme = useTheme()
  const { id } = useParams()
  const newWorkflowStateOverlay = useOverlay()
  const getThemeColor = useGetThemeColor()
  const { appendCurrentParamsToUrl } = useLocationHelpers()
  const { selectedWorkflow } = useSelectedWorkflowContext()

  return (
    <Box
      sx={{
        mb: 1,
      }}
    >
      {workflowStateType?.name && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ display: 'block' }}
          >
            {workflowStateType.name}
          </Typography>

          <IconButton
            size="small"
            color="primary"
            onClick={newWorkflowStateOverlay.open}
            sx={{ transform: 'translateX(2px)' }}
          >
            <Add fontSize="small" />
          </IconButton>
          <AddEditWorkflowStateDialog
            overlay={newWorkflowStateOverlay}
            workflowId={selectedWorkflow.id}
            workflowStateType={workflowStateType}
            listQueryKey={listQueryKey}
          />
        </Stack>
      )}
      {/* @ts-expect-error -- Container can have children */}
      <Container
        dragHandleSelector=".drag-handle"
        onDrop={onDrop}
        lockAxis="y"
        groupName="workflow-states"
        getGhostParent={() => document.body} // https://github.com/kutlugsahin/react-smooth-dnd/issues/56#issuecomment-545904390
        getChildPayload={(index) => {
          return {
            workflowState: workflowStates[index],
          }
        }}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={() => setIsDragging(false)}
        style={{
          border:
            isDragging && workflowStates.length === 0
              ? `dashed 1px ${theme.palette.divider}`
              : `dashed 1px transparent`,
          transition: 'border-color 0.2s',
          borderRadius: '4px',
        }}
      >
        {!isDragging && workflowStates.length === 0 && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ opacity: 0.5, lineHeight: '32px' }}
          >
            <i>None</i>
          </Typography>
        )}

        {workflowStates.map((state) => {
          return (
            // @ts-expect-error -- Draggable can have children
            <Draggable key={state.id}>
              <Stack
                spacing={0.5}
                key={state.id}
                direction="row"
                alignItems="center"
              >
                <Box
                  sx={{
                    background: getThemeColor(state.color),
                    width: 4,
                    height: 24,
                    borderRadius: 4,
                  }}
                />
                <ListItemButton
                  selected={state.id === id}
                  component={Link}
                  to={`../${appendCurrentParamsToUrl(state.id)}`}
                  sx={{
                    borderRadius: 2,
                    py: 0,
                    px: 1,
                  }}
                >
                  <ListItemText
                    sx={{ flexGrow: 1 }}
                    primary={state.name}
                    primaryTypographyProps={{
                      noWrap: true,
                    }}
                  />
                </ListItemButton>

                <DragIndicator
                  className="drag-handle"
                  sx={{
                    cursor: 'grab',
                    color: 'text.secondary',
                    ':active': { cursor: 'grabbing' },
                  }}
                />
              </Stack>
            </Draggable>
          )
        })}
      </Container>
    </Box>
  )
}

import { Dispatch, memo, SetStateAction, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useQueryClient, UseQueryResult } from '@tanstack/react-query'
import useGetDocumentWorkflowAndState from '@/hooks/useGetDocumentWorkflowAndState'
import { OverlayState } from '@/hooks/useOverlay'
import useStatefulSearchParam from '@/hooks/useStatefulSearchParam'
import queryKeys from '@/service-library/query-keys'
import useGetValidationDocDataQueries from '@/services/hooks/useGetValidationDocDataQueries'
import { Dialog } from '@/components/dialog'
import DrawerPortalProvider from '@/components/drawer/DrawerPortalProvider'
import DocumentCurrentStateViewProvider, {
  useDocumentCurrentStateViewContext,
} from '@/components/image-zoom-pan/providers/DocumentCurrentStateViewProvider'
import DocumentProvider from '@/components/image-zoom-pan/providers/DocumentProvider'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import QueryKeysMapProvider from '@/components/providers/QueryKeyProvider'
import DisabledDocumentsProvider from '@/components/validation/providers/DisabledDocumentsProvider'
import DocumentsChangeSetsProvider from '@/components/validation/providers/DocumentsChangeSetsProvider'
import DocumentChipsProvider from '@/components/validation/providers/DocumentChipsProvider'
import DocumentRowValuesProvider from '@/components/validation/providers/DocumentRowValuesProvider'
import DocumentRowsProvider from '@/components/validation/providers/DocumentRowsProvider'
import FieldErrorProvider from '@/components/validation/providers/FieldErrorProvider'
import SelectedFieldProvider from '@/components/validation/providers/SelectedFieldProvider'
import { useSelectedWorkflowStateContext } from '@/components/workflows/SelectedWorkflowStateProvider'
import ValidationOverlayContent from './ValidationOverlayContent'

const hotKeyConfig = {
  preventDefault: true,
  enableOnFormTags: true,
}

type CommonProps = {
  hasPreviousDocument: boolean
  hasNextDocument: boolean
  selectedIndex: number
  goToPreviousDocument: () => void
  goToNextAvailableDocumentOrClose: () => void
  refetchTableData: (options?: {
    throwOnError: boolean
    cancelRefetch: boolean
  }) => Promise<UseQueryResult>
  setUseOnlySelected: Dispatch<SetStateAction<boolean>>
  documentsCount?: number
}

type ValidationOverlayProps = CommonProps & {
  overlay: OverlayState
  documentIdState: ReturnType<typeof useStatefulSearchParam>
  nextDocumentId?: string
}

export type ValidationOverlayContentContainerProps = CommonProps & {
  dataQueries: ReturnType<typeof useGetValidationDocDataQueries>
  overlay: OverlayState
  documentIsInFlight: boolean
  resetValue: (replace?: boolean) => void
  documentId?: string
}

function ValidationOverlayContentContainer({
  documentId,
  ...props
}: ValidationOverlayContentContainerProps) {
  const {
    dataQueries,
    overlay,
    documentIsInFlight,
    hasNextDocument,
    goToPreviousDocument,
    goToNextAvailableDocumentOrClose,
  } = props
  const { documentCurrentStateView } = useDocumentCurrentStateViewContext()

  useHotkeys(
    'alt+1',
    () => {
      if (!documentIsInFlight) {
        goToPreviousDocument()
      }
    },
    {
      ...hotKeyConfig,
      enabled: overlay.isOpen,
    },
    [documentIsInFlight, goToPreviousDocument],
  )

  useHotkeys(
    'alt+2',
    () => {
      if (!documentIsInFlight && hasNextDocument) {
        goToNextAvailableDocumentOrClose()
      }
    },
    {
      ...hotKeyConfig,
      enabled: overlay.isOpen,
    },
    [documentIsInFlight, hasNextDocument, goToNextAvailableDocumentOrClose],
  )

  return (
    <DocumentProvider
      query={dataQueries.documentQuery}
      isDisabled={documentCurrentStateView === 'comparison'}
    >
      <DocumentRowsProvider query={dataQueries.documentRowsQuery}>
        <DocumentRowValuesProvider query={dataQueries.documentRowValuesQuery}>
          <FieldErrorProvider>
            <DocumentChipsProvider
              documentId={documentId}
              query={dataQueries.documentChipsQuery}
            >
              {/* This helps preventing the field panel from showing up outside the overlay.*/}
              <DrawerPortalProvider>
                <SelectedFieldProvider>
                  <ValidationOverlayContent
                    documentView={documentCurrentStateView}
                    {...props}
                  />
                </SelectedFieldProvider>
              </DrawerPortalProvider>
            </DocumentChipsProvider>
          </FieldErrorProvider>
        </DocumentRowValuesProvider>
      </DocumentRowsProvider>
    </DocumentProvider>
  )
}

const ValidationOverlay = ({
  overlay,
  documentIdState,
  refetchTableData,
  setUseOnlySelected,
  nextDocumentId,
  ...props
}: ValidationOverlayProps) => {
  const { project } = useProjectContext()
  const navigate = useNavigate()

  const { selectedWorkflowState } = useSelectedWorkflowStateContext()

  const { value: documentId, resetValue } = documentIdState

  const dataQueries = useGetValidationDocDataQueries({
    documentId,
  })

  const document = dataQueries.documentQuery.document

  const { project_id, workflow_states_ids } = document || {}

  const needToUpdateUrl = useMemo(
    () =>
      !!document?.id &&
      (project_id !== project.id ||
        !workflow_states_ids?.includes(selectedWorkflowState?.id || '')),
    [
      document?.id,
      project_id,
      project.id,
      workflow_states_ids,
      selectedWorkflowState?.id,
    ],
  )

  const { workflowId, workflowStateId } = useGetDocumentWorkflowAndState({
    document,
    enabled: needToUpdateUrl,
    useCurrentProject: project_id === project.id,
  })

  const documentIsInFlight =
    document && selectedWorkflowState
      ? !!document.document_workflow_states?.find(
          ({ workflow_state_id }) =>
            workflow_state_id === selectedWorkflowState.id,
        )?.processing_started_at
      : true

  // This pre-fetches the next document and puts it into cache so it'll load immediately when the user switches to it
  const { documentQuery: nextDocumentQuery } = useGetValidationDocDataQueries({
    documentId: nextDocumentId || '',
    enabled: !documentIsInFlight && !!nextDocumentId,
  })

  const { document: nextDocument } = nextDocumentQuery

  const queryClient = useQueryClient()
  useNotifications({
    keys: [
      'pdWorkflow/events.event_complete',
      'pdWorkflow/events.event_failed',
    ],
    callback: ({ updated_entity_ids }) => {
      if (documentId && updated_entity_ids.includes(documentId)) {
        queryClient.invalidateQueries(
          { queryKey: queryKeys.documents.all },
          { cancelRefetch: false },
        )
        queryClient.invalidateQueries(
          { queryKey: queryKeys.documentRows.all },
          { cancelRefetch: false },
        )
        queryClient.invalidateQueries(
          { queryKey: queryKeys.documentRowValues.all },
          { cancelRefetch: false },
        )
        queryClient.invalidateQueries(
          { queryKey: queryKeys.documentChips.all },
          { cancelRefetch: false },
        )
      }
    },
  })

  // This pre-fetches the next document's image so it will load from cache
  useEffect(() => {
    if (!nextDocument || !overlay.isOpen) return
    const img = new Image()
    img.src = nextDocument?.document_pages?.[0]?.image_urls.full
  }, [nextDocument, overlay.isOpen])

  useEffect(() => {
    if (!overlay.isOpen && documentId) {
      overlay.open()
    }
  }, [documentId, overlay, overlay.isOpen, resetValue])

  // If the project ID or the workflow state of document doesn't match the ones in the url, update the url.
  useEffect(() => {
    if (needToUpdateUrl && workflowId && workflowStateId) {
      navigate(
        `/projects/${project_id}/documents?workflow=${workflowId}&workflow_state=${workflowStateId}&document_id=${documentId}`,
        {
          replace: true,
        },
      )
    }
  }, [
    documentId,
    navigate,
    needToUpdateUrl,
    project.id,
    project_id,
    document,
    selectedWorkflowState?.id,
    workflowId,
    workflowStateId,
    workflow_states_ids,
  ])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryKeysMapProvider>
        <DocumentsChangeSetsProvider>
          <DisabledDocumentsProvider>
            <DocumentCurrentStateViewProvider document={document}>
              <Dialog
                {...overlay}
                fullScreen
                onClose={() => {
                  // clear doc param when closing overlay
                  resetValue(false)
                  refetchTableData()
                  setUseOnlySelected(false)
                }}
              >
                <ValidationOverlayContentContainer
                  documentId={documentId}
                  overlay={overlay}
                  dataQueries={dataQueries}
                  documentIsInFlight={documentIsInFlight}
                  refetchTableData={refetchTableData}
                  resetValue={resetValue}
                  setUseOnlySelected={setUseOnlySelected}
                  {...props}
                />
              </Dialog>
            </DocumentCurrentStateViewProvider>
          </DisabledDocumentsProvider>
        </DocumentsChangeSetsProvider>
      </QueryKeysMapProvider>
    </LocalizationProvider>
  )
}

export default memo(ValidationOverlay)

import { useMemo } from 'react'
import { Style } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import useLocalStorage from '@/hooks/useLocalStorage'
import useOverlay from '@/hooks/useOverlay'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import { useSelectedWorkflowStateContext } from '@/components/workflows/SelectedWorkflowStateProvider'

export type OptionsKeyType = keyof typeof options

const options: Record<string, string> = {
  vendorSelect: 'Vendor Select',
  propertySelect: 'Property Select',
  fields: 'Fields',
}

export const ENTRATA_ORG_ID = '018b8be7-6182-4beb-24a1-4819669451c7'

export function useGetVisibleCardsState() {
  const { rootOrganization } = useRootOrganization()
  const { selectedWorkflowState } = useSelectedWorkflowStateContext()

  const defaultVisibleCards = useMemo(() => {
    const visibleCards: OptionsKeyType[] = ['fields']
    if (rootOrganization?.id === ENTRATA_ORG_ID) {
      if (selectedWorkflowState.name === 'Property Match') {
        visibleCards.unshift('propertySelect')
      } else if (selectedWorkflowState.name === 'Vendor Match') {
        visibleCards.unshift('vendorSelect')
      }
    }
    return visibleCards
  }, [rootOrganization?.id, selectedWorkflowState.name])

  return useLocalStorage<OptionsKeyType[]>(
    `validation-visible-cards-${selectedWorkflowState.id}`,
    defaultVisibleCards,
  )
}

export default function VisibleCardsControl() {
  const { rootOrganization } = useRootOrganization()
  const overlay = useOverlay()

  const [visibleCards, setVisibleCards] = useGetVisibleCardsState()

  if (!rootOrganization || rootOrganization.id !== ENTRATA_ORG_ID) {
    return null
  }

  return (
    <>
      <IconButton size="small" onClick={overlay.open}>
        <Style />
      </IconButton>
      {overlay.anchorEl && (
        <Menu
          open={overlay.isOpen}
          onClose={overlay.close}
          anchorEl={overlay.anchorEl as HTMLButtonElement}
          elevation={16}
        >
          {Object.entries(options).map(([key, value]) => {
            return (
              <MenuItem
                dense
                key={key}
                onClick={() => {
                  setVisibleCards((prev) => {
                    if (prev.includes(key as OptionsKeyType)) {
                      return prev.filter((item) => item !== key)
                    }
                    return [...prev, key] as OptionsKeyType[]
                  })
                }}
                selected={visibleCards.includes(key as OptionsKeyType)}
              >
                {value}
              </MenuItem>
            )
          })}
        </Menu>
      )}
    </>
  )
}

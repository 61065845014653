import { Card, Stack } from '@mui/material'
import { ReactNode } from 'react'
import MetricHeading from './MetricHeading'
import SizeProvider from '../size-provider/SizeProvider'

type ChartCardProps = {
  children: ReactNode
  heading: string
  headingTooltip?: string
}

export default function ChartCard({
  children,
  heading,
  headingTooltip,
}: ChartCardProps) {
  return (
    <SizeProvider>
      <Card elevation={4} sx={{ p: 2 }}>
        <Stack spacing={1}>
          <MetricHeading heading={heading} tooltipMessage={headingTooltip} />
          {children}
        </Stack>
      </Card>
    </SizeProvider>
  )
}

import { useSelectedWorkflowContext } from '../workflows/SelectedWorkflowProvider'
import ProjectDashboard from '../project-dashboard/ProjectDashboard'
import ModelDashboard from '../model-page/ModelDashboard'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import { Navigate } from 'react-router-dom'

export default function DashboardRouting() {
  const featureFlags = useFeatureFlagContext()
  const { selectedWorkflow } = useSelectedWorkflowContext()

  if (selectedWorkflow.project_model_id) {
    return <ModelDashboard />
  }
  if (featureFlags.project_dashboard) {
    return <ProjectDashboard />
  }

  return <Navigate to="../documents" />
}

import { ProjectGridField } from '@/types/fields'
import {
  ProjectDocumentRuleType,
  ProjectGridFieldRuleType,
} from '@/types/rules'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import { FormCheckbox } from '@/components/forms'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import DependentFieldsAutocomplete from '@/components/project-settings/field-rules/DependentFieldsAutocomplete'
import PropertyField from '@/components/project-settings/params-schema-property-fields/PropertyField'
import MultiSelectWorkflowStatesAndList from './MultiWorkflowStateSelectAndList'

type CommonRuleConfigurationProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propertiesToRender: [string, any][]
  ruleType: ProjectGridFieldRuleType | ProjectDocumentRuleType
  fieldId?: string
  nonGridFields?: ProjectGridField[] // RuleSettings always passes this prop, but AddRuleDialog does not
}

export default function CommonRuleConfiguration({
  propertiesToRender,
  ruleType,
  fieldId,
  nonGridFields,
}: CommonRuleConfigurationProps) {
  const isSuperUser = useIsSuperUser()
  const [demoMode] = useDemoModeContext()

  return (
    <>
      {ruleType.uses_dependent_fields && (
        <DependentFieldsAutocomplete
          nonGridFields={nonGridFields}
          ruleType={ruleType}
          fieldId={fieldId}
        />
      )}
      {propertiesToRender.map(([propertyId, property]) => {
        const required = ruleType.params_schema.required?.includes(propertyId)
        return (
          <PropertyField
            key={`${ruleType.id}-${propertyId}`}
            propertyId={propertyId}
            property={property}
            required={required}
          />
        )
      })}
      {isSuperUser &&
        !demoMode &&
        (ruleType.code === 'run_py' || ruleType.code === 'doc_run_py') && (
          // Wrapping in div to "skip" default MUI margins
          <div>
            <FormCheckbox
              name="is_superuser_rule"
              label="Super User Only"
              sx={{
                color: SUPER_USER_ONLY_COLOR,
                '&.Mui-checked': {
                  color: SUPER_USER_ONLY_COLOR,
                },
              }}
            />
          </div>
        )}
      <MultiSelectWorkflowStatesAndList
        formFieldId="workflow_states_ids"
        label="Run in Workflow States"
        emptyListText="This rule will not run unless it is set to run in at least one workflow state."
      />
    </>
  )
}

import { createContext, ReactNode, useContext, useRef, useState } from 'react'

export type DocumentsChangeSetsContextValue = {
  documentsChangeSetIdsRef: React.MutableRefObject<Record<string, Set<string>>>
  documentLastChangeSetSentAt: Record<string, Date>
  setDocumentLastChangeSetSentAt: React.Dispatch<
    React.SetStateAction<Record<string, Date>>
  >
}

const DocumentsChangeSetsContext =
  createContext<DocumentsChangeSetsContextValue>(
    {} as DocumentsChangeSetsContextValue,
  )

export const useDocumentsChangeSetsContext = () =>
  useContext(DocumentsChangeSetsContext)

type DocumentsChangeSetsProviderProps = {
  children: ReactNode
}

export default function DocumentsChangeSetsProvider({
  children,
}: DocumentsChangeSetsProviderProps) {
  const documentsChangeSetIdsRef = useRef<Record<string, Set<string>>>({})

  const [documentLastChangeSetSentAt, setDocumentLastChangeSetSentAt] =
    useState<Record<string, Date>>({})

  return (
    <DocumentsChangeSetsContext.Provider
      value={{
        documentsChangeSetIdsRef,
        documentLastChangeSetSentAt,
        setDocumentLastChangeSetSentAt,
      }}
    >
      {children}
    </DocumentsChangeSetsContext.Provider>
  )
}

import { Stack } from '@mui/material'
import { ReactNode } from 'react'

type TableItemsContainerProps = {
  children: ReactNode
}

export default function TableItemsContainer({
  children,
}: TableItemsContainerProps) {
  return (
    <Stack sx={{ py: 1.5, width: '100%' }} spacing={1.5}>
      {children}
    </Stack>
  )
}

import { useState } from 'react'
import { InfiniteData, QueryKey, useQueryClient } from '@tanstack/react-query'
import { Document, DocumentRow } from '@/types/documents'
import PaginatedResponse from '@/types/paginated-response'
import { WorkflowStateEvent } from '@/types/workflow-state-events'
import useOverlay from '@/hooks/useOverlay'
import useDocumentSet from '@/services/hooks/useDocumentSet'
import { useTriggerWorkflowStateEvent } from '@/service-library/hooks/events'
import { useGetWorkflowStateEvents } from '@/service-library/hooks/workflow-state-events'
import EventButton from '@/components/validation/EventButton'
import DocumentsWithErrorsDialog from '@/components/validation/DocumentsWithErrorsDialog'

type DashboardEventButtonProps = {
  documentIds: string[]
  listQueryKey: QueryKey
  queryBy: 'rows' | 'documents'
  workflowStateId: string
}

export default function DashboardEventButton({
  documentIds,
  listQueryKey,
  queryBy,
  workflowStateId,
}: DashboardEventButtonProps) {
  const [triggeredEvent, setTriggeredEvent] = useState<WorkflowStateEvent>()

  const queryClient = useQueryClient()
  const hasErrorsOverlay = useOverlay()

  const { documents: documentsWithErrors } = useDocumentSet({
    documentIds,
    fieldsOnly: 'id,name',
    flagCodes: ['error', 'warning'],
    enabled: !!documentIds.length,
  })

  const { workflowStateEvents = [] } = useGetWorkflowStateEvents({
    filters: {
      limit: '100',
      workflow_state_id: workflowStateId,
    },
  })
  const visibleWorkflowStateEvents = workflowStateEvents.filter(
    ({ code, allow_manual_trigger }) =>
      code !== 'on_enter' && code !== 'on_exit' && allow_manual_trigger,
  )

  const { triggerWorkflowStateEvent } = useTriggerWorkflowStateEvent({
    onMutate: async (items) => {
      const isSameState = workflowStateEvents.some(
        ({ id }) => id === items[0].workflow_state_event_id,
      )
      if (isSameState) {
        const docIds = items.map(({ document_id }) => document_id)
        await queryClient.cancelQueries({ queryKey: listQueryKey })

        if (queryBy === 'documents') {
          queryClient.setQueryData<InfiniteData<PaginatedResponse<Document>>>(
            listQueryKey,
            (
              old = {
                pages: [],
                pageParams: [],
              },
            ) => {
              const pages = old?.pages.map((page) => {
                return {
                  ...page,
                  results: page.results.filter(
                    (storedDoc) => !docIds.includes(storedDoc.id),
                  ),
                }
              })
              return {
                ...old,
                pages,
              }
            },
          )
        } else {
          queryClient.setQueryData<
            InfiniteData<PaginatedResponse<DocumentRow>>
          >(
            listQueryKey,
            (
              old = {
                pages: [],
                pageParams: [],
              },
            ) => {
              const pages = old?.pages.map((page) => {
                return {
                  ...page,
                  results: page.results.filter(
                    (storedRow) => !docIds.includes(storedRow.document_id),
                  ),
                }
              })
              return {
                ...old,
                pages,
              }
            },
          )
        }
      }
    },
  })

  const hasProblemFlags = !!documentsWithErrors?.length

  function triggerEvent(event: WorkflowStateEvent) {
    triggerWorkflowStateEvent(
      documentIds.map((id) => ({
        workflow_state_event_id: event.id,
        document_id: id,
      })),
    )
  }

  function handleTriggerEvent(event: WorkflowStateEvent) {
    if (
      hasProblemFlags &&
      event.when_document_has_errors === 'trigger_with_warning'
    ) {
      hasErrorsOverlay.open()
      setTriggeredEvent(event)
    } else {
      triggerEvent(event)
    }
  }

  return visibleWorkflowStateEvents.length > 0 ? (
    <>
      <EventButton
        inDashboard
        workflowStateEvents={visibleWorkflowStateEvents}
        disabled={!documentIds.length}
        documentsIds={documentIds}
        documentsHaveErrors={hasProblemFlags}
        onTriggerEvent={handleTriggerEvent}
      />
      <DocumentsWithErrorsDialog
        inDashboard
        overlay={hasErrorsOverlay}
        onContinue={() => {
          triggeredEvent && triggerEvent(triggeredEvent)
          setTriggeredEvent(undefined)
          hasErrorsOverlay.close()
        }}
      />
    </>
  ) : null
}

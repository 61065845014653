import { FormControlLabel, Stack, Typography, Checkbox } from '@mui/material'
import { useDocumentFiltersContext } from '@/components/document-filters/DocumentFiltersProvider'
import { useOrganizationsContext } from '@/components/organizations/OrganizationsProvider'
import { getOrgName } from '@/utils/organization'
import OrganizationsFilterList from './OrganizationsFilterList'

export default function OrganizationsFilter() {
  const { organizations, isFetchingAll } = useOrganizationsContext()

  const filterContext = useDocumentFiltersContext()
  const { documentFilters, handleOnDocumentFilterChange } = filterContext

  const onChangeHandler = (orgId: string, checked: boolean) => {
    handleOnDocumentFilterChange((prev) => {
      if (!prev)
        return {
          flagCodes: [],
          orgIds: [orgId],
          teamIds: [],
          noFlags: false,
          tagIds: [],
        }

      const newFilters = { ...prev }
      if (checked) {
        newFilters.orgIds = [...newFilters.orgIds, orgId]
      } else {
        newFilters.orgIds = newFilters.orgIds.filter((id) => id !== orgId)
      }
      return newFilters
    })
  }

  return (
    documentFilters && (
      <Stack spacing={1}>
        <Typography>Organizations</Typography>
        {organizations.length >= 10 ? (
          <OrganizationsFilterList
            isFetching={isFetchingAll}
            organizations={organizations}
            filterContext={filterContext}
          />
        ) : (
          <Stack spacing={1}>
            <Stack spacing={0.5}>
              {organizations.map((organization) => (
                <FormControlLabel
                  key={organization.id}
                  label={getOrgName(organization)}
                  control={
                    <Checkbox
                      color="neutral"
                      sx={{ padding: 0 }}
                      checked={documentFilters.orgIds.includes(organization.id)}
                      onChange={(event, checked) =>
                        onChangeHandler(organization.id, checked)
                      }
                    />
                  }
                  slotProps={{ typography: { variant: 'body2' } }}
                  sx={{ mx: 0, columnGap: 0.5, width: 'fit-content' }}
                />
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    )
  )
}

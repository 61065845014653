import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import {
  Stack,
  Button,
  Typography,
  Skeleton,
  ListItemText,
  List,
  MenuItem,
} from '@mui/material'
import { DataList, DataListColumn } from '@/types/data-lists'
import { ProjectGridField, ProjectGridFieldType } from '@/types/fields'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import {
  FormAutocomplete,
  FormCheckbox,
  FormSelect,
  FormTextField,
  PixyDocsForm,
} from '@/components/forms'
import PickerListFields from '@/components/project-wizard/PickerListFields'
import { useCreateProjectGridFieldWithDefaults } from '@/service-library/hooks/project-grid-fields'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import { useProjectGridsContext } from './ProjectGridsProvider'
import {
  aggregateFunctionOptions,
  numberFieldTypes,
  validateFieldName,
} from '@/utils/field-utils'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import PaginatedResponse from '@/types/paginated-response'
import { ProjectGrid } from '@/types/projects'
import queryKeys from '@/service-library/query-keys'
import { useGetProjectGridFieldTypes } from '@/service-library/hooks/project-grid-field-types'
import ListUrlField from './ListUrlField'

export type FormValues = {
  name: string
  type: ProjectGridFieldType
  aggregate_function: ProjectGridField['aggregate_function']
  has_category_model: boolean
  params: {
    data_list?: DataList | null
    data_list_columns?: DataListColumn[]
    default_prediction_context_columns?: DataListColumn[]
    list_url?: string | null
  }
}

type AddFieldDialogProps = {
  overlay: OverlayState
  baseGridId: string
  isSettings: boolean
  projectGridFields: ProjectGridField[]
  parentField?: ProjectGridField
}

export default function AddFieldDialog({
  overlay,
  baseGridId,
  isSettings,
  projectGridFields,
  parentField,
}: AddFieldDialogProps) {
  const featureFlags = useFeatureFlagContext()

  const { projectGridFieldTypes = [], isLoading } = useGetProjectGridFieldTypes(
    {
      filters: {
        limit: '1000',
      },
      enabled: overlay.isOpen,
    },
  )
  const fieldNames = useMemo(
    () => projectGridFields.map((field) => field.name.toLowerCase()),
    [projectGridFields],
  )

  const { queryKey } = useProjectGridsContext()
  const queryClient = useQueryClient()
  const { createProjectGridField } = useCreateProjectGridFieldWithDefaults({
    sideEffectQueryKeys: [queryKey, queryKeys.projects.details()],
    onMutate: (newField) => {
      queryClient.setQueryData<InfiniteData<PaginatedResponse<ProjectGrid>>>(
        queryKey,
        (oldData) => {
          const updatedPages = oldData?.pages.map((page) => {
            return {
              ...page,
              results: page.results.map((grid) => {
                if (grid.id === newField.project_grid_id) {
                  return {
                    ...grid,
                    project_grid_fields: [
                      ...grid.project_grid_fields,
                      newField,
                    ],
                  }
                }
                return grid
              }),
            }
          })
          return {
            pageParams: oldData?.pageParams || [],
            pages: updatedPages || [],
          }
        },
      )
    },
    onError: () => {
      showErrorSnackbar('Failed to create field. Please try again later.')
    },
  })

  const filteredFieldTypes = useMemo(() => {
    return projectGridFieldTypes.filter((type) => {
      switch (type.code) {
        case 'category':
          return false
        case 'grid':
          return !parentField
        case 'live-list-picker':
          return featureFlags.live_list_pickers
        default:
          return true
      }
    })
  }, [projectGridFieldTypes, featureFlags.live_list_pickers, parentField])

  const textType = useMemo(
    () =>
      filteredFieldTypes.find(
        (type: ProjectGridFieldType) => type.code === 'text',
      ) as ProjectGridFieldType,
    [filteredFieldTypes],
  )

  const defaultValues = useMemo(
    () => ({
      name: '',
      type: textType || null,
      has_category_model: false,
      aggregate_function: 'none' as const,
      params: {
        data_list: null,
        data_list_columns: [],
        default_prediction_context_columns: [],
        list_url: null,
      },
    }),
    [textType],
  )

  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'onChange', // triggers validation onChange
  })

  const {
    formState: { isDirty, isValid, errors },
    register,
    reset,
    watch,
    setValue,
  } = methods

  const newType = watch('type')

  function handleCreateField(values: FormValues) {
    const fieldParams = {
      data_list_id: values.params.data_list?.id,
      data_list_column_names: values.params.data_list_columns?.map(
        ({ name }) => name,
      ),
      default_prediction_context_columns:
        values.params.default_prediction_context_columns?.map(
          (column) => column.name,
        ),
      list_url: values.params.list_url,
    }
    const newFieldData = {
      ...values,
      params: Object.values(fieldParams).find((value) =>
        Array.isArray(value) ? value.length : value,
      )
        ? fieldParams
        : {},
    }

    createProjectGridField({
      id: generateUuid(),
      project_grid_id: parentField?.sub_project_grid_id || baseGridId,
      alignment: 'left',
      project_grid_field_type_id: newFieldData.type.id,
      required: false,
      needed: 'optional',
      has_category_model: newFieldData.has_category_model,
      name: newFieldData.name,
      params: newFieldData.params,
      sort_order: 1000001,
      input_behavior: 'manual_allowed' as ProjectGridField['input_behavior'],
      is_hidden: false,
      is_user_editable: true,
      metadata: {},
      project_content_category_id: null,
      sub_project_grid_id: null,
      use_region_of_interest: false,
      region_of_interest_bottom: null,
      region_of_interest_left: null,
      region_of_interest_right: null,
      region_of_interest_top: null,
      region_of_interest_page: 1,
      contextual_type: null,
      user_can_change_data_type: true,
      user_can_delete: true,
      field_group_id: null,
      project_grid_field_type: newFieldData.type, // This is just to pass the data for the on mutate update
      category_items: newFieldData.type.code === 'category' ? [] : undefined,
      fields: newFieldData.type.code === 'grid' ? [] : undefined,
      included_grid_fields_ids: [],
      included_grid_fields: [],
      project_grid_field_rules: [],
      aggregate_function: newFieldData.aggregate_function,
      info_type_id: '',
    })
    overlay.close()
  }

  useEffect(() => {
    if (textType) setValue('type', textType)
  }, [setValue, textType])

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, overlay.isOpen, reset, textType])

  useEffect(() => {
    if (newType?.code !== 'picker') {
      methods.setValue('params.data_list', null)
      methods.setValue('params.data_list_columns', [])
    }
    if (newType?.code !== 'live-list-picker') {
      methods.setValue('params.list_url', '')
    }
  }, [newType, methods])

  return (
    <Dialog maxWidth="sm" {...overlay} title="Add Field">
      <PixyDocsForm methods={methods} onSubmit={handleCreateField}>
        <DialogContent>
          <Stack spacing={4}>
            {isLoading ? (
              <Stack spacing={2}>
                <Skeleton variant="rounded" height={30} />
                <Skeleton variant="rounded" height={30} />
                <Skeleton variant="rounded" height={30} />
              </Stack>
            ) : (
              <>
                {isSettings && (
                  <div>
                    <Typography variant="body1">
                      Adding fields to an existing project will not modify
                      existing documents that are already in the Training Batch.{' '}
                      <br />
                      <br /> You will need to:
                    </Typography>
                    <List sx={{ listStyleType: 'disc', pl: 4 }}>
                      <ListItemText sx={{ display: 'list-item' }}>
                        Edit all documents within the training batch
                      </ListItemText>
                      <ListItemText sx={{ display: 'list-item' }}>
                        Identify the new field in each of the documents
                      </ListItemText>
                      <ListItemText sx={{ display: 'list-item' }}>
                        Re-train the model with the edited documents
                      </ListItemText>
                    </List>
                  </div>
                )}
                <FormTextField
                  autoFocus
                  error={!!errors.name}
                  fullWidth
                  label="Name"
                  helperText="Must be unique."
                  required
                  {...register('name', {
                    validate: (value) => validateFieldName(value, fieldNames),
                  })}
                />
                <FormAutocomplete
                  required
                  name="type"
                  label="Data Type"
                  autoHighlight
                  options={filteredFieldTypes}
                  getOptionLabel={(option) => option?.name || ''}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value.id
                  }
                  disableClearable
                />
                {parentField &&
                  numberFieldTypes.includes(newType?.code || '') && (
                    <FormSelect
                      name="aggregate_function"
                      label="Show Aggregate"
                      variant="filled"
                      helperText="If set, shows the result of the selected aggregate function at the bottom of the table."
                    >
                      {aggregateFunctionOptions.map(({ label, value }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </FormSelect>
                  )}

                {newType?.code === 'picker' && (
                  <>
                    <FormCheckbox
                      name="has_category_model"
                      label={
                        <Stack>
                          <Typography>Create Model</Typography>
                          <Typography color="textSecondary" variant="caption">
                            Create a new category model for this field. The
                            model will be available under the Models tab.
                          </Typography>
                        </Stack>
                      }
                      sx={{ mt: -1 }}
                      formLabelSx={{
                        alignItems: 'flex-start',
                        width: 'fit-content',
                      }}
                    />
                    <PickerListFields paramsSchema={newType.params_schema} />
                  </>
                )}

                {newType?.code === 'live-list-picker' && (
                  <ListUrlField paramsSchema={newType.params_schema} />
                )}
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <LoadingButton
            disabled={!isDirty || !isValid}
            variant="contained"
            type="submit"
          >
            Save
          </LoadingButton>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}

import { createContext, ReactNode, useContext } from 'react'
import { ProjectGridField } from '@/types/fields'

const FieldsContext = createContext<ProjectGridField[]>([])

export const useFieldsContext = () => useContext(FieldsContext)

type FieldsProviderProps = {
  fields: ProjectGridField[]
  children: ReactNode
}

export default function FieldsProvider({
  fields,
  children,
}: FieldsProviderProps) {
  return (
    <FieldsContext.Provider value={fields}>{children}</FieldsContext.Provider>
  )
}

import { Dispatch, SetStateAction, useState } from 'react'
import {
  CheckCircleOutline,
  DeleteOutline,
  OpenInFull,
} from '@mui/icons-material'
import { Box, Card, IconButton, Stack } from '@mui/material'
import { DocumentPage } from '@/types/documents'
import { FULL_TILE_WIDTH, THUMBNAIL_TILE_WIDTH } from './SplitDocumentPages'

type ImageCardProps = {
  page: DocumentPage
  pagesToDelete: number[]
  onShowFullScreenImage: () => void
  setPagesToDelete: Dispatch<SetStateAction<number[]>>
  useFullImage?: boolean
}

export default function ImageCard({
  page,
  pagesToDelete,
  onShowFullScreenImage,
  setPagesToDelete,
  useFullImage,
}: ImageCardProps) {
  const [showIcons, setShowIcons] = useState(false)
  const pageIsToBeDeleted = pagesToDelete.includes(page.page_number)
  const tileWidth = useFullImage ? FULL_TILE_WIDTH : THUMBNAIL_TILE_WIDTH

  return (
    /* Display flex so it will get rid of default spacing pixels underneath the image */
    <Card
      sx={{ display: 'flex', position: 'relative' }}
      onMouseEnter={() => {
        !showIcons && setShowIcons(true)
      }}
      onMouseLeave={() => {
        !pageIsToBeDeleted && setShowIcons(false)
      }}
    >
      <Box
        sx={{
          background: 'black',
          width: tileWidth,
          height: useFullImage ? 'calc(60vh + 120px)' : undefined,
          textAlign: 'center',
        }}
      >
        <img
          loading="lazy"
          style={{
            objectFit: 'contain',
            height: '100%',
            width: '100%',
            filter: pageIsToBeDeleted ? 'brightness(60%)' : undefined,
          }}
          src={useFullImage ? page.image_urls.full : page.image_urls.thumbnail}
          onError={(event) => {
            if (useFullImage) return
            const target = event.target as HTMLImageElement
            target.src = page.image_urls.full
            target.onerror = null
          }}
        />
      </Box>
      {showIcons && (
        <Stack
          direction="row"
          sx={{
            position: 'absolute',
            top: 4,
            right: 4,
          }}
        >
          <IconButton
            color="primary"
            onClick={() => {
              onShowFullScreenImage()
              setShowIcons(false)
            }}
          >
            <OpenInFull />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => {
              setPagesToDelete((prev) => {
                return pageIsToBeDeleted
                  ? prev.filter((p) => p !== page.page_number)
                  : [...prev, page.page_number]
              })
            }}
          >
            {pageIsToBeDeleted ? <CheckCircleOutline /> : <DeleteOutline />}
          </IconButton>
        </Stack>
      )}
    </Card>
  )
}

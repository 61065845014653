import { Box, SxProps } from '@mui/material'
import { ReactNode } from 'react'

type PrimaryTextWrapperProps = {
  children: ReactNode
  sx?: SxProps
}

export default function PrimaryTextWrapper({
  children,
  sx,
}: PrimaryTextWrapperProps) {
  return (
    <Box component="span" sx={{ color: 'text.primary', ...sx }}>
      {children}
    </Box>
  )
}

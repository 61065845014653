import { Typography, Button } from '@mui/material'
import { Document } from '@/types/documents'
import { OverlayState } from '@/hooks/useOverlay'
import { useClearDocumentChipsWithDocChange } from '@/hooks/doc-changes-wrapper-hooks'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { useDocumentsChangeSetsContext } from './providers/DocumentsChangeSetsProvider'
import { useDocumentChipsContext } from './providers/DocumentChipsProvider'
import useRelevantWorkflowState from '@/hooks/useRelevantWorkflowState'

type ClearAllChipsDialogProps = {
  document: Document
  overlay: OverlayState
}

export default function ClearAllChipsDialog({
  document,
  overlay,
}: ClearAllChipsDialogProps) {
  const getRelevantWorkflowState = useRelevantWorkflowState()
  const relevantWorkflowState = getRelevantWorkflowState(document)
  const { listQueryKey } = useDocumentChipsContext()
  const documentsChangeSetsContextData = useDocumentsChangeSetsContext()

  const { clearAllChipAssignments } = useClearDocumentChipsWithDocChange({
    documentId: document.id,
    workflowId: relevantWorkflowState?.workflow_id || '',
    workflowStateId: relevantWorkflowState?.id || '',
    chipsListQueryKey: listQueryKey,
    documentsChangeSetsContextData,
    onError: () => {
      showErrorSnackbar('Failed to clear all chips. Please try again later.')
    },
  })

  return (
    <Dialog {...overlay} title="Clear All Chips" maxWidth="xs">
      <DialogContent>
        <Typography>
          You are about to clear all chips assigned to fields for this document.
          Are you sure?
        </Typography>
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            clearAllChipAssignments()
            overlay.close()
          }}
          variant="text"
          color="error"
        >
          Clear All Chips
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import useOverlay from '@/hooks/useOverlay'
import { Organization } from '@/types/organizations'
import { Project } from '@/types/projects'
import { useGetOrganizations } from '@/service-library/hooks/organizations'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import UploadOrgPickerDialog from '@/components/project-tables/UploadOrgPickerDialog'
import useUploadFiles from '@/components/project-tables/useUploadFiles'

type FileUploadContextValue = {
  handleConfirmUploadFiles: (files: FileList, targetOrg: Organization) => void
  handleFilesSelectedToUpload: (files: FileList) => void
  numberToUpload: number
}

const FileUploadContext = createContext<FileUploadContextValue>(
  {} as FileUploadContextValue,
)

export const useFileUploadContext = () => useContext(FileUploadContext)

type FileUploadProviderProps = {
  children: ReactNode
  project: Project
  workflowId?: string
}

export default function FileUploadProvider({
  children,
  project,
  workflowId,
}: FileUploadProviderProps) {
  const orgPickerOverlay = useOverlay()
  const { selectedWorkflow } = useSelectedWorkflowContext()

  const [filesToUpload, setFilesToUpload] = useState<FileList>()
  const { organizations, hasNextPage } = useGetOrganizations({
    filters: {
      self_and_descendants_for_id: project.org_id,
      limit: '1',
    },
  })
  const workflowIdToUse = selectedWorkflow?.id || workflowId

  const { uploadFilesAndSetOrgs, numberToUpload } = useUploadFiles({
    workflowId: workflowIdToUse as string,
  })

  function handleConfirmUploadFiles(files: FileList, targetOrg: Organization) {
    if (orgPickerOverlay.isOpen) orgPickerOverlay.close()
    uploadFilesAndSetOrgs({
      projectId: project.id,
      org: targetOrg,
      files,
    })
  }

  /** This handler is fired by the upload button _and_ by the dropzone. */
  function handleFilesSelectedToUpload(files: FileList) {
    setFilesToUpload(files)
    if (!hasNextPage) {
      handleConfirmUploadFiles(files, organizations[0])
    } else {
      orgPickerOverlay.open()
    }
  }

  const handleBeforeUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (numberToUpload > 0) {
        e.preventDefault()
      }
    },
    [numberToUpload],
  )

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [handleBeforeUnload])

  return (
    <FileUploadContext.Provider
      value={{
        handleConfirmUploadFiles,
        handleFilesSelectedToUpload,
        numberToUpload,
      }}
    >
      {children}
      {workflowIdToUse && (
        <UploadOrgPickerDialog
          overlay={orgPickerOverlay}
          rootTreeOrgId={project.org_id}
          uploadFiles={filesToUpload}
          onConfirm={(targetOrg) =>
            handleConfirmUploadFiles(filesToUpload as FileList, targetOrg)
          }
        />
      )}
    </FileUploadContext.Provider>
  )
}

import { memo } from 'react'
import { Box, Card, Divider, Stack, useTheme } from '@mui/material'
import Bar from './Bar'
import { Namespace } from '@/types/info-types'
import { ProjectModelVersionInfoType } from '@/types/project-models'
import NodeHeader from './NodeHeader'
import TableItemsContainer from './TableItemsContainer'
import TableNodeItem from './TableNodeItem'
import { Handle, Position } from '@xyflow/react'
import PlayPauseButton from './PlayPauseButton'
import {
  ProjectLinkedModel,
  ProjectLinkedModelInfoType,
} from '@/types/project-linked-models'

function TableNamespaceNode({
  id,
  data,
}: {
  id: string
  data: {
    namespaceDetails: {
      namespace: Namespace
      versionInfoTypes: ProjectModelVersionInfoType[]
    }
    flow: 'in' | 'out'
    projectLinkedModelInfoTypes: ProjectLinkedModelInfoType[]
    projectLinkedModel: ProjectLinkedModel
  }
}) {
  const theme = useTheme()
  const {
    namespaceDetails,
    flow,
    projectLinkedModelInfoTypes,
    projectLinkedModel,
  } = data
  return (
    <Card
      sx={{
        textAlign: 'start',
        width: '100%',
      }}
    >
      {flow === 'out' && (
        <Handle
          id={namespaceDetails.namespace.id}
          type="target"
          position={Position.Left}
          style={{
            top: 20,
            transform: `translate(-3px)`,
          }}
        />
      )}

      <Stack direction="row" spacing={1} sx={{ pr: 1 }}>
        <Bar
          color={
            flow === 'in' ? theme.palette.orange.main : theme.palette.blue.main
          }
        />
        <Box sx={{ py: 1 }}>
          <NodeHeader
            topLine="Table"
            bottomLine={namespaceDetails.namespace.name}
          />
        </Box>
      </Stack>

      <Divider />

      <Stack direction="row" sx={{ pr: 1.5 }}>
        <Bar
          color={
            flow === 'in' ? theme.palette.orange.main : theme.palette.blue.main
          }
        />
        <TableItemsContainer>
          {namespaceDetails.versionInfoTypes.map((versionInfoType, index) => {
            return (
              <Stack
                key={versionInfoType.id}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{ width: '100%' }}
              >
                <TableNodeItem
                  key={versionInfoType.id}
                  topLine="Info Type"
                  bottomLine={versionInfoType.info_type.name}
                  handleId={versionInfoType.id}
                  handleType="source"
                  showHandle
                  index={index}
                />

                <PlayPauseButton
                  nodeId={id}
                  projectLinkedModelInfoType={projectLinkedModelInfoTypes.find(
                    (linkedModelInfoType) =>
                      linkedModelInfoType.info_type_id ===
                      versionInfoType.info_type.id,
                  )}
                  projectLinkedModel={projectLinkedModel}
                  versionInfoType={versionInfoType}
                />
              </Stack>
            )
          })}
        </TableItemsContainer>
      </Stack>
    </Card>
  )
}

export default memo(TableNamespaceNode)

import HorizontalTab from '../tabs/HorizontalTab'
import HorizontalTabs from '../tabs/HorizontalTabs'
import { useLocation } from 'react-router-dom'
import { useProjectContext } from '../project-tables/ProjectProvider'
import usePermission from '@/hooks/usePermission'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import { useSelectedWorkflowContext } from '../workflows/SelectedWorkflowProvider'

export default function WorkflowTabs() {
  const { project } = useProjectContext()
  const { selectedWorkflow } = useSelectedWorkflowContext()
  const featureFlags = useFeatureFlagContext()

  const { hasEditingPermission } = usePermission()
  const canEditProject = hasEditingPermission('edit_projects', project.org_id)

  const routes = [
    selectedWorkflow?.project_model_id || featureFlags.project_dashboard
      ? {
          label: 'Dashboard',
          to: 'dashboard',
          keepQueryParams: true,
        }
      : null,
    {
      label: 'Documents',
      to: 'documents',
      keepQueryParams: true,
    },
    {
      label: 'Data',
      to: 'data',
      keepQueryParams: true,
    },
    canEditProject
      ? {
          label: 'Settings',
          to: 'settings/general',
          keepQueryParams: true,
        }
      : null,
  ].filter(Boolean) as {
    label: string
    to: string
    keepQueryParams?: boolean
  }[]

  const { pathname } = useLocation()
  const selectedRouteIndex = routes.findIndex((route) =>
    pathname.includes(`${project.id}/${route.to}`),
  )

  return (
    <HorizontalTabs>
      {routes.map((route, index) => {
        return (
          <HorizontalTab
            key={route.to}
            selected={selectedRouteIndex === index}
            {...route}
          />
        )
      })}
    </HorizontalTabs>
  )
}

import { Fragment, useEffect, useMemo, useState } from 'react'
import { Button, Divider, Skeleton, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { QueryKey } from '@tanstack/react-query'
import { WorkflowState } from '@/types/workflows'
import { Organization } from '@/types/organizations'
import { useUpdateDocsOrgWithDocChange } from '@/hooks/doc-changes-wrapper-hooks'
import { useGetDataLists } from '@/service-library/hooks/data-lists'
import { useGetDataListEntries } from '@/service-library/hooks/data-list-entries'
import { useGetDataListEntryCellValues } from '@/service-library/hooks/data-list-entry-cell-values'
import { useGetOrganization } from '@/service-library/hooks/organizations'
import useAllOrganizations from '@/services/hooks/useAllOrganizations'
import { showErrorSnackbar } from '@/utils/snackbars'
import ListAutocomplete from '@/components/list-autocomplete/ListAutocomplete'
import { useContainerSize } from '@/components/size-provider/SizeProvider'
import { useDocumentsChangeSetsContext } from './providers/DocumentsChangeSetsProvider'
import { ENTRATA_ORG_ID } from './VisibleCardsControl'

type EntrataPropertySelectProps = {
  documentId: string
  documentOrgId: string
  detailQueryKey: QueryKey
  layout: 'right' | 'left' | 'top' | 'bottom'
  workflowState: WorkflowState
}

const PROPERTY_ADDRESS_ROOT_DATA_LIST_ID =
  '0190e07d-312f-6c88-e0af-da4bc5cf115d'

const PROD_PROPERTY_NAMES_ROOT_DATA_LIST_ID =
  '0190e07d-31fb-0d42-0ed1-c00011865c46'

const DEV_PROPERTY_NAMES_ROOT_DATA_LIST_ID =
  '0190dc72-482a-0047-4a8e-f7a70a238ed4'

const addressPartsToDisplay = ['Street Address', 'City', 'State', 'Zipcode']

export default function EntrataPropertySelect({
  documentId,
  documentOrgId,
  detailQueryKey,
  layout,
  workflowState,
}: EntrataPropertySelectProps) {
  const { width } = useContainerSize()
  const isProd = import.meta.env.VITE_ENVIRONMENT === 'prod'

  const orgIsAcceptable = documentOrgId !== ENTRATA_ORG_ID
  const { organization: documentOrg, isLoading: orgIsLoading } =
    useGetOrganization({
      id: documentOrgId,
      enabled: orgIsAcceptable,
    })

  const { organizations, isFetchingAll } = useAllOrganizations({
    filters: {
      parent_org_id:
        documentOrg?.parent_org_id !== ENTRATA_ORG_ID
          ? documentOrg?.parent_org_id || ''
          : documentOrgId,
    },
    enabled: !!documentOrg,
  })

  const documentProperty = useMemo(
    () =>
      documentOrg?.parent_org_id === ENTRATA_ORG_ID
        ? null
        : documentOrg || null,
    [documentOrg],
  )

  // Stateful so it can still show in the Autocomplete even when documentOrg hasn't fetched yet
  const [selectedProperty, setSelectedProperty] = useState<Organization | null>(
    documentProperty,
  )
  const documentsChangeSetsContextData = useDocumentsChangeSetsContext()

  const propertyNamesListId = isProd
    ? PROD_PROPERTY_NAMES_ROOT_DATA_LIST_ID
    : DEV_PROPERTY_NAMES_ROOT_DATA_LIST_ID

  const { dataLists, isLoading: listsIsLoading } = useGetDataLists({
    filters: {
      parent_data_list_id__in: [
        PROPERTY_ADDRESS_ROOT_DATA_LIST_ID,
        propertyNamesListId,
      ].join(','),
      org_id: selectedProperty?.id,
      fields__only: 'id,parent_data_list_id,parent_list_columns',
    },
    enabled: !!documentProperty,
  })

  const listParentIdMap = useMemo(
    () =>
      dataLists.reduce<Record<string, string>>((acc, list) => {
        acc[list.parent_data_list_id as string] = list.id
        return acc
      }, {}),
    [dataLists],
  )

  const columnsMap = useMemo(
    () =>
      dataLists.reduce<Record<string, string>>((acc, list) => {
        list.parent_list_columns?.forEach((column) => {
          acc[column.id] = column.name
        })
        return acc
      }, {}),
    [dataLists],
  )

  const addressesListId = listParentIdMap[PROPERTY_ADDRESS_ROOT_DATA_LIST_ID]
  const namesListId = listParentIdMap[propertyNamesListId]

  const { dataListEntries: addressEntries, isLoading: addressesIsLoading } =
    useGetDataListEntries({
      filters: {
        data_list_id: addressesListId,
        fields__only: 'id,data_list_entry_cells',
        data_list_entry_cells__fields__only:
          'id,data_list_entry_cell_values,data_list_column_id',
        data_list_entry_cells__data_list_entry_cell_values__fields__only:
          'id,value',
      },
      enabled: !!addressesListId,
    })

  const { dataListEntryCellValues: nameValues, isLoading: namesIsLoading } =
    useGetDataListEntryCellValues({
      filters: {
        data_list_entry_cell__data_list_entry__data_list_id: namesListId,
        data_list_entry_cell__data_list_column__name: 'Property Name',
        fields__only: 'id,value',
      },
      enabled: !!namesListId,
    })

  const addressInfoIsLoading =
    (orgIsAcceptable && orgIsLoading) ||
    (documentProperty && listsIsLoading) ||
    (addressesListId && addressesIsLoading)

  const nameInfoIsLoading =
    (orgIsAcceptable && orgIsLoading) ||
    (documentProperty && listsIsLoading) ||
    (namesListId && namesIsLoading)

  const { updateDocumentsOrg } = useUpdateDocsOrgWithDocChange({
    detailQueryKey,
    documentsChangeSetsContextData,
    onError: () => {
      showErrorSnackbar('Failed to update document organization.')
    },
  })

  const displayInOneColumn = width < 600

  const noAliasesText = selectedProperty
    ? 'No aliases were found for this property.'
    : 'Select a property to see its aliases.'

  // List of value objects using column name as key + id to use as key
  const addressValues = useMemo(() => {
    return addressEntries.map((entry) => {
      return entry.data_list_entry_cells.reduce<Record<string, string>>(
        (acc, cell) => {
          const columnName = columnsMap[cell.data_list_column_id]
          if (!columnName || !addressPartsToDisplay.includes(columnName))
            return acc
          acc[columnsMap[cell.data_list_column_id]] =
            cell.data_list_entry_cell_values[0].value
          return acc
        },
        { id: entry.id },
      )
    })
  }, [addressEntries, columnsMap])

  useEffect(() => {
    setSelectedProperty((prev) =>
      prev?.id === documentOrgId ? prev : documentProperty,
    )
  }, [documentProperty, documentOrgId])

  return (
    <Stack spacing={3}>
      <Grid container spacing={2}>
        <Grid size={displayInOneColumn ? 12 : 6}>
          <ListAutocomplete
            label="Property"
            loading={isFetchingAll}
            options={organizations}
            selected={selectedProperty}
            setSelected={(newOrganization) => {
              if (newOrganization.id === documentOrgId) return
              setSelectedProperty(newOrganization)
              updateDocumentsOrg(
                [
                  {
                    doc_id: documentId,
                    workflow_id: workflowState.workflow_id,
                    workflow_state_id: workflowState.id,
                  },
                ],
                newOrganization.id,
              )
            }}
          />
        </Grid>
        <Grid size={displayInOneColumn ? 12 : 6}>
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" color="text.secondary">
              Entrata Property Id:
            </Typography>
            <Typography
              variant="body2"
              color={selectedProperty ? undefined : 'text.secondary'}
            >
              {selectedProperty?.code || 'None'}
            </Typography>
          </Stack>
        </Grid>

        {/* Name Aliases */}
        <Grid size={displayInOneColumn ? 12 : 6}>
          <Typography variant="body2" color="text.secondary">
            Name Aliases
          </Typography>
          {nameInfoIsLoading && <Skeleton height={20} width={225} />}
          {!nameInfoIsLoading && !nameValues.length && (
            <Typography variant="body2" color={'text.secondary'}>
              {noAliasesText}
            </Typography>
          )}
          {nameValues.map((nameValue) => (
            <Typography key={nameValue.id} variant="body2">
              {nameValue.value}
            </Typography>
          ))}
        </Grid>

        {/* Address Aliases */}
        <Grid size={displayInOneColumn ? 12 : 6}>
          <Typography variant="body2" color="text.secondary">
            Address Aliases
          </Typography>
          {addressInfoIsLoading && <Skeleton height={20} width={200} />}
          {!addressInfoIsLoading && !addressValues.length && (
            <Typography variant="body2" color={'text.secondary'}>
              {noAliasesText}
            </Typography>
          )}
          <Stack spacing={1}>
            {addressValues.map((addressValue) => (
              <Typography variant="body2" key={addressValue.id}>
                {addressPartsToDisplay.map((part, index) => (
                  <Fragment key={part}>
                    {index === 1 && <br />}
                    {addressValue[part]}
                    {!!index &&
                      index < addressPartsToDisplay.length - 1 &&
                      ', '}
                  </Fragment>
                ))}
              </Typography>
            ))}
          </Stack>
        </Grid>
      </Grid>
      {selectedProperty && (
        <Button
          variant="text"
          sx={{ width: 'fit-content', fontSize: 12, ml: '-5px !important' }}
          onClick={() => {
            updateDocumentsOrg(
              [
                {
                  doc_id: documentId,
                  workflow_id: workflowState.workflow_id,
                  workflow_state_id: workflowState.id,
                },
              ],
              selectedProperty.parent_org_id as string,
            )
          }}
        >
          Reset to Client
        </Button>
      )}
      {['top', 'bottom'].includes(layout) && <Divider sx={{ mt: 3 }} />}
    </Stack>
  )
}

import { Dispatch, SetStateAction } from 'react'
import { HelpOutline } from '@mui/icons-material'
import { Box, Stack, Tooltip } from '@mui/material'
import { Organization } from '@/types/organizations'
import OrganizationPickerWithDialog from '@/components/organization-select/OrganizationPickerWithDialog'
import { useOrganizationsContext } from '@/components/organizations/OrganizationsProvider'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import DateRangeFilter from './DateRangeFilter'
import { DateRange } from './ProjectMetricsDashboard'

type MetricFiltersBarProps = {
  selectedOrganization: Organization
  setSelectedOrganization: Dispatch<SetStateAction<Organization>>
  selectedDateRange: DateRange
  setSelectedDateRange: Dispatch<SetStateAction<DateRange>>
}

export default function MetricFiltersBar({
  selectedOrganization,
  setSelectedOrganization,
  selectedDateRange,
  setSelectedDateRange,
}: MetricFiltersBarProps) {
  const { rootOrganization } = useRootOrganization()
  const { organizations, isFetching } = useOrganizationsContext()
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box>
        <OrganizationPickerWithDialog
          currentOrganization={selectedOrganization}
          isFetching={isFetching}
          organizations={organizations}
          rootTreeOrgId={rootOrganization.id}
          title="Select an Organization"
          onSave={(organization) => {
            setSelectedOrganization(organization)
          }}
          variant="outlined"
          showRecentOrgs={false}
        />
      </Box>
      <DateRangeFilter
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
      />

      <Tooltip title="Use these filters to narrow the data to a specific time range and an organization. The organization's descendants will be included in the data.">
        <HelpOutline sx={{ color: 'text.secondary' }} />
      </Tooltip>
    </Stack>
  )
}

import { memo } from 'react'
import { Box, Skeleton, useTheme } from '@mui/material'
import { ThemeColor } from '@/theme/usePixydocsTheme'

type PercentageBarProps = {
  percentage?: number
  color: ThemeColor
}

const HEIGHT = 28
const BORDER_RADIUS = 20

function PercentageBar({ percentage, color }: PercentageBarProps) {
  const theme = useTheme()
  if (percentage === undefined) {
    return <Skeleton height={35} width="100%" />
  }

  return (
    <Box
      sx={{
        background: `radial-gradient(circle at 0%, ${color} ${
          percentage === 100 ? percentage : (percentage || 0) - 4
        }%, ${
          theme.palette.grey[theme.palette.mode === 'dark' ? 900 : 400]
        } ${percentage}%)`,
        borderRadius: BORDER_RADIUS,
        height: HEIGHT,
      }}
    >
      <Box
        sx={{
          height: HEIGHT,
          width: `${percentage}%`,
          borderRadius: percentage === 100 ? BORDER_RADIUS : '20px 0 0 20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: percentage < 5 ? 'start' : 'end',
          px: 1,
        }}
      >
        <b>{percentage}%</b>
      </Box>
    </Box>
  )
}

export default memo(PercentageBar)

import { ReactNode, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Button, Box, TextField, Typography, Alert } from '@mui/material'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { OverlayState } from '@/hooks/useOverlay'

type DeleteConfirmDialogProps = {
  /** Title shown on the dialog. */
  title: string

  /** The text that must be typed by the user before they can hit delete. */
  textToMatch: string

  /** Callback fired when delete is clicked. */
  onDelete: () => void

  /** Overlay state. */
  overlay: OverlayState

  /** Additional JSX to display above the input. */
  children: ReactNode

  /** Boolean to hide alert. */
  hideAlert?: boolean

  /** Boolean to be used when using a LoadingButton */
  isDeleting?: boolean
}

export default function DeleteConfirmDialog({
  title,
  textToMatch,
  onDelete,
  overlay,
  children,
  hideAlert = false,
  isDeleting = false,
}: DeleteConfirmDialogProps) {
  const [value, setValue] = useState('')
  const isMatching = value === textToMatch

  const onClose = () => {
    overlay.close()
    setValue('')
  }

  return (
    <Dialog {...overlay} title={title} onClose={onClose}>
      <DialogContent>
        {!hideAlert && (
          <Alert severity="error" variant="filled" sx={{ mb: 2 }}>
            <Typography>
              This is a destructive action. This action <b>CANNOT</b> be undone.
            </Typography>
          </Alert>
        )}
        <Box sx={{ mt: 1, mb: 2 }}>{children}</Box>
        <Typography sx={{ mt: 1, mb: 2 }}>
          Please type &quot;
          <b style={{ whiteSpace: 'pre-wrap' }}>{textToMatch}</b>&quot; to
          confirm and select <b>DELETE</b>.
        </Typography>
        <TextField
          autoFocus
          value={value}
          onChange={({ target }) => setValue(target.value)}
          error={isMatching ? false : true}
          sx={{
            mt: 1,
            mb: 1,
            width: '100%',
            input: {
              pt: 1.5,
            },
          }}
        />
      </DialogContent>
      <DialogFooter>
        <Button variant="text" onClick={onClose}>
          CANCEL
        </Button>
        <LoadingButton
          color="error"
          disabled={!isMatching}
          variant="contained"
          onClick={() => {
            onDelete()
            onClose()
          }}
          loading={isDeleting}
        >
          DELETE
        </LoadingButton>
      </DialogFooter>
    </Dialog>
  )
}

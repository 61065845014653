import { WorkflowState } from '@/types/workflows'

export function getWorkflowStatesByType(workflowStates: WorkflowState[]) {
  return workflowStates.reduce<Record<string, WorkflowState[]>>(
    (acc, state) => {
      if (!state.workflow_state_type) {
        acc['unassigned'] ||= []
        acc['unassigned'].push(state)
      } else {
        acc[state.workflow_state_type.code] ||= []
        acc[state.workflow_state_type.code].push(state)
      }
      return acc
    },
    {},
  )
}

export function getWorkflowStateGroupsBySortOrder(
  workflowStates: WorkflowState[],
) {
  const statesByType = getWorkflowStatesByType(workflowStates)
  return Object.values(statesByType).sort((a, b) => {
    return a[0].sort_order - b[0].sort_order
  })
}

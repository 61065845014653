import { Skeleton, Typography } from '@mui/material'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { useGetFlagTypes } from '@/service-library/hooks/flag-types'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import PrimaryTextWrapper from './PrimaryTextWrapper'

type RemoveFlagsOfSpecificTypeDisplayProps = {
  action: WorkflowStateAction
}

export default function RemoveFlagsOfSpecificTypeDisplay({
  action,
}: RemoveFlagsOfSpecificTypeDisplayProps) {
  const { fieldsMap } = useProjectContext()

  const { flagTypes, isLoading: isLoadingFlagTypes } = useGetFlagTypes({
    filters: {
      id__in: action.params.flag_type_ids.join(),
    },
  })
  if (!flagTypes.length) return null
  const fieldId = action.params.field_id

  return (
    <div>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
        Removes flags {flagTypes.length > 1 ? 'of these types' : 'of this type'}{' '}
        from the{' '}
        {fieldId ? (
          <>
            <PrimaryTextWrapper>{fieldsMap[fieldId].name}</PrimaryTextWrapper>{' '}
            field
          </>
        ) : (
          'document'
        )}
        :
      </Typography>
      <Typography variant="body2">
        <b>Flag Type{flagTypes.length > 1 ? 's' : ''}: </b>
      </Typography>
      {isLoadingFlagTypes ? (
        <Skeleton width={150} />
      ) : (
        flagTypes.map((flagType) => (
          <Typography key={flagType.id} variant="body2">
            {flagType?.short_description}{' '}
          </Typography>
        ))
      )}
    </div>
  )
}

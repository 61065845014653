import { Box, Stack, Skeleton, SkeletonProps } from '@mui/material'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'

export function OrgTreeSkeleton(props?: SkeletonProps) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <SimpleTreeView
        slots={{
          collapseIcon: Skeleton,
          expandIcon: Skeleton,
        }}
        slotProps={{
          collapseIcon: { width: 30, height: 30 },
          expandIcon: { width: 30, height: 30 },
        }}
        expandedItems={['1', '4']}
      >
        <TreeItem itemId="1" label={<Skeleton width="100%" {...props} />}>
          <TreeItem itemId="2" label={<Skeleton width="100%" {...props} />} />
          <TreeItem itemId="3" label={<Skeleton width="100%" {...props} />} />
          <TreeItem itemId="4" label={<Skeleton width="100%" {...props} />}>
            <TreeItem
              itemId="10"
              label={<Skeleton width="100%" {...props} />}
            />
            <TreeItem
              itemId="11"
              label={<Skeleton width="100%" {...props} />}
            />
          </TreeItem>
          <TreeItem itemId="5" label={<Skeleton width="100%" {...props} />} />
          <TreeItem itemId="6" label={<Skeleton width="100%" {...props} />} />
          <TreeItem itemId="7" label={<Skeleton width="100%" {...props} />} />
          <TreeItem itemId="8" label={<Skeleton width="100%" {...props} />} />
        </TreeItem>
      </SimpleTreeView>{' '}
    </Box>
  )
}

export default function OrganizationTreeSearchSkeleton() {
  const commonProps = {
    animation: 'pulse' as SkeletonProps['animation'],
    height: 30,
  }
  return (
    <Stack direction="row" spacing={2} sx={{ flexGrow: 1, minHeight: '300px' }}>
      <Stack sx={{ width: '300px' }}>
        <Skeleton animation="pulse" height={70} sx={{ mt: -1.25 }} />
      </Stack>
      <OrgTreeSkeleton {...commonProps} />
    </Stack>
  )
}

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Button, Card, Stack, Typography } from '@mui/material'
import {
  CustomVariable,
  CustomVariableCategory,
} from '@/types/custom-variables'
import { Organization } from '@/types/organizations'
import useAllOrganizations from '@/services/hooks/useAllOrganizations'
import { useGetOrganization } from '@/service-library/hooks/organizations'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import CustomVariablesPerOrg from './CustomVariablesPerOrg'
import useOverlay from '@/hooks/useOverlay'
import LargeHeading from '../large-heading/LargeHeading'
import SelectOrgDialog from './SelectOrgDialog'

type OrgWithVariableData = Organization & {
  custom_variables: CustomVariable[]
  custom_variable_categories: CustomVariableCategory[]
}

export default function CustomVariableValues() {
  const { '*': id } = useParams()

  const organizationSelectOverlay = useOverlay()

  const { rootOrganization } = useRootOrganization()
  const { organization } = useGetOrganization({
    id: rootOrganization.id,
    filters: {
      fields__include: 'custom_variables,custom_variable_categories',
    },
  })

  const { organizations, isFetchingAll } = useAllOrganizations({
    filters: {
      self_and_descendants_for_id: rootOrganization.id,
    },
  })

  const rootOrganizationWithVariables = organization
    ? (organization as OrgWithVariableData)
    : undefined

  const selectedOrg = organizations.find((org) => org.id === id)

  const categoriesWithVariables =
    rootOrganizationWithVariables?.custom_variable_categories.map(
      (category) => {
        const variables = rootOrganizationWithVariables.custom_variables
          .filter((variable) => variable.category_id === category.id)
          .sort((a, b) => a.name.localeCompare(b.name))
        return {
          ...category,
          variables,
        }
      },
    )

  const selectedOrganization = organizations.find(
    (org) => org.id === id,
  ) as Organization

  const [selectedOrgId, setSelectedOrgId] = useState(id as string)

  useEffect(() => {
    setSelectedOrgId(id as string)
  }, [id])

  if (!selectedOrganization) return null

  return (
    <Stack spacing={2} sx={{ mt: 2, pb: 4 }}>
      <SelectOrgDialog
        overlay={organizationSelectOverlay}
        selectedOrgId={selectedOrgId}
        setSelectedOrgId={setSelectedOrgId}
        organizations={organizations}
        selectedOrganization={selectedOrganization}
        isFetchingAll={isFetchingAll}
      />

      <Card
        sx={{
          position: 'sticky',
          top: 0,
          background: (theme) => theme.palette.background.paper,
          zIndex: 1,
          p: 2,
        }}
      >
        <LargeHeading
          heading={selectedOrganization.name}
          subHeading={
            selectedOrganization.parent_org_id
              ? 'Organization'
              : 'Root Organization'
          }
          actions={[
            <Button
              variant="outlined"
              onClick={organizationSelectOverlay.open}
              key="select-org"
            >
              Change Organization
            </Button>,
          ]}
        />
      </Card>

      <Box>
        <Typography variant="h6">Custom Variables</Typography>
        <Typography variant="body2" color="text.secondary">
          These variables can be used in custom python scripts and some actions.
          These values are specific to this organization.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Custom variables are inherited from parent organizations.
        </Typography>
      </Box>

      {id && categoriesWithVariables && (selectedOrg || !isFetchingAll) && (
        <CustomVariablesPerOrg
          categories={categoriesWithVariables}
          organization={selectedOrg}
        />
      )}
    </Stack>
  )
}

import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { useGetProjectTags } from '@/service-library/hooks/project-tags'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import TagChip from '@/components/tags/TagChip'

type AddProjectTagDisplayProps = {
  action: WorkflowStateAction
}

export default function AddProjectTagDisplay({
  action,
}: AddProjectTagDisplayProps) {
  const { projectTags, isLoading } = useGetProjectTags({
    filters: {
      id__in: action.params.project_tag_ids.join(),
    },
  })

  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary">
        Add {projectTags.length > 1 ? 'these tags' : 'this tag'} to the
        document:
      </Typography>
      <Stack spacing={1}>
        {isLoading ? (
          <Skeleton variant="rounded" width={50} height={20} />
        ) : (
          projectTags.map((projectTag) => (
            <Box key={projectTag.id} sx={{ display: 'flex' }}>
              <TagChip tag={projectTag} />
            </Box>
          ))
        )}
      </Stack>
    </Stack>
  )
}

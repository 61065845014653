import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Search } from '@mui/icons-material'
import {
  Alert,
  Box,
  DialogContent,
  LinearProgress,
  Stack,
  TextField,
} from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { useGetDocument } from '@/service-library/hooks/documents'
import { Dialog } from '@/components/dialog'
import useGetDocumentWorkflowAndState from '@/hooks/useGetDocumentWorkflowAndState'
import RecentDocumentsList from './RecentDocumentsList'
import useRecentDocuments from './useRecentDocumentIds'

type CommandPaletteDialogProps = {
  overlay: OverlayState
}

export default function CommandPaletteDialog({
  overlay,
}: CommandPaletteDialogProps) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const currentlyViewedDocumentId = searchParams.get('document_id')

  const [docId, setDocId] = useState('')
  const [enabled, setEnabled] = useState(false)

  const [, setRecentDocumentIds] = useRecentDocuments()

  const { document, isFetching, isError } = useGetDocument({
    id: docId,
    enabled,
    retry: 2,
    filters: {
      fields__only: 'id,project_id,workflow_states_ids',
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const { workflowId, workflowStateId } = useGetDocumentWorkflowAndState({
    document,
    enabled,
  })

  useEffect(() => {
    if (enabled && document && workflowId && workflowStateId) {
      navigate(
        `/projects/${document.project_id}/documents?workflow=${workflowId}&workflow_state=${workflowStateId}&document_id=${document.id}`,
      )
      overlay.close()
    }
  }, [document, enabled, navigate, overlay, workflowId, workflowStateId])

  useEffect(() => {
    if (!currentlyViewedDocumentId) return
    setRecentDocumentIds((prev) => {
      const filtered = prev.filter(
        (docId) => docId !== currentlyViewedDocumentId,
      )
      return [currentlyViewedDocumentId, ...filtered].slice(0, 5)
    })
  }, [currentlyViewedDocumentId, setRecentDocumentIds])

  const fieldRef = useRef<HTMLInputElement>(null)
  const formRef = useRef<HTMLFormElement>(null)

  return (
    <Dialog
      {...overlay}
      title=""
      maxWidth="sm"
      TransitionProps={{
        onExited: () => {
          if (enabled) {
            setEnabled(false)
            setDocId('')
          }
        },
      }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Stack spacing={1}>
          <div>
            <form
              // Wrapping with a form allows submitting by pressing enter
              style={{ flexGrow: 1 }}
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault()
                const newDocId = fieldRef.current?.value || ''
                setDocId(newDocId)
                if (newDocId.trim()) {
                  setEnabled(true)
                }
              }}
            >
              <TextField
                variant="standard"
                fullWidth
                inputRef={fieldRef}
                autoFocus
                InputProps={{
                  startAdornment: <Search />,
                  sx: { columnGap: 1, p: 2, alignItems: 'center' },
                }}
                inputProps={{
                  sx: {
                    p: 0,
                  },
                }}
                placeholder="Go to Document ID"
              />
            </form>
            {isFetching ? <LinearProgress /> : <Box sx={{ height: 4 }} />}
          </div>

          {isError && (
            <Box sx={{ px: 2 }}>
              <Alert severity="error" variant="outlined">
                We were unable to load this document. It may have been removed
                or there may have been an issue while retrieving the data.
                Please try again later.
              </Alert>
            </Box>
          )}

          <RecentDocumentsList
            onClick={(docId) => {
              setDocId(docId)
              setEnabled(true)
            }}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

import { useMemo } from 'react'
import { DocChipBasicData, DocumentChip } from '@/types/documents'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { updateList } from '../request-wrappers'

function getChipData<T extends keyof DocChipBasicData>(
  chip: DocumentChip,
  field: T,
): DocChipBasicData[T] {
  if (chip.overridden_data && field in chip.overridden_data) {
    return chip.overridden_data[field]
  }
  return chip[field]
}

const service = buildService<DocumentChip>({
  pathCategory: 'document_chips',
})

export function useGetDocumentChip({
  id,
  ...options
}: UseGetDetailOptions<DocumentChip>) {
  const query = useGetDetail<DocumentChip>({
    id,
    queryKey: queryKeys.documentChips.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })
  const chip = query.data
  const documentChip = chip
    ? {
        ...chip,
        _handwriting: getChipData(chip, 'handwriting'),
        _confidence: getChipData(chip, 'confidence'),
        _pbox: getChipData(chip, 'pbox'),
        _text: getChipData(chip, 'text'),
        _translations: getChipData(chip, 'translations'),
      }
    : null
  return {
    documentChip,
    ...query,
  }
}

export function useCreateDocumentChip(
  options: UseCreateDetailOptions<DocumentChip> = {},
) {
  const mutation = useCreateDetail<DocumentChip>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createDocumentChip: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateDocumentChip(
  options: UseUpdateDetailOptions<DocumentChip> = {},
) {
  const mutation = useUpdateDetail<DocumentChip>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateDocumentChip: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDocumentChip(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<DocumentChip>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteDocumentChip: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetDocumentChips({
  filters,
  ...options
}: UseGetListOptions<DocumentChip> = {}) {
  const query = useGetList<DocumentChip>({
    filters,
    queryKey: queryKeys.documentChips.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  const documentChips = useMemo(
    () =>
      query.allData.map((chip) => ({
        ...chip,
        _handwriting: getChipData(chip, 'handwriting'),
        _confidence: getChipData(chip, 'confidence'),
        _pbox: getChipData(chip, 'pbox'),
        _text: getChipData(chip, 'text'),
        _translations: getChipData(chip, 'translations'),
      })),
    [query.allData],
  )

  return {
    documentChips,
    ...query,
  }
}

export function useUpdateDocumentChips(
  options: UseUpdateListOptions<DocumentChip> = {},
) {
  const mutation = useUpdateList<DocumentChip>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateDocumentChips: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDocumentChips({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<DocumentChip>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteDocumentChips: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Assign chips to a field
 * Used to update chip field assignments.
 */
export function useUpdateChipAssignments({
  workflowId,
  ...options
}: UseUpdateListOptions<DocumentChip> & { workflowId: string }) {
  const mutation = useUpdateList<DocumentChip>({
    serviceFn: (serviceFnOptions) => {
      return updateList<DocumentChip>({
        url: '/v2/pd/document_chips/assign_to_field',
        ...serviceFnOptions,
        filters: {
          ...serviceFnOptions.filters,
          workflow_id: workflowId,
        },
      })
    },
    ...options,
  })

  return {
    updateDocumentChips: mutation.mutateAsync,
    ...mutation,
  }
}

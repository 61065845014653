import {
  useCreateDocumentChangeSets,
  UseCreateDocumentChangeSetsOptions,
} from '@/service-library/hooks/doc-changes'
import queryKeys from '@/service-library/query-keys'
import {
  ChangeSet,
  ChipAssignmentChange,
  ValueChange,
} from '@/types/doc-changes'
import {
  Document,
  DocumentChip,
  DocumentRow,
  DocumentRowValue,
} from '@/types/documents'
import PaginatedResponse from '@/types/paginated-response'
import generateUuid from '@/utils/generate-uuid'
import { createDocumentRowValuesForCache } from '@/utils/row-values'
import { useQueryClient, InfiniteData, QueryKey } from '@tanstack/react-query'

type SingleDocumentChangeSetOptions = {
  workflowId: string
  workflowStateId: string
  documentId?: string
}

export function useCreateRowsAtTheBottomWithDocChange({
  documentId,
  workflowId,
  workflowStateId,
  listQueryKey,
  rowValuesListQueryKey,
  onError,
  ...options
}: UseCreateDocumentChangeSetsOptions &
  SingleDocumentChangeSetOptions & {
    rowValuesListQueryKey?: QueryKey
  }) {
  const queryClient = useQueryClient()

  const mutation = useCreateDocumentChangeSets({
    onError: (error, variables, context) => {
      if (listQueryKey) {
        queryClient.invalidateQueries({ queryKey: listQueryKey })
      } else {
        queryClient.invalidateQueries({
          queryKey: queryKeys.documentRows.lists(),
        })
      }

      rowValuesListQueryKey &&
        queryClient.invalidateQueries({ queryKey: rowValuesListQueryKey })

      onError?.(error, variables, context)
    },
    ...options,
  })

  const createDocumentRows = async (
    rows: DocumentRow[],
    fieldIdsForValues: string[],
    rowsQueryKey?: QueryKey,
  ) => {
    if (!documentId) return
    const rowWithValues: DocumentRow[] = []
    const newValues: DocumentRowValue[] = []
    rows.forEach((row) => {
      const values = createDocumentRowValuesForCache(row.id, fieldIdsForValues)
      rowWithValues.push({
        ...row,
        document_row_values: values,
      })
      newValues.push(...values)
    })

    const listQueryKeyToUse = listQueryKey || rowsQueryKey

    if (listQueryKeyToUse) {
      await queryClient.cancelQueries({ queryKey: listQueryKeyToUse })

      queryClient.setQueryData<InfiniteData<PaginatedResponse<DocumentRow>>>(
        listQueryKeyToUse,
        (
          old = {
            pages: [],
            pageParams: [],
          },
        ) => {
          const pages = old?.pages.map((page, index) => {
            if (index !== old?.pages.length - 1) return page
            return {
              ...page,
              results: [...page.results, ...rowWithValues],
            }
          })
          return {
            ...old,
            pages,
          }
        },
      )
    }

    if (rowValuesListQueryKey) {
      await queryClient.cancelQueries({ queryKey: rowValuesListQueryKey })

      queryClient.setQueryData<
        InfiniteData<PaginatedResponse<DocumentRowValue>>
      >(
        rowValuesListQueryKey,
        (
          old = {
            pages: [],
            pageParams: [],
          },
        ) => {
          const pages = old?.pages.map((page, index) => {
            if (index !== old?.pages.length - 1) return page
            return {
              ...page,
              results: [...page.results, ...newValues],
            }
          })
          return {
            ...old,
            pages,
          }
        },
      )
    }

    return mutation.mutateAsync([
      {
        id: generateUuid(),
        doc_id: documentId,
        workflow_id: workflowId,
        workflow_state_id: workflowStateId,
        rows: rows.map(({ id, row_number, project_grid_id }) => ({
          op: 'new',
          row_id: id,
          row_number,
          grid_id: project_grid_id,
        })),
        values: newValues.map(
          ({ id, document_row_id, project_grid_field_id }) => ({
            op: 'new',
            value_id: id,
            row_id: document_row_id,
            field_id: project_grid_field_id,
          }),
        ),
      },
    ])
  }
  return {
    createDocumentRows,
    ...mutation,
  }
}

function updateRowNumbers(items: DocumentRow[], initialRowNumber: number) {
  return items.map((item, index) => ({
    ...item,
    row_number: initialRowNumber + index,
  }))
}

export function useInsertDocumentRowWithDocChange({
  documentId,
  workflowId,
  workflowStateId,
  listQueryKey,
  rowValuesListQueryKey,
  onError,
  ...options
}: UseCreateDocumentChangeSetsOptions &
  SingleDocumentChangeSetOptions & {
    listQueryKey: QueryKey
    rowValuesListQueryKey?: QueryKey
  }) {
  const queryClient = useQueryClient()

  function getCachedRows() {
    // @ts-expect-error -- shush
    const { pages } = queryClient.getQueryData(listQueryKey)
    // We will only ever have one page in this case
    return pages[0].results as DocumentRow[]
  }

  function getUpdatedTrailingRows(
    newItems: DocumentRow[],
    originalRowNumber: number,
  ) {
    const cachedRows = getCachedRows()
    const highestNewRowNumber = newItems[newItems.length - 1].row_number

    const rowsToUpdate = cachedRows.filter(
      (row) =>
        row.row_number >= originalRowNumber &&
        !newItems.find(({ id }) => id === row.id),
    )

    const updatedRows = rowsToUpdate.map((row, index) => {
      return {
        ...row,
        row_number: highestNewRowNumber + index + 1,
      }
    })

    return updatedRows
  }

  const mutation = useCreateDocumentChangeSets({
    onError: (error, variables, context) => {
      if (listQueryKey) {
        queryClient.invalidateQueries({ queryKey: listQueryKey })
      } else {
        queryClient.invalidateQueries({
          queryKey: queryKeys.documentRows.lists(),
        })
      }

      rowValuesListQueryKey &&
        queryClient.invalidateQueries({ queryKey: rowValuesListQueryKey })

      onError?.(error, variables, context)
    },
    ...options,
  })

  const insertDocumentRow = async (
    rows: DocumentRow[],
    fieldIdsForValues: string[],
  ) => {
    if (!documentId) return
    const rowsWithValues: DocumentRow[] = []
    const newValues: DocumentRowValue[] = []
    rows.forEach((row) => {
      const values = createDocumentRowValuesForCache(row.id, fieldIdsForValues)
      rowsWithValues.push({
        ...row,
        document_row_values: values,
      })
      newValues.push(...values)
    })

    await queryClient.cancelQueries({ queryKey: listQueryKey })

    const cached =
      queryClient.getQueryData<InfiniteData<PaginatedResponse<DocumentRow>>>(
        listQueryKey,
      )

    if (!cached) return

    const cachedRows = getCachedRows()
    const highestRowNumber = cachedRows[cachedRows.length - 1].row_number + 1
    const newRows = updateRowNumbers(rowsWithValues, highestRowNumber)
    const trailingItems = getUpdatedTrailingRows(newRows, rows[0].row_number)

    const updatedCacheRows = [
      ...(trailingItems.length
        ? cachedRows.slice(0, -trailingItems.length)
        : cachedRows), // If there are no trailing items, we want to keep all the original items
      ...newRows,
      ...trailingItems,
    ]

    queryClient.setQueryData(listQueryKey, {
      ...cached,
      pages: [{ ...cached.pages[0], results: updatedCacheRows }],
    })

    if (rowValuesListQueryKey) {
      await queryClient.cancelQueries({ queryKey: rowValuesListQueryKey })

      queryClient.setQueryData<
        InfiniteData<PaginatedResponse<DocumentRowValue>>
      >(
        rowValuesListQueryKey,
        (
          old = {
            pages: [],
            pageParams: [],
          },
        ) => {
          const pages = old?.pages.map((page, index) => {
            if (index !== old?.pages.length - 1) return page
            return {
              ...page,
              results: [...page.results, ...newValues],
            }
          })
          return {
            ...old,
            pages,
          }
        },
      )
    }

    return mutation.mutateAsync([
      {
        id: generateUuid(),
        doc_id: documentId,
        workflow_id: workflowId,
        workflow_state_id: workflowStateId,
        rows: [
          ...newRows.map(({ id, row_number, project_grid_id }) => ({
            op: 'new' as const,
            row_id: id,
            row_number,
            grid_id: project_grid_id,
          })),
          ...trailingItems.map(({ id, row_number, project_grid_id }) => ({
            op: 'update' as const,
            row_id: id,
            row_number,
            grid_id: project_grid_id,
          })),
        ],
        values: newValues.map(
          ({ id, document_row_id, project_grid_field_id }) => ({
            op: 'new',
            value_id: id,
            row_id: document_row_id,
            field_id: project_grid_field_id,
          }),
        ),
      },
    ])
  }
  return {
    insertDocumentRow,
    ...mutation,
  }
}

export function useDeleteDocumentRowsWithDocChange({
  documentId,
  workflowId,
  workflowStateId,
  listQueryKey,
  chipsListQueryKey,
  rowValuesListQueryKey,
  onError,
  ...options
}: UseCreateDocumentChangeSetsOptions &
  SingleDocumentChangeSetOptions & {
    chipsListQueryKey?: QueryKey
    rowValuesListQueryKey?: QueryKey
  }) {
  const queryClient = useQueryClient()

  const mutation = useCreateDocumentChangeSets({
    onError: (error, variables, context) => {
      listQueryKey && queryClient.invalidateQueries({ queryKey: listQueryKey })
      rowValuesListQueryKey &&
        queryClient.invalidateQueries({ queryKey: rowValuesListQueryKey })
      chipsListQueryKey &&
        queryClient.invalidateQueries({ queryKey: chipsListQueryKey })

      onError?.(error, variables, context)
    },
    ...options,
  })

  const deleteDocumentRows = async (rowIds: string[]) => {
    if (!documentId) return
    if (listQueryKey) {
      await queryClient.cancelQueries({ queryKey: listQueryKey })
      queryClient.setQueryData<InfiniteData<PaginatedResponse<DocumentRow>>>(
        listQueryKey,
        (
          old = {
            pages: [],
            pageParams: [],
          },
        ) => {
          const pages = old.pages.map((page) => {
            return {
              ...page,
              results: page.results.filter(
                (storedRow) => !rowIds.includes(storedRow.id),
              ),
            }
          })
          return {
            ...old,
            pages,
          }
        },
      )
    }

    if (rowValuesListQueryKey) {
      await queryClient.cancelQueries({ queryKey: rowValuesListQueryKey })
      queryClient.setQueryData<
        InfiniteData<PaginatedResponse<DocumentRowValue>>
      >(
        rowValuesListQueryKey,
        (
          old = {
            pages: [],
            pageParams: [],
          },
        ) => {
          const pages = old?.pages.map((page) => {
            return {
              ...page,
              results: page.results.filter(
                (storedValue) => !rowIds.includes(storedValue.document_row_id),
              ),
            }
          })
          return {
            ...old,
            pages,
          }
        },
      )
    }

    if (chipsListQueryKey) {
      await queryClient.cancelQueries({ queryKey: chipsListQueryKey })

      queryClient.setQueryData<InfiniteData<PaginatedResponse<DocumentChip>>>(
        chipsListQueryKey,
        (
          old = {
            pages: [],
            pageParams: [],
          },
        ) => {
          const pages = old?.pages.map((page) => {
            return {
              ...page,
              results: page.results.map((storedChip) => {
                if (
                  !storedChip.document_row_id ||
                  !rowIds.includes(storedChip.document_row_id)
                )
                  return storedChip
                return {
                  ...storedChip,
                  project_grid_field_id: null,
                  document_row_id: null,
                }
              }),
            }
          })
          return {
            ...old,
            pages,
          }
        },
      )
    }

    return mutation.mutateAsync([
      {
        id: generateUuid(),
        doc_id: documentId,
        workflow_id: workflowId,
        workflow_state_id: workflowStateId,
        rows: rowIds.map((id) => ({
          op: 'delete',
          row_id: id,
        })),
      },
    ])
  }
  return {
    deleteDocumentRows,
    ...mutation,
  }
}

export function useCreateOrUpdateDocumentRowValueWithDocChange({
  documentId,
  workflowId,
  workflowStateId,
  listQueryKey,
  onError,
  ...options
}: UseCreateDocumentChangeSetsOptions & SingleDocumentChangeSetOptions) {
  const queryClient = useQueryClient()

  const mutation = useCreateDocumentChangeSets({
    onError: (error, variables, context) => {
      listQueryKey && queryClient.invalidateQueries({ queryKey: listQueryKey })
      onError?.(error, variables, context)
    },
    ...options,
  })

  const createOrUpdateDocumentRowValue = async (
    rowValue: DocumentRowValue,
    operation: ValueChange['op'],
  ) => {
    if (!documentId) return
    if (listQueryKey) {
      await queryClient.cancelQueries({ queryKey: listQueryKey })

      queryClient.setQueryData<
        InfiniteData<PaginatedResponse<DocumentRowValue>>
      >(
        listQueryKey,
        (
          old = {
            pages: [],
            pageParams: [],
          },
        ) => {
          const pages = old?.pages.map((page, index) => {
            if (operation === 'new') {
              if (index !== old?.pages.length - 1) return page
              return {
                ...page,
                results: [...page.results, { ...rowValue, in_cache: true }],
              }
            }
            return {
              ...page,
              results: page.results.map((storedValue) => {
                if (storedValue.id === rowValue.id) {
                  return rowValue
                }
                return storedValue
              }),
            }
          })
          return {
            ...old,
            pages,
          }
        },
      )
    }

    return mutation.mutateAsync([
      {
        id: generateUuid(),
        doc_id: documentId,
        workflow_id: workflowId,
        workflow_state_id: workflowStateId,
        values: [
          {
            op: rowValue.in_cache ? 'new' : operation,
            row_id: rowValue.document_row_id,
            field_id: rowValue.project_grid_field_id,
            value_id: rowValue.id,
            manual_value: rowValue.manual_value,
            manual_ref_id: rowValue.manual_ref_id,
            manual_value_json: rowValue.manual_value_json,
          },
        ],
      },
    ])
  }
  return {
    createOrUpdateDocumentRowValue,
    ...mutation,
  }
}

export function useUpdateChipAssignmentWithDocChange({
  documentId,
  workflowId,
  workflowStateId,
  listQueryKey,
  valuesListQueryKey,
  onError,
  ...options
}: UseCreateDocumentChangeSetsOptions &
  SingleDocumentChangeSetOptions & {
    valuesListQueryKey?: QueryKey
  }) {
  const queryClient = useQueryClient()

  const mutation = useCreateDocumentChangeSets({
    onError: (error, variables, context) => {
      listQueryKey && queryClient.invalidateQueries({ queryKey: listQueryKey })
      valuesListQueryKey &&
        queryClient.invalidateQueries({ queryKey: valuesListQueryKey })
      onError?.(error, variables, context)
    },
    ...options,
  })

  const updateChipsAssignment = async (
    changes: {
      value?: DocumentRowValue
      updatedChips: DocumentChip[]
      chipsOperation: ChipAssignmentChange['op'][]
    }[],
    valueChangesForReassignedChips: ValueChange[],
  ) => {
    if (!documentId) return
    if (listQueryKey) {
      await queryClient.cancelQueries({ queryKey: listQueryKey })
      const chipsMap = changes
        .flatMap(({ updatedChips }) => updatedChips)
        .reduce<Record<string, DocumentChip>>((acc, chip) => {
          acc[chip.id] = chip
          return acc
        }, {})

      queryClient.setQueryData<InfiniteData<PaginatedResponse<DocumentChip>>>(
        listQueryKey,
        (
          old = {
            pages: [],
            pageParams: [],
          },
        ) => {
          const pages = old?.pages.map((page) => {
            return {
              ...page,
              results: page.results.map((storedChip) => {
                return chipsMap[storedChip.id] || storedChip
              }),
            }
          })
          return {
            ...old,
            pages,
          }
        },
      )
    }
    const newRowValues: DocumentRowValue[] = []
    const changeSetValues = changes.map<ValueChange>(
      ({ value, updatedChips, chipsOperation }) => {
        let changedValue = value
        if (!changedValue) {
          // If we don't have a value, but we have chips, it mens we are assigning them
          // so the updated chips should have row and field ids
          changedValue = createDocumentRowValuesForCache(
            updatedChips[0].document_row_id as string,
            [updatedChips[0].project_grid_field_id as string],
          )[0]
          newRowValues.push(changedValue)
        }

        return {
          op: value && !value.in_cache ? 'update' : 'new',
          value_id: changedValue.id,
          row_id: changedValue.document_row_id,
          field_id: changedValue.project_grid_field_id,
          chips: updatedChips.map((chip, index) => ({
            op: chipsOperation[index],
            chip_id: chip.id,
          })),
        }
      },
    )
    if (newRowValues.length && valuesListQueryKey) {
      await queryClient.cancelQueries({ queryKey: valuesListQueryKey })

      queryClient.setQueryData<
        InfiniteData<PaginatedResponse<DocumentRowValue>>
      >(
        valuesListQueryKey,
        (
          old = {
            pages: [],
            pageParams: [],
          },
        ) => {
          const pages = old?.pages.map((page, index) => {
            if (index !== old?.pages.length - 1) return page
            return {
              ...page,
              results: [...page.results, ...newRowValues],
            }
          })
          return {
            ...old,
            pages,
          }
        },
      )
    }
    return mutation.mutateAsync([
      {
        id: generateUuid(),
        doc_id: documentId,
        workflow_id: workflowId,
        workflow_state_id: workflowStateId,
        values: [...valueChangesForReassignedChips, ...changeSetValues],
      },
    ])
  }
  return {
    updateChipsAssignment,
    ...mutation,
  }
}

export function useUpdateChipOverriddenDataWithDocChange({
  documentId,
  workflowId,
  workflowStateId,
  listQueryKey,
  onError,
  ...options
}: UseCreateDocumentChangeSetsOptions & SingleDocumentChangeSetOptions) {
  const queryClient = useQueryClient()

  const mutation = useCreateDocumentChangeSets({
    onError: (error, variables, context) => {
      listQueryKey && queryClient.invalidateQueries({ queryKey: listQueryKey })
      onError?.(error, variables, context)
    },
    ...options,
  })

  const updateChipOverriddenData = async (
    originalChip: DocumentChip,
    overrideWithChip: DocumentChip,
  ) => {
    if (!documentId) return
    if (listQueryKey) {
      await queryClient.cancelQueries({ queryKey: listQueryKey })
      const updatedChip = {
        ...originalChip,
        overridden_data:
          overrideWithChip.id ===
          originalChip.related_chips_data?.primary_chip_id
            ? null
            : { ...overrideWithChip, primary_chip_id: overrideWithChip.id },
      }

      queryClient.setQueryData<InfiniteData<PaginatedResponse<DocumentChip>>>(
        listQueryKey,
        (
          old = {
            pages: [],
            pageParams: [],
          },
        ) => {
          const pages = old?.pages.map((page) => {
            return {
              ...page,
              results: page.results.map((storedChip) => {
                return storedChip.id === updatedChip.id
                  ? updatedChip
                  : storedChip
              }),
            }
          })
          return {
            ...old,
            pages,
          }
        },
      )
    }

    return mutation.mutateAsync([
      {
        id: generateUuid(),
        doc_id: documentId,
        workflow_id: workflowId,
        workflow_state_id: workflowStateId,
        chips: [
          {
            chip_id: originalChip.id,
            override_with_chip_id: overrideWithChip.id,
          },
        ],
      },
    ])
  }
  return {
    updateChipOverriddenData,
    ...mutation,
  }
}

export function useClearDocumentChipsWithDocChange({
  documentId,
  workflowId,
  workflowStateId,
  chipsListQueryKey,
  onError,
  ...options
}: UseCreateDocumentChangeSetsOptions &
  SingleDocumentChangeSetOptions & {
    chipsListQueryKey?: QueryKey
  }) {
  const queryClient = useQueryClient()

  const mutation = useCreateDocumentChangeSets({
    onError: (error, variables, context) => {
      chipsListQueryKey &&
        queryClient.invalidateQueries({ queryKey: chipsListQueryKey })
      onError?.(error, variables, context)
    },
    ...options,
  })

  const clearAllChipAssignments = async () => {
    if (!documentId) return
    if (chipsListQueryKey) {
      await queryClient.cancelQueries({ queryKey: chipsListQueryKey })

      queryClient.setQueryData<InfiniteData<PaginatedResponse<DocumentChip>>>(
        chipsListQueryKey,
        (
          old = {
            pages: [],
            pageParams: [],
          },
        ) => {
          const pages = old?.pages.map((page) => {
            return {
              ...page,
              results: page.results.map((storedChip) => {
                return {
                  ...storedChip,
                  project_grid_field_id: null,
                  document_row_id: null,
                }
              }),
            }
          })
          return {
            ...old,
            pages,
          }
        },
      )
    }

    return mutation.mutateAsync([
      {
        id: generateUuid(),
        doc_id: documentId,
        workflow_id: workflowId,
        workflow_state_id: workflowStateId,
        clear_all_chips_assignments: true,
      },
    ])
  }
  return {
    clearAllChipAssignments,
    ...mutation,
  }
}

// ===== MARK: Bulk Document Changes

export function useUpdateDocsOrgWithDocChange({
  detailQueryKey,
  documentSetQueryKey,
  listQueryKey,
  queryBy,
  onError,
  ...options
}: UseCreateDocumentChangeSetsOptions & {
  detailQueryKey?: QueryKey
  documentSetQueryKey?: QueryKey
  queryBy?: 'rows' | 'documents'
  rowsQueryKey?: QueryKey
}) {
  const queryClient = useQueryClient()

  const mutation = useCreateDocumentChangeSets({
    onError: (error, variables, context) => {
      detailQueryKey &&
        queryClient.invalidateQueries({ queryKey: detailQueryKey })
      documentSetQueryKey &&
        queryClient.invalidateQueries({ queryKey: documentSetQueryKey })
      listQueryKey && queryClient.invalidateQueries({ queryKey: listQueryKey })
      onError?.(error, variables, context)
    },
    ...options,
  })

  const updateDocumentsOrg = async (
    documentsChanges: Required<
      Pick<ChangeSet, 'doc_id' | 'workflow_id' | 'workflow_state_id'>
    >[],
    orgId: string,
  ) => {
    if (!documentsChanges.length) return

    const documentIds = documentsChanges.map(({ doc_id }) => doc_id)

    if (detailQueryKey && documentsChanges.length === 1) {
      await queryClient.cancelQueries({ queryKey: detailQueryKey })

      queryClient.setQueryData<Document>(detailQueryKey, (prev) => {
        if (!prev) return undefined
        return {
          ...prev,
          owner_org_id: orgId,
        }
      })
    }

    if (documentSetQueryKey) {
      await queryClient.cancelQueries({ queryKey: documentSetQueryKey })

      queryClient.setQueryData<Document[]>(documentSetQueryKey, (old) => {
        const documents = old?.map((storedDoc) => {
          if (documentIds.includes(storedDoc.id)) {
            return {
              ...storedDoc,
              owner_org_id: orgId,
            }
          }
          return storedDoc
        })
        return documents
      })
    }

    if (listQueryKey) {
      await queryClient.cancelQueries({ queryKey: listQueryKey })

      if (queryBy === 'documents') {
        queryClient.setQueryData<InfiniteData<PaginatedResponse<Document>>>(
          listQueryKey,
          (
            old = {
              pages: [],
              pageParams: [],
            },
          ) => {
            const pages = old?.pages.map((page) => {
              return {
                ...page,
                results: page.results.map((storedDoc) => {
                  if (documentIds.includes(storedDoc.id)) {
                    return {
                      ...storedDoc,
                      owner_org_id: orgId,
                    }
                  }
                  return storedDoc
                }),
              }
            })
            return {
              ...old,
              pages,
            }
          },
        )
      } else {
        queryClient.setQueryData<InfiniteData<PaginatedResponse<DocumentRow>>>(
          listQueryKey,
          (
            old = {
              pages: [],
              pageParams: [],
            },
          ) => {
            const pages = old?.pages.map((page) => {
              return {
                ...page,
                results: page.results.map((storedRow) => {
                  if (
                    storedRow.document?.id &&
                    documentIds.includes(storedRow.document.id)
                  ) {
                    return {
                      ...storedRow,
                      document: {
                        ...storedRow.document,
                        owner_org_id: orgId,
                      },
                    }
                  }

                  return storedRow
                }),
              }
            })
            return {
              ...old,
              pages,
            }
          },
        )
      }
    }

    return mutation.mutateAsync(
      documentsChanges.map((change) => ({
        ...change,
        id: generateUuid(),
        owner_org_id: orgId,
      })),
    )
  }
  return {
    updateDocumentsOrg,
    ...mutation,
  }
}

export function useRunAllRulesWithDocChange({
  documentIds,
  workflowId,
  workflowStateId,
  ...options
}: UseCreateDocumentChangeSetsOptions & {
  documentIds: string[]
  workflowId: string
  workflowStateId: string
}) {
  const mutation = useCreateDocumentChangeSets({
    ...options,
  })

  const runRules = (forceClearCache = false) => {
    if (!documentIds.length) return

    return mutation.mutateAsync(
      documentIds.map((documentId) => ({
        id: generateUuid(),
        doc_id: documentId,
        workflow_id: workflowId,
        workflow_state_id: workflowStateId,
        options: { force_clear_cache: forceClearCache },
      })),
    )
  }
  return {
    runRules,
    ...mutation,
  }
}

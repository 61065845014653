import { useState, Dispatch, SetStateAction, useEffect } from 'react'
import { Button, MenuItem, TextField } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { exportOriginalDocuments } from '@/services/export'
import useExportDocuments from '@/services/hooks/useExportDocuments'
import { showErrorSnackbar, showInfoSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import { useSelectedWorkflowStateContext } from './SelectedWorkflowStateProvider'

type DownloadOriginalDocumentsDialogProps = {
  overlay: OverlayState
  documentIds: string[]
  setSelectedRows: Dispatch<SetStateAction<Record<string, boolean>>>
}

export default function DownloadOriginalDocumentsDialog({
  overlay,
  documentIds,
  setSelectedRows,
}: DownloadOriginalDocumentsDialogProps) {
  const { project } = useProjectContext()
  const { selectedWorkflowState } = useSelectedWorkflowStateContext()

  const [exportType, setExportType] = useState<'csv' | 'json' | 'none'>('none') // using none, so text is displayed in input field

  const { isLoading, allFieldsIds, csvMappingId } = useExportDocuments({
    projectId: project.id,
    enabled: exportType !== 'none',
  })

  const handleTypeChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setExportType(e.target.value as typeof exportType)
  }

  function exportDocuments() {
    overlay.close()
    showInfoSnackbar('Preparing Download...')
    exportOriginalDocuments({
      document_ids: documentIds,
      document_type_to_add: exportType === 'none' ? undefined : exportType,
      project_id: project.id,
      project_grid_field_ids: allFieldsIds,
      workflow_state_id: selectedWorkflowState?.id,
      project_mapping_id: csvMappingId,
    })
      .catch(() => {
        showErrorSnackbar('Failed to download files. Please try again later.')
      })
      .finally(() => {
        setSelectedRows({})
      })
  }

  useEffect(() => {
    setExportType('none')
  }, [overlay.isOpen])

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      {...overlay}
      onClose={overlay.close}
      title={`Download Original Files (${documentIds.length})`}
    >
      <DialogContent>
        <TextField
          select
          variant="filled"
          size="small"
          label="Include Data File"
          helperText="Adds an additional file that contains all data from all included documents."
          value={exportType}
          onChange={handleTypeChange}
          sx={{ width: '100%' }}
        >
          <MenuItem value="none">None</MenuItem>
          <MenuItem value="csv">CSV</MenuItem>
          <MenuItem value="json">JSON</MenuItem>
        </TextField>
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={exportDocuments}
          disabled={exportType !== 'none' && isLoading}
        >
          Download
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

import { useState, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { Delete, Edit } from '@mui/icons-material'
import {
  Link as MUILink,
  Container,
  Button,
  Stack,
  Skeleton,
  Switch,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { ProjectCategory } from '@/types/projects'
import useOverlay from '@/hooks/useOverlay'
import { useGetOrganization } from '@/service-library/hooks/organizations'
import {
  useDeleteProject,
  useUpdateProject,
} from '@/service-library/hooks/projects'
import queryKeys from '@/service-library/query-keys'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import CopyIDButton from '@/components/copy-id-button/CopyIDButton'
import LabeledData from '@/components/labeled-data/LabeledData'
import LargeHeading from '@/components/large-heading/LargeHeading'
import ProjectBreadcrumb from '@/components/project-tables/ProjectBreadcrumb'
import ProjectCategoryAutocomplete from '@/components/projects/ProjectCategoryAutocomplete'
import { useProjectContext } from '@/components/project-tables/ProjectProvider'
import DeleteProjectDialog from './DeleteProjectDialog'
import EditProjectNameDialog from './EditProjectNameDialog'

export default function ProjectSettings() {
  const { project, queryKey } = useProjectContext()

  const { organization, isLoading: isLoadingOrg } = useGetOrganization({
    id: project.org_id,
  })

  const editOverlay = useOverlay()
  const deleteOverlay = useOverlay()

  const navigate = useNavigate()

  const { updateProject } = useUpdateProject({
    detailQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar('Unable to update project. Please try again later.')
    },
  })

  const { deleteProject } = useDeleteProject({
    sideEffectQueryKeys: [queryKeys.projects.lists()],
    onSuccess: () => {
      showSuccessSnackbar('Project Deleted')
      navigate('/projects')
    },
    onError: () => {
      showErrorSnackbar('Unable to delete project. Please try again later.')
    },
  })

  const onDeleteProject = () => {
    deleteOverlay.close()
    deleteProject(project.id)
  }

  const debouncedUpdateProject = useMemo(
    () => debounce(updateProject, 250),
    [updateProject],
  )

  const [internalCategory, setInternalCategory] = useState(
    project.project_category as ProjectCategory,
  )

  function handleRemoveDupEntities(checked: boolean) {
    debouncedUpdateProject({ ...project, remove_duplicate_entities: checked })
  }

  function handleCategoryChange(category: ProjectCategory) {
    setInternalCategory(category)
    updateProject(
      {
        ...project,
        project_category_id: category.id,
      },
      {
        onError: () =>
          setInternalCategory(project.project_category as ProjectCategory),
      },
    )
  }

  return (
    <Container maxWidth="md" sx={{ height: '100%', py: 1 }}>
      <ProjectBreadcrumb label="Project General" url="../general" />

      <Stack justifyContent="space-between" sx={{ height: '100%' }}>
        <Stack spacing={4}>
          <LargeHeading
            heading={project.name}
            subHeading="Project Settings"
            actions={
              <>
                <Button
                  variant="text"
                  startIcon={<Edit />}
                  onClick={editOverlay.open}
                >
                  Edit
                </Button>
                <Button
                  variant="text"
                  startIcon={<Delete />}
                  color="error"
                  onClick={() => deleteOverlay.open()}
                >
                  Delete
                </Button>
                <CopyIDButton stringToCopy={project.id} />
                <DeleteProjectDialog
                  name={project.name}
                  onDelete={onDeleteProject}
                  overlay={deleteOverlay}
                />
                <EditProjectNameDialog
                  overlay={editOverlay}
                  project={project}
                  updateProject={updateProject}
                />
              </>
            }
          />

          <ProjectCategoryAutocomplete
            selected={internalCategory}
            onCategoryChange={handleCategoryChange}
            sx={{ maxWidth: 350 }}
          />
          {/* Wrapping div to keep this from expanding to full width of page */}
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={project.remove_duplicate_entities}
                  sx={{ mr: 1 }}
                />
              }
              label={
                <>
                  <Typography>Remove Subsequent Duplicate Entities</Typography>
                  <Typography variant="caption">
                    If enabled, any additional predicted values that are likely
                    duplicate entities or unexpected values will be
                    automatically removed. Enable this if seeing chips predicted
                    from multiple locations in the document.
                  </Typography>
                </>
              }
              onChange={(event, checked) => handleRemoveDupEntities(checked)}
            />
          </div>
          {/* Wrapping div to keep this from expanding to full width of page */}
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={project.documents_contain_pii}
                  sx={{ mr: 1 }}
                />
              }
              label={
                <>
                  <Typography>
                    Documents Contain Personally Identifiable Information (PII)
                  </Typography>
                  <Typography variant="caption">
                    Documents uploaded to this project may contain information
                    that can be used to identify an individual.
                  </Typography>
                </>
              }
              onChange={(event, checked) =>
                debouncedUpdateProject({
                  ...project,
                  documents_contain_pii: checked,
                })
              }
            />
          </div>
          {project.org_id && (
            <LabeledData
              label="Parent Organization"
              data={
                <>
                  {isLoadingOrg ? (
                    <Skeleton width={125} />
                  ) : (
                    <MUILink
                      component={Link}
                      underline="hover"
                      to={`/settings/organizations/${organization?.id}`}
                    >
                      {organization?.name}
                    </MUILink>
                  )}
                </>
              }
            />
          )}
        </Stack>
      </Stack>
    </Container>
  )
}
